import React from 'react'
import { store } from 'common/global-store'
import { process } from 'common/process'
import Typography from '@material-ui/core/Typography'
import { MdPerson } from '@react-icons/all-files/md/MdPerson'
import { getActiveUser, getCurrentUserEmail, hasActiveClient, isAnonymous } from 'common/global-store/api'
import { isDebugger } from 'common/debugger'
import { makeCachedStyles } from 'common/inline-styles'

const useStyles = makeCachedStyles(() => {
    return {
        banner: {
            color: 'white',
            display: 'inline-table',
            position: 'fixed',
            left: '50%',
            bottom: 24,
            padding: '10px 15px 14px 15px',
            transform: 'translateX(-50%)',
            zIndex: 1,
            background: '#7EBE8B',
            borderRadius: 5,
            boxShadow: '2px 3px 4px 0 rgba(0,0,0,0.11)',
            '& > p': {
                display: 'inline-block',
            },
        },
        button: {
            cursor: 'pointer',
            color: 'white',
            fontSize: 14,
            letterSpacing: '0.08rem',
            textTransform: 'uppercase',
            border: 0,
            marginLeft: 25,
            backgroundColor: 'rgb(126, 190, 139)',
            '&:focus': {
                outline: '1px white solid',
            },
        },
        icon: {
            transform: 'translateY(25%)',
            marginRight: 15,
        },
    }
})

export function EmulatedBanner() {
    const classes = useStyles()
    const currentUserEmail = getCurrentUserEmail()
    const emulatedEmail = store?.profile$?.profile?.email
    const isEmulated = emulatedEmail && currentUserEmail !== emulatedEmail && emulatedEmail !== ''
    if (isDebugger() || !isEmulated || isAnonymous() || !hasActiveClient()) return null

    return (
        <div className={classes.banner}>
            <Typography>
                <MdPerson size={24} className={classes.icon} />
                You are currently emulating another user: {emulatedEmail}
            </Typography>
            <button
                className={classes.button}
                onClick={async () => {
                    try {
                        await process({ type: 'emulate.stop', realId: getActiveUser() })
                        window.location.reload()
                    } catch (e) {
                        console.error('Unable to remove emulation')
                    }
                }}
            >
                Stop Emulation
            </button>
        </div>
    )
}
