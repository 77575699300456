import React from 'react'

import PropTypes from 'prop-types'
import { makeCachedStyles } from 'common/inline-styles'

//TODO: This will pull in styles from theme provider - probably
const useStyles = makeCachedStyles((__theme) => ({
    h6: {
        fontFamily: 'Montserrat',
        fontWeight: 400,
        fontSize: '1.167em',
        margin: 0,
        padding: 0,
    },
}))

export const H6 = ({ children, ...rest }) => {
    const classes = useStyles()
    return (
        <h6 className={classes.h6} {...rest}>
            {children}
        </h6>
    )
}

H6.propTypes = {
    children: PropTypes.node.isRequired,
    rest: PropTypes.any,
}
