/**
 * @module common/offline-data-service
 */

import './documentation'
import React from 'react'
import { dontWaitForAsyncHandlerAndDefer, handle, raise } from 'common/events'
import { store } from 'common/global-store'
import './behaviour-cache'
import * as InternalEvents from './events'
import * as LocalStorageFacade from './localStorageFacade'
import { dropDb } from './localStorageFacade'
import { menuItems } from 'common/menu'
import { defer } from 'common/deferred'
import { clearAll } from 'common/offline-data-service/utils'
import { Sync } from 'common/offline-data-service/sync-component'
import { getVersionOfId } from 'common/offline-data-service/document-versions'
import { cacheRecords } from 'common/offline-data-service/caching'
import { onlyOne } from 'common/guard'

// let inflight = {}

export const platform = process.env.REACT_APP_PLATFORM
export const mobile = platform === 'ios' || platform === 'android'

store.set({ aweSync: false, aweSyncNumber: 0 })

export const setRecord = InternalEvents.setRecord
export const getRecords = InternalEvents.getRecords
export const getList = InternalEvents.getList
export const getListByIds = InternalEvents.getListIds

/**
 * ONLINE ONLY - retrieves the number of records that match
 * a where clause
 * @function getCount
 * @param {string} database - the database of the table
 * @param {string} table - the table to query
 * @param {WhereDefinition} where - the query to count
 * @returns {Promise<number>} a promise for the number of records
 */
export const getCount = InternalEvents.getCount

/**
 * Prepares a document for database storage, this removes
 * all of the behaviours and replaces them with a reference
 * to the underlying document type that creates them.  It
 * also stores the current state of the document.
 * @param {Document} record - the record to prepare
 * @returns {Document} the record having been modified
 */
export const prepareDocument = InternalEvents.prepareDocument

handle('clear-local-system', clearAll)

const ods = defer()
export let offlineDataStorageReady = ods.promise
handle('initialize-app', async () => {
    try {
        await LocalStorageFacade.initializeOfflineStorage()
        ods.resolve()
    } catch (e) {
        console.error('Could not initialize app')
        ods.reject(e)
    }
})

handle('before.secure-token-ready', dontWaitForAsyncHandlerAndDefer(onlyOne(refreshLocal)))

async function refreshLocal() {
    await ods
    let rows = (
        await LocalStorageFacade.select({
            from: 'records',
        })
    )
        .filter((r) => r && r.data)
        .map('id')
    const localVersions = await Promise.all(
        rows.map(async (row) => ({
            id: row,
            __: await getVersionOfId(row),
        }))
    )
    if (localVersions.some((v) => v.__ === null)) {
        return await dropDb()
    }
    try {
        if (localVersions.length) {
            const retrieved = await InternalEvents.getRecords(localVersions, false)
            if (!retrieved) {
                LocalStorageFacade.clean().catch(console.error)
                return
            }
            await cacheRecords(retrieved)
            for (let record of retrieved) {
                raise(`data.updated.${record._id}`, record._id, record)
            }
        }
    } catch (e) {
        console.error(e)
    }
}

menuItems(() => ({
    sync: {
        priority: -20,
        aboveTheLine: true,
        title: 'Offline Sync',
        bottom: true,
        Component: Sync,
    },
}))
