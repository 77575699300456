import React from 'react'
import { plug } from 'common/widgets'
import useAsync from 'common/use-async'

function Nothing() {
    return null
}

export function makeLazy(fn, extract = 'default', fallback = <div />) {
    return function (props) {
        const Component = useAsync(async () => {
            const module = await fn()
            if (module === null) {
                return () => null
            }
            let extracted
            extracted = module[extract || 'default'] || Nothing

            try {
                if (extracted.bind) {
                    return extracted.bind(this)
                }
            } catch (e) {
                //
                console.error('Could not load', fn.toString())
            }
            return extracted
        }, Blank)

        return Component ? <Component {...{ definition: this, ...props }} /> : fallback
    }
}

export function makeLazyWithoutBinding(fn, extract = 'default', fallback = <div />) {
    return function (props) {
        const Component = useAsync(async () => {
            const module = await fn()
            return module[extract || 'default'] || Nothing
        }, Blank)

        return Component ? <Component {...{ definition: this, ...props }} /> : fallback
    }
}

function Blank() {
    return <div />
}

export function extract(fn, extract) {
    return (...props) => fn(...props).then((f) => ({ ...f, default: f[extract] }))
}

export function lazyPlug(type, fn, extract) {
    return plug(type, makeLazy(fn, extract))
}
