import React from 'react'

import PropTypes from 'prop-types'

import Badge from '@material-ui/core/Badge'
import IconButton from '@material-ui/core/IconButton'
import { withStyles } from '@material-ui/core/styles'
import NotificationsIcon from '@material-ui/icons/Notifications'
import { makeCachedStyles } from 'common/inline-styles'

export const NotificationBadge = withStyles((__theme) => ({
    badge: {
        border: '2px solid white',
        backgroundColor: '#009fda',
        color: 'white',
        fontSize: 9,
        fontFamily: 'Roboto',
    },
}))(Badge)

const useNotificationIconStyles = makeCachedStyles((__theme) => ({
    removeOutline: {
        background: 'rgba(0,0,0,0.1)',
        '&:hover': {
            background: 'rgba(0,0,0,0.2)',
        },
        '&:focus': {
            outline: 'none',
        },
    },
}))

export const NotificationIcon = ({ count, ...rest }) => {
    const classes = useNotificationIconStyles()
    return (
        <IconButton
            aria-label="Notifications"
            color="inherit"
            variant="contained"
            className={classes.removeOutline}
            {...rest}
        >
            <NotificationBadge badgeContent={count} data-testid="notification-count-badge">
                <NotificationsIcon data-testid="notification-count-icon" />
            </NotificationBadge>
        </IconButton>
    )
}

NotificationIcon.propTypes = {
    count: PropTypes.number.isRequired,
}
