import debounce from 'lodash/debounce'
import { getItemAndParseCached, setItemWithStringifyCached } from 'common/using-local-storage-key'
import { store } from 'common/global-store'
import { cacheRecords } from 'common/offline-data-service/caching'
import { handle, raise, raiseLater } from 'common/events'
import constants from 'common/offline-data-service/constants.json'
import * as LocalStorageFacade from 'common/offline-data-service/localStorageFacade'
import { getRecords } from 'common/offline-data-service/index'
import { OfflineFirstRetriever } from 'common/offline-data-service/offlineFirstRetriever'
import { run } from 'js-coroutines'
import events from 'packages/alcumus-local-events'
import { isDatabaseId } from 'common/offline-data-service/utils'
import { getActiveClient } from 'common/global-store/api'

let processing = false

export function getVersions(...ids) {
    return run(retrieveVersion(ids.flat(Infinity)))
}

export const processChanges = debounce(
    async function () {
        if (processing) {
            return
        }
        try {
            processing = true

            let toProcess
            while ((toProcess = Object.keys(getItemAndParseCached('retrieve_ids', {}))).length !== 0) {
                store.set({ aweSync: true })
                let toSync = toProcess.first(100)
                if (toSync.length) {
                    let ids = getItemAndParseCached('retrieve_ids', {})
                    store.set({ aweSyncNumber: Object.keys(ids).length })
                    toSync.forEach((id) => {
                        delete ids[id]
                    })
                    setItemWithStringifyCached('retrieve_ids', ids)
                    const toSendIds = await getVersions(toSync)
                    let records = (await getRecords(toSendIds, false)).filter((r) => r && r.data)
                    await cacheRecords(records)
                    for (let record of records) {
                        raiseLater(`data.updated.${record.id}`, record.id, record.data)
                    }
                }
                //Allow the system some breathing room between sections as
                //this is a background task
                await new Promise((resolve) => setTimeout(resolve, 50))
            }
        } catch (e) {
            setTimeout(processChanges, 1000 * 5)
        } finally {
            store.set({ aweSync: false })
            processing = false
        }
    },
    1000,
    { maxWait: constants.MAX_QUEUE_WAIT }
)

export function retrieveNow() {
    processChanges.flush()
}

export function addRetrieveId(id, urgent = false) {
    let ids = getItemAndParseCached('retrieve_ids', {})
    ids[id] = true
    setItemWithStringifyCached('retrieve_ids', ids)
    processChanges()
    if (urgent) {
        processChanges.flush()
    }
}

export function isRetrieving(id) {
    let ids = getItemAndParseCached('retrieve_ids', {})
    return ids[id]
}

export async function removeRetrieveId(id, includeRecord = true) {
    let ids = getItemAndParseCached('retrieve_ids', {})
    delete ids[id]
    setItemWithStringifyCached('retrieve_ids', ids)
    if (includeRecord) {
        await LocalStorageFacade.remove({ from: 'records', where: { id } })
    }
}

setInterval(processChanges, 60000)
processChanges()

export function* retrieveVersion(ids) {
    let output = []
    for (let id of ids) {
        let unfilteredResults =
            (yield LocalStorageFacade.select({
                from: 'records',
                where: {
                    id,
                },
            })) || []
        if (unfilteredResults) {
            let results = unfilteredResults.filter((r) => r && r.data)
            for (let result of results) {
                let existing = OfflineFirstRetriever.getLocal(id)
                if (existing && result.data.__ !== existing.__) {
                    OfflineFirstRetriever.setLocal(id, result.data)
                    raise(`data.updated.${id}`, id, result.data)
                }
            }
            if (results?.length) {
                output.push({ id, __: results[0].data.__ })
            } else {
                output.push({ id })
            }
        }
    }
    return output
}

handle(
    class Data {
        async dataChanged({ id, client, urgent = false }) {
            const shouldChange = { change: true, id, client }
            // console.log({ shouldChange, id, client })
            events.emit(`shouldload.${id}`, shouldChange)
            if (shouldChange.change) {
                addRetrieveId(id, urgent)
            }
        }

        async aweAssociated(id) {
            if (getActiveClient() === '<test>') return
            isDatabaseId(id) && addRetrieveId(id)
        }

        async aweDisassociated(id) {
            isDatabaseId(id) && (await removeRetrieveId(id))
        }
    }
)
