import { generate } from 'packages/identifiers'

export class LRUCache {
    constructor(size = 50, { maxAge, mapValue = (v) => v } = {}) {
        this.size = size
        this.id = generate()
        this.maxAge = maxAge
        this.map = new Map()
        this.mapValue = mapValue
        this.head = null
        this.tail = null
        this.keys = this.map.keys()
    }

    _putAtTop(current) {
        if (!current.prev) return
        if (current === this.tail) {
            this.tail = current.prev
        }
        current.prev.next = current.next
        current.next && (current.next.prev = current.prev)
        current.prev = null
        current.next = this.head
        this.head.prev = current
        current.time = Date.now()
        this.head = current
    }

    has(key) {
        return this.map.has(key)
    }

    set(key, value) {
        let current = this.map.get(key)
        if (current) {
            this._putAtTop(current)
        } else {
            current = { prev: null, next: this.head, key, time: Date.now() }
            if (!this.tail) this.tail = current
            if (this.head) this.head.prev = current
            this.head = current
        }
        current.value = this.mapValue(value)
        this.map.set(key, current)
        if (this.map.size > this.size) {
            this.map.delete(this.tail.key)
            this.tail && this.tail.prev && (this.tail.prev.next = null)
            this.tail = this.tail.prev
        }
    }

    del(key) {
        const current = this.map.get(key)
        if (!current) return
        this._putAtTop(current)
        this.head = this.head.next
        if (this.tail === current) {
            this.tail = null
        }
        this.map.delete(key)
    }

    get(key) {
        const current = this.map.get(key)
        if (this.maxAge && Date.now() - current.time > this.maxAge) {
            const end = current.prev
            let scan = current
            while (scan) {
                this.map.delete(scan.key)
                scan = scan.next
            }
            end && (end.next = null)
            this.tail = end
            return
        }
        if (current) {
            this._putAtTop(current)
            return this.mapValue(current.value)
        }
    }
}
export default LRUCache
