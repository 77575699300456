import debounce from 'lodash/debounce'
import { getUnprefixedItemAndParse, setUnprefixedItemWithStringify } from 'common/using-local-storage-key'
import { handle } from 'common/events'
import { getNamespace } from 'common/global-store/api'

export let cache = getUnprefixedItemAndParse('version__cache', {})

export function getVersionOfIds(ids) {
    return ids.map((id) => cache[`${getNamespace()}${id}`]?.version)
}

export function getVersionOfId(id) {
    let cached = cache[`${getNamespace()}${id}`]
    try {
        if (cached) {
            if (typeof cached === 'string') {
                cached = cache[`${getNamespace()}${id}`] = { version: cached, now: Date.now() }
            } else if (typeof cached === 'object') {
                cached.now = Date.now()
            }
            return cached.version
        }
    } catch (e) {
        console.error('getVersionId error')
        console.error({ id, namespace: getNamespace(), cached })
        console.error(e)
    }
    return null
}

export function setVersion(id, version) {
    cache[`${getNamespace()}${id}`] = { version, now: Date.now() } || null
    storeCache()
}

handle('clear-db-cache', function () {
    cache = {}
    setUnprefixedItemWithStringify('version__cache', cache)
})

const storeCache = debounce(
    function storeCache() {
        let entries = Object.entries(cache)
        if (entries.length > 5000) {
            entries = entries.sortBy(([, value]) => value.now).slice(-4500)
        }
        cache = Object.fromEntries(entries)
        setUnprefixedItemWithStringify('version__cache', cache, true)
    },
    500,
    { maxWait: 3000 }
)
