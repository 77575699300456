import React, { useEffect, useState } from 'react'

import PropTypes from 'prop-types'

import Avatar from '@material-ui/core/Avatar'
import ListItem from '@material-ui/core/ListItem'
import ListItemAvatar from '@material-ui/core/ListItemAvatar'
import ListItemText from '@material-ui/core/ListItemText'
import { emphasize } from '@material-ui/core/styles/colorManipulator'
import Typography from '@material-ui/core/Typography'

import { parameter, send } from 'common/process'
import { useLocation } from 'common/routing'
import { color, initials } from 'common/standard-names'
import { useRefresh } from 'common/useRefresh'
import { makeCachedStyles } from 'common/inline-styles'

const viewedNotification = send('notification.viewed', parameter('id'))

export const NOTIFICATION_HEIGHT = 62
export const NOTIFICATION_BG_COLOR = '#FFFFFF'
export const NOTIFICATION_BG_UNREAD_COLOR = '#F4F5F6'

const useNotificationStyles = makeCachedStyles(
    (theme) => ({
        root: {
            width: '100%',
            backgroundColor: theme.palette.background.paper,
        },
        notification: {
            color: ({ color = theme.palette.color }) => color,
            '&:hover, &:focus': {
                cursor: 'pointer',
                backgroundColor: ({
                    hoverBackgroundColor = theme.palette.hoverBackgroundColor,
                    backgroundColor = theme.palette.backgroundColor,
                }) => (hoverBackgroundColor ? hoverBackgroundColor : emphasize(backgroundColor, 0.08)),
            },
            '&:active': {
                backgroundColor: ({ hoverBackgroundColor, backgroundColor }) =>
                    emphasize(hoverBackgroundColor ? hoverBackgroundColor : backgroundColor, 0.12),
            },
        },
        primaryText: {
            fontFamily: 'Roboto',
            fontSize: '12px',
        },
        secondaryText: {
            fontFamily: 'Roboto',
            fontSize: '12px',
            color: 'grey',
        },
        emphasis: {
            fontWeight: 'bold',
        },
        read: {
            backgroundColor: '#F4F5F6',
        },
    }),
    'hoverBackgroundColor',
    'backgroundColor',
    'color'
)

export const getPrimaryText = ({ classes, name, notification }) => (
    <Typography className={classes.primaryText} data-testid="notification-primary-text">
        <span className={classes.emphasis} data-testid="notification-primary-text-name">
            {name}
        </span>{' '}
        <span data-testid="notification-primary-text-notification">{notification}</span>
    </Typography>
)

getPrimaryText.propTypes = {
    classes: PropTypes.object,
    name: PropTypes.string,
    notification: PropTypes.string,
}

export const getSecondaryText = ({ classes, timestamp }) => (
    <Typography className={classes.secondaryText} data-testid="notification-secondary-text">
        {timestamp}
    </Typography>
)

getSecondaryText.propTypes = {
    classes: PropTypes.object,
    timestamp: PropTypes.string,
}

const LISTITEM_OVERRIDES = {
    color: 'black',
    backgroundColor: 'white',
    hoverBackgroundColor: '#F1F1F1',
}

export let allViewed = {}

export const NotificationListItem = ({ id, divider = true, title, url, image, time, viewed = false }) => {
    const [, setTime] = useState(Date.now())
    const { navigate } = useLocation()
    const classes = useNotificationStyles({ ...LISTITEM_OVERRIDES })
    const refresh = useRefresh()
    viewed = viewed || allViewed[id]
    useEffect(() => {
        let timer = setInterval(() => setTime(Date.now()), 1000 * 4)
        return () => clearInterval(timer)
    }, [])
    return (
        <div
            className={classes.root}
            style={{
                backgroundColor: !viewed ? NOTIFICATION_BG_UNREAD_COLOR : NOTIFICATION_BG_COLOR,
            }}
        >
            <ListItem
                className={classes.notification}
                data-testid={`notification-listitem-${id}`}
                divider={divider}
                disableGutters={false}
                onClick={show}
            >
                <ListItemAvatar>
                    <Avatar alt={name} style={{ background: color(title) }} src={image} className={classes.avatar}>
                        {initials(title, 2)}
                    </Avatar>
                </ListItemAvatar>
                <ListItemText primary={title} secondary={Date.create(time).relative()} />
            </ListItem>
        </div>
    )

    function show() {
        allViewed[id] = true
        viewedNotification(id)
        refresh()
        if (url) {
            navigate(url)
        }
    }
}

NotificationListItem.propTypes = {
    content: PropTypes.any,
    divider: PropTypes.bool,
    footer: PropTypes.any,
    header: PropTypes.any,
    id: PropTypes.string,
    image: PropTypes.string,
    time: PropTypes.any,
    title: PropTypes.any,
    url: PropTypes.any,
    viewed: PropTypes.bool,
}
