import React, { useContext } from 'react'
export const Embedded = React.createContext(false)

export function useEmbeddedContext() {
    return useContext(Embedded)
}

export function Embed({ children }) {
    return <Embedded.Provider value={true}>{children}</Embedded.Provider>
}
