import React from 'react'

import PropTypes from 'prop-types'

import { MdDelete } from '@react-icons/all-files/md/MdDelete'

import { IconButton } from '@material-ui/core'
import { Box } from 'common/styled-box'
import ClickAwayListener from '@material-ui/core/ClickAwayListener'
import List from '@material-ui/core/List'
import Paper from '@material-ui/core/Paper'

import { parameter, send } from 'common/process'

import { H6 } from 'framework/experimental/components/Headers'

import { allViewed, NotificationListItem } from './notification-listitem'
import { STANDARD_PADDING_Y } from './styles'
import { makeCachedStyles } from 'common/inline-styles'

const viewedNotification = send('notification.viewed', parameter('id'))

const useNotificationHeaderStyles = makeCachedStyles((__theme) => ({
    notificationHeader: {
        padding: STANDARD_PADDING_Y,
        backgroundColor: 'white',
        color: 'black',
        height: LISTITEM_HEIGHT,
    },
}))

const NotificationHeader = ({ onDelete }) => {
    const classes = useNotificationHeaderStyles()
    return (
        <Box
            display="flex"
            alignItems="center"
            className={classes.notificationHeader}
            data-testid="notification-list-header"
        >
            <Box flexGrow={1}>
                <H6>Notifications</H6>
            </Box>
            <Box>
                <IconButton onClick={onDelete}>
                    <MdDelete />
                </IconButton>
            </Box>
        </Box>
    )
}

const useStyles = makeCachedStyles((__theme) => ({
    root: {
        borderRadius: '8px',
        boxShadow: '0px 8px 16px rgba(0, 0, 0, 0.16)',
        overflow: 'hidden',
        width: '480px',
    },
    list: {
        '& *': {
            userSelect: 'none',
        },
        overflow: 'auto',
        padding: 0,
        maxHeight: '75vh',
    },
}))

const LISTITEM_HEIGHT = 65

export const NotificationList = ({ items = [], close }) => {
    const classes = useStyles()

    return (
        <Paper className={classes.root}>
            <ClickAwayListener onClickAway={close}>
                <Paper>
                    <NotificationHeader onDelete={trashNotifications} />
                    <List data-testid="notifications-list" className={classes.list}>
                        {items.map((item, ix) => (
                            <NotificationListItem
                                key={ix}
                                data-testid={`notification-list-${item.id}`}
                                id={ix}
                                {...item}
                            />
                        ))}
                    </List>
                </Paper>
            </ClickAwayListener>
        </Paper>
    )

    function trashNotifications() {
        for (let item of items) {
            viewedNotification(item.id)
            allViewed[item.id] = true
        }
    }
}

NotificationList.propTypes = {
    close: PropTypes.func.isRequired,
    items: PropTypes.array.isRequired,
    defaultViewableItems: PropTypes.number,
}

NotificationHeader.propTypes = {
    onDelete: PropTypes.func,
}
