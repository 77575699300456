import React, { useRef } from 'react'

import PropTypes from 'prop-types'

import events from 'packages/alcumus-local-events'

import classNames from 'classnames'

import { store } from 'common/global-store'
import { menu } from 'common/menu'
import { useLocalEvent } from 'common/use-event'
import { makeCachedStyles } from 'common/inline-styles'

const useStyles = makeCachedStyles((theme) => ({
    container: {
        background: theme.palette.background.default,
    },
}))

store.set({ containerClasses: [] })

export function AppContainer({ children }) {
    let ref = useRef(null)
    const classes = useStyles()

    function updateClasses() {
        const classesToReturn = classNames(classes.container, 'menu-default', {})
        if (ref.current) ref.current.className = classesToReturn
        return classesToReturn
    }

    const containerClasses = store.$containerClasses || []
    useLocalEvent('resize-vp', updateClasses)
    menu.selected.useChange(updateClasses)
    return (
        <div
            onClick={click}
            ref={ref}
            id="app-container"
            className={classNames(updateClasses(), 'justify-content-f', 'align-items-center', containerClasses)}
        >
            {children}
        </div>
    )

    function click(e) {
        let target = e.target
        while (target) {
            if (('' + target.className || '').indexOf('-menu') !== -1) return
            target = target.parentElement
        }
        events.emit('clicked.background', null)
    }
}

AppContainer.propTypes = {
    children: PropTypes.any,
}
