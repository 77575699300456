import events from 'packages/alcumus-local-events'

let timer = 0

document.addEventListener('visibilitychange', function () {
    if (isActive()) {
        clearTimeout(timer)
        timer = setTimeout(() => events.emit('activated'), 1000)
    } else {
        clearTimeout(timer)
        events.emit('deactivated')
    }
})

export function isActive() {
    return document.visibilityState !== 'hidden'
}

export function whenActive(fn) {
    return function (...params) {
        if (isActive()) {
            try {
                return fn(...params)
            } catch (err) {
                //Hide error
            }
        }
    }
}
