import React, { useCallback, useContext, useEffect, useRef, useState } from 'react'
import { SortableContainer, SortableElement } from 'alcumus-react-sortable-hoc'

import PropTypes from 'prop-types'

import { generate } from 'packages/identifiers'

import { Box } from 'common/styled-box'

import { arrayMoveInPlace } from 'common/array-move-in-place'
import { Bound, useBoundContext } from 'common/component-utilities'
import { useLocalEvent } from 'common/use-event'
import { useRefresh } from 'common/useRefresh'
import { ScrollIndicatorHolder, useMeasurement, Virtual } from 'packages/react-virtual-dynamic-list'
import { render } from 'react-dom'
import { SortableDiv } from 'common/sortable'
import noop from 'common/noop'
import Color from 'color'
import VisibilitySensor from 'packages/visibility-sensor'
import { makeCachedStyles } from 'common/inline-styles'

export const useHelperClass = makeCachedStyles((theme) => {
    return {
        list: {
            fontSize: 16,
        },
        scroller: {
            overflowY: 'auto',
        },
        darkList: {
            background: theme.palette.background.default,
        },
        helper: {
            zIndex: 100000,
            background: `${Color(theme.palette.background.default).hex()}A0`,
        },
        flexHelper: {
            listStyle: 'none',
            width: '100%',
            position: 'relative',
            '& .hide-drag': {
                display: 'none',
            },
            '& .show-drag': {
                display: 'block',
            },
        },
    }
})

function defaultKey(item, index) {
    Array.isArray(item) && (item = item[0])
    if (item && typeof item === 'object') {
        return item._id || (item.id ? item.id : (item.id = generate()))
    }
    return index
}

export const RepeatContext = React.createContext({})
export const VirtualRepeatContext = React.createContext({})

function Dummy({ children }) {
    return <>{children}</>
}

export function SortableVirtualRepeat({ onSortEnd, helperClass, collection, ...props }) {
    const { save } = useBoundContext()
    const refresh = useRefresh(save)
    const classes = useHelperClass()
    helperClass = helperClass || classes.helper
    onSortEnd = onSortEnd || sort

    return <SortVirtualRepeat collection={collection} helperClass={helperClass} onSortEnd={onSortEnd} {...props} />

    function sort(oldIndex, newIndex) {
        arrayMoveInPlace(collection, oldIndex, newIndex)
        refresh()
    }
}

export function SortableRepeat({ onSortEnd, helperClass, collection, ...props }) {
    const { save } = useBoundContext()
    const refresh = useRefresh(save)
    onSortEnd = onSortEnd || sort
    const classes = useHelperClass()
    helperClass = helperClass || classes.helper

    return <SortRepeat collection={collection} helperClass={helperClass} onSortEnd={onSortEnd} {...props} />

    function sort({ oldIndex, newIndex }) {
        arrayMoveInPlace(collection, oldIndex, newIndex)
        refresh()
    }
}

const sorters = new Map()

export function Sortable({ Component, ...props }) {
    if (!sorters.has(Component)) {
        sorters.set(Component, SortableElement(Component))
    }
    const Sortable = sorters.get(Component)
    return <Sortable {...props} />
}

export const SortableBox = SortableElement(Box)

export function SortContainer({ Component, collection, onSortEnd, ...props }) {
    const Sortable = SortableContainer(Component)
    const { save } = useBoundContext()
    const refresh = useRefresh(save)
    onSortEnd = onSortEnd || sort
    return <Sortable onSortEnd={onSortEnd} {...props} />

    function sort({ oldIndex, newIndex }) {
        arrayMoveInPlace(collection, oldIndex, newIndex)
        refresh()
    }
}

const SortVirtualRepeat = SortableContainer(VirtualRepeat)
const SortRepeat = SortableContainer(Repeat)

let key = 0
export function useMeasuredItem(...fn) {
    fn = fn.compact(true)
    const [element] = useState(() => {
        return document.createElement('div')
    })
    // const [size, setSize] = useState({ height: 0, width: 0 })
    const [size, attach] = useMeasurement()
    useEffect(() => {
        render(
            <SortableDiv key={key++}>
                <div>
                    {fn.map((Fn) => (
                        <Render Fn={Fn} key={key++} />
                    ))}
                </div>
            </SortableDiv>,
            element
        )
    }, [fn.length])
    return [{ height: size.height / (fn.length || 1), width: size.width }, connect]

    function Render({ Fn }) {
        return <Fn />
    }

    function connect(target) {
        attach(element)
        if (target) {
            target.innerHTML = ''
            target.appendChild(element)
        }
    }
}

export function VirtualRepeat({
    collection,
    id,
    transformCollection,
    item,
    children,
    transformItem,
    height,
    onClick,
    tag = 'virtual',
    adjustHeight = noop,
    overscan,
    showCount = 7,
    itemSize = 64,
    keyFn = defaultKey,
    ...props
}) {
    const originalHeight = useRef(height)
    const heightAdjust = useRef()
    adjustHeight((v) => {
        heightAdjust.current && heightAdjust.current(v)
    })
    const meanHeight = useRef(itemSize)
    const maxHeight = useRef(0)
    const resolve = useRef(2)
    const listBox = useRef()
    const classes = useHelperClass()
    const heightTimer = useRef()
    const list = useRef()
    const Item = item?.type || Dummy
    let items = Array.isArray(collection) ? collection : Object.entries(collection || {})
    transformCollection && (items = transformCollection(items))
    let actualHeight = Math.min(
        window.innerHeight,
        height !== undefined
            ? height
            : Math.max(
                  maxHeight.current,
                  Math.min(items.length, showCount + (items.length > showCount ? 0.5 : 0)) * meanHeight.current + 8
              )
    )
    maxHeight.current = Math.max(actualHeight, maxHeight.current)
    items._id = items.id = items?._id || collection?._id || id || tag || generate()
    const resize = useCallback(_resize, [items._id, items.length])
    const addAdjuster = useCallback(_addAdjuster, [items._id, items.length])

    if (typeof collection === 'object') {
        collection._id = items._id
    }
    useLocalEvent('scroll-list-to', scrollInItem)
    return (
        <Bound refresh={() => {}}>
            <VirtualRepeatContext.Provider
                value={{
                    Item,
                    item,
                    transformItem,
                    keyFn,
                    items,
                    children,
                    ...props,
                }}
            >
                <Box ref={listBox} className={classes.list} onClick={onClick} width="100%">
                    <Virtual
                        key={items._id}
                        {...props}
                        tag={tag}
                        onSize={!height ? resize : noop}
                        overscan={overscan}
                        Holder={ScrollIndicatorHolder}
                        shadow="0 0 32px 14px black"
                        expectedHeight={itemSize}
                        height={actualHeight}
                        maxHeight={actualHeight}
                        adjustHeight={addAdjuster}
                        items={items}
                        renderItem={(item, index) => {
                            return <RenderItem index={index} />
                        }}
                    />
                </Box>
            </VirtualRepeatContext.Provider>
        </Bound>
    )

    function _addAdjuster(adjuster) {
        heightAdjust.current = adjuster
        adjuster(
            originalHeight.current !== undefined
                ? originalHeight.current
                : Math.min(items.length, showCount + (items.length > showCount ? 0.5 : 0)) * meanHeight.current + 8
        )
    }

    function _resize({ averageHeight }) {
        averageHeight = Math.round(averageHeight)
        if (Math.abs(averageHeight - meanHeight.current) >= resolve.current) {
            clearTimeout(heightTimer.current)
            heightTimer.current = setTimeout(() => {
                resolve.current *= 1.02
                meanHeight.current = averageHeight
                const height =
                    originalHeight.current !== undefined
                        ? originalHeight.current
                        : Math.min(items.length, showCount + (items.length > showCount ? 0.5 : 0)) *
                              meanHeight.current +
                          8

                if (heightAdjust.current) {
                    heightAdjust.current(height)
                }
            }, 200)
        }
    }

    function scrollInItem(itemOrId) {
        let idx = items.findIndex((i) => i.id === itemOrId || i._selectId === itemOrId || i === itemOrId)
        if (idx !== -1) {
            setTimeout(() => {
                if (list.current) {
                    let firstVisible = list.current.state.scrollOffset / list.current.props.itemSize
                    let totalVisible = list.current.props.height / list.current.props.itemSize
                    if (idx <= firstVisible || idx >= firstVisible + totalVisible) {
                        list.current && list.current.scrollToItem(idx, 'center')
                    }
                }
            })
        }
    }
}

const RenderItem = React.forwardRef(function RenderItem({ index }, ref) {
    const { transformItem, item, Item, items, pass, children } = useContext(VirtualRepeatContext)
    const iterated = items[index]
    if (!iterated) return <Box ref={ref} />
    return (
        <Box ref={ref} key={iterated.id || iterated._id || index} clone>
            <RepeatItem
                item={item}
                pass={pass}
                index={index}
                iterated={iterated}
                Item={Item}
                transformItem={transformItem}
            >
                {children}
            </RepeatItem>
        </Box>
    )
})

RenderItem.propTypes = {
    index: PropTypes.any,
}

function VirtualRepeatItem({ index, style }) {
    const { transformItem, Item, item, items, pass, children } = useContext(VirtualRepeatContext)
    return (
        <div style={style}>
            <RepeatItem
                item={item}
                pass={pass}
                index={index}
                iterated={items[index]}
                Item={Item}
                transformItem={transformItem}
            >
                {children}
            </RepeatItem>
        </div>
    )
}

export function Repeat({
    collection,
    transformCollection = (items) => items,
    group,
    item,
    children,
    keyFn = defaultKey,
    ...props
}) {
    const Group = group?.type || Dummy
    const Item = item?.type || Dummy
    let items = Array.isArray(collection) ? collection : Object.entries(collection || {})
    items = transformCollection(items)
    return <Component {...{ ...props, Group, group, Item, item, items, keyFn }}>{children}</Component>
}

const useMarkerStyles = makeCachedStyles(() => {
    return {
        marker: {
            width: 1,
            height: 1,
            opacity: 0,
        },
    }
})

function Marker() {
    const classes = useMarkerStyles()
    return <div className={classes.marker}>MORE</div>
}

export function MoreRepeat({
    moreContents = <Marker />,
    collection,
    transformCollection = (items) => items,
    group,
    item,
    more = noop,
    tag,
    children,
    keyFn = defaultKey,
    ...props
}) {
    const Group = group?.type || Dummy
    const Item = item?.type || Dummy
    let items = Array.isArray(collection) ? collection : Object.entries(collection || {})
    items = transformCollection(items)
    return (
        <div key={tag}>
            <Component {...{ ...props, Group, group, Item, item, items, keyFn }}>{children}</Component>
            <VisibilitySensor onChange={check}>
                <div>{moreContents}</div>
            </VisibilitySensor>
        </div>
    )

    function check(visible) {
        if (visible && collection.length) more()
    }
}

export function keyByIndex(__, index) {
    return index
}

function Component({ Group, group, groupProps, items, transformItem, keyFn, Item, item, pass, bind, props, children }) {
    return (
        <Group {...{ ...(group?.props || {}), ...groupProps }}>
            {items.compact().map((iterated, index) => {
                transformItem && (iterated = transformItem(iterated))
                const key = keyFn(iterated, index)
                return (
                    <RepeatItem
                        key={key}
                        transformItem={transformItem}
                        Item={Item}
                        item={item}
                        items={items}
                        bind={bind}
                        iterated={iterated}
                        pass={pass}
                        props={props}
                        index={index}
                    >
                        {children}
                    </RepeatItem>
                )
            })}
        </Group>
    )
}

function RepeatItem({ transformItem, iterated, Item, item, pass, props, bind, index, children }) {
    let { target, save, refresh } = useBoundContext()
    transformItem && (iterated = transformItem(iterated))
    const localRefresh = useRefresh(save)
    refresh = bind ? localRefresh : refresh
    if (Array.isArray(iterated)) {
        const value = [iterated[1], iterated[0]]
        return (
            <RepeatContext.Provider value={value}>
                <Bound target={bind ? iterated[1] : target} refresh={refresh}>
                    <Item
                        {...{ ...props, ...item?.props, [pass]: iterated[1] }}
                        property={iterated[0]}
                        item={iterated}
                        index={index}
                    >
                        {children && children(...value)}
                    </Item>
                </Bound>
            </RepeatContext.Provider>
        )
    } else {
        return (
            <RepeatContext.Provider value={iterated}>
                <Bound target={bind ? iterated : target} refresh={refresh}>
                    <Item {...{ ...props, ...item?.props }} {...{ [pass]: iterated }} index={index}>
                        {children && children(iterated, index)}
                    </Item>
                </Bound>
            </RepeatContext.Provider>
        )
    }
}

Repeat.propTypes = {
    children: PropTypes.any,
    collection: PropTypes.any,
    group: PropTypes.any,
    item: PropTypes.any,
    keyFn: PropTypes.func,
    transformCollection: PropTypes.func,
    transformItem: PropTypes.func,
}

Component.propTypes = {
    Group: PropTypes.any,
    Item: PropTypes.any,
    bind: PropTypes.any,
    children: PropTypes.any,
    group: PropTypes.any,
    groupProps: PropTypes.any,
    item: PropTypes.any,
    items: PropTypes.any,
    keyFn: PropTypes.func,
    pass: PropTypes.any,
    props: PropTypes.any,
    transformItem: PropTypes.func,
}

VirtualRepeatItem.propTypes = {
    index: PropTypes.any,
    style: PropTypes.any,
}

VirtualRepeat.propTypes = {
    children: PropTypes.any,
    className: PropTypes.any,
    collection: PropTypes.any,
    height: PropTypes.any,
    item: PropTypes.any,
    itemSize: PropTypes.number,
    keyFn: PropTypes.any,
    m: PropTypes.any,
    onClick: PropTypes.any,
    overscan: PropTypes.any,
    p: PropTypes.any,
    showCount: PropTypes.number,
    tag: PropTypes.any,
    transformCollection: PropTypes.func,
    transformItem: PropTypes.any,
}

RepeatItem.propTypes = {
    Item: PropTypes.any,
    bind: PropTypes.any,
    children: PropTypes.func,
    index: PropTypes.any,
    item: PropTypes.any,
    iterated: PropTypes.any,
    pass: PropTypes.any,
    props: PropTypes.any,
    transformItem: PropTypes.func,
}

Dummy.propTypes = {
    children: PropTypes.any,
}

SortableVirtualRepeat.propTypes = {
    collection: PropTypes.any,
    helperClass: PropTypes.any,
    onSortEnd: PropTypes.any,
}

SortableRepeat.propTypes = {
    collection: PropTypes.any,
    helperClass: PropTypes.any,
    onSortEnd: PropTypes.any,
}

Sortable.propTypes = {
    Component: PropTypes.any,
}

SortContainer.propTypes = {
    Component: PropTypes.any,
    collection: PropTypes.any,
    onSortEnd: PropTypes.any,
}

VirtualRepeat.defaultProps = {
    itemSize: 32,
    keyFn: defaultKey,
    showCount: 7,
}
