import events from 'packages/alcumus-local-events'

import merge from 'lodash/merge'

import { getClientId } from 'common/client-id'
import { getItem, removeItem, setItem, setUnprefixedItemWithStringify } from 'common/using-local-storage-key'
import { baseUrl } from 'common/tokens-and-eventsource'
import { parameter } from 'common/query-params'
import { navigate } from 'common/routing'
import { tokenRefreshing } from 'utils/authenticationService'
import { getCurrentAuthenticatedUser, setActiveClient } from 'common/global-store/api'

require('common/debug')

const configFile = 'server.json'

if (parameter('magic')) {
    window._useToken = 'magic'
    setActiveClient('<test>')
    window._magic = parameter('magic')
}

export function getToken() {
    return window._useToken || getItem('token')
}

export async function getConfig() {
    return await import(`../../configuration/${configFile}`)
}

const tokenMap = new Map()
let tokenId = 1

setToken(getItem('token'))

export function idFromToken(token = getToken()) {
    return tokenMap.get(token) || 'never seen'
}

export function setToken(token) {
    if (!token || token === '' || token === 'undefined' || token === 'null') {
        tokenMap.clear()
        removeItem('token')

        return
    }
    setItem('token', token)
    if (!tokenMap.has(token)) {
        tokenMap.set(token, tokenId++)
    }
}

export function addBaseUrl(url) {
    if (baseUrl && !url.startsWith('http') && !url.startsWith('data:')) {
        if (!url.startsWith('/')) url = '/' + url
        url = baseUrl + url
    }
    if (url.startsWith('data:')) return url
    url = url.indexOf('?') !== -1 ? `${url}&client=${getClientId()}` : `${url}?client=${getClientId()}`
    return url
}

export function addTokenToUrl(url) {
    let token = getToken()
    if (window._useToken && window._magic) {
        token = `${window._useToken}&magic=${window._magic}`
    }
    if (url.startsWith('data:')) return url
    url = addBaseUrl(url)
    url = url.indexOf('?') !== -1 ? `${url}&token=${token}` : `${url}?token=${token}`
    return url
}

export const ready = (async () => {
    if (!baseUrl) {
        console.info('base url not set, please set in .env if not running symlinked')
    }

    try {
        let token = getToken()
        if (token === 'null') token = null
        await events.emitAsync('secure-token-ready', token)
    } catch (e) {
        // console.error(e) // mute
    }
})()

export async function getAuthToken() {
    await ready
    try {
        let token = getCurrentAuthenticatedUser().accessToken
        setToken(token)
        return token
    } catch (e) {
        console.error(e)
    }
}

function updateTokenInLocalStorage(user) {
    if (!user) {
        setToken('')
        setItem('access-key', '')
        setItem('user-access-data', '{}')
        return
    }
    try {
        let token = user.accessToken || user.access_token
        setItem('access-key', token)
        setToken(token)
        const currentAccessData = JSON.parse(getItem('user-access-data'))
        if (currentAccessData) {
            user = { ...currentAccessData, ...user }
        }
        setItem('user-access-data', JSON.stringify(user))
    } catch (e) {
        console.error(e)
    }
}

events.on('sign-in-event', async (__event, user) => {
    updateTokenInLocalStorage(user)
})

events.on('refresh-token-event', async (__event, user) => {
    // eslint-disable-next-line no-console
    console.log('Token was refreshed')
    updateTokenInLocalStorage(user)
})

events.on('signed-out-event', (_, destination) => {
    // invalidateToken(getToken()).catch(console.error)
    let token = ''
    setToken(token)
    setItem('access-key', token)
    setItem('user-access-data', null)
    setItem('user', null)
    removeItem('@worknetwork-currentUser')
    removeItem('user-access-data')
    removeItem('access-key')
    delete window._useToken
    removeItem('token')
    setUnprefixedItemWithStringify('currentAuthenticatedUser', null)
    events.emit('reinitialise-user-loaded', false)
    setTimeout(() => {
        if (process.env.REACT_APP_SWITCH_TO_PORTAL === 'true') {
            navigate(
                `https://${process.env.REACT_APP_PORTAL_ADDRESS}/logout?logout=true&rp=${window.location.origin}${
                    destination || '/login'
                }`
            )
        } else {
            navigate(destination ? destination : '/login')
            window.location.reload(true)
        }
    })
})

export async function open(url, options) {
    return window.open(addTokenToUrl(url), options)
}

/**
 *
 * @param {*} url
 * @param {*} options
 */
export async function fetch(url, options = {}, anonymous) {
    try {
        const info = { url, options, anonymous, result: null }
        await events.emitAsync('will-fetch', info)
        if (info.result) {
            return info.result
        }

        await ready
        await tokenRefreshing()
        let token = getToken() // MikeT: DO NOT USE ONE THAT IS CACHED OUTSIDE OF STORAGE
        if (token) {
            if (options.onlyToken) {
                url = addTokenToUrl(url)
            }
        }

        merge(options, {
            headers: {
                'x-access-token': window._useToken || token,
                ...options?.headers,
                'Accept-Encoding': 'gzip,br',
            },
        })
        if (anonymous) {
            delete options.headers['x-access-token']
        }
        return await window.fetch(addBaseUrl(url), options)
    } catch (e) {
        console.error(e)
        throw e
    }
}
