import React, { useEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types'

import events from 'packages/alcumus-local-events'

import { MdCheck } from '@react-icons/all-files/md/MdCheck'
import generate from 'shortid'
import { Box } from 'common/styled-box'
// MUI
import { CardActions, CardContent, ListItemAvatar, ListItemText, TextField, Typography } from '@material-ui/core'
import Avatar from '@material-ui/core/Avatar'
import Button from '@material-ui/core/Button'
import Card from '@material-ui/core/Card'
import CardHeader from '@material-ui/core/CardHeader'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import Divider from '@material-ui/core/Divider'
// common
import SecureButton from 'common/secure/SecureButton'
import noop from 'common/noop'
import { SecureBox } from 'common/secure/SecureBox'
import { VirtualRepeat } from 'common/repeat'
import { batch } from 'common/batched'
import { color } from 'common/standard-names'
import { setFromEvent } from 'common/set-from-event'
import { showModal, showNotification } from 'common/modal'
import { store } from 'common/global-store'

// local
import { Demands } from '../demands'
import { getClients, getUserClientList, getUserClients, setClient, updateActiveClient } from '../request-selectors'
import { CreateClient, CreateClientWithoutId, MigrateClient, UpdateClient } from './modals'
import { ApiToken } from './ApiToken'
import { useDebounce } from 'framework/experimental/hooks/use-debouce'
import InputAdornment from '@material-ui/core/InputAdornment'
import clsx from 'clsx'

import useAsync, { useCachedAsync, useCurrentState } from 'common/use-async'
import { getClient } from 'plugins/client-plugin/api'

import { raise } from 'common/events'
import { useRefresh } from 'common/useRefresh'
import { getActiveClient } from 'common/global-store/api'
import { MdSearch } from '@react-icons/all-files/md/MdSearch'
import { MdLens } from '@react-icons/all-files/md/MdLens'
import { makeCachedStyles } from 'common/inline-styles'
import isEmpty from 'lodash/isEmpty'

function makeRealIdItems(item) {
    return { userSwitcher: item._id.split(':')[0], ...item }
}

export function useClients() {
    const refresh = useRefresh()
    const selected = useRef(getActiveClient())

    const {
        active,
        clients = [],
        suspendedClients = [],
        activeClientName,
    } = useAsync(
        async function () {
            const { client: { clients = [], suspendedClients = [] } = {} } = await getUserClients()

            const mappedClients = clients.map(makeRealIdItems)
            const mappedSuspended = suspendedClients.map(makeRealIdItems)

            let currentlyActive = mappedClients.find((cl) => cl.userSwitcher === getActiveClient()) || mappedClients[0]
            let activeClientId = currentlyActive?.userSwitcher
            let activeClientName = currentlyActive?.name

            return {
                clients: mappedClients,
                active: activeClientId,
                suspendedClients: mappedSuspended,
                activeClientName: activeClientName,
            }
        },
        {},
        [refresh.id]
    )

    function setSelected(id) {
        selected.current = id
        refresh()
    }

    async function setActiveClient(id) {
        try {
            await updateActiveClient(id)
            setSelected(id)
        } catch (e) {
            showNotification('Error: Unable to change the activeClient')
            console.error(e)
        }
    }

    return {
        active,
        activeClientName,
        clients,
        selected: selected.current,
        setActiveClient,
        setSelected,
        suspendedClients,
    }
}

const useStyles = makeCachedStyles((theme) => ({
    clientItem: {
        cursor: 'pointer',
        '& button': {
            whiteSpace: 'nowrap',
        },
    },
    removePad: {
        marginTop: -1,
        paddingTop: 0,
    },
    searchField: {
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
        marginBottom: theme.spacing(1),
    },
    editClientPopup: {
        minHeight: 460,
    },
}))

const DEFAULT_PAGE_SIZE = 20

const getViewableClientListWithClientTotal = async (searchTerm, count) => {
    const data = await getUserClientList()

    const filteredData = data?.filter((client) => {
        const { name, _id, _deleted } = client

        if (!isEmpty(_deleted)) {
            return false
        }

        const trimmedSearchTerm = searchTerm.trim().toLowerCase() ?? ''
        return !isEmpty(trimmedSearchTerm)
            ? name.toLowerCase().includes(trimmedSearchTerm) || _id.toLowerCase().includes(trimmedSearchTerm)
            : true
    })
    const sortedData = filteredData.sort((a, b) => a.name.localeCompare(b.name))

    return {
        viewableClients: sortedData.length > count ? sortedData.slice(0, count) : sortedData,
        totalClients: sortedData.length,
    }
}

function ClientsContent({ onUpdated = noop, pageSize = DEFAULT_PAGE_SIZE, database = 'hestia', table = 'client' }) {
    const classes = useStyles()
    const [count, setCount, currentCount] = useCurrentState(pageSize)
    const [clients, setClients] = useState([])
    const [totalCount, setTotalCount] = useState(0)
    const [current, setCurrent] = useState(null)
    const [searchTerm, setSearchTerm] = React.useState('')
    const [debouncedSearchTerm] = useDebounce(searchTerm)
    let activeClient = store.$profile.activeClient

    const { name: clientName = null } = useCachedAsync(
        'document.activeClient',
        () => getClient(activeClient),
        {},
        activeClient
    )

    useEffect(() => {
        const fetchClients = async () => {
            const { viewableClients, totalClients } = await getViewableClientListWithClientTotal(
                debouncedSearchTerm,
                count
            )
            setClients(viewableClients)
            setTotalCount(totalClients)
        }

        fetchClients()
    }, [debouncedSearchTerm, count])

    function streamMore() {
        setCount(currentCount() + pageSize)
    }

    function scroll(props) {
        if (props.last >= currentCount() - pageSize * 0.8) {
            batch(() => {
                streamMore()
            })
        }
    }

    async function setActiveClient(id) {
        const [newActiveClient] = id?.split(':') ?? []
        await updateActiveClient(newActiveClient)
        const [selected] = clients?.filter((client) => client._id === id) ?? []
        const profile = store.get('profile').useValue()
        store.set({ profile: { ...profile, activeClient: newActiveClient } })
        await events.emitAsync('change-active-client', selected)
    }

    return (
        <>
            <Card data-testid="client-list">
                <CardHeader
                    title={'Clients'}
                    subheader={
                        <div className={classes.clientCardHeaderSubHeader}>
                            <small>
                                <strong>Current:&nbsp;</strong>
                                {clientName} ({activeClient})
                            </small>
                        </div>
                    }
                    className={classes.clientCardHeader}
                />

                <CardContent className={classes.removePad}>
                    <TextField
                        type={'search'}
                        className={clsx(classes.searchField, 'client-search-filter')}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <MdSearch />
                                </InputAdornment>
                            ),
                        }}
                        fullWidth
                        value={searchTerm}
                        onChange={setFromEvent(setSearchTerm)}
                    />
                    <List data-testid="clients-client-list">
                        <VirtualRepeat
                            tag={'client-list'}
                            keyFn={(item, index) => index}
                            collection={clients}
                            onScroll={scroll}
                            showCount={5}
                        >
                            {(item, ix) => renderClient(item, item?._id?.includes(activeClient))}
                        </VirtualRepeat>
                    </List>
                </CardContent>
                <Divider />
                <CardActions>
                    <Box display={'flex'} alignItems={'center'} ml={2} mr={2} width={'100%'}>
                        <Box display="flex" flexGrow={1} justifyContent={'flex-start'}>
                            <Button onClick={addClient} color="primary" data-testid="clients-add-client">
                                + Add Client
                            </Button>

                            <SecureBox demands="add-generic-client" display={'flex'}>
                                <Button
                                    onClick={addClientWithoutId}
                                    color="primary"
                                    data-testid="clients-add-generic-client"
                                >
                                    + Add Client without ID
                                </Button>
                            </SecureBox>
                        </Box>
                        <Box display="flex" justifyContent={'flex-end'} alignItems={'center'}>
                            <Typography variant={'overline'}>
                                {
                                    <small>
                                        {totalCount} Client{totalCount !== 1 && 's'}
                                    </small>
                                }
                            </Typography>
                        </Box>
                    </Box>
                </CardActions>
            </Card>
            {current && (
                <>
                    <Box mt={2}>
                        <ClientEditor key={current._id} client={current} />
                    </Box>
                    <Box mt={2}>
                        <ApiToken selectedClient={current} />
                    </Box>
                </>
            )}
        </>
    )

    function renderClient(client, active) {
        const refresh = useRefresh()
        if (client._loading) {
            return null
        }
        async function editClient() {
            const { newName, safeSupplierClientId } = await showModal(
                (props) => <UpdateClient {...props} {...client} />,
                {
                    fullWidth: true,
                    PaperProps: {
                        className: classes.editClientPopup,
                    },
                }
            )
            if (newName || safeSupplierClientId) {
                client.name = newName
                client.ssClientId = safeSupplierClientId
                refresh()
            }
        }

        return (
            <ListItem
                className={classes.clientItem}
                data-testid={`client:${client.name}`}
                onClick={() => setCurrent(client)}
                key={client._id}
                selected={client === current}
            >
                <ListItemAvatar>
                    <Avatar style={{ background: color(client._id) }}>
                        <MdLens />
                    </Avatar>
                </ListItemAvatar>
                <ListItemText primary={client.name || client._id} secondary={client._id.split(':')[0]} />

                <Button onClick={() => editClient()} color="primary" data-testid="clients-edit-client">
                    + Edit
                </Button>

                {(client.migrated == null || client.migrated === false) && (
                    <Box mr={1} clone>
                        <Button
                            onClick={() => migrateClient(client)}
                            color="primary"
                            data-testid="clients-migrate-client"
                        >
                            + Migrate
                        </Button>
                    </Box>
                )}

                <SecureButton
                    onClick={() => setActiveClient(client._id)}
                    color="primary"
                    data-testid="clients-switch-client"
                    disabled={active}
                    alignItems="center"
                    className={classes.selectedClient}
                >
                    {active ? (
                        <>
                            <MdCheck />
                            &nbsp;Active
                        </>
                    ) : (
                        <>Switch To</>
                    )}
                </SecureButton>
            </ListItem>
        )
    }

    function handleUpdated() {
        raise('data.updated.clients', `${generate()}:${database}/${table}`)
        onUpdated()
    }

    async function addClient() {
        if (await showModal(CreateClient, { fullWidth: true })) {
            handleUpdated()
        }
    }

    async function addClientWithoutId() {
        if (await showModal(CreateClientWithoutId, { fullWidth: true })) {
            handleUpdated()
        }
    }

    async function migrateClient(client) {
        if (
            await showModal((props) => <MigrateClient {...props} {...client} />, {
                fullWidth: true,
            })
        ) {
            handleUpdated()
        }
    }
}

export function Clients() {
    return (
        <Box m="auto" mt={2} width={1} maxWidth={768}>
            <ClientsContent clients={getClients} />
        </Box>
    )
}

ClientsContent.propTypes = {
    clients: PropTypes.any,
}

function ClientEditor({ client, onChange = noop }) {
    let [selectedRoles, setSelectedRoles] = useState(client.authorise || [])

    async function setAuthorise(value) {
        client.authorise = value
        setClient(client._id, value)
        setSelectedRoles(value)
        onChange(client)
    }

    return (
        <Card data-testid={`client-${client.name}`}>
            <CardHeader title={client.name} />
            <CardContent>
                <Demands label="Authorise For All Users" value={selectedRoles} onChange={setFromEvent(setAuthorise)} />
            </CardContent>
        </Card>
    )
}

ClientEditor.propTypes = {
    client: PropTypes.any,
    onChange: PropTypes.any,
}
