import { generate } from 'packages/identifiers'
import { raise } from 'common/events'
import { using } from 'common/using-local-storage-key'
import * as InternalEvents from 'common/offline-data-service/events'
import { offlineDataStorageReady, prepareDocument } from 'common/offline-data-service'
import debounce from 'lodash/debounce'
import { isOnline } from 'common/utils'
import constants from 'common/offline-data-service/constants.json'
import Window from 'common/globals/window'
import { cacheRecords } from 'common/offline-data-service/caching'

/**
 * Stores a document in the database. We don't often do this
 * on the client as we expect AWE processing to do it on our
 * behalf. But for other kinds of document this will work.
 * @param {Document|Object} record - the record to store
 * @param {boolean} [cache=false] - whether we should cache the record
 * @param {boolean} [now=false] - if true the record is immediately stored, otherwise it is queued
 * @param {boolean} [skipAssociation=false] - if true the record is not associated with
 * the user
 * @returns {Promise<Document>} a promise for the storage of the document
 */
export async function set(record, cache = false, now = false, skipAssociation = false) {
    record.__ = generate()
    record.__modified = Date.now()
    const original = record
    await offlineDataStorageReady
    record = prepareDocument(record)
    if (cache) await cacheRecords(record, true)
    raise(`data.updated.${original._id}`, original._id, original)
    if (!now) {
        await using(
            'alcumus.set-queue',
            async (queue) => {
                toSend = [...toSend.filter((r) => r._id !== record.id)]
                return [...queue.filter((r) => r._id !== record._id), record]
            },
            []
        )
        processQueue()
    } else {
        await InternalEvents.setRecord(record, true, skipAssociation)
    }
    return record
}

let toSend = []

const processQueue = debounce(
    async function processQueue() {
        if (!isOnline() || sending) return setTimeout(processQueue, 5000)

        try {
            sending = true
            await using(
                'alcumus.set-queue',
                async (queue) => {
                    toSend.push(...queue)
                    return []
                },
                []
            )

            let retries = []
            let item
            do {
                item = scan(toSend)
                if (item) {
                    try {
                        await InternalEvents.setRecord(item, true)
                    } catch (e) {
                        item.__retries = (item.__retries || 0) + 1
                        retries.push(item)
                    }
                }
            } while (item)
            await using(
                'alcumus.set-queue',
                async (queue) => {
                    queue.push(...retries.filter((r) => r.__retries < constants.MAX_RETRIES))
                    return queue
                },
                []
            )
        } finally {
            sending = false
        }
    },
    200,
    { maxWait: 1500 }
)

try {
    Window.addEventListener('online', processQueue)
} catch (e) {
    //Swallow
}

setInterval(processQueue, constants.MAX_QUEUE_WAIT)

let sending = false

function scan(array) {
    let item
    do {
        item = array.shift()
    } while (!item && array.length)
    return item
}
