import React from 'react'
import ReactDOM from 'react-dom'

import debounce from 'lodash/debounce'

let batched = []
// let waiting = new Set()

// const update = debounce(ReactDOM.unstable_batchedUpdates, 0, {leading: false, trailing: true})
const update = ReactDOM.unstable_batchedUpdates

const internalBatch = debounce(
    function () {
        let toRun = batched
        batched = []
        // waiting.clear()

        update(function () {
            for (let run of toRun) {
                try {
                    run()
                } catch (e) {
                    console.error(e)
                }
            }
        })
    },
    32,
    { leading: false, maxWait: 120, trailing: true }
)

export function batcherise(fn) {
    return function (...params) {
        batch(() => fn(...params))
    }
}

export function batch(fn) {
    // if (process.env.NODE_ENV === 'development') {
    //     return fn()
    // }
    // if (dedupe && waiting.has(dedupe)) return
    // waiting.add(dedupe)
    batched.push(fn)
    internalBatch()
}
