import React, { useCallback, useEffect, useState } from 'react'
import classNames from 'classnames'

import PropTypes from 'prop-types'

import events from 'packages/alcumus-local-events'
import { Location, Router } from '@reach/router'
import * as utils from '@reach/router/lib/utils'

import { store } from 'common/global-store'
import { showNotification } from 'common/modal'
import { CenteredLoader } from 'common/utils/centered-loader'
import { useLocalEvent } from 'common/use-event'
import useAsync from 'common/use-async'
import { securityIsReady } from 'common/secure/validate'
import { handle, raise, raiseLater } from 'common/events'
import { isAnonymous } from 'common/global-store/api'
import { localHistory } from 'common/history'
import { makeCachedStyles } from 'common/inline-styles'

localHistory.listen((params) => {
    raise('app.navigate', params)
})

export function getLocation() {
    return {
        location: localHistory.location,
        navigate: localHistory.navigate,
    }
}

export const useLocation = () => {
    const initialState = {
        location: localHistory.location,
        navigate: localHistory.navigate,
    }
    const canSetLocation = React.useRef(true)

    const [state, setState] = useState(initialState)
    useEffect(() => {
        const removeListener = localHistory.listen((params) => {
            const { location } = params
            const newState = Object.assign({}, initialState, { location })
            setTimeout(() => canSetLocation.current && setState(newState), 250)
        })
        return () => {
            canSetLocation.current = false
            removeListener()
        }
    }, [])

    return state
}

const { match } = utils

function SecureRoute({ secure, Component }) {
    const isReady = useAsync(
        async () => {
            await securityIsReady
            return true
        },
        false,
        'standard'
    )
    let path = secure()
    if (path) {
        if (!isReady) return null
        if (isAnonymous()) {
            store.set({ intendedDest: location.href })
            localHistory.navigate('/login')
            return null
        }
        setTimeout(() => {
            showNotification(`Error: You don't have permission to access that function`)
            localHistory.navigate('/')
        })
        return null
    }

    return Component
}

let isRedirecting = false

export function Route({ Component, path, secure, ...props }) {
    const info = {
        route: { path, secure, ...props },
        redirect: null,
    }
    events.emit('check-url-redirect', info)
    if (info.redirect) {
        if (!isRedirecting) {
            localHistory.navigate(info.redirect)
            isRedirecting = true
        }
        return null
    }

    if (secure) {
        return <SecureRoute secure={secure} Component={<Component path={path} {...props} />} />
    }

    setTimeout(() => (isRedirecting = false), 100)
    return <Component path={path} {...props} />
}

const PAGE_LOAD_TIMEOUT = 10000 //ms security will kick in after this
// 5 seconds to try load the page or presume they're trying to go somewhere unauthorised.

let shouldHideMenu = true
handle('using-path', (config) => {
    if (shouldHideMenu !== !!config.hideMenu) {
        shouldHideMenu = !!config.hideMenu
        raiseLater('refresh-menu-visible', shouldHideMenu)
        raiseLater('refresh-menu')
    }
})

handle('shouldShowMenu', (info) => {
    info.showMenu = !shouldHideMenu
})

function Simple() {
    Simple.propTypes = {
        navigate: PropTypes.any,
    }
    const timeout = React.useRef()
    React.useEffect(() => {
        // Attempt to wait 10 seconds to load page (OR enable app to reload in background if we hit > 1.5 secs then move them to login page)
        raise('using-path', { hideMenu: true })
        const handleNoPageFoundError = () => {
            //Page doesn't exist or user not authorised
            showNotification(`Error: You don't have permission to access that function`)
            console.error('Page navigation timeout hit, presuming unauthorised or bad URL')
            store.set({ intendedDest: location.href })
            localHistory.navigate('/')
        }
        clearTimeout(timeout.current)
        const timerId = (timeout.current = setTimeout(handleNoPageFoundError, PAGE_LOAD_TIMEOUT))
        return () => {
            clearTimeout(timerId)
        }
    })
    return <CenteredLoader />
}

export function getRoutes(purpose) {
    let routes = []
    events.emit('get-routes', routes, purpose)
    return routes.filter((route) => route.parts[purpose])
}

/**
 * Routing component to be embedded in the app
 * @param {String} purpose - routes may have multiple purposes returned to put parts of the UI in different locations,
 *     this specifies the purpose of the currently considered routing component
 * @param {Object} ...props - all other props passed through
 * @returns {Component} The Routing component for the desired route
 */
export const Routes = React.memo(
    function Routes({ children, purpose, ...props }) {
        const checkRoutes = useCallback(checkRoutesOuter, [])
        const [usefulRoutes, setUsefulRoutes] = useState(() => getRoutes(purpose))
        useLocalEvent('apps-loaded', checkRoutes)
        useLocalEvent('apps-updated', checkRoutes)
        useLocalEvent('routes-updated', checkRoutes)
        return (
            <Router key="router" primary={false} {...props}>
                {usefulRoutes.map((config) => {
                    const { path, parts, defaultRoute, ...route } = config
                    let Component = parts[purpose]

                    return <Route Component={active} default={defaultRoute} key={path} path={path} {...route} />
                    function active(props) {
                        raise('using-path', config)
                        return <Component {...props} />
                    }
                })}
                {children}
                <Simple default />
            </Router>
        )

        function checkRoutesOuter() {
            const currentRoutes = getRoutes(purpose)
            if (currentRoutes.length > usefulRoutes.length) {
                setUsefulRoutes(currentRoutes)
            }
        }
    },
    (a, b) => a.purpose === b.purpose
)

Routes.propTypes = {
    children: PropTypes.any,
    purpose: PropTypes.string.isRequired,
}

// eslint-disable-next-line react/prop-types
function Empty({ children }) {
    return <>{children}</>
}

export function RoutePartials({ purpose, children, ...props }) {
    let routes = []
    events.emit('get-routes', routes, purpose)
    let usefulRoutes = routes.filter((route) => route.parts[purpose])
    return (
        <Location>
            {({ location }) => {
                return (
                    <Router component={Empty} primary={false} {...props}>
                        {usefulRoutes
                            .filter((route) => match(route.path, location.pathname))
                            .map(({ path, parts, ...route }) => {
                                let Component = parts[purpose]
                                return <Route Component={Component} key={path} path="/" {...route} {...props} />
                            })}
                        {children}
                    </Router>
                )
            }}
        </Location>
    )
}

RoutePartials.propTypes = {
    purpose: PropTypes.string.isRequired,
    children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
}

/**
 * Add a route handling function
 * @param {Function} routeFn - a function to return routes
 * @example
 * routes(function() {
    return [{
        path: '/',
        parts: {
            main: Launcher,
            title() {
                return <>Welcome</>;
            }
        }
    }];
});

 */
export function routes(routeFn) {
    events.on('get-routes', function (event, routes) {
        let result = routeFn(routes)
        if (result) {
            result = Array.isArray(result) ? result : [result]
            for (let entry of result) {
                events.emit(`adapt-route.${entry.path}`, entry)
            }
            routes.push.apply(routes, result)
        }
    })
}

export const navigate = localHistory.navigate

Route.propTypes = {
    Component: PropTypes.any,
    path: PropTypes.any,
    secure: PropTypes.any,
}

SecureRoute.propTypes = {
    Component: PropTypes.any,
    children: PropTypes.any,
    secure: PropTypes.any,
}

const useStyles = makeCachedStyles((theme) => ({
    link: {
        cursor: 'pointer',
        '&:hover': {
            textDecoration: 'underline !important',
            color: theme.palette.primary.main,
        },
    },
}))

export function Link({ to, state, children, additionalClassName, ...props }) {
    const classes = useStyles()
    return (
        <a
            {...props}
            className={classNames(classes.link, additionalClassName)}
            onClick={(e) => {
                e.preventDefault()
                navigate(to, { state })
            }}
        >
            {children}
        </a>
    )
}

Link.propTypes = {
    children: PropTypes.any,
    state: PropTypes.any,
    to: PropTypes.any,
}
