import React from 'react'

import events from 'packages/alcumus-local-events'

import { store } from 'common/global-store'
import { routes } from 'common/routing'
import { getCurrentAuthenticatedUser } from 'common/global-store/api'
import SSOLogin from './SSO-login'
import LoginLayout from 'framework/LoginPage/login-layout'

events.on('check-url-redirect', (__event, info) => {
    if (
        (info.route.path.startsWith('/secure') || info.route.secure !== false) &&
        !getCurrentAuthenticatedUser() &&
        !window.location.href.includes('login')
    ) {
        store.set({ intendedDest: location.href })
        info.redirect = '/login'
    }
})
const LoginPage = React.lazy(() => import('./default-page'))
const ForgottenPasswordPage = React.lazy(() => import('./forgotten-password-page'))
const ForgottenPasswordConfirmEmailPage = React.lazy(() => import('./forgotten-password-confirm-email-page'))
const ForgottenPasswordConfirmSmsPage = React.lazy(() => import('./forgotten-password-confirm-sms-page'))
const ForgottenPasswordSuccessPage = React.lazy(() => import('./forgotten-password-success-page'))
const ForgottenPasswordUpdatePage = React.lazy(() => import('./forgotten-password-update-page'))
const RegistrationPage = React.lazy(() => import('./registration-page'))
const VerifyEmailRedirectPage = React.lazy(() => import('./verify-email-redirect-page'))
const VerifyEmailPage = React.lazy(() => import('./verify-email-page'))
const VerifySmsPage = React.lazy(() => import('./verify-sms-page'))
const VerifySmsRedirectPage = React.lazy(() => import('./verify-sms-redirect-page'))
const UpdateAccount = React.lazy(() => import('./update-account'))

const OtpLoginEmailRedirectPage = React.lazy(() => import('./otp-login-email-redirect-page'))
const OtpEmailPage = React.lazy(() => import('./otp-email-page'))
const OtpSmsPage = React.lazy(() => import('./otp-sms-page'))
const LoginCompletedElsewhere = React.lazy(() => import('./login-completed-elsewhere'))
const InvalidLink = React.lazy(() => import('./invalid-link'))
// const SSOLogin = React.lazy(() => import('./SSO-login'))

const switchPortal = process.env.REACT_APP_SWITCH_TO_PORTAL
const portalAddress = process.env.REACT_APP_PORTAL_ADDRESS
const clientPortalURL = process.env.REACT_APP_CLIENT_PORTAL_ADDRESS

const RedirectToPortal = () => {
    window.location.replace(
        `https://${portalAddress}/login?p=supplyChainCompliance&cu=${clientPortalURL}/api/auth/login/session`
    )
    return null
}

const ssoRoutes = [
    {
        path: '/login/sso/:ssoKey/:ssoToken',
        title: 'SSO Login',
        secure: false,
        parts: {
            main: LoginLayout,
            content: SSOLogin,
        },
    },
]

const existingRoutes = [
    ...ssoRoutes,
    {
        path: '/login',
        title: 'Login',
        secure: false,
        hideMenu: true,
        parts: {
            main: LoginLayout,
            content: LoginPage,
        },
    },
    {
        path: '/login/forgot',
        title: 'Forgotten Password',
        secure: false,
        parts: {
            main: LoginLayout,
            content: ForgottenPasswordPage,
        },
    },
    {
        path: '/login/forgot/confirm/email',
        title: 'Forgotten Password',
        secure: false,
        parts: {
            main: LoginLayout,
            content: ForgottenPasswordConfirmEmailPage,
        },
    },
    {
        path: '/login/forgot/confirm/sms',
        title: 'Forgotten Password',
        secure: false,
        parts: {
            main: LoginLayout,
            content: ForgottenPasswordConfirmSmsPage,
        },
    },
    {
        path: '/login/forgot/success',
        title: 'Forgotten Password',
        secure: false,
        parts: {
            main: LoginLayout,
            content: ForgottenPasswordSuccessPage,
        },
    },
    {
        path: '/login/register',
        title: 'Register Account',
        secure: false,
        parts: {
            main: LoginLayout,
            content: RegistrationPage,
        },
    },
    {
        path: '/login/verify',
        title: 'Verify Your Account',
        secure: false,
        parts: {
            main: LoginLayout,
            content: VerifyEmailRedirectPage,
        },
    },
    {
        path: '/login/verify/email',
        title: 'Verify Email',
        secure: false,
        parts: {
            main: LoginLayout,
            content: VerifyEmailPage,
        },
    },
    {
        path: '/login/verify/sms',
        title: 'Verify SMS',
        secure: false,
        parts: {
            main: LoginLayout,
            content: VerifySmsPage,
        },
    },
    {
        path: '/login/verify/sms/redirect',
        title: 'Verify SMS',
        secure: false,
        parts: {
            main: LoginLayout,
            content: VerifySmsRedirectPage,
        },
    },
    {
        path: '/login/update',
        secure: false,
        title: 'Update Account',
        parts: {
            main: LoginLayout,
            content: UpdateAccount,
        },
    },
    {
        path: '/login/updatepassword',
        secure: false,
        title: 'Update Password',
        parts: {
            main: LoginLayout,
            content: ForgottenPasswordUpdatePage,
        },
    },
    {
        path: '/login/otp/email/redirect',
        title: 'Login with OTP',
        secure: false,
        parts: {
            main: LoginLayout,
            content: OtpLoginEmailRedirectPage,
        },
    },
    {
        path: '/login/otp/email',
        title: 'Check your email',
        secure: false,
        parts: {
            main: LoginLayout,
            content: OtpEmailPage,
        },
    },
    {
        path: '/login/otp/sms',
        title: 'Check your sms',
        secure: false,
        parts: {
            main: LoginLayout,
            content: OtpSmsPage,
        },
    },
    {
        path: '/login/safetoclose',
        title: 'Login Completed elsewhere',
        secure: false,
        parts: {
            main: LoginLayout,
            content: LoginCompletedElsewhere,
        },
    },
    {
        path: '/login/invalid',
        title: 'Invalid link',
        secure: false,
        parts: {
            main: LoginLayout,
            content: InvalidLink,
        },
    },
]

const portalRoute = [
    ...ssoRoutes,
    {
        path: '/login',
        title: 'Login',
        secure: false,
        hideMenu: true,
        parts: {
            main: LoginLayout,
            content: RedirectToPortal,
        },
    },
]

switchPortal === 'true' ? routes(() => portalRoute) : routes(() => existingRoutes)
