import { jssPreset, useTheme } from '@material-ui/core/styles'
import { create } from 'jss'
import { generate } from 'shortid'
import { isMobile } from 'common/responsive'
import { useEffect } from 'react'
import { waitForIdle } from 'common/wait-for-idle'

const jss = create({
    plugins: jssPreset().plugins,
})
export function makeInlineStyles(fn) {
    return function useStyles(props) {
        const theme = useTheme()
        const values = fn(theme)
        processValues(values)
        return values

        function processValues(values) {
            for (let [key, value] of Object.entries(values)) {
                if (typeof value === 'function') value = values[key] = value(props)
                if (typeof value === 'object') processValues(value)
            }
        }
    }
}

let purgeFunctions = []

/**
 * Call to purge unused styles in idle time
 * @function
 */
export const purgeUnusedStyles = waitForIdle(function purgeUnusedStyles(inactiveTime = 3000) {
    for (let fn of purgeFunctions) {
        try {
            fn(inactiveTime)
        } catch (e) {
            //
        }
    }
}, 9)

function prepareSimple(props = {}, validProps) {
    let copied

    if (Object.isObject(props)) {
        copied = { window, ...props }
    } else {
        copied = { window, value: props }
        validProps = validProps || []
        validProps.push('value')
    }
    let useProps = props
    if (validProps) {
        useProps = { value: copied.value }
        for (let prop of validProps) {
            Object.set(useProps, prop, Object.get(copied, prop, true) || '')
        }
    }

    return JSON.stringify(useProps)
}

/**
 * Drop in replacement for Material UI/JSS makeStyles
 * @param {Function|Object} styles - the styles to make classes for
 * @param {...String|...<Array>.<String>} validProps - the parameters that will be used to create unique styles
 * @returns {function(*=): Object} a function to mount and use the styles, returning the classes
 */
export function makeCachedStyles(styles, ...validProps) {
    const toCall =
        typeof styles === 'function'
            ? styles
            : function () {
                  return styles
              }
    validProps = validProps.flat(Infinity)
    let sheets = new Map()
    let toPurge = new Map()
    let result = function useStyles(props) {
        const theme = useTheme()
        theme.id = theme.id || generate()
        let key = `${isMobile()}:${prepareSimple(props, validProps) || 'na'}:${theme.id}`
        if (!sheets.has(key)) {
            let sheet = jss.createStyleSheet(toCall(theme))
            try {
                sheet.update(props || {})
            } catch (e) {
                //
            }
            sheet.attach()
            sheets.set(key, { sheet, count: 0, time: Date.now() })
        } else {
            toPurge.delete(key)
        }
        useEffect(() => {
            let currentSheet = sheets.get(key)

            if (!currentSheet) return
            currentSheet.count++
            currentSheet.time = Date.now()

            return () => {
                currentSheet.count--
                currentSheet.time = Date.now()
                if (currentSheet.count === 0) {
                    toPurge.set(key, currentSheet)
                }
            }
        }, [key])
        return sheets.get(key).sheet.classes
    }
    purgeFunctions.push(purge)
    result.purge = purge
    return result

    function purge(inactiveTime = 3000) {
        let now = Date.now()
        for (let [key, value] of toPurge.entries()) {
            if (value?.count === 0 && now - value.time > inactiveTime) {
                value.sheet.detach()
                sheets.delete(key)
            }
        }
        toPurge.clear()
    }
}

const sheets = new Map()

/**
 * Creates and mounts a style sheet using a set of parameters
 * @param {Object} settings - the styles to mount as a style sheet
 * @returns {Object} the classes of the styles mounted
 */
export function makeCachedSheet(settings) {
    let key = prepareSimple(settings) || 'na'
    if (!sheets.has(key)) {
        let sheet = jss.createStyleSheet(settings)
        sheet.attach()
        sheets.set(key, sheet)
    }
    return sheets.get(key).classes
}
