import LRUCache from 'common/offline-data-service/lru'
import debounce from 'lodash/debounce'
import { clone } from 'common/utils/deep-copy'
import { getNamespacedKey } from 'common/namespaced-key'

function getPreferredStorageAPI() {
    return window.Cypress ? window.sessionStorage : window.localStorage
}

export async function using(key, fn, defaultValue) {
    let item = JSON.parse(getPreferredStorageAPI().getItem(getNamespacedKey(key)) || 'null')
    item = item ? item : clone(defaultValue)
    let result = await Promise.resolve(fn(item))
    item = result || item
    if (Object.isEqual(item, defaultValue)) {
        getPreferredStorageAPI().removeItem(getNamespacedKey(key))
    } else {
        item && getPreferredStorageAPI().setItem(getNamespacedKey(key), JSON.stringify(item))
    }
}

export function getItemAndParse(key, defaultValue) {
    let item = JSON.parse(getPreferredStorageAPI().getItem(getNamespacedKey(key)) || 'null')
    item = item ? item : JSON.parse(getPreferredStorageAPI().getItem(getNamespacedKey(key)) || 'null') || defaultValue
    return item
}

export function setItemWithStringify(key, value) {
    getPreferredStorageAPI().setItem(getNamespacedKey(key), JSON.stringify(value))
    return value
}

const lruKeys = new LRUCache(100)

export function getItemAndParseCached(key, defaultValue) {
    const existing = lruKeys.get(key)
    if (existing) return existing
    let item = JSON.parse(getPreferredStorageAPI().getItem(getNamespacedKey(key)) || 'null')
    item = item ? item : JSON.parse(getPreferredStorageAPI().getItem(getNamespacedKey(key)) || 'null') || defaultValue
    lruKeys.set(key, item)
    return item
}

export function setItemWithStringifyCached(key, value) {
    lruKeys.set(key, value)
    store(key, value)
    return value
}

const store = debounce(function store(key, value) {
    getPreferredStorageAPI().setItem(getNamespacedKey(key), JSON.stringify(value))
}, 1000)

export function getUnprefixedItemAndParse(key, defaultValue) {
    let item = JSON.parse(getPreferredStorageAPI().getItem(key) || 'null')
    item = item ? item : defaultValue
    return item
}

export function setUnprefixedItemWithStringify(key, value) {
    if (!value) {
        getPreferredStorageAPI().removeItem(key)
    } else {
        getPreferredStorageAPI().setItem(`${key}`, JSON.stringify(value))
    }
    return value
}

export function getItem(key) {
    return getPreferredStorageAPI().getItem(`${key}`)
}

export function setItem(key, value) {
    return getPreferredStorageAPI().setItem(`${key}`, value)
}

export function removeItem(key) {
    return getPreferredStorageAPI().removeItem(`${key}`)
}
