export let options = {
    opacity: {
        AlcOpacity_001: '10%',
        AlcOpacity_002: '25%',
        AlcOpacity_003: '50%',
        AlcOpacity_004: '75%',
    },
    document: {
        form: {
            fontFamily: 'Roboto, sans-serif',
        },
    },
    palette: {
        type: 'light',
        AlcBrand_001: '#1da7e0',
        AlcBrand_002: '#0081AF',
        AlcBrand_003: '#294A76',
        AlcBrand_004: '#0099B3',
        AlcBrand_005: '#604696',
        AlcBrand_006: '#D26A37',
        AlcBrand_007: '#679861',
        AlcBrand_008: '#EBBA00',
        AlcBrand_009: '#8C1D82',
        AlcBrand_010: '#DC840A',
        bronze: '#853',
        silver: 'silver',
        gold: 'gold',
        primary: {
            main: '#1da7e0',
            contrastText: '#fff',
        },
        AlcSys_001: '#FFFFFF',
        AlcSys_002: '#F7F8F9',
        AlcSys_003: '#F4F5F6',
        AlcSys_004: '#EBEBED',
        AlcSys_005: '#CCCCCC',
        AlcSys_006: 'rgba(125, 125, 125, 1)',
        AlcSys_006_75: 'rgba(125, 125, 125, .75)',
        AlcSys_006_50: 'rgba(125, 125, 125, .50)',
        AlcSys_006_25: 'rgba(125, 125, 125, .25)',
        AlcSys_006_10: 'rgba(125, 125, 125, .1)',
        AlcSys_007: '#263836',
        AlcSys_008: '#000000',
        AlcSys_Grad001: 'linear-gradient(55deg, #F7F8F9 100%, #F4F5F6 100%)',
        AlcSys_Grad002: 'linear-gradient(55deg, #1da7e0 100%, #604696 100%)',
        AlcSysState_green: 'rgba(63, 195, 128, 1)',
        AlcSysState_green_75: 'rgba(63, 195, 128, 0.75)',
        AlcSysState_green_50: 'rgba(63, 195, 128, 0.5)',
        AlcSysState_green_25: 'rgba(63, 195, 128, 0.25)',
        AlcSysState_green_10: 'rgba(63, 195, 128, 0.1)',
        AlcSysState_prog: 'rgba(235, 186, 0, 1)',
        AlcSysState_prog_75: 'rgba(235, 186, 0, .75)',
        AlcSysState_prog_50: 'rgba(235, 186, 0,.5)',
        AlcSysState_prog_25: 'rgba(235, 186, 0,.251)',
        AlcSysState_prog_10: 'rgba(235, 186, 0, .1)',
        AlcSysState_issue: 'rgba(233, 165, 75, 1)',
        AlcSysState_issue_75: 'rgba(233, 165, 75, .75)',
        AlcSysState_issue_50: 'rgba(233, 165, 75, .50)',
        AlcSysState_issue_25: 'rgba(233, 165, 75, .25)',
        AlcSysState_issue_10: 'rgba(233, 165, 75, .1)',
        AlcSysState_risk: 'rgba(233, 75, 75, 1)',
        AlcSysState_risk_75: 'rgba(233, 75, 75, .75)',
        AlcSysState_risk_50: 'rgba(233, 75, 75, .50)',
        AlcSysState_risk_25: 'rgba(233, 75, 75, .25)',
        AlcSysState_risk_10: 'rgba(233, 75, 75, .1)',
    },
    typography: {
        h1: {
            fontFamily: 'Montserrat, sans-serif',
            fontWeight: 700,
            fontSize: '2.8em',
        },
        h2: {
            fontFamily: 'Montserrat',
            fontWeight: 400,
            fontSize: '2.3em',
        },
        h3: {
            fontFamily: 'Montserrat',
            fontWeight: 400,
            fontSize: '2em',
        },
        h4: {
            fontFamily: 'Montserrat',
            fontWeight: 500,
            fontSize: '1.667em',
        },
        h5: {
            fontFamily: 'Montserrat',
            fontWeight: 500,
            fontSize: '1.333em',
        },
        h6: {
            fontFamily: 'Montserrat',
            fontWeight: 400,
            fontSize: '1.167em',
        },
        Action1_SemiBold: {
            fontFamily: 'Montserrat',
            fontWeight: 600,
            fontSize: '1.167em',
        },
        Action1_Medium: {
            fontFamily: 'Montserrat',
            fontWeight: 500,
            fontSize: '1.167em',
        },
        Action1_Regular: {
            fontFamily: 'Montserrat',
            fontSize: '1.167em',
        },
        Action2_SemiBold: {
            fontFamily: 'Montserrat',
            fontWeight: 600,
            fontSize: '1em',
        },
        Action2_Medium: {
            fontFamily: 'Montserrat',
            fontWeight: 500,
            fontSize: '1em',
        },
        Action2_Regular: {
            fontFamily: 'Montserrat',
            fontSize: '1em',
        },
        overline: {
            fontFamily: 'Montserrat',
            fontWeight: 500,
            fontSize: '0.917em',
            textTransform: 'uppercase',
        },
        body1: {
            fontFamily: 'Roboto',
            fontSize: '1.167em',
        },
        body2: {
            fontFamily: 'Roboto',
            fontSize: '1em',
        },
        body3: {
            fontFamily: 'Roboto',
            fontSize: '0.9em',
        },
        body4: {
            fontFamily: 'Roboto',
            fontSize: '14px',
        },
    },
    overrides: {
        MuiInputBase: {
            input: {
                fontSize: 16,
            },
            root: {
                fontSize: 16,
            },
        },
    },
}

export let thingBuilderOptions = {
    opacity: {
        AlcOpacity_001: '10%',
        AlcOpacity_002: '25%',
        AlcOpacity_003: '50%',
        AlcOpacity_004: '75%',
    },
    document: {
        fontFamily: 'Montserrat, sans-serif',
        fontSize: 16,
        form: {
            fontFamily: 'Montserrat, sans-serif',
        },
    },
    palette: {
        type: 'light',
        bronze: '#853',
        silver: 'silver',
        gold: 'gold',
        primary: { main: '#1C9DD4', contrastText: '#fff' },
        AlcSys_001: '#FFFFFF',
        AlcSys_002: '#F7F8F9',
        AlcSys_003: '#F4F5F6',
        AlcSys_004: '#EBEBED',
        AlcSys_005: '#CCCCCC',
        AlcSys_006: 'rgba(125, 125, 125, 1)',
        AlcSys_006_75: 'rgba(125, 125, 125, .75)',
        AlcSys_006_50: 'rgba(125, 125, 125, .50)',
        AlcSys_006_25: 'rgba(125, 125, 125, .25)',
        AlcSys_006_10: 'rgba(125, 125, 125, .1)',
        AlcSys_007: '#263836',
        AlcSys_008: '#000000',
        AlcSys_Grad001: 'linear-gradient(55deg, #F7F8F9 100%, #F4F5F6 100%)',
        AlcSys_Grad002: 'linear-gradient(55deg, #1da7e0 100%, #604696 100%)',
        AlcSysState_green: 'rgba(63, 195, 128, 1)',
        AlcSysState_green_75: 'rgba(63, 195, 128, 0.75)',
        AlcSysState_green_50: 'rgba(63, 195, 128, 0.5)',
        AlcSysState_green_25: 'rgba(63, 195, 128, 0.25)',
        AlcSysState_green_10: 'rgba(63, 195, 128, 0.1)',
        AlcSysState_prog: 'rgba(235, 186, 0, 1)',
        AlcSysState_prog_75: 'rgba(235, 186, 0, .75)',
        AlcSysState_prog_50: 'rgba(235, 186, 0,.5)',
        AlcSysState_prog_25: 'rgba(235, 186, 0,.251)',
        AlcSysState_prog_10: 'rgba(235, 186, 0, .1)',
        AlcSysState_issue: 'rgba(233, 165, 75, 1)',
        AlcSysState_issue_75: 'rgba(233, 165, 75, .75)',
        AlcSysState_issue_50: 'rgba(233, 165, 75, .50)',
        AlcSysState_issue_25: 'rgba(233, 165, 75, .25)',
        AlcSysState_issue_10: 'rgba(233, 165, 75, .1)',
        AlcSysState_risk: 'rgba(233, 75, 75, 1)',
        AlcSysState_risk_75: 'rgba(233, 75, 75, .75)',
        AlcSysState_risk_50: 'rgba(233, 75, 75, .50)',
        AlcSysState_risk_25: 'rgba(233, 75, 75, .25)',
        AlcSysState_risk_10: 'rgba(233, 75, 75, .1)',
        AlcBrand_001: '#1C9DD4',
        AlcBrand_002: '#0081AF',
        AlcBrand_003: '#FFD61B',
        AlcBrand_004: '#F15A29',
        AlcBrand_005: '#AB218E',
        AlcBrand_006: '#F99D1C',
        AlcBrand_007: '#4CA22F',
        AlcBrand_008: '#EEECE8',
    },
    overrides: {
        MuiCardHeader: {
            title: {
                fontSize: 24,
                fontWeight: 500,
                fontFamily: 'Montserrat, sans-serif',
            },
        },
        MuiButton: {
            root: {
                // letterSpacing: 1.43,
                fontFamily: 'Montserrat, sans-serif',
                fontSize: 16,
                textTransform: 'none',
            },
        },
        MuiChip: {
            root: {
                fontFamily: 'Montserrat, sans-serif',
            },
        },
        MuiPaper: {
            root: {
                fontFamily: 'Montserrat, sans-serif',
            },
        },
        MuiDialogTitle: {
            root: {
                fontSize: 24,
                fontWeight: 500,
            },
        },
    },
    typography: {
        h1: {
            fontFamily: 'Montserrat, sans-serif',
            fontWeight: 500,
            fontSize: 36,
            textTransform: 'uppercase',
        },
        h2: {
            fontFamily: 'Montserrat, sans-serif',
            fontWeight: 500,
            fontSize: 24,
            textTransform: 'uppercase',
        },
        h3: {
            fontFamily: 'Montserrat, sans-serif',
            fontWeight: 500,
            fontSize: 24,
            textTransform: 'none',
        },
        h4: {
            fontFamily: 'Montserrat, sans-serif',
            fontWeight: 500,
            fontSize: 18,
            textTransform: 'none',
        },
        h5: {
            fontFamily: 'Montserrat, sans-serif',
            fontWeight: 400,
            fontSize: 16,
            textTransform: 'none',
        },
        h6: {
            fontFamily: 'Montserrat, sans-serif',
            fontWeight: 400,
            fontSize: 14,
            textTransform: 'none',
        },
        Action1_SemiBold: {
            fontFamily: 'Montserrat, sans-serif',
            fontWeight: 600,
            fontSize: '1.167em',
        },
        Action1_Medium: {
            fontFamily: 'Montserrat, sans-serif',
            fontWeight: 500,
            fontSize: '1.167em',
        },
        Action1_Regular: {
            fontFamily: 'Montserrat, sans-serif',
            fontSize: '1.167em',
        },
        Action2_SemiBold: {
            fontFamily: 'Montserrat, sans-serif',
            fontWeight: 600,
            fontSize: '1em',
        },
        Action2_Medium: {
            fontFamily: 'Montserrat, sans-serif',
            fontWeight: 500,
            fontSize: '1em',
        },
        Action2_Regular: {
            fontFamily: 'Montserrat, sans-serif',
            fontSize: '1em',
        },
        overline: {
            fontFamily: 'Montserrat, sans-serif',
            fontWeight: 400,
            fontSize: 15,
            textTransform: 'uppercase',
        },
        body1: {
            fontFamily: 'Montserrat, sans-serif',
            fontSize: 16,
        },
        body2: {
            fontFamily: 'Montserrat, sans-serif',
            fontSize: 14,
        },
        body3: {
            fontFamily: 'Montserrat, sans-serif',
            fontSize: 12,
        },
        body4: {
            fontFamily: 'Montserrat, sans-serif',
            fontSize: 10,
        },
        button: {
            fontFamily: 'Montserrat, sans-serif',
            fontSize: 16,
            textTransform: 'none',
        },
        subtitle1: {
            fontFamily: 'Montserrat, sans-serif',
            fontSize: 24,
        },
        subtitle2: {
            fontFamily: 'Montserrat, sans-serif',
            fontSize: 20,
        },
        input: {
            fontFamily: 'Montserrat, sans-serif',
            fontSize: 16,
        },
        caption: {
            fontFamily: 'Montserrat, sans-serif',
            fontSize: 12,
        },
    },
}
