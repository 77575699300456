import { useWidgetContext } from './contexts'

function inPriorityOrder(a, b) {
    return -(b.priority || 1) + (a.priority || 1)
}

export function thereCanBeOnlyOne(fn) {
    let resultFn = function () {
        let ctx = useWidgetContext()
        if (ctx._tcbooIsRendered) return null
        let result = fn()
        ctx._tcbooIsRendered = !!result
        return result
    }
    Object.defineProperties(resultFn, {
        priority: {
            get: function () {
                return fn.priority
            },
        },
        onBeforeRender: {
            get: function () {
                return fn.onBeforeRender
            },
        },
    })
    return resultFn
}

function processRenderFunctions(list) {
    list.sort(inPriorityOrder)
    let renderFunctionList = list.filter((item) => item.onBeforeRender)
    for (let item of renderFunctionList) {
        const result = item.onBeforeRender(list)
        list = Array.isArray(result) ? result : list
    }
    return list
}

export { processRenderFunctions }
export { inPriorityOrder }
