import queryString from 'query-string'

let { fake_navigator_online = window.FAKE_NAVIGATOR_ONLINE ?? process.env.FAKE_NAVIGATOR_ONLINE } = queryString.parse(
    window.location.search
)

window.FAKE_NAVIGATOR_ONLINE = fake_navigator_online

let { app_env_url = process.env.REACT_APP_ENV_URL } = queryString.parse(window.location.search)
window.app_env_url = app_env_url

export let baseUrl = app_env_url
