import React, { useCallback, useContext, useEffect, useRef, useState } from 'react'
import useAsync, { AsyncWaitContext } from 'common/use-async'

/**
 * A hook that allows you to create a function
 * that will be nulled when the current component
 * unmounts.  Calling the function after an unmount
 * will be a noop.
 * @param {function} fn - the function to be wrapped
 * @returns {function} a version of the function that is a
 * noop after the component unmounts
 */
export function useCurrent(fn) {
    let currentSetV = useRef()
    currentSetV.current = fn
    useEffect(() => {
        return () => (currentSetV.current = null)
    }, [])
    return function (...params) {
        currentSetV.current && currentSetV.current(...params)
    }
}

/**
 * A hook that does the equivalent of React.useState, however
 * if the component unmounts, the set function of the
 * state will be a noop.  Helps with asyncs that later try to
 * update a component that has been unmounted.
 * @param {...*} [params] - parameters passed to useState
 * @returns {Array} - the same as React useState
 */
export function useCurrentState(...params) {
    const [value, setValue] = useState(...params)
    let currentSetV = useRef()
    let currentGotV = useRef()
    currentSetV.current = setValue
    currentGotV.current = value
    useEffect(() => {
        return () => (currentSetV.current = null)
    }, [])
    return [
        value,
        function (...params) {
            currentSetV.current && currentSetV.current(...params)
        },
        () => currentGotV.current,
    ]
}

export function useWaitBump(waitMs = 50) {
    const bumped = useRef(false)
    const bumpedTimer = useRef(0)
    const delay = useWaitingDelay()
    return function () {
        if (bumped.current) return true
        bumped.current = true
        clearTimeout(bumpedTimer.current)
        bumpedTimer.current = setTimeout(() => {
            bumped.current = false
        })
        delay(waitMs)
    }
}

export function useWaitingDelay() {
    const context = useContext(AsyncWaitContext)
    return function (delay = 500) {
        context.increment()
        setTimeout(() => context.decrement(), delay)
    }
}

export function useWaitUntilDrawn() {
    const { increment, decrement } = useAsyncWaitContext()
    useState(() => increment())
    useEffect(() => {
        decrement()
    }, [])
}

export function useAsyncWaitContext() {
    return useContext(AsyncWaitContext)
}

export function asyncProp(fn) {
    if (fn) {
        fn._isAsync = true
        return fn
    } else {
        return fn
    }
}

export function importComponent(component) {
    let loaded = false
    let current = () => {}
    return function Component(props) {
        const Component = useAsync(async () => {
            let result = current
            if (!loaded) {
                result = await component()
            }
            loaded = true
            return result
        }, current)
        const Draw = useCallback(Component, [Component ? 1 : 0])
        return <Draw {...props} />
    }
}

export function createKey(...ids) {
    return ids
        .flatten()
        .map((i) => (Object.isObject(i) ? i?._id || i?.id || JSON.stringify(i) || 'N' : `${i || '-'}`))
        .join(':')
}

export function simpleOnly(key, value) {
    if (key.startsWith('_') || key.startsWith('$')) return undefined
    if (typeof value === 'string' && value.startsWith('data:')) return value.slice(0, 100)
    return value
}

export const CancelAsync = Symbol('CancelAsync')

export function standard(fn) {
    fn._isAsync = false
    return fn
}
