import { getDocumentWithChanges } from 'dynamic/awe-library/runtime/lib/get-instance-controller'
import { handle, raiseAsync } from 'common/events'

export const retrieveDocument = async function retrieveDocument(id) {
    if (retrieved.has(id)) return retrieved.get(id)
    toRetrieve.set(id, true)
    let doc
    if (retrieving.has(id)) {
        doc = retrieving.get(id)
    } else {
        doc = getDocumentWithChanges(id)
        retrieving.set(id, doc)
    }
    doc = await doc
    if (doc) await raiseAsync('prepare-document-context', doc)
    retrieving.delete(id)
    retrieved.set(id, doc)
    return doc
}
const retrieving = new Map()
export const retrieved = new Map()
export const toRetrieve = new Map()
handle('data.changed', (id) => {
    retrieved.delete(id)
    toRetrieve.delete(id)
})
