import * as Behaviours from 'packages/alcumus-behaviours'

const init = Behaviours.initialize

Behaviours.initialize = function (item, ...params) {
    if (!item) return null
    return init(item, ...params)
}

export async function initialize(item, ...params) {
    if (!item) return null
    // if (item.behaviours) return item
    return init(item, ...params)
}

export function initializeSync(item, ...params) {
    if (!item) return null
    return init(item, ...params)
}
