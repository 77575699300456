/**
 * @module common/sortable
 * @description Sortable Material UI components using react-sortable-hoc
 */
import React from 'react'
import PropTypes from 'prop-types'
import { SortableContainer, SortableElement, SortableHandle } from 'alcumus-react-sortable-hoc'
import { generate } from 'packages/identifiers'

import { MdDelete } from '@react-icons/all-files/md/MdDelete'
import { MdFormatAlignJustify } from '@react-icons/all-files/md/MdFormatAlignJustify'

import { Button, Icon, IconButton, List } from '@material-ui/core'
import ListItem from '@material-ui/core/ListItem'

function Div({ children, ...props }) {
    return <div {...props}>{children}</div>
}

/**
 * @function SortableListItem
 * @param {object} props - the properties for the ListItem and hoc
 * @description A sortable Material UI List Item
 * @returns {JSX.Element}
 */
export const SortableListItem = SortableElement(ListItem)
/**
 * @function SortableDiv
 * @description A sortable div
 * @param {object} props - the properties for the div and hoc
 * @type {JSX.Element}
 */
export const SortableDiv = SortableContainer(Div)
/**
 * @function SortableList
 * @description A sortable Material UI List
 * @param {object} props - the properties for the List and hoc
 * @returns {JSX.Element}
 */
export const SortableList = SortableContainer(List)
/**
 * @function
 * @description A default handle for a sortable item
 * @returns {JSX.Element}
 */
export const Handle = SortableHandle(() => (
    <Icon style={{ color: '#aaa', lineHeight: 0, display: 'inline-block' }}>
        <MdFormatAlignJustify />
    </Icon>
))
export const WrappedHandle = SortableHandle(({ children }) => children)

/**
 * @function
 * @description A button to remove this item from a sortable list
 * @param refresh - the refresh hook for the current list
 * @param {Array} collection - the list
 * @param entry - the entry that will be removed if the button is clicked
 * @returns {JSX.Element} An element that contains a button to remove the element
 */
export const DeleteFromCollection = function ({ refresh, collection, entry, ...props }) {
    return (
        <IconButton onClick={remove} color="secondary" {...props}>
            <MdDelete />
        </IconButton>
    )

    function remove() {
        collection.splice(collection.indexOf(entry), 1)
        refresh()
    }
}

DeleteFromCollection.propTypes = {
    refresh: PropTypes.func,
    collection: PropTypes.array,
    entry: PropTypes.object,
}

export const AddToCollection = function ({ refresh, collection, defaultEntry = {}, buttonText = '+ Tab', ...props }) {
    return (
        <Button color="primary" onClick={addEntry} {...props}>
            {buttonText}
        </Button>
    )

    function addEntry() {
        collection.push({ ...defaultEntry, id: generate() })
        refresh()
    }
}

AddToCollection.propTypes = {
    refresh: PropTypes.func,
    collection: PropTypes.array,
    defaultEntry: PropTypes.object,
    buttonText: PropTypes.string,
}
