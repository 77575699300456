import { store, tracked } from 'common/global-store'
import { getWindowDimensions } from 'common/responsive'

if (!store.menu) {
    store.set(
        tracked({
            menu: tracked({
                items: tracked([]),
                selected: null,
                title: '',
                visible: false,
                width: getWindowDimensions().innerWidth,
                showMain: false,
                hideSub: false,
                hideMain: false,
                showSub: false,
                ariaLabel: '',
                ariaSubMenuIsVisible: false,
                ariaMainMenuIsVisible: false,
            }),
        })
    )
}

export const menu = store.menu.value
export default store
