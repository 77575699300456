/**
 * @interface Window
 * @global
 * @description Wraps the global window object.
 */
export class WindowWrapper {
    constructor(theWindow) {
        this._window = theWindow
    }

    /**
     * If you are calling this function to check a property on window, consider introducing a {DebugFlag} instead
     * @param path - the field name on the window object to check
     * @returns {boolean} whether the property is defined and truthy
     */
    hasProperty(path) {
        return !!(this._window[path] !== undefined && this._window[path])
    }

    getProperty(path) {
        return Object.get(this._window, path)
    }

    setProperty(path, value) {
        Object.set(this._window, path, value)
    }

    addEventListener(eventName, eventHandler) {
        this._window.addEventListener(eventName, eventHandler)
    }

    removeEventListener(eventName, eventHandler) {
        this._window.removeEventListener(eventName, eventHandler, false)
    }

    getInstance() {
        return this._window
    }
}

const Window = new WindowWrapper(window)
export default Window
