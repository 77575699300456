const guards = new WeakSet()

export function guard(fn) {
    return process

    function process(...params) {
        if (guards.has(fn)) {
            return setTimeout(() => process(...params))
        }
        guards.add(fn)
        try {
            return fn(...params)
        } finally {
            guards.delete(fn)
        }
    }
}

export function onlyOne(fn) {
    const cache = new Map()
    return process

    async function process(...params) {
        let key = params.map(makeSafe).join(':')
        if (cache.has(key)) {
            return await cache.get(key)
        }
        try {
            let promise = fn(...params)
            cache.set(key, promise)
            return await promise
        } finally {
            cache.delete(key)
        }
    }
}

function makeSafe(param) {
    try {
        return JSON.parse(JSON.stringify(param))
    } catch (e) {
        if (param) {
            return param.name || param.toString()
        } else {
            return param === 'undefined' ? '<un>' : param
        }
    }
}

export function blockDeep(fn) {
    return process

    function process(...params) {
        if (guards.has(fn)) {
            return
        }
        guards.add(fn)
        try {
            let result = fn(...params)
            if (result?.then) {
                return result.finally(() => guards.delete(fn))
            }
            guards.delete(fn)
        } catch (e) {
            guards.delete(fn)
        }
    }
}
