import React, { useState } from 'react'
import { store } from 'common/global-store'

import { plug } from 'common/widgets'
import { parameter } from 'common/query-params'
import { validateSecurity } from './common/secure/validate'
import { getActiveUser, getCurrentAuthenticatedUser } from 'common/global-store/api'

export function DebugWindow() {
    const [hidden, setHidden] = useState(true)
    const Pi = () => (
        <div
            style={{
                height: 25,
                width: 25,
                backgroundColor: '#bbb',
                borderRadius: '50%',
                display: 'inline-block',
                textAlign: 'center',
            }}
            onClick={() => setHidden(!hidden)}
        >
            &pi;
        </div>
    )

    const Info = () => {
        return (
            <div
                style={{
                    padding: 20,
                    overflow: 'auto',
                    userSelect: 'auto !important',
                }}
            >
                <Pi />
                <hr />
                <h5>store.user</h5>
                <pre style={{ color: 'white', userSelect: 'all' }}>
                    {JSON.stringify(getCurrentAuthenticatedUser(), null, 2)}
                </pre>
                <h5>store.profile</h5>
                <pre style={{ color: 'white', userSelect: 'all' }}>{JSON.stringify(store.$profile, null, 2)}</pre>
                {/*<h5>store.demands</h5>*/}
                {/*<pre style={{ color: 'white', userSelect: 'all' }}>{JSON.stringify(store.$demands, null, 2)}</pre>*/}
                <h5>store.identityId</h5>
                <pre style={{ color: 'white', userSelect: 'all' }}>{JSON.stringify(getActiveUser(), null, 2)}</pre>
                <h5>localStorage</h5>
                <pre style={{ color: 'white', userSelect: 'all' }}>{JSON.stringify(localStorage, null, 2)}</pre>
            </div>
        )
    }

    if (!validateSecurity('debugmode') && !window.SHOW_DEBUG) return null

    return (
        <div
            style={{
                position: 'absolute',
                right: 10,
                backgroundColor: hidden ? 'inherit' : '#444',
                height: hidden ? 25 : '100%',
                bottom: 10, //hidden ? 90 : 0,
                color: 'white',
                width: hidden ? 25 : '100%',
                zIndex: 99999,
                overflow: 'auto',
                userSelect: 'auto !important',
            }}
        >
            {hidden ? <Pi /> : <Info />}
        </div>
    )
}

if (!parameter('noLoad')) {
    plug('main-after', function () {
        return <DebugWindow />
    })
}
