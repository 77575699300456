import { events } from 'common/events'
import { navigate } from 'common/routing'
import { starting } from './index'
import { loaderFinished } from 'common/use-async'

window.Events = {
    post(...params) {
        return new Promise((resolve) => {
            setTimeout(async () => {
                await events.emitAsync(
                    ...params.map((c) => {
                        if (typeof c === 'function') return c
                        return JSON.parse(JSON.stringify(c))
                    })
                )
                resolve()
            }, 10)
        })
    },
    emit(...params) {
        events.emit(...params)
    },
    emitAsync(...params) {
        return events.emitAsync(...params)
    },
    once(...params) {
        setTimeout(() => {
            events.once(...params)
        })
    },
    on(...params) {
        events.on(...params)
    },
    off(...params) {
        events.off(...params)
    },
    clear() {
        events.removeAllListeners()
    },
}

events.on('navigate', async (__, url) => {
    await navigate(url)
})

events.on('wait-for-ready', async () => {
    await starting
    await new Promise((resolve) => setTimeout(resolve, 50))
    await loaderFinished()
    await new Promise((resolve) => setTimeout(resolve, 10))
    await loaderFinished()
})

window.onerror = function (event) {
    if (event?.message?.startsWith('Resize')) {
        event.preventDefault()
        return true
    }
}

window.addEventListener('error', (event) => {
    if (event?.message?.startsWith('Resize')) {
        event.preventDefault()
        return true
    }
})
