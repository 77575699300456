import events from 'packages/alcumus-local-events'

import filter from 'lodash/filter'
import isEqual from 'lodash/isEqual'
import keyBy from 'lodash/keyBy'
import { store } from 'common/global-store'
import { asyncLogIif, logAndReturn, logIif } from 'common/logCall'
import { whenActive } from 'common/page-active'
import { define } from 'common/process'

import { parse } from 'framework/demands/parser'

import { dontWaitForAsyncHandlerAndDefer, handle, raise } from 'common/events'
import { getLocalItem, storeLocalItem } from 'common/local-storage/local-store'
import noop from 'common/noop'
import { getActiveUser } from 'common/global-store/api'

const getRoles = define('roles.get', function ({ cacheResult, offlineCache, returns }) {
    cacheResult(async (result) => {
        await storeLocalItem('roles_cache', result)
    })
    offlineCache(async () => {
        return getLocalItem('roles_cache', null)
    })
    returns('roles')
})
let roleDictionary = {}
let lastDictionary = {}

let rolePromise = Promise.resolve(true)
let cancelRoleRequest = noop

handle('stop-listening', cancelRoleRequest)

export const loadRoles = async () => {
    try {
        await rolePromise
        // eslint-disable-next-line no-async-promise-executor
        rolePromise = new Promise(async (resolve, reject) => {
            cancelRoleRequest = () => reject('cancelled')
            const roles = await getRoles()
            store.set({ demands: null })
            roleDictionary = keyBy(
                filter(roles, (role) => role && role.name),
                (role) => role.name.toLowerCase()
            )
            if (!isEqual(roleDictionary, lastDictionary)) {
                lastDictionary = roleDictionary
                await events.emitAsync('roles-ready', null)
                await events.emitAsync('refresh-apps', null)
            }
            resolve()
        })
        await rolePromise
    } catch (e) {
        console.warn('Unable to acquire roles.')
    } finally {
        raise('roles-processed')
    }
}

setInterval(whenActive(loadRoles), 1000 * 60 * 5)

events.on('auth.user-stored', dontWaitForAsyncHandlerAndDefer(loadRoles))

export const securityIsReady = new Promise((resolve) => {
    events.once('user-is-ready', resolve)
})

function standardResolve() {
    return store?.profile?.value?.fundamental ?? []
}

async function process(currentDemand, availableDemands, ...params) {
    if (typeof currentDemand === 'string') {
        return availableDemands.includes(currentDemand)
    }
    if (currentDemand.or) {
        let result = currentDemand.or.some((demand) => process(demand, availableDemands, ...params))
        return currentDemand.not ? !result : result
    }
    if (currentDemand.and) {
        let result = currentDemand.and.every((demand) => process(demand, availableDemands, ...params))
        return currentDemand.not ? !result : result
    }
    if (currentDemand.call) {
        let info = { authorise: false }
        await events.emitAsync(`validation.${currentDemand.call}`, info, availableDemands, ...params)
        return info.authorise
    }

    return false
}

function processSync(currentDemand, availableDemands, ...params) {
    if (typeof currentDemand === 'string') {
        return availableDemands.includes(currentDemand)
    }
    if (currentDemand.or) {
        const result = currentDemand.or.some((demand) => availableDemands.includes(demand))
        return currentDemand.not ? !result : result
    }
    if (currentDemand.and) {
        const result = currentDemand.and.every((demand) => availableDemands.includes(demand))
        return currentDemand.not ? !result : result
    }
    if (currentDemand.call) {
        let info = { authorise: false }
        events.emit(`validation.${currentDemand.call}`, info, availableDemands, ...params)
        return info.authorise
    }
    return false
}

export function validateSecurity(demands = '', resolveDemands = standardResolve, ...params) {
    if (!demands || demands.length === 0) return true
    let availableDemands = resolveDemands()

    if (availableDemands.includes('super-admin')) return true
    if (!getActiveUser()) return logAndReturn(false, 'No valid user')
    demands = Array.isArray(demands) ? demands.join(',') : demands
    let toExecute = parse(demands)

    return logIif(
        () => processSync(toExecute, availableDemands, ...params),
        null,
        'process returned false',
        'Process security exception'
    )
}

export async function validateSecurityAsync(demands, resolveDemands = standardResolve, ...params) {
    if (demands.length === 0) return true
    if (!getActiveUser()) return logAndReturn(false, 'No valid user')
    demands = Array.isArray(demands) ? demands.join(',') : demands
    let availableDemands = resolveDemands()
    if (availableDemands.includes('super-admin')) return true
    let toExecute = parse(demands)
    return asyncLogIif(
        async () => await process(toExecute, availableDemands, ...params),
        null,
        'process returned false',
        'Process security exception'
    )
}
