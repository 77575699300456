import { dontWaitForAsyncHandlerAndDefer, handle } from 'common/events'
import { define } from 'common/process'
import { isDatabaseId } from 'common/offline-data-service/utils'
import { addRetrieveId } from 'common/offline-data-service/offline-sync-catchup'

handle('catchupFailed', dontWaitForAsyncHandlerAndDefer(checkForSyncDocuments, 'catchupFailed'))
handle('change-active-client', dontWaitForAsyncHandlerAndDefer(checkForSyncDocuments, 'change-active'))

const getAssociations = define('associations.get', ({ returns }) => {
    returns('ids')
})

async function checkForSyncDocuments() {
    const associations = await getAssociations()
    const associationsWithDb = associations?.filter(isDatabaseId)
    if (associationsWithDb) {
        associationsWithDb.forEach((association) => addRetrieveId(association))
    }
}
