import { LRUCache } from 'common/offline-data-service/lru'
import { handle } from 'common/events'
import { clone } from 'common/utils/deep-copy'

let _lruCache

export class OfflineFirstRetriever {
    static resetInstance() {
        _lruCache = new LRUCache(1200, {
            mapValue: (v) => clone(v || {}),
        })
    }

    static removeLocal(id) {
        _lruCache.del(id)
    }

    static setLocal(id, record) {
        _lruCache.set(id, record)
    }

    static getLocal(id) {
        return _lruCache.get(id)
    }

    static getKeys() {
        return _lruCache.keys
    }
}

window.getLruRecord = OfflineFirstRetriever.getLocal

handle('clear-all-caches', OfflineFirstRetriever.resetInstance)
handle('published', OfflineFirstRetriever.resetInstance)

OfflineFirstRetriever.resetInstance()
