export function logCall(fn) {
    return function (...params) {
        if (window.DEBUG_LOGS) {
            // eslint-disable-next-line no-console
            console.trace('Call', fn.name, params)
        }
        return fn(...params)
    }
}

export function logParams(fn, trace, urgent) {
    return function (...params) {
        let result = fn(...params)
        if (window.DEBUG_LOGS || urgent) {
            if (trace) {
                // eslint-disable-next-line no-console
                console.trace('Call', fn.name || urgent, params, result)
            } else {
                // eslint-disable-next-line no-console
                console.log('Call', fn.name || urgent, params, result)
            }
        }
        return result
    }
}

export function logAndReturn(returnValue, ...message) {
    if (!window.DEBUG_LOGS) return returnValue
    // eslint-disable-next-line no-console
    console.log(...message, returnValue)
    return returnValue
}

export function traceAndReturn(returnValue, ...message) {
    if (!window.DEBUG_LOGS) return returnValue
    // eslint-disable-next-line no-console
    console.trace(...message, returnValue)
    return returnValue
}

export function logIif(returnValue, trueMessage, falseMessage, exceptionMessage) {
    if (!window.DEBUG_LOGS) return returnValue()
    if (typeof returnValue === 'function') {
        try {
            returnValue = returnValue()
        } catch (e) {
            // eslint-disable-next-line no-console
            console.log(exceptionMessage, e.message)
            throw e
        }
    }
    // eslint-disable-next-line no-console
    if (!returnValue && falseMessage) console.trace(falseMessage, returnValue)
    // eslint-disable-next-line no-console
    if (!!returnValue && trueMessage) console.trace(trueMessage, returnValue)
    return returnValue
}

export async function asyncLogIif(returnValue, trueMessage, falseMessage, exceptionMessage) {
    if (!window.DEBUG_LOGS) return await returnValue()
    if (typeof returnValue === 'function') {
        try {
            returnValue = await returnValue()
        } catch (e) {
            // eslint-disable-next-line no-console
            console.log(exceptionMessage, e.message)
            throw e
        }
    }
    // eslint-disable-next-line no-console
    if (!returnValue && falseMessage) console.trace(falseMessage, returnValue)
    // eslint-disable-next-line no-console
    if (returnValue && trueMessage) console.trace(trueMessage, returnValue)
    return returnValue
}
