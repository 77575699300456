import React from 'react'

import Fab from '@material-ui/core/Fab'
import FormControl from '@material-ui/core/FormControl'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import CreditCard from '@material-ui/icons/CreditCard'

import { showModal } from 'common/modal'

import { handleInvite, InviteAccept } from './launcher-invite'
import { makeCachedStyles } from 'common/inline-styles'

const useStyles = makeCachedStyles((theme) => ({
    label: {
        marginTop: 8,
        fontSize: 12,
        fontFamily: 'Roboto',
        color: '#03a9f4',
    },
    inviteDialogButton: {
        bottom: theme.spacing(2),
        color: 'white',
        position: 'fixed',
        right: theme.spacing(2),
    },
}))

export const LaunchpadActions = () => {
    const classes = useStyles()

    async function onClick() {
        const code = await showModal(InviteAccept)
        if (code) {
            await handleInvite({ code })
        }
    }

    return (
        <FormControl className={classes.inviteDialogButton}>
            <FormControlLabel
                style={{ color: 'black', marginRight: 8, marginTop: 8 }}
                classes={{
                    label: classes.label,
                }}
                size="small"
                control={
                    <Fab
                        style={{ color: 'white', padding: 8 }}
                        color="primary"
                        aria-label="add"
                        size="large"
                        onClick={onClick}
                        data-testid="accept-invitation-fab"
                    >
                        <CreditCard />
                    </Fab>
                }
                label="Accept Invitation"
                labelPlacement="bottom"
            >
                Accept Invitation
            </FormControlLabel>
        </FormControl>
    )
}
