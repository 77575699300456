/**
 * @module dynamic/awe-library/runtime/question-value
 */
import { useAsync, usePreferCachedAsync } from 'common/use-async'
import { lookup, lookupAsync } from './lookup'
import { initialize } from 'common/offline-data-service/behaviour-cache'
import { handle, raise } from 'common/events'
import { ensureArray } from 'common/ensure-array'
import { useRefresh } from 'common/useRefresh'
import { useRefreshOnField } from 'dynamic/awe-library/runtime/use-value'
import { parse } from 'packages/data-embed'
import { isDatabaseId } from 'common/offline-data-service/utils'
import { retrieveDocument } from 'dynamic/awe-library/runtime/document-retrieve'
import React, { useState } from 'react'

async function getSubValue(document, parts) {
    // if (parts.includes('todaysTasksCount')) debugger
    if (parts.length > 1) {
        const question = lookup(document)[parts[0]]
        let name = parts[0]
        if (question) {
            name = question.name
        }
        const value = Object.get(document, name, true)
        if (value === undefined || value === null) return [null, null]
        if (Array.isArray(value)) {
            const output = []
            for (let entry of value) {
                let subDocument = await retrieveDocument(entry)
                await initialize(subDocument)
                output.push(await getSubValue(subDocument, parts.slice(1)))
            }
            return [
                output
                    .map((o) => o[0])
                    .filter(Boolean)
                    .join(', '),
                output.map((o) => o[1]).compact(true)[0],
            ]
        } else {
            let subDocument = typeof value !== 'string' ? value : await retrieveDocument(value)
            await initialize(subDocument)
            return getSubValue(subDocument, parts.slice(1))
        }
    } else {
        const lookup = await lookupAsync(document)
        let question = lookup[parts[0]]
        if (!question) {
            return [Object.get(document, parts[0], true), {}]
        }
        return [Object.get(document, question.name, true), question]
    }
}

async function getSubValueQuick(document, parts, index = 0) {
    if (parts.length - index > 1) {
        let name = parts[index]
        const value = Object.get(document, name, true)
        if (!value) return [null, null]
        if (isDatabaseId(value)) {
            return await getSubValueQuick(await retrieveDocument(value), parts, index + 1)
        } else if (typeof value === 'object') {
            return await getSubValueQuick(value, parts, index + 1)
        } else {
            return [null, null]
        }
    } else {
        const lookup = await lookupAsync(document)
        let question = lookup[parts[index]]
        const value = Object.get(document, parts[index], true)
        if (!question) {
            return [value, {}]
        }
        if (question.choices) {
            return [question.choices.find((c) => c.value === value)?.label ?? value]
        }
        return [value, question]
    }
}

const fieldToParts = new Map()

export async function getQuestionValueQuick(document, field) {
    let parts = fieldToParts.get(field)
    if (!parts) {
        parts = field.split('.')
        fieldToParts.set(field, parts)
    }
    return await getSubValueQuick(document, parts)
}

/**
 * Given a document or the instance values of a document, retrieve the value
 * of a field and the question that created it
 * @param {Document|object} document - the set of values for the document
 * @param {string} field - the name of the field to retrieve (can include a property path string)
 * @returns {Promise.<Array>} A promise for an array with the results, the first element is the value, the second is
 *     the question
 * @example
 * const [value, question] = await getQuestionValue(document, 'some.field')
 */
export async function getQuestionValue(document, field) {
    const parts = field.split('.')
    return await getSubValue(document, parts)
}

/**
 * A hook to retrieve the value of a question and the question definition.  The definition
 * is useful for things like choice questions where you might want to look up the label
 * @param {Document|object} document - the set of values for the document
 * @param {string} field - the name of the field to retrieve (can include a property path string)
 * @returns {Array} the first element is the value, the second is the question
 * @example
 * const {instance: {instance}} = useInstanceContext()
 * const [value] = useQuestionValue(instance, 'fieldNameGoesHere')
 */
export function useQuestionValue(document, field) {
    return useAsync(
        async () => {
            return await getQuestionValue(document, field)
        },
        [null, null],
        document?._id
    )
}

export const parameter = /{([^}]+)}/g

const parsed = new Map()

export const embedFunctions = {}
let id = 1

/**
 * Given a document or instance, populates a string that uses { and } delimited
 * parameters to embed values from the document.  The document
 * can be a document value or the instance for the document
 * @param {Document|object} document - the set of values for the document
 * @param {string} message - the text to replace
 * @param {boolean} [quick] - whether to use a quick, but non updating version
 * @returns {Promise<string>} A promise for the string with the values replaced
 */
export async function getMappedString(document, message, quick) {
    if (typeof message !== 'string' || message.trim().length === 0) return ['', []]
    if (!message.includes('{')) return [message, []]
    if (!parsed.has(message)) {
        try {
            parsed.set(message, parse(message))
        } catch (e) {
            console.error('Parsing error in message', message)
            return ['Error: ' + e.message, []]
        }
    }

    const output = []
    let values = {}
    let fields = new Set()

    const parser = parsed.get(message)
    for (let entry of parser) {
        output.push(entry.text)
        if (entry.parameter) {
            if (entry.parameter.call) {
                if (embedFunctions[entry.parameter.call]) {
                    for (let field of entry.parameter.param) {
                        if (typeof field !== 'object') {
                            fields.add(field.split('.')[0])
                        }
                    }
                    output.push(
                        await embedFunctions[entry.parameter.call]({
                            parameters: await resolveParameters(entry.parameter.param),
                            document,
                        })
                    )
                } else {
                    output.push(`#Missing Function: ${entry.parameter.call}`)
                }
            }
            if (entry.parameter.field) {
                let [value, question] = await (quick ? getQuestionValueQuick : getQuestionValue)(
                    document,
                    entry.parameter.field
                )
                fields.add(entry.parameter.field.split('.')[0])
                value = ensureArray(value)
                    .map((v) => question?.choices?.find((c) => c.value === v)?.label ?? v)
                    .join(',')
                if (entry.parameter.fns) {
                    ;({ value } = raise(`string-process-${entry.parameter.fns[0]}`, {
                        process: entry.parameter.fns,
                        value,
                    }))
                }
                values[entry.parameter.field] = value || ''
                output.push(value)
            }
        }
    }
    return [output.join(''), [...fields]]

    async function resolveParameters(parameters) {
        return await Promise.all(
            parameters.map(async (p) => {
                if (typeof p === 'object' && p.call) {
                    for (let field of p.param) {
                        if (typeof field !== 'object') {
                            fields.add(field.split('.')[0])
                        }
                    }
                    if (embedFunctions[p.call]) {
                        let value = await embedFunctions[p.call]({
                            parameters: await resolveParameters(p.param),
                            document,
                        })
                        const field = `___${id++}`
                        Object.defineProperty(document, field, { value })
                        return field
                    } else {
                        output.push(`#Missing Function: ${p.call}`)
                        return ''
                    }
                } else {
                    return p
                }
            })
        )
    }
}

handle('string-process-date', function (info) {
    if (!info.value) return
    let type = info.process[1] || 'medium'
    let ensureValIsNum = isNaN(+info.value) ? info.value : +info.value
    info.value = new Date(ensureValIsNum)[type]()
})

handle('string-process-if', function (info) {
    info.value =
        (Array.isArray(info.value) && info.value.length > 0) || (!Array.isArray(info.value) && info.value)
            ? info.process[1]
            : info.process[2] || ''
})

export function useMappedStringWithUpdates(document, text, refreshId = 'standard') {
    const refresh = useRefresh()
    const [filterString, fields] = useMappedString(document, text || '', JSON.stringify([refresh.id, refreshId]))
    useRefreshOnField(fields, refresh)
    return filterString
}

export function useRefreshOnFieldsFrom(document, text = '', refresh, refreshId = 'standard') {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    refresh = refresh || useRefresh()
    const [, fields] = useMappedString(document, (text || '').toString(), JSON.stringify([text, refreshId]))
    useRefreshOnField(fields, refresh)
    return refresh.id
}

/**
 * A hook, that given a document or instance, populates a string that uses { and } delimited
 * parameters to embed values from the document.  The document
 * can be a document value or the instance for the document.
 *
 * The string parameters can have a function and parameters delimited by : the functions are
 * passed using a string-process-FUNCTIONNAME event and the standard ones are:
 *
 * date - that takes a format or long/short/medium (default)
 * if - which returns the first parameter if the value exists or the second if it does not.
 *
 * <code> The date is {someDate:date:short} {someText:Text is} {someText}</code>
 *
 * This can be used to embed values in HTML etc.
 *
 * @param {Document|object} document - the set of values for the document
 * @param {string} text - the text to replace
 * @param {string} refreshId - an id used to indicate that the process should run again
 * @returns {string} The resulting string having embedded the parameters
 */
export function useMappedString(document, text = '', refreshId = 'standard') {
    const [plain] = useState(() => !text?.includes('{'))
    const idInfo = {
        refId: `${document?._id}:${text}:${document?.$currentUser ? 'user' : 'nouser'}`,
        runId: `${refreshId}-${document?.__}-${document?.$seq}`,
        text,
        document,
    }
    raise('adjustStringMappingKeys', idInfo)
    if (plain) return [text, []]
    // eslint-disable-next-line react-hooks/rules-of-hooks
    return usePreferCachedAsync(
        'getStringMapping' + document?._id,
        async () => {
            if (!document) return [text && text.includes('{') ? '' : text, []]
            return await getMappedString(document, text)
        },
        [text && text.includes('{') ? '' : text, []],
        idInfo.refId,
        idInfo.runId
    )
}

export function MappedString({ document, text, update = false }) {
    const refresh = useRefresh()
    const [value, fields] = useMappedString(document, text, refresh.id)
    if (update) {
        // eslint-disable-next-line react-hooks/rules-of-hooks
        useRefreshOnField(fields, refresh)
    }
    return <span dangerouslySetInnerHTML={{ __html: value }} />
}
