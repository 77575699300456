/**
 * @module dynamic/awe-library/runtime/app-urls
 */
import { ensure } from 'dynamic/awe-library/runtime/create-document'
import { useCachedAsync } from 'common/use-async'
import { handle } from 'common/events'
import { getType } from 'dynamic/awe-library/runtime/records'
import { getActiveClient, getCurrentAuthenticatedUser } from 'common/global-store/api'
import { useRefresh } from 'common/useRefresh'
import { useStableLocalEvent } from 'common/use-event'

export let knownUrls = {}
handle('sign-in-event', () => (knownUrls = {}))

/**
 * Returns a url given an app type
 * @param {DocumentDefinition} appType - the type to get a url for
 * @returns {string} a unique id for a document
 */
export function appUrl(appType) {
    let id = `${getCurrentAuthenticatedUser()?.id || 'UNKNOWN'}${getActiveClient()}:${appType.database}/${
        appType.table
    }`
    ensure(id, appType).catch(console.error)
    return id
}

const guaranteed = []

/**
 * Returns a url given an app type, ensuring that the document exists
 * @param {DocumentDefinition} appType - the type to get a url for
 * @returns {Promise<string>} a promise for a unique id for a document
 */
export async function guaranteedAppUrl(appType) {
    if (!appType || !appType.database || !appType.table) return `notvalid`
    let id = `${getCurrentAuthenticatedUser()?.id || 'UNKNOWN'}${getActiveClient()}:${appType.database}/${
        appType.table
    }`
    guaranteed.push(appType._id)
    // eslint-disable-next-line no-console
    // console.log('guaranteed app url', id)
    await ensure(id, appType)
    return id
}

/**
 * A hook to get a url for an application which is guaranteed to
 * exist
 * @param {string} typeId - the type for which a url is required
 * @returns {string} an empty string until an app url is generated
 */
export function useAppUrl(typeId) {
    const refresh = useRefresh()
    useStableLocalEvent('refresh-apps', `data.updated.${typeId}`, refresh)
    return useCachedAsync(
        'getUrl',
        async () => {
            if (!typeId) return ''
            let type = await getType(typeId)
            return await guaranteedAppUrl(type)
        },
        '',
        [typeId, refresh.id]
    )
}
