import React from 'react'

import PropTypes from 'prop-types'

import { MdCheckCircle } from '@react-icons/all-files/md/MdCheckCircle'
import { MdError } from '@react-icons/all-files/md/MdError'

import { Box } from 'common/styled-box'
import Button from '@material-ui/core/Button'

import Typography from '@material-ui/core/Typography'
import { InlineLoader } from 'common/utils/centered-loader'
import { makeTestId } from 'common/component-utilities'
import { useLoginStyles } from './login-styles'
import { makeCachedStyles } from 'common/inline-styles'

export const ErrorIcon = ({ color = 'red', size = 20 }) => <MdError color={color} size={size} />
ErrorIcon.propTypes = {
    color: PropTypes.string,
    size: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
}

export const CheckMark = ({ color = 'green', size = 20 }) => <MdCheckCircle color={color} size={size} />
CheckMark.propTypes = {
    color: PropTypes.string,
    size: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
}

export const CenteredCaption = ({ caption = '', BoxProps = {}, TypographyProps = {} }) => {
    return (
        <Box {...BoxProps} style={{ textAlign: 'center' }}>
            <Typography {...TypographyProps} variant="body2">
                {caption}
            </Typography>
        </Box>
    )
}

const useStyles = makeCachedStyles(() => ({
    infoBoxContainer: {
        backgroundColor: '#F4F5F6',
        height: 70,
    },
}))

export const InfoBoxWithLeading = ({ leading, text }) => {
    InfoBoxWithLeading.propTypes = {
        leading: PropTypes.any,
        text: PropTypes.string,
    }
    const { infoBoxContainer } = useStyles()

    return (
        <Box display="flex" width="100%" alignItems="center" justifyContent="flex-start" className={infoBoxContainer}>
            <Box display="flex" width={60} alignItems="center" justifyContent="center">
                {leading}
            </Box>
            <Box display="flex">
                <Typography variant="body2">{text}</Typography>
            </Box>
        </Box>
    )
}

//Whilst not thing render loader

export const WaitFor = ({ condition, children }) => <>{!condition ? <InlineLoader /> : <>{children}</>}</>

export const RightAlignedButton = ({ dataTestId, label, onClick = () => {}, ...props }) => {
    RightAlignedButton.propTypes = {
        onClick: PropTypes.func,
    }
    const { submitButton } = useLoginStyles()
    return (
        <Box display="flex" mt={2} mb={2} width="100%" alignItems="flex-end" justifyContent="flex-end">
            <Button
                data-testid={dataTestId || makeTestId(`${label}-pwtextfield`)}
                type="button"
                variant="contained"
                color="primary"
                className={submitButton}
                onClick={onClick}
                size="small"
                {...props}
            >
                {label.toUpperCase()}
            </Button>
        </Box>
    )
}
