export const applications = {}
export let appVersion = 0

export async function loadComponents(editor, ...components) {
    let list = components
        .flat(Infinity)
        .map((f) => (typeof f === 'string' ? f.split(',') : f))
        .flat(Infinity)
        .filter(Boolean)
        .map((c) => c.trim())
        .filter(Boolean)
    await Promise.all(list.map((component) => loadComponent(component, editor, true)))
}

//Retrieve the apps to run in the framework
export async function loadComponent(name, __editor = false, __runtime = true) {
    return
}
