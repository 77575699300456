import { raiseLater } from 'common/events'
import * as DeviceInfo from 'react-device-detect'

let innerWidth = window.innerWidth
let innerHeight = window.innerHeight

window.addEventListener('resize', function () {
    innerWidth = window.innerWidth
    innerHeight = window.innerHeight
    raiseLater('recalculate-middle')
})

export function isIE11() {
    return DeviceInfo.isIE
}

export function isEdgeLegacy() {
    return DeviceInfo.isEdge
}

export function isDesktop() {
    return DeviceInfo.isDesktop
}

export function isMobile2() {
    return DeviceInfo.isMobile
}

export function isMobile() {
    return innerWidth < 1200 || innerHeight < 600
}

export function getWindowDimensions() {
    return { innerHeight, innerWidth }
}

export function getDocumentDimensions() {
    const documentBody = document?.querySelectorAll('[data-testid="documentBody"]')[0]
    const clientRect = documentBody?.getBoundingClientRect()
    return { documentWidth: documentBody?.clientWidth || 1000, x: clientRect?.x || 0 }
}
