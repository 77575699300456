import { makeCachedStyles } from 'common/inline-styles'

export const useLoginStyles = makeCachedStyles((theme) => ({
    usernameBox: { minHeight: 154 },
    submitButton: {
        color: 'white',
        height: 42,
        width: '40%',
        maxWidth: 150,
        textTransform: 'unset',
    },
    link: {
        color: theme.palette.AlcBrand_001,
    },
    inputBox: {
        display: 'flex',
        flexDirection: 'column',
        gap: '24px',
        marginBottom: '16px',
    },
    checkboxFormControl: {
        alignItems: 'flex-start',
        marginRight: 0,
        '& > .MuiCheckbox-root': {
            paddingTop: 0,
        },
        '& > .MuiFormControlLabel-label': {
            fontSize: '14px',
        },
    },
}))
