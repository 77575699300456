import React from 'react'

import PropTypes from 'prop-types'

import { Box } from '@material-ui/core'

import { validateSecurityAsync } from 'common/secure/validate'
import { withAsyncProps } from 'common/use-async'

export function SecureBox({ demands = [], securityResolver, fallback = null, children, ...props }) {
    return (
        <InnerBox
            isValid={() => validateSecurityAsync(demands, securityResolver, props)}
            fallback={fallback}
            {...props}
        >
            {children}
        </InnerBox>
    )
}
const InnerBox = withAsyncProps(function ({ isValid = false, children, component = Box, fallback, ...props }) {
    const Component = component
    return isValid ? <Component {...props}>{children}</Component> : fallback
})

SecureBox.propTypes = {
    children: PropTypes.any,
    demands: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
    fallback: PropTypes.any,
    securityResolver: PropTypes.func,
}

export default SecureBox
