import { useEffect, useRef, useState } from 'react'
import { simpleOnly, useAsyncWaitContext, useCurrentState } from 'common/use-async'
import LRU from 'lru-cache'

export const testCache = new LRU(600)
let _nextRefreshId = 0

export function nextRefreshId() {
    return _nextRefreshId++
}

/**
 * Runs a generator function which can yield values
 * to have the caller update with the new value or
 * also yield promises to have them awaited.  The generator
 * function is passed a merge function so that parallel
 * tracks can pass their results into a single output
 * @param {Function<IterableIterator<any>>} generator The generator function
 * @param {any} defaultValue A default value to return
 * @param {any} cacheId Anything that will allow a cached result to be used
 * @param {any} runId Added to the cache key to decide if the result should
 * be recalculated while the cache value is initially returned
 * @return the yielded value or the return value of the function
 */
export function useStepByStep(generator, defaultValue, cachedId, runId, incrementContext) {
    const [instanceId] = useState(() => nextRefreshId())
    runId = runId || `_${instanceId}_`
    const key = JSON.stringify(cachedId, simpleOnly)
    const runKey = JSON.stringify([runId, key])
    const running = useRef(null)
    const [result, commitResult] = useCurrentState(testCache.get(key) || defaultValue)
    const actualValue = useRef()
    actualValue.current = result
    const context = useAsyncWaitContext()
    useEffect(() => {
        startProcessing().catch(console.error)
    }, [runKey])
    return result

    async function startProcessing() {
        incrementContext && context.increment()
        try {
            const iterator = generator(merge)
            running.current = iterator
            let ok = true
            let parameter = null
            let returnValue = null
            do {
                const { value, done } = iterator.next(parameter)
                if (iterator !== running.current) return
                if (value.then) {
                    parameter = await value
                    if (iterator !== running.current) return
                    returnValue = parameter !== undefined ? parameter : returnValue
                } else {
                    if (value !== undefined) setResult(value)
                    returnValue = value !== undefined ? value : returnValue
                }
                ok = !done
            } while (ok)
            if (returnValue !== undefined) setResult(returnValue)
            if (returnValue !== undefined) testCache.set(key, returnValue)
        } finally {
            incrementContext && context.decrement()
        }
    }

    function setResult(value) {
        if (!Object.isEqual(value, actualValue.current)) {
            commitResult(value)
        }
    }

    function merge(value) {
        try {
            commitResult({ ...actualValue.current, ...value })
        } catch (e) {
            commitResult(value)
        }
    }
}
