import { lookup, lookupAsync } from 'dynamic/awe-library/runtime/lookup'
import { initialize } from 'common/offline-data-service/behaviour-cache'
import { embedFunctions, getQuestionValue } from 'dynamic/awe-library/runtime/question-value'
import { retrieveDocument } from 'dynamic/awe-library/runtime/document-retrieve'

async function getSubValueWithMapping(document, parts, mapping = (v) => v) {
    if (parts.length > 1) {
        const question = lookup(document)[parts[0]]
        let name = parts[0]
        if (question) {
            name = question.name
        }
        const value = document[name]
        if (!value) return [null, null]
        if (Array.isArray(value)) {
            const output = []
            for (let entry of value) {
                let subDocument = await retrieveDocument(entry)
                await initialize(subDocument)
                output.push(await getSubValueWithMapping(subDocument, parts.slice(1), mapping))
            }
            return [mapping(output.map((o) => o[0]).filter(Boolean)), output.map((o) => o[1]).compact(true)[0]]
        } else {
            let subDocument = typeof value !== 'string' ? value : await retrieveDocument(value)
            await initialize(subDocument)
            return getSubValueWithMapping(subDocument, parts.slice(1), mapping)
        }
    } else {
        const lookup = await lookupAsync(document)
        let question = lookup[parts[0]]
        if (!question) {
            return [document[parts[0]], {}]
        }
        return [document[question.name], question]
    }
}

export async function getQuestionValueWithMapping(document, field, mapping) {
    const parts = field.split('.')
    return await getSubValueWithMapping(document, parts, mapping)
}

embedFunctions.join = async function ({ parameters: [path, joinString], document }) {
    const [allValues] = await getQuestionValueWithMapping(document, path, (array) => array.join(joinString))
    return allValues.join(joinString).replace(/\s+/g, ' ')
}

embedFunctions.list = async function ({ parameters: [path, quote = '', titleize = false], document }) {
    const [allValues] = await getQuestionValueWithMapping(document, path, processArray)
    return allValues

    function process(entry) {
        entry = Array.isArray(entry) ? entry[0] : entry
        if (titleize) {
            entry.titleize()
        }
        return `${quote}${entry}${quote}`
    }

    function processArray(allValues) {
        if (allValues.length > 1) {
            return `${allValues.slice(0, -1).map(process).join(', ')} and ${process(allValues.last())}`
        }
        return allValues.length === 1 ? process(allValues[0]) || '' : ''
    }
}

async function getValue(field, document, recordMap) {
    if (!isNaN(+field)) return +field
    return +((await getQuestionValue(document, field, recordMap))[0] || 0)
}

embedFunctions.divide = async function ({ parameters, document, recordMap }) {
    let total = 0
    let isSet = false
    for (let field of parameters) {
        if (!isSet) {
            total = await getValue(field, document, recordMap)
            isSet = true
            continue
        }
        let negative = false
        if (field.startsWith('-')) {
            negative = true
            field = field.slice(1)
        }
        const value = await getValue(field, document, recordMap)
        if (negative) {
            total /= -+value
        } else {
            total /= +value
        }
    }
    return total
}

embedFunctions.multiply = async function ({ parameters, document, recordMap }) {
    let total = 0
    let isSet = false
    for (let field of parameters) {
        if (!isSet) {
            total = await getValue(field, document, recordMap)
            isSet = true
            continue
        }
        let negative = false
        if (field.startsWith('-')) {
            negative = true
            field = field.slice(1)
        }
        const value = await getValue(field, document, recordMap)
        if (negative) {
            total *= -+value
        } else {
            total *= +value
        }
    }
    return total
}

embedFunctions.add = async function ({ parameters, document, recordMap }) {
    let total = 0
    for (let field of parameters) {
        if (!isNaN(+field)) {
            total += await getValue(field, document, recordMap)
            continue
        }
        let negative = false
        if (field.startsWith('-')) {
            negative = true
            field = field.slice(1)
        }
        const value = await getValue(field, document, recordMap)
        if (negative) {
            total -= +value
        } else {
            total += +value
        }
    }
    return total
}

embedFunctions.date = async function ({ parameters: [field, format = 'medium'], document }) {
    const [date] = await getQuestionValue(document, field)
    return Date.create(date).format(format)
}

embedFunctions.isoDate = async function ({ parameters: [field], document }) {
    const [date] = await getQuestionValue(document, field)
    return Date.create(date).toISOString()
}

embedFunctions.dateToNumber = async function ({ parameters: [field], document }) {
    const [date] = await getQuestionValue(document, field)
    return 0 + +Date.create(date)
}

embedFunctions.increment = async function ({ parameters: [field, amount = 1], document }) {
    let [value] = (await getQuestionValue(document, field)) || 0
    value = +value + +(amount || 1)
    return value
}

embedFunctions.if = async function ({ parameters: [condition, truthy, falsey], document }) {
    const [value] = await getQuestionValue(document, condition)
    return value ? truthy : falsey
}
