import { clone } from 'common/utils/deep-copy'
import { get } from 'common/offline-data-service/functions/get'
import { showNotification } from 'common/modal'

export function getCurrentVersionId(type) {
    return type.currentVersion?.live
}

export async function getCurrentVersion(type) {
    return await getVersion(type, getCurrentVersionId(type))
}

export function getVersionId(type, id) {
    if (!type.versions) {
        return undefined
    }
    return type.versions.find((v) => v.id === id || v.name === id)?.id
}

export async function getVersion(type, id) {
    if (!type.versions) {
        return JSON.parse(JSON.stringify(type))
    }
    let definition = type.versions.find((v) => v.id === id || v.name === id)
    if (!definition) {
        let result = clone(type)
        delete result.versions
        delete result.currentVersion
        return result
    }
    if (definition.content) {
        let result = JSON.parse(definition.content)
        result._id = type._id
        return result
    }
    let result =
        (await get(`${type._id.split(':')[0]}-${definition.id || definition.name}:hestia/versions`, true, true)) || type

    if (!result) {
        console.error('Could not find version')
        showNotification('Warning: did not find correct version of document, creating from current settings')
        result = clone(type)
    } else {
        result = clone(result)
    }
    result._versionId = result._id
    result._id = type._id
    return result
}
