import React from 'react'

import PropTypes from 'prop-types'

import InternalTextField from '@material-ui/core/TextField'

export const MAX_TEXTFIELD_LENGTH = 256

export function TextField({ inputProps = {}, ...rest }) {
    TextField.propTypes = {
        inputProps: PropTypes.object,
    }
    const LimitInputProps = { maxLength: MAX_TEXTFIELD_LENGTH, ...inputProps }
    return <InternalTextField inputProps={LimitInputProps} {...rest} />
}

export default TextField
