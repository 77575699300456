import { command, define, document, parameter, retrieve, send } from 'common/process'

// Client
export const migrateClient = send('client.migrate', parameter('clientId'))
export const getClients = define('clients.get', function ({ optional, returns }) {
    optional('onlyWithDocs')
    optional('namespace')
    returns('clients')
})
export const setClient = send('client.setAuthorise', document(), parameter('authorise'))
export const canLinkToClient = send('client.exists', parameter('clientId'))
export const getClientSecret = command('client.setSecret')
export const createClient = send('client.create', parameter('clientId'))
export const createClientWithoutId = send('client.createWithoutId', parameter('name'))
export const createClientWithCustomId = send('client.createWithCustomId', parameter('name'), parameter('clientId'))
export const updateClient = send('client.update', parameter('clientId'), parameter('name'), parameter('ssClientId'))
export const getClientRoles = command('client.roles.get', 'roles')

// User
export const getUserClients = retrieve('user.clients.get')
export const getUserClientList = define('user.clients.get', ({ returns }) => {
    returns('clients')
})
export const getUserClientCount = define('user.clients.get.count', ({ optional, returns }) => {
    optional('searchTerm')
    returns('clientCount')
})

export const setUserActiveClient = define('set.user.activeClient', ({ required }) => {
    required('userId')
    required('activeClient')
})

export const updateActiveClient = define('users.updateActiveClient', ({ required }) => {
    required('activeClient')
})
// Demands
export const getRegisteredDemands = command('demands.get', 'demands', parameter('registeredDemands'))

// Roles
export const getRoles = command('roles.get', 'roles')
export const storeRoles = command('roles.set')

// Invites
export const getInvitations = command('invites.list', 'invitations')
export const createInvite = command('invites.create')
export const deleteInvites = command('invites.delete')

// Emulate
export const setEmulate = send('emulate.user', parameter('userId'))
