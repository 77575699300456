import { define, parameter, retrieve } from 'common/process'
import * as LocalStorageFacade from './localStorageFacade'
import { OfflineFirstRetriever } from './offlineFirstRetriever'
import { raiseLater } from 'common/events'
import { clone } from 'common/utils/deep-copy'

export function prepareDocument(record) {
    if (record && record._version && record._settings && record._settings._id) {
        delete record.__cached
        delete record.__size
        delete record.__namespace
        delete record.__modified
        const output = clone(record)
        delete output.$valid
        delete output.$invalid
        if (output._settings) {
            output._settings = {
                $id: record._settings._id,
                $state: record._behaviours._state,
            }
        }
        delete output._behaviours
        return output
    }
    return record
}

export const deleteRecord = define('data.record.delete', ({ required, before }) => {
    required('recordId')
    before(({ recordId }) => {
        LocalStorageFacade.remove({ from: 'records', where: { id: recordId } })
        OfflineFirstRetriever.removeLocal(recordId)
    })
})

export const readAll = define('data.retrieve.all', ({ required, optional, returns }) => {
    required('tableId')
    optional('chunk')
    returns('records')
    returns('chunk')
})

export const setRecord = define('data.record.set', ({ required, before, optional }) => {
    required('updatedRecord')
    optional('skipVersion')
    optional('skipAssociation')
    before((doc) => {
        raiseLater('writing.data')
        return prepareDocument(doc)
    })
})

export const getRecord = retrieve('data.record.get', 'record', parameter('recordId'), parameter('track'))

export const getRecords = retrieve('data.record.get.multiple', 'records', parameter('recordIds'), parameter('track'))

export const getList = retrieve(
    'data.record.list',
    'records',
    parameter('database'),
    parameter('table'),
    parameter('where'),
    parameter('options')
)
export const getListIds = retrieve(
    'data.record.list.by.id',
    'records',
    parameter('database'),
    parameter('table'),
    parameter('where'),
    parameter('options')
)

export const getCount = retrieve(
    'data.record.count',
    'count',
    parameter('database'),
    parameter('table'),
    parameter('where')
)
