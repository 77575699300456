import React from 'react'
import CircularProgress from '@material-ui/core/CircularProgress'

import clsx from 'clsx'
import { withStyles } from '@material-ui/core/styles'

const styles = withStyles({
    loader: {
        '& div': {
            height: '100%',
            width: '100%',
            position: 'relative',
            '& > *': {
                position: 'absolute',
                left: '50%',
                top: '50%',
                transform: 'translate3d(-50%, -50%, 0)',
            },
            '&.spinner': {
                position: 'absolute',
                left: '50%',
                top: '50%',
                transform: 'translate3d(-50%, -50%, 0)',
            },
        },
    },
    progress: {
        position: 'absolute',
        left: '50%',
        top: '50%',
        transform: 'translate3d(-50%, -50%, 0)',
    },
    inlineLoader: {
        height: '100%',
        '& div': {
            height: '100px',
            position: 'relative',

            '& svg': {
                position: 'absolute',
                left: '50%',
                top: '50%',
                transform: 'translate3d(-50%, -50%, 0)',
            },
            '&.spinner': {
                top: '25%',
                transform: 'translate3d(-50%, -50%, 0)',
            },
        },
    },
    doNotOffset: {
        height: '100%',
        '& div': {
            height: '100px',
            position: 'relative',
        },
    },
})

const isIE11 = () => !!window.MSInputMethodContext && !!document.documentMode
const DEFAULT_SIZE = isIE11() ? 50 : 100
const DEFAULT_COLOR = '#39f'

export const CenteredLoader = styles(function CenteredLoader({
    loader,
    classes,
    size = DEFAULT_SIZE,
    color = DEFAULT_COLOR,
}) {
    return (
        <div className={classes.loader}>
            {loader ? loader : <LoadingIndicator type="Rings" width={size} height={size} color={color} />}
        </div>
    )
})
export const CenteredProgress = styles(function CenteredProgress({ classes }) {
    return (
        <div className={classes.progress}>
            <CircularProgress disableShrink={true} />
        </div>
    )
})

export const InlineLoader = styles(function InlineLoader({
    classes,
    size = DEFAULT_SIZE,
    color = DEFAULT_COLOR,
    offset = true,
    ...props
}) {
    return (
        <div {...props} className={offset ? classes.inlineLoader : classes.doNotOffset}>
            <LoadingIndicator type="Rings" width={size} height={size} color={color} />
        </div>
    )
})

export const LoadingIndicator = ({ width = 18, height = 18, color = DEFAULT_COLOR, className }) => {
    return <IE11Spinner width={width} height={height} color={color} className={className} />
}

export const IE11Spinner = ({ width = DEFAULT_SIZE, height = DEFAULT_SIZE, color = DEFAULT_COLOR, className }) => (
    <div
        className={clsx('spinner', 'ie11Spinner', className)}
        style={{ margin: 'auto', width: width, height: height, color: color }}
    />
)
