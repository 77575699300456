import React from 'react'

import PropTypes from 'prop-types'

import events from 'packages/alcumus-local-events'

import { MdCreditCard } from '@react-icons/all-files/md/MdCreditCard'

import { DialogContent, DialogTitle } from '@material-ui/core'
import { Box } from 'common/styled-box'
import Button from '@material-ui/core/Button'
import FormControl from '@material-ui/core/FormControl'
import Input from '@material-ui/core/Input'
import InputAdornment from '@material-ui/core/InputAdornment'

import { StandardDialog } from 'common/Dialog'
import { hasActiveClient, isLoggedInAndAuthenticated, setActiveClient } from 'common/global-store/api'
import { useClients } from 'plugins/security-management-plugin/clients/'
import { define } from 'common/process'
import { useWindowSize } from 'common/use-event'

import { LaunchpadActions } from './launcher-actions'

import { raiseAsync } from 'common/events'
import { delay } from 'common/utils/delay'
import { MdExitToApp } from '@react-icons/all-files/md/MdExitToApp'
import { makeCachedStyles } from 'common/inline-styles'

const INVITE_CODE_LENGTH = 6

const acceptInvite = define('invites.consume', ({ required, returns }) => {
    required('code')
    returns('newActiveClient')
})

const useStyles = makeCachedStyles({
    acceptButton: {
        color: 'white',
        height: 56,
        width: '150px',
    },
    blockIcon: {
        display: 'block',
        fontSize: 28,
    },
    emphasizeBox: {
        background: '#EBEBED 0% 0% no-repeat padding-box',
    },
    emphasizeText: {
        color: '#000',
        fontWeight: 'bold',
    },
    fixedBottom: {
        background: '#F4F5F6 0% 0% no-repeat padding-box',
        borderRadius: '8px',
        bottom: 0,
        boxShadow: '0px 2px 4px #00000019',
        left: 0,
        position: 'fixed',
        right: 0,
        width: '100%',
    },
    inviteBody: {
        color: ' #4D4D4D',
        fontFamily: 'Montserrat',
        fontWeight: 500,
        fontSize: 16,
        letterSpacing: ' 0.11px',
        marginTop: 16,
        opacity: 1,
        textAlign: 'left',
    },
    inviteHeader: {
        color: '#000000D9',
        fontFamily: 'Montserrat',
        fontWeight: 500,
        fontSize: 20,
        letterSpacing: 0,
        marginBottom: 0,
        marginLeft: 8,
        opacity: 1,
    },
    inviteInput: {
        backgroundColor: 'white',
        border: '1px solid #C4C4C4',
        borderRadius: '4px',
        fontFamily: 'Montserrat',
        fontSize: 32,
        height: 56,
        padding: 8,
    },
    inviteInstruction: {
        color: ' #4D4D4D',
        fontFamily: 'Montserrat',
        fontWeight: 500,
        fontSize: 16,
        letterSpacing: ' 0.11px',
        marginTop: 16,
        opacity: 1,
        textAlign: 'left',
    },
    inviteInstructionMinimal: {
        textAlign: 'right',
    },
    inviteLink: {
        color: '#7D7D7D',
        fontFamily: 'Roboto',
        fontSize: 14,
        opacity: 1,
        textAlign: 'left',
    },
    splitBox: {
        borderRadius: 8,
        height: 197,
        padding: '8px 16px',
        width: 580,
    },
})

export const InviteHelp = () => {
    const { inviteBody, inviteLink } = useStyles()
    return (
        <>
            <p className={inviteBody}>
                You should have received an email which included an invite code to get you set up on the new portal.
            </p>
            <p className={inviteLink}>
                If you haven&apos;t yet received the invitation code then please contact us through Client Services on
                Email (<a href="mailto:sc.clientservices@safecontractor.com">sc.clientservices@safecontractor.com</a>)
                or Telephone: 02920 266715
            </p>
        </>
    )
}

export const MinimalInviteForm = ({ onSubmit }) => {
    MinimalInviteForm.propTypes = {
        onSubmit: PropTypes.func,
    }
    const [code, setCode] = React.useState('')

    const { acceptButton, emphasizeText, inviteInput, inviteInstruction, inviteInstructionMinimal } = useStyles()

    const isValid = () => code.length === INVITE_CODE_LENGTH

    return (
        <form
            onSubmit={(e) => {
                e.preventDefault()
                e.stopPropagation()
                if (isValid()) {
                    onSubmit({ code })
                }
            }}
        >
            <Box display="flex" justifyContent="center" alignItems="center" p={2}>
                <Box mr={2}>
                    <p className={`${inviteInstruction} ${inviteInstructionMinimal}`}>
                        Have another invite code?
                        <br />
                        Enter the <span className={emphasizeText}>{`${INVITE_CODE_LENGTH}-digit`}</span> code here:
                    </p>
                </Box>
                <Box display="flex" justifyContent="center" alignItems="center">
                    <Box>
                        <FormControl>
                            <Input
                                autoComplete="new-password"
                                autoFocus={true}
                                className={inviteInput}
                                disableUnderline
                                inputProps={{
                                    maxLength: `${INVITE_CODE_LENGTH}`,
                                }}
                                endAdornment={
                                    <InputAdornment style={{ color: '#7d7d7d' }} position="start">
                                        <MdCreditCard />
                                    </InputAdornment>
                                }
                                onChange={(e) => {
                                    setCode(e.target.value.toUpperCase())
                                }}
                                value={code}
                            />
                        </FormControl>
                    </Box>
                    <Box ml={1}>
                        <FormControl width="100%">
                            <Button
                                className={acceptButton}
                                color="primary"
                                type="submit"
                                disabled={!isValid()}
                                variant="contained"
                            >
                                Accept
                            </Button>
                        </FormControl>
                    </Box>
                </Box>
            </Box>
        </form>
    )
}

export const InviteForm = ({ onSubmit }) => {
    InviteForm.propTypes = {
        onSubmit: PropTypes.func,
    }
    const [code, setCode] = React.useState('')

    const { acceptButton, emphasizeText, inviteInput, inviteInstruction } = useStyles()

    const isValid = () => code.length === INVITE_CODE_LENGTH

    return (
        <form
            onSubmit={(e) => {
                e.preventDefault()
                e.stopPropagation()
                if (isValid()) {
                    onSubmit({ code })
                }
            }}
        >
            <p className={inviteInstruction}>
                Copy and paste the <span className={emphasizeText}>{`${INVITE_CODE_LENGTH}-digit`}</span> code below:
            </p>
            <Box display="flex" justifyContent="center">
                <Box>
                    <FormControl>
                        <Input
                            autoComplete="new-password"
                            autoFocus={true}
                            className={inviteInput}
                            disableUnderline
                            inputProps={{
                                maxLength: `${INVITE_CODE_LENGTH}`,
                            }}
                            endAdornment={
                                <InputAdornment style={{ color: '#7d7d7d' }} position="start">
                                    <MdCreditCard />
                                </InputAdornment>
                            }
                            onChange={(e) => {
                                setCode(e.target.value.toUpperCase())
                            }}
                            value={code}
                        />
                    </FormControl>
                </Box>
                <Box ml={1}>
                    <FormControl>
                        <Button
                            className={acceptButton}
                            color="primary"
                            type="submit"
                            style={{ width: 100 }}
                            disabled={!isValid()}
                            variant="contained"
                        >
                            Accept
                        </Button>
                    </FormControl>
                </Box>
            </Box>
        </form>
    )
}

export const InviteHeader = () => {
    const { blockIcon, inviteHeader } = useStyles()

    return (
        <Box display="flex" flexDirection="row" alignItems="center">
            <MdExitToApp className={blockIcon} />
            <p className={inviteHeader}>Accept Invite Code</p>
        </Box>
    )
}

const serverSetUserActiveClient = define('users.updateActiveClient', ({ required, returns }) => {
    required('activeClient')
})

export const handleInvite = async ({ code }) => {
    events.emit('gtm.event', {
        event: 'user.consume-invite',
        category: 'user',
        action: 'consume-invite',
        label: `Used invite ${(<code className="code" />)}`,
        value: code.code,
    })
    const newActiveClient = await acceptInvite({ code })
    // Set activeClient to last received invite client
    if (newActiveClient) {
        await serverSetUserActiveClient(newActiveClient)
        await setActiveClient(newActiveClient)
    }
    await raiseAsync('refresh-profile')
    await delay(0.5)
    await raiseAsync('reinitialize-app')
}

const MinimalView = ({ onSubmit }) => <MinimalInviteForm onSubmit={onSubmit} />

MinimalView.propTypes = {
    onSubmit: PropTypes.func,
}

export const LauncherInvite = () => {
    const { blockIcon, emphasizeBox, fixedBottom, inviteHeader, splitBox } = useStyles()
    const { selectedClient } = useClients()
    const { width, height } = useWindowSize()
    const isCompactView = () => {
        //TODO: Don't hate Andy
        if (hasActiveClient(selectedClient)) {
            return width < 850 || height < 522
        }
        return (width < 580 && height < 920) || (width > 580 && height < 830)
    }

    if (!isLoggedInAndAuthenticated()) return null

    if (isCompactView()) {
        return <LaunchpadActions />
    }

    if (hasActiveClient(selectedClient)) return null

    return (
        <div className={fixedBottom}>
            <Box
                alignItems="center"
                display="flex"
                flexDirection="row"
                flexWrap="wrap"
                justifyContent="center"
                m={2}
                p={4}
            >
                <Box className={splitBox}>
                    <Box display="flex" flexDirection="row" alignItems="center">
                        <MdExitToApp className={blockIcon} />
                        <p className={inviteHeader}>Accept Invite Code</p>
                    </Box>

                    <InviteHelp />
                </Box>
                <Box className={`${splitBox} ${emphasizeBox}`}>
                    <InviteForm onSubmit={handleInvite} />
                </Box>
            </Box>
        </div>
    )
}

export function InviteAccept({ cancel, ok }) {
    InviteAccept.propTypes = {
        cancel: PropTypes.func,
        ok: PropTypes.func,
    }
    return (
        <StandardDialog onCancel={cancel}>
            <DialogTitle>
                <InviteHeader />
            </DialogTitle>
            <DialogContent>
                <Box mt={-2}>
                    <InviteHelp />
                    <InviteForm onSubmit={ok} />
                </Box>
            </DialogContent>
        </StandardDialog>
    )
}
