/*eslint-disable*/
module.exports = /*
 * Generated by PEG.js 0.10.0.
 *
 * http://pegjs.org/
 */ (function () {
    'use strict'

    function peg$subclass(child, parent) {
        function ctor() {
            this.constructor = child
        }

        ctor.prototype = parent.prototype
        child.prototype = new ctor()
    }

    function peg$SyntaxError(message, expected, found, location) {
        this.message = message
        this.expected = expected
        this.found = found
        this.location = location
        this.name = 'SyntaxError'

        if (typeof Error.captureStackTrace === 'function') {
            Error.captureStackTrace(this, peg$SyntaxError)
        }
    }

    peg$subclass(peg$SyntaxError, Error)

    peg$SyntaxError.buildMessage = function (expected, found) {
        var DESCRIBE_EXPECTATION_FNS = {
            literal: function (expectation) {
                return '"' + literalEscape(expectation.text) + '"'
            },

            class: function (expectation) {
                var escapedParts = '',
                    i

                for (i = 0; i < expectation.parts.length; i++) {
                    escapedParts +=
                        expectation.parts[i] instanceof Array
                            ? classEscape(expectation.parts[i][0]) + '-' + classEscape(expectation.parts[i][1])
                            : classEscape(expectation.parts[i])
                }

                return '[' + (expectation.inverted ? '^' : '') + escapedParts + ']'
            },

            any: function (expectation) {
                return 'any character'
            },

            end: function (expectation) {
                return 'end of boundInput'
            },

            other: function (expectation) {
                return expectation.description
            },
        }

        function hex(ch) {
            return ch.charCodeAt(0).toString(16).toUpperCase()
        }

        function literalEscape(s) {
            return s
                .replace(/\\/g, '\\\\')
                .replace(/"/g, '\\"')
                .replace(/\0/g, '\\0')
                .replace(/\t/g, '\\t')
                .replace(/\n/g, '\\n')
                .replace(/\r/g, '\\r')
                .replace(/[\x00-\x0F]/g, function (ch) {
                    return '\\x0' + hex(ch)
                })
                .replace(/[\x10-\x1F\x7F-\x9F]/g, function (ch) {
                    return '\\x' + hex(ch)
                })
        }

        function classEscape(s) {
            return s
                .replace(/\\/g, '\\\\')
                .replace(/\]/g, '\\]')
                .replace(/\^/g, '\\^')
                .replace(/-/g, '\\-')
                .replace(/\0/g, '\\0')
                .replace(/\t/g, '\\t')
                .replace(/\n/g, '\\n')
                .replace(/\r/g, '\\r')
                .replace(/[\x00-\x0F]/g, function (ch) {
                    return '\\x0' + hex(ch)
                })
                .replace(/[\x10-\x1F\x7F-\x9F]/g, function (ch) {
                    return '\\x' + hex(ch)
                })
        }

        function describeExpectation(expectation) {
            return DESCRIBE_EXPECTATION_FNS[expectation.type](expectation)
        }

        function describeExpected(expected) {
            var descriptions = new Array(expected.length),
                i,
                j

            for (i = 0; i < expected.length; i++) {
                descriptions[i] = describeExpectation(expected[i])
            }

            descriptions.sort()

            if (descriptions.length > 0) {
                for (i = 1, j = 1; i < descriptions.length; i++) {
                    if (descriptions[i - 1] !== descriptions[i]) {
                        descriptions[j] = descriptions[i]
                        j++
                    }
                }
                descriptions.length = j
            }

            switch (descriptions.length) {
                case 1:
                    return descriptions[0]

                case 2:
                    return descriptions[0] + ' or ' + descriptions[1]

                default:
                    return descriptions.slice(0, -1).join(', ') + ', or ' + descriptions[descriptions.length - 1]
            }
        }

        function describeFound(found) {
            return found ? '"' + literalEscape(found) + '"' : 'end of boundInput'
        }

        return 'Expected ' + describeExpected(expected) + ' but ' + describeFound(found) + ' found.'
    }

    function peg$parse(input, options) {
        options = options !== void 0 ? options : {}

        var peg$FAILED = {},
            peg$startRuleFunctions = { Expression: peg$parseExpression },
            peg$startRuleFunction = peg$parseExpression,
            peg$c0 = 'OR',
            peg$c1 = peg$literalExpectation('OR', false),
            peg$c2 = 'AND',
            peg$c3 = peg$literalExpectation('AND', false),
            peg$c4 = '&',
            peg$c5 = peg$literalExpectation('&', false),
            peg$c6 = '|',
            peg$c7 = peg$literalExpectation('|', false),
            peg$c8 = ',',
            peg$c9 = peg$literalExpectation(',', false),
            peg$c10 = function (head, tail) {
                return tail.reduce(function (result, element) {
                    if (element[1] === 'OR' || element[1] === '|') {
                        if (result.or) return { or: [...result.or, element[3]] }
                        return { or: [result, element[3]] }
                    } else {
                        if (result.and) return { and: [...result.and, element[3]] }
                        return { and: [result, element[3]] }
                    }
                }, head)
            },
            peg$c11 = peg$otherExpectation('not'),
            peg$c12 = 'NOT',
            peg$c13 = peg$literalExpectation('NOT', false),
            peg$c14 = '(',
            peg$c15 = peg$literalExpectation('(', false),
            peg$c16 = ')',
            peg$c17 = peg$literalExpectation(')', false),
            peg$c18 = function (expr) {
                expr.not = true
                return expr
            },
            peg$c19 = function (expr) {
                return expr
            },
            peg$c20 = function (expr) {
                return { or: [expr.trim()], not: true }
            },
            peg$c21 = peg$otherExpectation('roleorfunc'),
            peg$c22 = function (r, param) {
                return { call: r, param: param[0] || [] }
            },
            peg$c23 = peg$otherExpectation('param'),
            peg$c24 = function (list, role) {
                return [...list, role.trim()]
            },
            peg$c25 = function (role) {
                return [role.trim()]
            },
            peg$c26 = peg$otherExpectation('role'),
            peg$c27 = /^[a-zA-Z\-]/,
            peg$c28 = peg$classExpectation([['a', 'z'], ['A', 'Z'], '-'], false, false),
            peg$c29 = function () {
                return text()
            },
            peg$c30 = peg$otherExpectation('whitespace'),
            peg$c31 = /^[ \t\n\r]/,
            peg$c32 = peg$classExpectation([' ', '\t', '\n', '\r'], false, false),
            peg$currPos = 0,
            peg$savedPos = 0,
            peg$posDetailsCache = [{ line: 1, column: 1 }],
            peg$maxFailPos = 0,
            peg$maxFailExpected = [],
            peg$silentFails = 0,
            peg$result

        if ('startRule' in options) {
            if (!(options.startRule in peg$startRuleFunctions)) {
                throw new Error('Can\'t start parsing from rule "' + options.startRule + '".')
            }

            peg$startRuleFunction = peg$startRuleFunctions[options.startRule]
        }

        function text() {
            return input.substring(peg$savedPos, peg$currPos)
        }

        function location() {
            return peg$computeLocation(peg$savedPos, peg$currPos)
        }

        function expected(description, location) {
            location = location !== void 0 ? location : peg$computeLocation(peg$savedPos, peg$currPos)

            throw peg$buildStructuredError(
                [peg$otherExpectation(description)],
                input.substring(peg$savedPos, peg$currPos),
                location
            )
        }

        function error(message, location) {
            location = location !== void 0 ? location : peg$computeLocation(peg$savedPos, peg$currPos)

            throw peg$buildSimpleError(message, location)
        }

        function peg$literalExpectation(text, ignoreCase) {
            return { type: 'literal', text: text, ignoreCase: ignoreCase }
        }

        function peg$classExpectation(parts, inverted, ignoreCase) {
            return {
                type: 'class',
                parts: parts,
                inverted: inverted,
                ignoreCase: ignoreCase,
            }
        }

        function peg$anyExpectation() {
            return { type: 'any' }
        }

        function peg$endExpectation() {
            return { type: 'end' }
        }

        function peg$otherExpectation(description) {
            return { type: 'other', description: description }
        }

        function peg$computePosDetails(pos) {
            var details = peg$posDetailsCache[pos],
                p

            if (details) {
                return details
            } else {
                p = pos - 1
                while (!peg$posDetailsCache[p]) {
                    p--
                }

                details = peg$posDetailsCache[p]
                details = {
                    line: details.line,
                    column: details.column,
                }

                while (p < pos) {
                    if (input.charCodeAt(p) === 10) {
                        details.line++
                        details.column = 1
                    } else {
                        details.column++
                    }

                    p++
                }

                peg$posDetailsCache[pos] = details
                return details
            }
        }

        function peg$computeLocation(startPos, endPos) {
            var startPosDetails = peg$computePosDetails(startPos),
                endPosDetails = peg$computePosDetails(endPos)

            return {
                start: {
                    offset: startPos,
                    line: startPosDetails.line,
                    column: startPosDetails.column,
                },
                end: {
                    offset: endPos,
                    line: endPosDetails.line,
                    column: endPosDetails.column,
                },
            }
        }

        function peg$fail(expected) {
            if (peg$currPos < peg$maxFailPos) {
                return
            }

            if (peg$currPos > peg$maxFailPos) {
                peg$maxFailPos = peg$currPos
                peg$maxFailExpected = []
            }

            peg$maxFailExpected.push(expected)
        }

        function peg$buildSimpleError(message, location) {
            return new peg$SyntaxError(message, null, null, location)
        }

        function peg$buildStructuredError(expected, found, location) {
            return new peg$SyntaxError(peg$SyntaxError.buildMessage(expected, found), expected, found, location)
        }

        function peg$parseExpression() {
            var s0, s1, s2, s3, s4, s5, s6, s7

            s0 = peg$currPos
            s1 = peg$parseFactor()
            if (s1 !== peg$FAILED) {
                s2 = []
                s3 = peg$currPos
                s4 = peg$parse_()
                if (s4 !== peg$FAILED) {
                    if (input.substr(peg$currPos, 2) === peg$c0) {
                        s5 = peg$c0
                        peg$currPos += 2
                    } else {
                        s5 = peg$FAILED
                        if (peg$silentFails === 0) {
                            peg$fail(peg$c1)
                        }
                    }
                    if (s5 === peg$FAILED) {
                        if (input.substr(peg$currPos, 3) === peg$c2) {
                            s5 = peg$c2
                            peg$currPos += 3
                        } else {
                            s5 = peg$FAILED
                            if (peg$silentFails === 0) {
                                peg$fail(peg$c3)
                            }
                        }
                        if (s5 === peg$FAILED) {
                            if (input.charCodeAt(peg$currPos) === 38) {
                                s5 = peg$c4
                                peg$currPos++
                            } else {
                                s5 = peg$FAILED
                                if (peg$silentFails === 0) {
                                    peg$fail(peg$c5)
                                }
                            }
                            if (s5 === peg$FAILED) {
                                if (input.charCodeAt(peg$currPos) === 124) {
                                    s5 = peg$c6
                                    peg$currPos++
                                } else {
                                    s5 = peg$FAILED
                                    if (peg$silentFails === 0) {
                                        peg$fail(peg$c7)
                                    }
                                }
                                if (s5 === peg$FAILED) {
                                    if (input.charCodeAt(peg$currPos) === 44) {
                                        s5 = peg$c8
                                        peg$currPos++
                                    } else {
                                        s5 = peg$FAILED
                                        if (peg$silentFails === 0) {
                                            peg$fail(peg$c9)
                                        }
                                    }
                                }
                            }
                        }
                    }
                    if (s5 !== peg$FAILED) {
                        s6 = peg$parse_()
                        if (s6 !== peg$FAILED) {
                            s7 = peg$parseFactor()
                            if (s7 !== peg$FAILED) {
                                s4 = [s4, s5, s6, s7]
                                s3 = s4
                            } else {
                                peg$currPos = s3
                                s3 = peg$FAILED
                            }
                        } else {
                            peg$currPos = s3
                            s3 = peg$FAILED
                        }
                    } else {
                        peg$currPos = s3
                        s3 = peg$FAILED
                    }
                } else {
                    peg$currPos = s3
                    s3 = peg$FAILED
                }
                while (s3 !== peg$FAILED) {
                    s2.push(s3)
                    s3 = peg$currPos
                    s4 = peg$parse_()
                    if (s4 !== peg$FAILED) {
                        if (input.substr(peg$currPos, 2) === peg$c0) {
                            s5 = peg$c0
                            peg$currPos += 2
                        } else {
                            s5 = peg$FAILED
                            if (peg$silentFails === 0) {
                                peg$fail(peg$c1)
                            }
                        }
                        if (s5 === peg$FAILED) {
                            if (input.substr(peg$currPos, 3) === peg$c2) {
                                s5 = peg$c2
                                peg$currPos += 3
                            } else {
                                s5 = peg$FAILED
                                if (peg$silentFails === 0) {
                                    peg$fail(peg$c3)
                                }
                            }
                            if (s5 === peg$FAILED) {
                                if (input.charCodeAt(peg$currPos) === 38) {
                                    s5 = peg$c4
                                    peg$currPos++
                                } else {
                                    s5 = peg$FAILED
                                    if (peg$silentFails === 0) {
                                        peg$fail(peg$c5)
                                    }
                                }
                                if (s5 === peg$FAILED) {
                                    if (input.charCodeAt(peg$currPos) === 124) {
                                        s5 = peg$c6
                                        peg$currPos++
                                    } else {
                                        s5 = peg$FAILED
                                        if (peg$silentFails === 0) {
                                            peg$fail(peg$c7)
                                        }
                                    }
                                    if (s5 === peg$FAILED) {
                                        if (input.charCodeAt(peg$currPos) === 44) {
                                            s5 = peg$c8
                                            peg$currPos++
                                        } else {
                                            s5 = peg$FAILED
                                            if (peg$silentFails === 0) {
                                                peg$fail(peg$c9)
                                            }
                                        }
                                    }
                                }
                            }
                        }
                        if (s5 !== peg$FAILED) {
                            s6 = peg$parse_()
                            if (s6 !== peg$FAILED) {
                                s7 = peg$parseFactor()
                                if (s7 !== peg$FAILED) {
                                    s4 = [s4, s5, s6, s7]
                                    s3 = s4
                                } else {
                                    peg$currPos = s3
                                    s3 = peg$FAILED
                                }
                            } else {
                                peg$currPos = s3
                                s3 = peg$FAILED
                            }
                        } else {
                            peg$currPos = s3
                            s3 = peg$FAILED
                        }
                    } else {
                        peg$currPos = s3
                        s3 = peg$FAILED
                    }
                }
                if (s2 !== peg$FAILED) {
                    s3 = peg$parse_()
                    if (s3 !== peg$FAILED) {
                        peg$savedPos = s0
                        s1 = peg$c10(s1, s2)
                        s0 = s1
                    } else {
                        peg$currPos = s0
                        s0 = peg$FAILED
                    }
                } else {
                    peg$currPos = s0
                    s0 = peg$FAILED
                }
            } else {
                peg$currPos = s0
                s0 = peg$FAILED
            }

            return s0
        }

        function peg$parseNOT() {
            var s0, s1

            peg$silentFails++
            if (input.substr(peg$currPos, 3) === peg$c12) {
                s0 = peg$c12
                peg$currPos += 3
            } else {
                s0 = peg$FAILED
                if (peg$silentFails === 0) {
                    peg$fail(peg$c13)
                }
            }
            peg$silentFails--
            if (s0 === peg$FAILED) {
                s1 = peg$FAILED
                if (peg$silentFails === 0) {
                    peg$fail(peg$c11)
                }
            }

            return s0
        }

        function peg$parseFactor() {
            var s0, s1, s2, s3, s4, s5, s6

            s0 = peg$currPos
            s1 = peg$parseNOT()
            if (s1 !== peg$FAILED) {
                if (input.charCodeAt(peg$currPos) === 40) {
                    s2 = peg$c14
                    peg$currPos++
                } else {
                    s2 = peg$FAILED
                    if (peg$silentFails === 0) {
                        peg$fail(peg$c15)
                    }
                }
                if (s2 !== peg$FAILED) {
                    s3 = peg$parse_()
                    if (s3 !== peg$FAILED) {
                        s4 = peg$parseExpression()
                        if (s4 !== peg$FAILED) {
                            s5 = peg$parse_()
                            if (s5 !== peg$FAILED) {
                                if (input.charCodeAt(peg$currPos) === 41) {
                                    s6 = peg$c16
                                    peg$currPos++
                                } else {
                                    s6 = peg$FAILED
                                    if (peg$silentFails === 0) {
                                        peg$fail(peg$c17)
                                    }
                                }
                                if (s6 !== peg$FAILED) {
                                    peg$savedPos = s0
                                    s1 = peg$c18(s4)
                                    s0 = s1
                                } else {
                                    peg$currPos = s0
                                    s0 = peg$FAILED
                                }
                            } else {
                                peg$currPos = s0
                                s0 = peg$FAILED
                            }
                        } else {
                            peg$currPos = s0
                            s0 = peg$FAILED
                        }
                    } else {
                        peg$currPos = s0
                        s0 = peg$FAILED
                    }
                } else {
                    peg$currPos = s0
                    s0 = peg$FAILED
                }
            } else {
                peg$currPos = s0
                s0 = peg$FAILED
            }
            if (s0 === peg$FAILED) {
                s0 = peg$currPos
                if (input.charCodeAt(peg$currPos) === 40) {
                    s1 = peg$c14
                    peg$currPos++
                } else {
                    s1 = peg$FAILED
                    if (peg$silentFails === 0) {
                        peg$fail(peg$c15)
                    }
                }
                if (s1 !== peg$FAILED) {
                    s2 = peg$parse_()
                    if (s2 !== peg$FAILED) {
                        s3 = peg$parseExpression()
                        if (s3 !== peg$FAILED) {
                            s4 = peg$parse_()
                            if (s4 !== peg$FAILED) {
                                if (input.charCodeAt(peg$currPos) === 41) {
                                    s5 = peg$c16
                                    peg$currPos++
                                } else {
                                    s5 = peg$FAILED
                                    if (peg$silentFails === 0) {
                                        peg$fail(peg$c17)
                                    }
                                }
                                if (s5 !== peg$FAILED) {
                                    peg$savedPos = s0
                                    s1 = peg$c19(s3)
                                    s0 = s1
                                } else {
                                    peg$currPos = s0
                                    s0 = peg$FAILED
                                }
                            } else {
                                peg$currPos = s0
                                s0 = peg$FAILED
                            }
                        } else {
                            peg$currPos = s0
                            s0 = peg$FAILED
                        }
                    } else {
                        peg$currPos = s0
                        s0 = peg$FAILED
                    }
                } else {
                    peg$currPos = s0
                    s0 = peg$FAILED
                }
                if (s0 === peg$FAILED) {
                    s0 = peg$currPos
                    s1 = peg$parseNOT()
                    if (s1 !== peg$FAILED) {
                        s2 = peg$parseRole()
                        if (s2 !== peg$FAILED) {
                            peg$savedPos = s0
                            s1 = peg$c20(s2)
                            s0 = s1
                        } else {
                            peg$currPos = s0
                            s0 = peg$FAILED
                        }
                    } else {
                        peg$currPos = s0
                        s0 = peg$FAILED
                    }
                    if (s0 === peg$FAILED) {
                        s0 = peg$parseRoleOrFunction()
                    }
                }
            }

            return s0
        }

        function peg$parseRoleOrFunction() {
            var s0, s1, s2, s3, s4

            peg$silentFails++
            s0 = peg$currPos
            s1 = peg$parseRole()
            if (s1 !== peg$FAILED) {
                if (input.charCodeAt(peg$currPos) === 40) {
                    s2 = peg$c14
                    peg$currPos++
                } else {
                    s2 = peg$FAILED
                    if (peg$silentFails === 0) {
                        peg$fail(peg$c15)
                    }
                }
                if (s2 !== peg$FAILED) {
                    s3 = []
                    s4 = peg$parseParam()
                    while (s4 !== peg$FAILED) {
                        s3.push(s4)
                        s4 = peg$parseParam()
                    }
                    if (s3 !== peg$FAILED) {
                        if (input.charCodeAt(peg$currPos) === 41) {
                            s4 = peg$c16
                            peg$currPos++
                        } else {
                            s4 = peg$FAILED
                            if (peg$silentFails === 0) {
                                peg$fail(peg$c17)
                            }
                        }
                        if (s4 !== peg$FAILED) {
                            peg$savedPos = s0
                            s1 = peg$c22(s1, s3)
                            s0 = s1
                        } else {
                            peg$currPos = s0
                            s0 = peg$FAILED
                        }
                    } else {
                        peg$currPos = s0
                        s0 = peg$FAILED
                    }
                } else {
                    peg$currPos = s0
                    s0 = peg$FAILED
                }
            } else {
                peg$currPos = s0
                s0 = peg$FAILED
            }
            if (s0 === peg$FAILED) {
                s0 = peg$parseRole()
            }
            peg$silentFails--
            if (s0 === peg$FAILED) {
                s1 = peg$FAILED
                if (peg$silentFails === 0) {
                    peg$fail(peg$c21)
                }
            }

            return s0
        }

        function peg$parseParam() {
            var s0, s1, s2, s3, s4

            peg$silentFails++
            s0 = peg$currPos
            s1 = peg$parse_()
            if (s1 !== peg$FAILED) {
                s2 = []
                s3 = peg$parseRole()
                if (s3 !== peg$FAILED) {
                    while (s3 !== peg$FAILED) {
                        s2.push(s3)
                        s3 = peg$parseRole()
                    }
                } else {
                    s2 = peg$FAILED
                }
                if (s2 !== peg$FAILED) {
                    if (input.charCodeAt(peg$currPos) === 44) {
                        s3 = peg$c8
                        peg$currPos++
                    } else {
                        s3 = peg$FAILED
                        if (peg$silentFails === 0) {
                            peg$fail(peg$c9)
                        }
                    }
                    if (s3 !== peg$FAILED) {
                        s4 = peg$parseRole()
                        if (s4 !== peg$FAILED) {
                            peg$savedPos = s0
                            s1 = peg$c24(s2, s4)
                            s0 = s1
                        } else {
                            peg$currPos = s0
                            s0 = peg$FAILED
                        }
                    } else {
                        peg$currPos = s0
                        s0 = peg$FAILED
                    }
                } else {
                    peg$currPos = s0
                    s0 = peg$FAILED
                }
            } else {
                peg$currPos = s0
                s0 = peg$FAILED
            }
            if (s0 === peg$FAILED) {
                s0 = peg$currPos
                s1 = peg$parse_()
                if (s1 !== peg$FAILED) {
                    s2 = peg$parseRole()
                    if (s2 !== peg$FAILED) {
                        peg$savedPos = s0
                        s1 = peg$c25(s2)
                        s0 = s1
                    } else {
                        peg$currPos = s0
                        s0 = peg$FAILED
                    }
                } else {
                    peg$currPos = s0
                    s0 = peg$FAILED
                }
            }
            peg$silentFails--
            if (s0 === peg$FAILED) {
                s1 = peg$FAILED
                if (peg$silentFails === 0) {
                    peg$fail(peg$c23)
                }
            }

            return s0
        }

        function peg$parseRole() {
            var s0, s1, s2, s3

            peg$silentFails++
            s0 = peg$currPos
            s1 = peg$parse_()
            if (s1 !== peg$FAILED) {
                s2 = []
                if (peg$c27.test(input.charAt(peg$currPos))) {
                    s3 = input.charAt(peg$currPos)
                    peg$currPos++
                } else {
                    s3 = peg$FAILED
                    if (peg$silentFails === 0) {
                        peg$fail(peg$c28)
                    }
                }
                if (s3 !== peg$FAILED) {
                    while (s3 !== peg$FAILED) {
                        s2.push(s3)
                        if (peg$c27.test(input.charAt(peg$currPos))) {
                            s3 = input.charAt(peg$currPos)
                            peg$currPos++
                        } else {
                            s3 = peg$FAILED
                            if (peg$silentFails === 0) {
                                peg$fail(peg$c28)
                            }
                        }
                    }
                } else {
                    s2 = peg$FAILED
                }
                if (s2 !== peg$FAILED) {
                    peg$savedPos = s0
                    s1 = peg$c29()
                    s0 = s1
                } else {
                    peg$currPos = s0
                    s0 = peg$FAILED
                }
            } else {
                peg$currPos = s0
                s0 = peg$FAILED
            }
            peg$silentFails--
            if (s0 === peg$FAILED) {
                s1 = peg$FAILED
                if (peg$silentFails === 0) {
                    peg$fail(peg$c26)
                }
            }

            return s0
        }

        function peg$parse_() {
            var s0, s1

            peg$silentFails++
            s0 = []
            if (peg$c31.test(input.charAt(peg$currPos))) {
                s1 = input.charAt(peg$currPos)
                peg$currPos++
            } else {
                s1 = peg$FAILED
                if (peg$silentFails === 0) {
                    peg$fail(peg$c32)
                }
            }
            while (s1 !== peg$FAILED) {
                s0.push(s1)
                if (peg$c31.test(input.charAt(peg$currPos))) {
                    s1 = input.charAt(peg$currPos)
                    peg$currPos++
                } else {
                    s1 = peg$FAILED
                    if (peg$silentFails === 0) {
                        peg$fail(peg$c32)
                    }
                }
            }
            peg$silentFails--
            if (s0 === peg$FAILED) {
                s1 = peg$FAILED
                if (peg$silentFails === 0) {
                    peg$fail(peg$c30)
                }
            }

            return s0
        }

        peg$result = peg$startRuleFunction()

        if (peg$result !== peg$FAILED && peg$currPos === input.length) {
            return peg$result
        } else {
            if (peg$result !== peg$FAILED && peg$currPos < input.length) {
                peg$fail(peg$endExpectation())
            }

            throw peg$buildStructuredError(
                peg$maxFailExpected,
                peg$maxFailPos < input.length ? input.charAt(peg$maxFailPos) : null,
                peg$maxFailPos < input.length
                    ? peg$computeLocation(peg$maxFailPos, peg$maxFailPos + 1)
                    : peg$computeLocation(peg$maxFailPos, peg$maxFailPos)
            )
        }
    }

    return {
        SyntaxError: peg$SyntaxError,
        parse: peg$parse,
    }
})()
