/**
 * @module common/process
 * @description Functions to send server commands
 */
import events from 'packages/alcumus-local-events'
import debounce from 'lodash/debounce'
import get from 'lodash/get'
import set from 'lodash/set'
import LRU from 'lru-cache'
import { generate } from 'packages/identifiers'
import { raise } from 'common/events'
import { deviceId, tabId } from 'common/remote-ids'
import { fetch } from 'common/request'
import { getItem, setItem } from 'common/using-local-storage-key'
import './monitor'
import './debug'
import { DEBUG_PROCESS, ONLINE } from 'common/globals'
import noop from 'common/noop'
import { showNotification } from 'common/modal'
import { getActiveClient, getCurrentAuthenticatedUser } from 'common/global-store/api'
import { defer } from 'common/deferred'

let context = { parameters: [] }

const instanceId = sessionStorage.getItem('alcumus.instanceId') || (process.env === 'production' ? generate() : '1')
sessionStorage.setItem('alcumus.instanceId', instanceId)

export let queue = JSON.parse(getItem('_queue') || '[]')

const saveItems = debounce(function saveItems() {
    setItem('_queue', JSON.stringify(queue))
}, 50)

events.on('queue-updated', saveItems)

/**
 * @callback TransformFunction
 * @global
 * @description A function to transform a value
 * @param {*} value
 * @returns {*} The transformed value
 */

/**
 * Creates a parameter setting function
 * @param {string} path - the path of the value to set in the request parameters
 * @param {TransformFunction} [transform] - a function to transform the value before setting it
 * @returns {Parameter} a function to set the parameter
 */
export function parameter(path, transform = returnValue) {
    if (path === 'type') throw new Error('Cannot set the type of a request')
    return function (value, output) {
        set(output, path, transform(value))
    }
}

function returnValue(value) {
    return value
}

function returnId(value) {
    if (Object.isObject(value)) {
        return value._id || value.id
    }
    return value
}

/**
 * @callback Parameter
 * @global
 * @description A function that sets a value in the outbound parameters
 * of the request.
 *
 * This is often created by the <code>parameter()</code> function
 * @param {*} value - the value to set
 * @param {object} output - the outbound parameter request
 */

/**
 * Declares a server call as an async function that returns a value.
 *
 * One specifies the api call to make and a value to extract. The result
 * is an async function to perform the operation.
 *
 * @param {string} type - the name of the command to execute
 * @param {string} extract - the value to extract from the server result
 * @param {...Parameter} params - a list of parameters for the function, normally
 * declared with the <code>parameter()</code> function
 * @returns {function(...[*]): Promise<unknown>}
 * @example
 * // Declares an acquireLock function that calls 'lock.acquire' which
 * // takes an 'id' as a parameter and returns the value of 'locked' returned
 * // by the server
 *
 * const acquireLock = retrieve('lock.acquire', 'locked', parameter('id'))
 *
 */
export function retrieve(type, extract, ...params) {
    return command(type, extract, ...params)
}

/**
 * Declares a server call that does not return a value
 * @param {string} type - the name of the command to execute
 * @param {...Parameter} params - a list of parameters for the function, normally
 * declared with the <code>parameter()</code> function
 * @returns {function(...[*]): Promise<unknown>}
 */
export function send(type, ...params) {
    return command(type, undefined, ...params)
}

export function command(type, extract, ...params) {
    return async function (...parameters) {
        if (parameters.length < params.length) {
            throw new Error('Not enough parameters')
        }
        let toSend = {}
        for (let i = 0; i < params.length; i++) {
            params[i](parameters[i], toSend)
        }
        for (let i = params.length; i < parameters.length; i++) {
            Object.assign(toSend, parameters[i])
        }
        let result = await process({ ...toSend, type })
        if (extract) {
            return get(result, `client.${extract}`)
        } else {
            return result
        }
    }
}

/**
 * @interface Process
 * @global
 * @description A command to run on the server, the other properties
 * of this object are parameters for the function being run
 * @property {string} type - the command to execute on the server
 * @property {string} [id] - an id for the job, one will be supplied if missing
 */

/**
 * Send a command to the server as a post, this will
 * not return until the results are ready
 * @param {Process} item - the item to process
 * @returns {Promise<*>} the result of the command
 */
export async function immediateProcess(item) {
    if (Array.isArray(item)) new Error('immediateProcess only excepts single items')
    const task = {
        ...item,
        id: item.id || generate(),
        instanceId: instanceId,
        deviceId: deviceId,
        tabId: tabId,
    }
    events.emit('processing', task)
    let response = await fetch(`/api/process?requestId=${generate()}`, {
        method: 'POST',
        body: JSON.stringify(task),
        headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
        },
    })
    if (!response.ok) {
        if (response.status === 403 || response.statusText === 'Unauthorised') {
            if (getCurrentAuthenticatedUser()) {
                if (!window.location.pathname.startsWith('/login')) {
                    raise('sign-out-event')
                    sessionStorage.setItem('intendedDest', window.location.href)
                    setTimeout(() => (window.location.href = '/login'))
                }
            }
            let e = new Error(response.statusText)
            e.nonNetwork = true
            return e
        }
        let e = new Error(response.statusText)
        e.nonNetwork = true
        return e
    }
    let result = await response.json()
    let { client } = result
    window.isEmulated = !!client.isEmulated
    if (client.notifications) {
        await Promise.all(
            client.notifications.map(async (notification) => {
                await events.emitAsync('show-notification', notification)
            })
        )
    }
    await Promise.all(
        client.events.map(async (event) => {
            let params = event.params || []
            params = Array.isArray(params) ? params : [params]
            params.push(Date.now())
            await events.emitAsync(event.event, ...params)
        })
    )
    return result
}

events.on(`jobDone.**`, (event, packet) => {
    if (!packet.message) return
    const { jobId } = packet.message
    if (running[jobId]) {
        running[jobId].finished = Date.now()
        raise('update-jobs', running)
        setTimeout(() => {
            delete running[jobId]
            raise('update-jobs', running)
        }, 2500)
    }
    events.emit(`job.completed.${packet.type}`, packet.message.result.client, packet.type, packet.message.result)
})

export const running = {}
export let metrics = {}

events.on(`metrics.reset`, () => {
    metrics = {}
    raise('metrics', metrics)
})
events.on('auth.login-success', () => {
    setTimeout(() => {
        Object.keys(running).forEach((key) => delete running[key])
        raise('updated-jobs', running)
    }, 50)
})

/**
 * Returns a promise for a time when there are no jobs
 * running on the server for this client
 * @returns {Promise<void>}
 */
export async function notRunning() {
    return new Promise((resolve) => {
        events.on('update-jobs', check)
        check()

        function check() {
            if (!Object.isEmpty(running)) return
            events.off('update-jobs', check)
            resolve()
        }
    })
}

const toSend = []
let sendPromise
let sendTimer

function sendPacket(packet) {
    clearTimeout(sendTimer)
    sendTimer = setTimeout(doSend, 25)
    toSend.push(packet)
    if (sendPromise) {
        return sendPromise
    }
    sendPromise = defer()
    return sendPromise

    async function doSend() {
        let localPromise = sendPromise
        const body = JSON.stringify(toSend)
        const types = toSend.map('type').join(',')
        toSend.length = 0
        sendPromise = null

        let response = await fetch(
            `/api/enqueue?type=${types}&requestId=${generate()}`,
            {
                method: 'POST',
                body,
                headers: {
                    'Content-Type': 'application/json',
                    Accept: 'application/json',
                },
            },
            false
        )
        localPromise.resolve(response)
    }
}

/**
 * Queues a job to run on the server
 * @param {Process} singleItem - the item to process
 * @param {number} [timeout=300000] - the timeout for the function in m/s default 5 mins
 * @param {string} [name] - a name to display in the job list for this job
 * @param {boolean} [important=false] - a flag to indicate that the job is important.
 * Important jobs are shown in a running list always, other jobs are only
 * shown in debug mode
 * @param {boolean} [isAnonymous=false] - flag to indicate that the job should be run as an anonymous user
 * @param {boolean} [batched=true] - flag to indicate that the job can be batched
 * @returns {Promise<*>} The result of the job after processing on the server
 */
export async function process(singleItem, timeout = 60000 * 5, name, important, isAnonymous = false, batched = true) {
    singleItem = Array.isArray(singleItem) ? singleItem[0] : singleItem

    let $id = (singleItem.$id = singleItem.$id || generate())
    events.emit('processing', singleItem)
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async (resolve, reject) => {
        const id = setTimeout(reject, timeout)
        running[singleItem.$id] = { name, important, start: Date.now(), type: singleItem.type, id: singleItem.$id }
        raise('update-jobs', running)
        const handler = async (event, packet, length = 0) => {
            raise('update-jobs', running)
            const { result } = packet.message
            const { client } = result
            if (window.DEBUG.CALLS) {
                const toReport = result?.errors?.filter(Boolean)
                if (toReport?.length) {
                    delete singleItem.password
                    // eslint-disable-next-line no-console
                    console.error(
                        `%cError: "${singleItem.type}" - ${toReport.join(', ')}.  Source: ${JSON.stringify(
                            singleItem,
                            null,
                            2
                        )}`,
                        'display: block;background: darkred;color: white;'
                    )
                }
            }
            clearTimeout(id)
            if (window.DEBUG.CALLS) {
                try {
                    let ms = Date.now() - running[singleItem.$id]?.start ?? Date.now() + 100
                    metrics[singleItem.type] = metrics[singleItem.type] || { count: 0, total: 0, median: 0 }
                    metrics[singleItem.type].ts = Date.now()
                    metrics[singleItem.type].count++
                    metrics[singleItem.type].length = (metrics[singleItem.type].length || 0) + length
                    metrics[singleItem.type].total += ms
                    metrics[singleItem.type].mean = metrics[singleItem.type].total / metrics[singleItem.type].count
                    // eslint-disable-next-line no-console
                    console.groupCollapsed(
                        `%cProcessed ${singleItem.type} ${ms.toFixed(0)}ms (Av. ${metrics[singleItem.type].mean.toFixed(
                            0
                        )}ms) - ${$id}`,
                        'color: white;font-weight: 200;background: #668B8B; display: inline-block;padding: 2px;'
                    )
                    // eslint-disable-next-line no-console
                    console.log(
                        '%cParameters:',
                        'color: white;font-weight: medium;background: #444; display: inline-block;padding: 2px;'
                    )
                    // eslint-disable-next-line no-console
                    console.table(singleItem)
                    // eslint-disable-next-line no-console
                    console.log(
                        '%cResult:',
                        'color: white;font-weight: medium;background: navy; display: inline-block;padding: 2px;'
                    )
                    // eslint-disable-next-line no-console
                    console.table(result.client)
                    // eslint-disable-next-line no-console
                    console.log(
                        '%cfull-packet: %O',
                        'color: blue;font-weight: medium;background: #f1f1f1; display: inline-block;padding: 2px;',
                        result
                    )
                    if (result._log && result._log.length) {
                        // eslint-disable-next-line no-console
                        console.group(
                            `%cSERVER LOG in ${singleItem.type} (${result._log.length})`,
                            'display: inline-block;font-weight:200; padding: 2px; padding-left: 10px; padding-right: 10px; background: #888; color: gold;'
                        )
                        for (let entry of result._log) {
                            const type = Object.keys(entry)[0]
                            // eslint-disable-next-line no-console
                            console[type](entry[type])
                        }
                        // eslint-disable-next-line no-console
                        console.groupEnd()
                    }
                    // eslint-disable-next-line no-console
                    console.groupEnd()

                    /* eslint-disable no-console */
                    // console.table(metrics)
                    raise('metrics', metrics)
                } catch (e) {
                    //
                }
            }
            window.isEmulated = !!client.isEmulated
            if (client.notifications) {
                await Promise.all(
                    client.notifications.map(async (notification) => {
                        await events.emitAsync('show-notification', notification)
                    })
                )
            }
            await Promise.all(
                client.events.map(async (event) => {
                    let params = event.params || []
                    params = Array.isArray(params) ? params : [params]
                    params.push(Date.now())
                    await events.emitAsync(event.event, ...params)
                })
            )
            resolve(result)
        }
        events.once(`jobDone.${singleItem.$id}`, handler)
        let response
        if (!batched || isAnonymous || getActiveClient() === '<test>') {
            response = await fetch(
                `/api/enqueue?type=${singleItem.type}&requestId=${generate()}`,
                {
                    method: 'POST',
                    body: JSON.stringify(singleItem),
                    headers: {
                        'Content-Type': 'application/json',
                        Accept: 'application/json',
                    },
                },
                isAnonymous
            )
        } else {
            response = await sendPacket(singleItem)
        }
        if (window.DEBUG.CALLS) {
            console.groupCollapsed(
                `%cStarted ${singleItem.type} - ${$id}`,
                'color: navy; font-weight: bold;background: #C3E4ED; display: inline-block;padding: 2px;'
            )
            console.log('%cParams', 'color: navy')
            console.table(singleItem)
            console.log('%cResponse', 'background: #333; color: lightgray', response)
            console.groupCollapsed(`${singleItem.type} Stack`)
            console.trace()
            console.groupEnd()
            console.groupEnd()
        }
        if (!response.ok) {
            events.off(`jobDone.${singleItem.$id}`, handler)
            delete running[singleItem.$id]
            raise('update-jobs', running)

            const isUnauthorized = response.status === 403 || response.statusText === 'Unauthorised'
            let message = isUnauthorized
                ? 'Error: logged out due to network unauthorised access'
                : `Error: Server responded with: ${response.statusText} (${response.status}) to "${singleItem.type}"`
            if (isUnauthorized) {
                if (!window.location.pathname.startsWith('/login')) {
                    raise('sign-out-event')
                } else {
                    message = ''
                }
            }
            showNotification(message)
            let e = new Error(message)
            e.nonNetwork = true
            reject(e)
        }
    })
}

function stringify(o) {
    return JSON.stringify(o)
}

/**

 */

/**
 * @interface DeclarationApi
 * @global
 * @description An api that is used to define server calls.
 * The order of calls declares the API.
 *
 * You may also import these functions directly from common/process.
 *
 * These calls should only be used inside a call to <code>define()</code>
 * to create a server api call.
 *
 * @see {@link @module:common/process.define}
 */

/**
 * @function DeclarationApi#required
 * @description Declare a required parameter
 * @param {string} name - the name by which the parameter will be known on the server
 * @param {TransformFunction} [transform] - a function to transform the value
 * export const addApp = define('app.types.add', function ({required, optional, returns}) {
    required('name') // First parameter will be known as 'name' in the server
    optional('definition')
    returns('id')
})
 */

/**
 * @function DeclarationApi#optional
 * @description Declare an optional parameter
 * @param {string} name - the name by which the parameter will be known on the server
 * @param {TransformFunction} [transform] - a function to transform the value
 * export const addApp = define('app.types.add', function ({required, optional, returns}) {
    required('name') // First parameter will be known as 'name' in the server
    optional('definition')
    returns('id')
})
 */

/**
 * @function DeclarationApi#returns
 * @description Declare a return value for the function.  If only
 * one value is returned, it becomes the return value of the generated
 * server call, if more than one - then the multiple values are
 * returned in an array.
 * @param {string} path - the path of the property to retrieve from the server result object
 * @example
 * export const addApp = define('app.types.add', function ({required, optional, returns}) {
    required('name') // First parameter will be known as 'name' in the server
    optional('definition')
    returns('id') // Returns the result.id property
})
 */

/**
 * @function DeclarationApi#lruCache
 * @description Declare that calls with the same parameters will
 * be cached and returned without a server call
 * @param {number} [count=50] - the number of calls to retain
 */

/**
 * @function DeclarationApi#cacheResult
 * @description Declares a function that will be used to
 * cache the result of a server call
 * @param {CacheFunction} cache
 */
/**
 * @callback CacheFunction
 * @global
 * @description cache a result
 * @param {object} result - the result to be cached
 * @param {Process} command - the parameters that were sent to create the result
 */

/**
 * @function DeclarationApi#cacheRetrieve
 * @description Declare a function to retrieve a value from a
 * cache, this allows you to write your own caching functions.
 * @param {CacheRetrieveFunction} retrieve - a function to retrieve a value from the cache
 */

/**
 * @callback CacheRetrieveFunction
 * @global
 * @param {Process} command - the command to retrieve from the cache
 * @returns {*|null} returns the cached value for the parameters or null
 */

/**
 * @function DeclarationApi#before
 * @description Declare a function to be called before sending the command,
 * there may be more than one before function.
 * @param {TransformFunction} transform - transform the command that is being sent
 */

/**
 * @function DeclarationApi#after
 * @description Declare a function to be called after retrieving the result,
 * there may be more than one after function.
 * @param {TransformFunction} transform - transform the result that has been retrieved
 */

/**
 * @function DeclarationApi#cacheKey
 * @description Declare a function that will create a cache key from an API packet.
 * By default the key is all of the parameters in the packet.
 * @param {CacheKeyFunction} getKey - a function to get a key from the command
 */
/**
 * @callback CacheKeyFunction
 * @global
 * @description retrieve a key from a command object
 * @param {Process} command - the command that is being sent
 * @returns {string} the key for the given command
 */

/**
 * @callback OfflineProcessor
 * @global
 * @param {Process} command - the command being processed
 * @returns {Promise<object>} - the result of processing the command offline
 */

/**
 * @function DeclarationApi#offline
 * @description Declare a function that will be used when the app is offline.
 * @param {OfflineProcessor} fn - the function to be called when the app is offline.
 */

/**
 * @function DeclarationApi#important
 * @description Flags this command as being registered in the "important"
 * running jobs
 */

/**
 * @function DeclarationApi#timeout
 * @description Declare the timeout for the function call in m/s
 * @param {number} timeout - the number of m/s that the call should run before timing out
 */

/**
 * @function DeclarationApi#description
 * @description Set a name for the job in the list of running jobs
 * @param {string|function(Process):string} name - the name of the job that is running for the monitored list
 */

/**
 * @callback DeclarationFunction
 * @global
 * @description A function that uses function calls to
 * declare an API call to the server
 * @param {DeclarationApi} api - the api for declaring server calls
 * @example
 * export const addApp = define('app.types.add', function ({required, optional, returns}) {
    required('name') // First parameter will be known as 'name' in the server
    optional('definition')
    returns('id')
})
 */

/**
 * Define an API call using a richer syntax that allows
 * for required and optional parameters, outbound and inbound
 * transformations plus one or more return values.
 *
 * @param {string} type - the api process to call
 * @param {DeclarationFunction} fn - a function that will be
 * called to create the definition
 * @returns {function(...[*]): Promise<*>} a function to asynchronously
 * make the API call
 */
export function define(type, fn = noop) {
    context = {
        parameters: [],
        returns: [],
        transformRequest: [],
        transformResult: [],
        type,
        notBatched: false,
        anonymous: false,
        offlineCache: null,
        cacheKey: stringify,
    }
    try {
        fn({
            required,
            optional,
            returns,
            lruCache,
            anonymous,
            cacheResult,
            cacheRetrieve,
            offlineCache,
            before,
            after,
            cacheKey,
            offline,
            important,
            timeout,
            description,
            doNotBatch,
        })
    } catch (e) {
        // eslint-disable-next-line no-console
        console.log(fn.toString())
        console.error(e)
    }
    const definition = context
    const result = async function (...parameters) {
        let toSend = {}
        for (let i = 0; i < definition.parameters.length; i++) {
            let hasParameter = parameters.length > i
            let parameter = definition.parameters[i]
            let value = parameters[i] || parameter.defaultValue
            if (!hasParameter && !parameter.optional) {
                throw new Error(`Missing parameter '${parameter.name}'`)
            }
            parameter.fn(value, toSend)
        }
        if (definition.cacheRetrieve) {
            const existing = await definition.cacheRetrieve(toSend)
            if (existing) {
                return existing
            }
        }
        for (let fn of definition.transformRequest) {
            toSend = (await fn(toSend)) || toSend
        }
        let debugProcess = DEBUG_PROCESS.isEnabled()
        if (debugProcess) console.info('Process', type, toSend)
        let result
        if (ONLINE.isEnabled()) {
            try {
                result = await process(
                    { ...toSend, type },
                    definition.timeout,
                    definition.name,
                    definition.important,
                    definition.anonymous,
                    !definition.notBatched
                )
            } catch (e) {
                console.error('%c')
            }
        } else {
            if (definition.offlineCache) {
                return await definition.offlineCache(toSend)
            }
            if (definition.offline) {
                result = await definition.offline(toSend)
            }
            if (!result) {
                return result
            }
        }

        for (let fn of definition.transformResult) {
            result.client = (await fn(result.client, toSend)) || result.client
        }
        const results = definition.returns.length
            ? definition.returns.map((extract) => get(result, `client.${extract}`))
            : [result.client]
        let finalResult = results.length === 1 ? results[0] : results
        if (definition.cacheResult) {
            await definition.cacheResult(finalResult, toSend)
        }
        return finalResult
    }
    Object.assign(result, definition)
    context = { parameters: [] }
    return result
}

export function lruCache(number = 50) {
    let definition = context
    let cache = (definition.$cache = new LRU(number))
    definition.cacheRetrieve = (toSend) => {
        return cache.get(definition.cacheKey(toSend))
    }
    definition.cacheResult = (result, toSend) => {
        cache.set(definition.cacheKey(toSend), result)
    }
}

export function timeout(time) {
    context.timeout = time
}

export function anonymous(anon = true) {
    context.anonymous = anon
}

export function important() {
    context.important = true
}

export function description(name) {
    context.description = name
    context.name = name
}

export function cacheKey(fn) {
    context.cacheKey = fn
}

export function cacheResult(fn) {
    context.cacheResult = fn
}

export function offline(fn) {
    context.offline = fn
}

export function cacheRetrieve(fn) {
    context.cacheRetrieve = fn
}

export function offlineCache(fn) {
    context.offlineCache = fn
}

export function before(fn) {
    context.transformRequest.push(fn)
}

export function after(fn) {
    context.transformResult.push(fn)
}

export function doNotBatch() {
    context.notBatched = true
}

export function required(name, transform = returnValue) {
    name = name.trim()
    if (!name) throw new Error('You must specify a parameter name')
    if (name === 'type' || name === 'id') throw new Error('Invalid parameter name: ' + name)
    if (['id', 'type'].includes(name)) {
        throw new Error(`You may not pass a '${name}' parameter, it is reserved. Choose a different name.`)
    }
    if (context.parameters.some((p) => p.optional && !p.defaultValue)) {
        throw new Error('Optional parameters without defaults must come after required parameters')
    }
    const param = { name, fn: parameter(name, transform) }
    context.parameters.push(param)
    const result = (value) => (param.defaultValue = value)
    result.$ = param
    return result
}

export function optional(name, transform = returnValue) {
    name = name.trim()
    if (!name) throw new Error('You must specify a parameter name')
    if (['id', 'type'].includes(name)) {
        throw new Error(`You may not pass a '${name}' parameter, it is reserved. Choose a different name.`)
    }
    const param = {
        name,
        fn: parameter(name, transform),
        optional: true,
    }
    context.parameters.push(param)
    const result = (value) => {
        param.defaultValue = value
        console.warn('Optional values with defaults are treated the same as required parameters with defaults')
    }
    result.$ = param
    return result
}

export function returns(path) {
    context.returns.push(...(Array.isArray(path) ? path : [path]))
}

export function document(transform = returnId) {
    const param = {
        fn: process,
        document: true,
    }
    context.parameters.push(param)
    return process

    function process(value, output) {
        let docs = (output.documents = output.documents || [])
        docs.push(transform(value))
        return false
    }
}

export function reference(transform = returnId) {
    const param = {
        fn: process,
        reference: true,
    }
    context.parameters.push(param)
    return process

    function process(value, output) {
        let docs = (output.references = output.references || [])
        docs.push(transform(value))
        return false
    }
}
