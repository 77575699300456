import { generate } from 'packages/identifiers'
import { parse } from 'query-string'

const params = parse(location.search)

let internalClientId = generate()
export const clientId = params.isDebugger ? `debug-${internalClientId}` : internalClientId
export function getClientId() {
    return clientId
}
