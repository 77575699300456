import React from 'react'
import mingo from 'mingo'
import { addOperators, OperatorType } from 'mingo/core'
import { ensureArray } from 'common/ensure-array'
import noop from 'common/noop'
import { handle } from 'common/events'
import { clone } from 'common/utils/deep-copy'
import { getUserContext } from 'dynamic/awe-library/runtime/user-context'
import { isEnabled } from 'dynamic/awe-library/enabled-question'
import { get } from 'common/offline-data-service/functions/get'
import { getStandardMappings, isDatabaseId } from 'common/offline-data-service/utils'
import { list } from 'common/offline-data-service/functions/list'
import { groupBy } from 'common/offline-data-service/functions/groupby'

const regexes = {}

const tableMaps = new WeakMap()

handle('get-query-fn-mappings', function (mappings) {
    mappings.$joinsTo = async function (settings) {
        const [database, table] = settings.table.split('/')
        let records = await list(database, table, settings.where)
        tableMaps.set(settings, new Set(records.map('id')))
    }
    mappings.$relatedTo = async function (settings) {
        const [database, table] = settings.table.split('/')
        let countMap = await groupBy(database, table, settings.where, settings.linkColumn)
        tableMaps.set(settings, countMap)
    }
    mappings.$topicRange = async function (settings) {
        const context = await getUserContext()
        const topics = {}
        await recurseGetTopics(context, topics, settings.range, 0)
        tableMaps.set(settings, topics)
    }
})

addOperators(OperatorType.QUERY, () => {
    return {
        $like,
        $joinsTo,
        $relatedTo,
        $topicRange,
    }
})

async function tryToGet(id) {
    try {
        return await get(id)
    } catch (e) {
        return null
    }
}

async function recurseGetTopics(record, topics, maxRange = 3, range = 0) {
    if (range > maxRange) return
    let instance = Object.isObject(record) ? record : await tryToGet(record)
    if (instance) {
        await scan(instance)
    }
    return topics

    async function scan(instance) {
        if (typeof instance === 'string' && isDatabaseId(instance)) {
            if (topics[instance] === undefined || topics[instance] > range) {
                topics[instance] = range
            }
            await recurseGetTopics(instance, topics, maxRange, range + 1)
        } else if (instance && typeof instance === 'object') {
            for (let [, value] of Object.entries(instance).filter(
                ([key]) => key === '_id' || (!key.startsWith('_') && !key.startsWith('$'))
            )) {
                if (Array.isArray(value)) {
                    for (let i = 0; i < value.length; i++) {
                        await scan(value)
                    }
                } else {
                    await scan(value)
                }
            }
        }
    }
}

function $joinsTo(selector, value, settings) {
    return tableMaps.get(settings)?.has(value)
}

function $relatedTo(selector, value, settings) {
    const relatedRows = tableMaps.get(settings)?.get(value) ?? 0
    switch (settings.operator) {
        case '<':
            return relatedRows < +settings.operand
        case '>':
            return relatedRows > +settings.operand
        default:
            return relatedRows === +settings.operand
    }
}

function $topicRange(selector, value, settings) {
    return tableMaps.get(settings)?.[value] !== undefined
}
function $like(selector, value, args) {
    let criteria = regexes[args]
    if (!criteria) {
        let regex = args.replace(/%/g, '.*').replace(/\?/g, '.')
        let flags = ''
        if (regex.startsWith('CI-')) {
            flags = 'i'
            regex = regex.slice(3)
        }
        criteria = regexes[args] = new RegExp(regex, flags)
    }
    return criteria.test(value)
}

function processParams(params, cb = noop, noChange) {
    if (Array.isArray(params)) {
        params.forEach((p) => processParams(p, cb, noChange))
        for (let i = params.length - 1; i >= 0; i--) {
            if (Object.isObject(params[i])) {
                if (params[i].$and && params[i].$and.length === 0) {
                    params.splice(i, 1)
                } else if (params[i].$or && params[i].$or.length === 0) {
                    params.splice(i, 1)
                }
            }
        }
    } else if (Object.isObject(params)) {
        for (let [key, value] of Object.entries(params)) {
            if (Array.isArray(value)) {
                processParams(value, cb, noChange)
            } else {
                cb(value, key)
                let inputKey = key
                if (!noChange && key.startsWith('_ci_')) {
                    key = key.slice(4)
                    if (Object.isObject(value)) {
                        let key = Object.keys(value)[0]
                        value[key] = 'CI-' + value[key]
                    } else {
                        value = 'CI-' + value
                    }
                }
                key = key.replace(/\+/g, '.').replace(/\[([^\]]*)]/, (match, capture) => `.${capture}`)
                delete params[inputKey]
                params[key] = value
                if (Object.isObject(value)) processParams(value, cb, noChange)
            }
        }
    }
}

export async function prepareQuery(params, mappings = getStandardMappings(), oneLevel) {
    const query = clone(params)
    const promises = []
    await processParams(query, check, oneLevel)
    await Promise.all(promises)
    return new mingo.Query(query)

    function check(value, key) {
        const fn = mappings[key] || mappings.all
        if (fn) {
            promises.push(Promise.resolve(fn(value, key)))
        }
    }
}

export function createQuery(params) {
    if (!params) return undefined
    const query = clone(params)
    processParams(query)
    let result = new mingo.Query(query)
    result._query = query
    return result
}

export let fields = {}

export function lastFields() {
    return Object.fromEntries(
        Object.entries(fields).map(([name, value]) => {
            return [name.split('.')[0], value]
        })
    )
}

export function map(value, instance) {
    let results = value.filter(Boolean).map((v) => {
        if (Object.isObject(v)) return v
        if (typeof v === 'number') return v
        if (v === 'NULL') return [null, '', undefined, 'undefined']
        return v
            .toString()
            .replace(/\$now\(([0-9.\-+]+)\)/g, (_, i) => {
                return Date.create(Date.now()).addDays(-i, true).toISOString()
            })
            .replace(/{([^}]*)}/, (_, field) => {
                if (field && (isEnabled(field, instance) || field.startsWith('$currentUser.'))) {
                    fields[field.trim()] = true
                    let parsedValue = Object.get(instance, field.trim(), true)
                    if (Array.isArray(parsedValue)) parsedValue = parsedValue.join('<ARR>')
                    return parsedValue || ''
                }
                return ''
            })
    })

    return results
        .map((r) => (typeof r !== 'string' ? r : r.split('<ARR>')))
        .flat(Infinity)
        .filter(Boolean)
}

export function processQuery(query = {}, instance) {
    fields = {}
    return recurseProcessQuery(query, instance)

    function recurseProcessQuery(query, instance) {
        query = clone(query)
        return Object.keys(query).reduce(processElement, {})

        function processElement(c, a) {
            let v = query[a]
            if (!v) return c
            switch (a) {
                case '$state':
                    a = '_settings+$state'
                    break
                case '$and':
                case '$or':
                    c[a] = v.map((query) => recurseProcessQuery(query, instance)).filter(Boolean)
                    return c
                case '$joinsTo':
                    c[a] = { table: v.table, where: recurseProcessQuery(v.where, instance) }
                    return c
                case '$query':
                    try {
                        fields[v.$where.field] = true
                        if (instance[v.$where.field] && isEnabled(v.$where.field, instance)) {
                            return recurseProcessQuery(instance[v.$where.field], instance)
                        } else {
                            return null
                        }
                    } catch (e) {
                        console.error('Error', e)
                    }
            }
            if (Object.isObject(v)) {
                let [[key, value]] = Object.entries(v)
                value = ensureArray(value)
                if (value.length === 0) return c
                switch (key) {
                    case '$in':
                        c[a] = {
                            [key]: map(value, instance),
                        }
                        if (c[a][key][0] === '') delete c[a]
                        break
                    case '$joinsTo':
                        c[a] = {
                            [key]: { table: value[0].table, where: recurseProcessQuery(value[0].where, instance) },
                        }
                        break
                    default:
                        c[a] = {
                            [key]: map(value, instance)[0],
                        }
                        if (c[a][key] === '') delete c[a]
                        break
                }
            } else {
                c[a] = map([v], instance)[0]
            }

            return c
        }
    }
}
