import React from 'react'

import PropTypes from 'prop-types'

import decamelize from 'decamelize'

import { Box, InputAdornment, ListSubheader, TextField, useMediaQuery } from '@material-ui/core'
import Autocomplete from '@material-ui/lab/Autocomplete'

import { makeTestId } from 'common/component-utilities'
import noop from 'common/noop'
import { VirtualRepeat } from 'common/repeat'
import clsx from 'clsx'
import useAsync from 'common/use-async'
import { useTheme } from '@material-ui/core/styles'
import { makeCachedStyles } from 'common/inline-styles'
import { onlyOne } from 'common/guard'

const icons = {}

export async function iconsReady() {
    return true
}

const OuterElementContext = React.createContext({})

const ListboxComponent = React.forwardRef(function ListboxComponent(props, ref) {
    const { children, ...other } = props
    const itemData = React.Children.toArray(children)
    const theme = useTheme()
    const smUp = useMediaQuery(theme.breakpoints.up('sm'), { noSsr: true })
    const itemSize = smUp ? 36 : 48

    return (
        <div ref={ref}>
            <OuterElementContext.Provider value={other}>
                <VirtualRepeat keyFn={(item, index) => index} collection={itemData} itemSize={itemSize}>
                    {(item) => {
                        return <>{item}</>
                    }}
                </VirtualRepeat>
            </OuterElementContext.Provider>
        </div>
    )
})

ListboxComponent.propTypes = {
    children: PropTypes.node,
}

const useStyles = makeCachedStyles((theme) => ({
    icon: {
        color: theme.palette.text.primary,
        '& i': {
            fontSize: 24,
        },
    },
    holder: {
        textAlign: 'center',
    },
    title: {
        minWidth: 96,
        textTransform: 'capitalize',
        '& *': {
            textTransform: 'capitalize',
        },
    },
}))

const renderGroup = (params) => {
    return [
        <ListSubheader key={params.group} component="div">
            {params.group}
        </ListSubheader>,
        params.children,
    ]
}

function Show({ icon, className, ...props }) {
    const Component = useAsync(
        async () => {
            if (icons[icon]) return icons[icon]
            let folder = icon.slice(0, 2).toLowerCase()
            let module
            switch (folder) {
                case 'fa':
                    module = /* webpackInclude: /.esm.js/ */ await import(`@react-icons/all-files/fa/${icon}.esm.js`)
                    break
                case 'ai':
                    module = /* webpackInclude: /.esm.js/ */ await import(`@react-icons/all-files/ai/${icon}.esm.js`)
                    break
                case 'md':
                    module = /* webpackInclude: /.esm.js/ */ await import(`@react-icons/all-files/md/${icon}.esm.js`)
                    break
                case 'hi':
                    module = /* webpackInclude: /.esm.js/ */ await import(`@react-icons/all-files/hi/${icon}.esm.js`)
                    break
                case 'bi':
                    module = /* webpackInclude: /.esm.js/ */ await import(`@react-icons/all-files/bi/${icon}.esm.js`)
                    break
            }

            return (icons[icon] = Object.values(module)[0] || Box)
        },
        icons[icon] || Box,
        icon
    )

    return <Component className={clsx(`alcumus-icon icon-${icon}`, className, 'alcumusIcon')} {...props} />
}

export function AlcumusIcon({ icon, ...props }) {
    return !!icon && typeof icon === 'string' && icon.trim() && <Show icon={icon} {...props} />
}

let iconNames
const getIconNames = onlyOne(async function getIconNames() {
    return (iconNames =
        iconNames ||
        (await import('../icons')).ICONS.split(',')
            .sortBy(nameOf)
            .unique(nameOf)
            .filter((g) => nameOf(g).trim()))
})

export function ReactIconSelector({ label, placeholder, onChange = noop, 'data-testid': dataTestId, ...props }) {
    const classes = useStyles()
    const iconNames = useAsync(async () => {
        return getIconNames()
    }, [])
    return (
        <Autocomplete
            {...props}
            disableListWrap
            autoComplete
            autoHighlight
            autoSelect
            ListboxComponent={ListboxComponent}
            options={iconNames}
            groupBy={(g) => nameOf(g).toUpperCase().slice(0, 1)}
            onChange={(_, v) => onChange(v)}
            getOptionLabel={(o) => nameOf(o)}
            renderGroup={renderGroup}
            renderOption={(o) => {
                const testId = makeTestId(`${dataTestId}-${nameOf(o)}`)

                return (
                    <Box display="flex" width="100%" className={classes.icon} alignItems="center">
                        <Box mr={1} width={32} className={classes.holder} data-testid={`${testId}-icon`}>
                            <AlcumusIcon icon={o} />
                        </Box>
                        <Box className={classes.title} data-testid={`${testId}-label`}>
                            {nameOf(o)}
                        </Box>
                    </Box>
                )
            }}
            renderInput={({ InputProps, ...inputProps }) => {
                return (
                    <TextField
                        {...inputProps}
                        label={label}
                        data-testid={`${dataTestId}-text`}
                        className={classes.title}
                        placeholder={placeholder}
                        InputProps={{
                            ...InputProps,
                            startAdornment: (
                                <InputAdornment position="start">
                                    <Box className={classes.icon} ml={1}>
                                        <AlcumusIcon icon={props.value} />
                                    </Box>
                                </InputAdornment>
                            ),
                        }}
                        fullWidth
                        variant="outlined"
                    />
                )
            }}
        />
    )
}

function nameOf(o) {
    if (typeof o === 'object') o = o.icon
    o = o || ''
    o = o
        ?.replace(/Ios/g, '')
        ?.replace(/Md/g, '')
        .replace(/Outline/gi, '')
    return decamelize(o, { separator: ' ' }).split(' ').slice(1).join(' ').titleize()
}

AlcumusIcon.propTypes = {
    icon: PropTypes.any,
}

ReactIconSelector.propTypes = {
    label: PropTypes.any,
    onChange: PropTypes.func,
    placeholder: PropTypes.any,
    value: PropTypes.any,
    'data-testid': PropTypes.string,
}
