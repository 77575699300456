import { useEffect, useRef } from 'react'
import { useRefresh, useRefreshWhen } from 'common/useRefresh'
import { useLocalEvent, useStableLocalEvent } from 'common/use-event'
import useAsync from 'common/use-async'
// import { documentChangesApply, documentRetrieve } from 'dynamic/awe-library/runtime/lib/simple-db'
import { initialize } from 'common/offline-data-service/behaviour-cache'
import { appReady } from 'common/app-ready'
import { getInstanceController } from 'dynamic/awe-library/runtime/lib/get-instance-controller'
import { raise, raiseAsync } from 'common/events'
import { useBoundContext } from 'common/component-utilities'
import { isMatchingDocument } from 'common/compare-documents'
import { set } from 'common/offline-data-service/functions/set'
import { retrieveDocument } from 'dynamic/awe-library/runtime/document-retrieve'
import { getActiveClient } from 'common/global-store/api'

export function useInstanceController(id, actionId) {
    const returnValue = useRef({ notLoaded: true })
    const { document } = useBoundContext()
    const refresh = useRefresh()
    useStableLocalEvent(`context.updated.${id}.${actionId}.*`, refresh)

    return useAsync(
        async () => {
            let controller = await getInstanceController(id, actionId, document)
            returnValue.current = controller
            return controller
        },
        returnValue.current,
        refresh.id
    )
}

export async function getDocument(id, allowNull = false) {
    const info = { id, allowNull }
    // await documentRetrieve(info)
    // await documentChangesApply(info)
    await initialize(info.document)
    if (!info.document) return null
    await info.document.sendMessageAsync('hydrated')
    await info.document.sendMessageAsync('hasHydrated')

    return info.document
}

export function useDocumentRefresh(docs, refresh) {
    const localRefresh = useRefresh()
    refresh = refresh || localRefresh
    const all = docs.flatten()
    useLocalEvent('data.updated.*', check)
    return refresh.id

    function check(id) {
        if (all.find((v) => v._id === id)) {
            refresh()
        }
    }
}

export function useDocument(id, defaultValue = null, create = false) {
    const returnValue = useRef(defaultValue)
    if (returnValue.current && returnValue.current._id !== id) {
        returnValue.current = defaultValue
    }
    const refresher = useRef()
    const releaseValue = useRef()
    const timer = useRef(0)
    const shouldRefresh = useRef(false)
    refresher.current = useRefresh()
    const { id: version } = useRefreshWhen('published')
    raise('prepare-document-context', returnValue.current || {})
    useStableLocalEvent(`data.updated.**`, async (updatedId) => {
        if (
            returnValue.current &&
            (returnValue.current?._settings?.$hydrate === updatedId ||
                returnValue.current?._settings?._versionId === updatedId)
        ) {
            shouldRefresh.current = true
            raise(`data.updated.${id}`, id)
        }
    })
    useStableLocalEvent(`data.updated.${id}`, async () => {
        try {
            releaseValue.current && releaseValue.current.sendMessage('unmounted')
            const updatedDocument = await retrieveDocument(id)
            await updatedDocument.sendMessage('mounted')
            if (!shouldRefresh.current && isMatchingDocument(updatedDocument, returnValue.current)) return
            shouldRefresh.current = false
            clearTimeout(timer.current)
            returnValue.current = updatedDocument
            releaseValue.current = updatedDocument
            timer.current = setTimeout(() => refresher.current(), 50)
        } catch (e) {
            //
        }
    })
    useEffect(() => {
        setTimeout(async () => {
            try {
                if (!id) {
                    returnValue.current = defaultValue
                    await raiseAsync('prepare-document-context', returnValue.current)
                    return
                }
                const info = { id }

                if (!info.document) {
                    returnValue.current = defaultValue
                    await raiseAsync('prepare-document-context', returnValue.current)
                    if (defaultValue !== null && create) {
                        await set(
                            { _id: id, _client: getActiveClient(), _behaviours: { _state: '' }, ...defaultValue },
                            true,
                            true
                        )
                    }
                    return
                }
                await Promise.resolve(info.document._components)
                await Promise.resolve(info.document._hydrated)

                await initialize(info.document)
                await info.document.sendMessageAsync('hydrated')
                await info.document.sendMessageAsync('hasHydrated')
                await info.document.sendMessage('mounted')
                await appReady()
                await raiseAsync('prepare-document-context', info.document)
                returnValue.current = info.document
                releaseValue.current = info.document
                refresher.current()
            } catch (e) {
                console.error('info.doc EXCEPTION', id, e)
                returnValue.current = defaultValue
                refresher.current()
            }
        })
        return () => {
            if (releaseValue.current) {
                releaseValue.current.sendMessage('unmounted')
                releaseValue.current = defaultValue
            }
        }
    }, [id, version])
    return returnValue.current
}
