// import { getCurrentTopicsForUser } from 'dynamic/awe-runner-plugin/tasks/tasks'
import { useCachedAsync } from 'common/use-async'
import { getActiveUser, getActiveUserProfile, getCurrentUserEmail } from 'common/global-store/api'
// import { get } from 'common/offline-data-service/functions/get'

// let cache = new Map()
let _currentUserContext = {}
export function currentUserContext() {
    return _currentUserContext
}

export async function getUserContext() {
    // const userTopics = await getCurrentTopicsForUser()
    const reference = {}
    // const key = userTopics.related.join(':')
    // const existing = cache.get(key)
    // if (existing) {
    //     return existing
    // }
    // await Promise.all(
    //     userTopics.related.map(async (id) => {
    //         let record = await get(id)
    //         reference[record._settings.name] = record
    //     })
    // )
    reference.id = getActiveUser()
    reference.email = getCurrentUserEmail()
    reference.profile = getActiveUserProfile()
    // cache.set(key, reference)
    // setTimeout(() => cache.delete(key), 60000)
    _currentUserContext = reference
    return reference
}

export function useUserContext() {
    return useCachedAsync(
        'getUserContext',
        async () => {
            // notReady()
            try {
                return getUserContext()
            } finally {
                // makeReady()
            }
        },
        null,
        getCurrentUserEmail(),
        `${(Date.now() / 60000) | 0}`
    )
}
