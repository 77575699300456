const { nanoid } = require('nanoid')
const short = require('short-uuid')

function generate(size) {
    return nanoid(size || 14)
}

/**
 * Determine if the string is a short ID or classic GUID
 * @param {string} UUID
 * @returns {boolean}
 */
function isShortID(UUID) {
    try {
        return UUID.split('-').length <= 3 && UUID.length <= 25
    } catch (e) {
        return false
    }
}

/**
 * If UUID is a short ID make it a classic GUID and vice versa
 * @param {string} UUID
 * @returns {string}
 */

function switchFormat(UUID) {
    const translator = short(short.constants.cookieBase90, {
        consistentLength: false,
    })
    if (isShortID(UUID)) {
        return translator.toUUID(UUID)
    } else {
        return translator.fromUUID(UUID)
    }
}

module.exports = {
    generate,
    switchFormat,
    isShortID,
}
