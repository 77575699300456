import { createTheme } from '@material-ui/core/styles'
import { options, thingBuilderOptions } from '../theme-options'

export const theme = createTheme({
    ...options,
    palette: { ...options.palette, type: 'light' },
})
export const autoTheme = theme
export const thingBuilderTheme = createTheme(thingBuilderOptions)
export default theme
