import events from 'packages/alcumus-local-events'
import queryString, { parse } from 'query-string'
import { clientId } from 'common/client-id'
import { deviceId, tabId } from 'common/remote-ids'
import { fetch } from 'common/request'

import { once, raise } from 'common/events'
import { isOnline } from 'common/utils'
import { ONLINE } from 'common/globals'
import { ack } from 'common/remote-messages'
import { isDebugger } from 'common/debugger'
import { getItem, setItem } from 'common/using-local-storage-key'
import { generate } from 'packages/identifiers'

const params = parse(location.search)
let lastReceivedId = params.lastId || Date.now()
let backoff = 500
const lastIdRef = isDebugger() ? 'debugger' : 'client'

function delay(time) {
    return new Promise((resolve) => setTimeout(resolve, time))
}

async function trackEvents() {
    let stop = false
    await fetch(
        `/poll/initialize?${queryString.stringify({
            listen: [deviceId, tabId, clientId, 'atWorkNetwork'],
        })}&requestId=${generate()}`
    )
    let loopStop = startLoop()
    window.addEventListener('online', restart)
    return () => {
        raise('stop-listening', Date.now())
        stop = true
        window.removeEventListener('online', restart)
    }

    function restart() {
        if (stop) return
        backoff = 500
        loopStop()
        loopStop = startLoop()
    }

    function startLoop() {
        // eslint-disable-next-line no-console
        let localStop = false
        loop(() => !stop && !localStop).catch(console.error)
        return () => (localStop = true)
    }

    async function loop(check = () => true) {
        if (window.useLongPolling === true) {
            while (check() && window.useLongPolling === true) {
                await innerTrackEvents()
            }
        } else {
            //console.warn('no loop-> check because !useLongPolling')
        }
    }

    async function innerTrackEvents() {
        let lastId = getItem(`messages.lastId.${lastIdRef}`)
        if (!isOnline()) {
            await delay(backoff)
            backoff = Math.min(180000, backoff * 2)
            return
        }
        try {
            setTimeout(() => raise('tracking'))
            const response = await fetch(
                `/poll?${queryString.stringify({
                    lastEventId: !params.isDebugger ? lastId : lastReceivedId,
                    listen: [deviceId, tabId, clientId, 'atWorkNetwork'],
                })}&requestId=${generate()}`
            )
            backoff = 500
            if (response.ok) {
                const messages = JSON.parse(await response.text())
                for (let message of messages) {
                    try {
                        await handleMessage(message)
                    } catch (e) {
                        console.error(e)
                    }
                }
            } else {
                throw new Error('failed')
            }
        } catch (e) {
            if (stop) return
            console.error(e)
            await delay(backoff)
            backoff = Math.min(5000, backoff * 2)
        }

        async function handleMessage(packet) {
            if (window.useLongPolling === true) {
                if (packet.id) {
                    // if (seen[packet.id]) return
                    // seen[packet.id] = true
                    // setTimeout( () => delete seen[packet.id], 5000 )
                    if (!isNaN(+packet.id)) {
                        lastReceivedId = Math.max(lastReceivedId, +packet.id)
                    }

                    setItem(`messages.lastId.${lastIdRef}`, lastReceivedId)
                    events.emit(`${packet.message.event}`, packet.message.data)
                    ack(clientId, lastReceivedId)
                }
            } else {
                //console.warn('not polling because !useLongPolling')
            }
        }
    }
}

let oldStop = null

export async function startTrackingPolling() {
    let promise = new Promise((resolve) => once('tracking', resolve))
    oldStop = trackEvents()
    await (ONLINE.isEnabled() ? promise : Promise.resolve(true))
    return oldStop
}
