// import { EventEmitter2 as Events } from './eventemitter'
import isFunction from 'lodash/isFunction'
import Events from 'packages/alcumus-events'
import { inPriorityOrder } from './utilities'

/**
 * <p>Event source for all of the system based Widget events, can also be used
 * for other general purposes.  This is based on [EventEmitter2]{@link https://github.com/EventEmitter2/EventEmitter2}
 * and inherits all the functionality of that.  If you need one you should construct it by calling
 * {@link widgetEventsFactory}.
 * </p>
 * <p>Wildcards are turned on and the delimiter is set to "." </p>
 * @typedef WidgetEvents
 * @hideconstructor
 * @class
 */

/**
 * @method render
 * @memberOf WidgetEvents
 * @instance
 * @description
 * <p>Adds a handler for when an item is rendered
 * in a {@link Widget}</p>
 * <p> See also {@link useContent}, {@link useInline} and {@link useEndOfLine} for ways of populating the item.</p>
 * @param {string} what - the type that is being rendered
 * @param {HandlerCallback} handler - adds any required renderers
 */

/**
 * @method configure
 * @memberOf WidgetEvents
 * @instance
 * @description
 * Adds a handler for when the outer {@link Widgets} will fill
 * out its layout. This provides an opportunity for you
 * to create the UI or override other elements.
 * <p> See also {@link useLayout} for a way to populate the canvas.</p>
 * @param {string} what - the type that is being rendered
 * @param {HandlerCallback} handler - adds any required renderers
 */

/**
 * @method editor
 * @memberOf WidgetEvents
 * @instance
 * @description
 * Adds a handler for when the {@link Editor} needs to render,
 * probably due to a focus change.
 * <p> See also {@link useTabs} for a way to populate the editor tabs.</p>
 * @param {string} what - the type that is being rendered
 * @param {HandlerCallback} handler - adds any required renderers
 */

/**
 * @method context
 * @memberOf WidgetEvents
 * @instance
 * @description
 * Adds a handler for when the {@link Widgets} needs to discover,
 * context menu contents, probably due to a focus change.
 * @param {string} what - the type that is being rendered
 * @param {HandlerCallback} handler - adds any required renderers
 */

/**
 * @method removeListener
 * @memberOf WidgetEvents
 * @instance
 * @description
 * <p>Removes a handler from an event on the emitter.  This is a wild card event emitter with the delimiter set to
 *     '.'.</p>
 * <p>It's an {@link EventEmitter2} so you can use '*' to match part of an event pattern or '**' to match every
 *     subsequent thing.</p>
 * @param {string} eventPattern - the event or pattern to remove the handler from
 * @param {HandlerCallback} handler - adds any required renderers
 */

/**
 * @method emit
 * @memberOf WidgetEvents
 * @instance
 * @description
 * <p>Emits an event on the event emitter. </p>
 * <p>It's an {@link EventEmitter2} so you can use '*' to match part of an event pattern or '**' to match every
 *     subsequent thing.</p>
 * @param {string} event - the event to emit
 * @param {Any} parameter - one or more parameters may be passed to the event
 */

/**
 * @method on
 * @memberOf WidgetEvents
 * @instance
 * @description
 * <p>Adds a handler to an event on the emitter.  This is a wild card event emitter with the delimiter set to '.'.</p>
 * <p>It's an {@link EventEmitter2} so you can use '*' to match part of an event pattern or '**' to match every
 *     subsequent thing.</p>
 * <p>Standard events are:
 *  * configure.<designType>
 *  * editor.<designType>
 *  * render.<widgetType>
 * </p>
 * @example
 * //Log the design used for any render of any component in any document type
 * globalWidgets.on("render.**", ()=>console.log(useDesign()[0]))
 * @param {string} eventPattern - the type that is being rendered, can use wildcards and '.' delimiter
 * @param {HandlerCallback} handler - adds any required renderers
 */

/**
 * Creates an event source suitable for passing the the widgets property of a {@link Widgets} and {@link Editor}
 * component. Helpful for writing tests and keeping things separate between tests.
 * @returns {WidgetEvents}
 */

function widgetEventsFactory() {
    let widgets = new Events({
        storeHandlers(handlers) {
            handlers.sort(inPriorityOrder)
            return handlers
        },
    })
    widgets.render = function (what, handler) {
        if (isFunction(what)) {
            handler = what
            return widgets.onAny((_, ...params) => _.startsWith('render') && handler(...params))
        }
        return widgets.on(`render.${what}`, handler)
    }
    widgets.editor = function (what, handler) {
        if (isFunction(what)) {
            handler = what
            return widgets.onAny((_, ...params) => _.startsWith('editor') && handler(...params))
        }
        return widgets.on(`editor.${what}`, handler)
    }
    widgets.configure = function (what, handler) {
        if (isFunction(what)) {
            handler = what
            return widgets.onAny((_, ...params) => _.startsWith('configure') && handler(...params))
        }
        return widgets.on(`configure.${what}`, handler)
    }
    widgets.context = function (what, handler) {
        if (isFunction(what)) {
            handler = what
            return widgets.onAny((_, ...params) => _.startsWith('context') && handler(...params))
        }
        return widgets.on(`context.${what}`, handler)
    }
    return widgets
}

/**
 * The global {@link WidgetEvents} that are used by default if
 * no override is specified on a {@link Widgets} and {@link Editor} component.
 * @type {WidgetEvents}
 */
const globalWidgets = widgetEventsFactory()
export { globalWidgets, widgetEventsFactory }
