import React from 'react'

import PropTypes from 'prop-types'

import { Button } from '@material-ui/core'

import { validateSecurity } from 'common/secure/validate'

export function SecureButton({ demands = [], children, ...props }) {
    return (
        <Button disabled={!validateSecurity(demands)} {...props}>
            {children}
        </Button>
    )
}

SecureButton.propTypes = {
    children: PropTypes.any,
    demands: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
}

export default SecureButton
