import { generate } from 'packages/identifiers'

import { initialize } from 'common/offline-data-service/behaviour-cache'
import { getActiveClient } from 'common/global-store/api'
import { getUserContext } from 'dynamic/awe-library/runtime/user-context'
import { decorate } from 'common/utils'
// import { resetStorage } from 'dynamic/awe-library/runtime/lib/simple-db'
import { clone } from 'common/utils/deep-copy'

export function validId(id) {
    let parts = id.split(':')
    return [parts[0].replace(/[:/]/g, '-'), ...parts.slice(1)].join(':')
}

export async function createDocument(template, idPrefix = '', id) {
    template._behaviours = template._behaviours || {}
    let document = {
        _behaviours: clone(template._behaviours),
        _settings: template,
        client: getActiveClient(),
        _client: getActiveClient(),
        _created: Date.now(),
        _modified: Date.now(),
    }
    document._behaviours._state = document._settings.defaultState
    delete document._settings._behaviours
    delete document._settings.versions
    delete document._settings.currentVersion
    id = id || `${idPrefix.replace(/:/g, '|of|')}${idPrefix ? '<|>' : ''}${generate()}`
    if (!id.includes(':') && template.database && template.table) {
        document._id = `${id}:${template.database}/${template.table}`
    } else {
        document._id = id
    }
    await initialize(document)
    // await resetStorage(document._id)
    await document.sendMessageAsync('createDocument', template)
    decorate(document, { $currentUser: await getUserContext(), $activeClient: getActiveClient() })
    await document.sendMessage('applyDefaultValues', {})
    return document
}
