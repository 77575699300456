import React from 'react'

import PropTypes from 'prop-types'

import { Box } from 'common/styled-box'
import Button from '@material-ui/core/Button'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import IconButton from '@material-ui/core/IconButton'
import CloseIcon from '@material-ui/icons/Close'

import { resolveValue } from 'common/resolve-value'
import { MdClose } from '@react-icons/all-files/md/MdClose'
import { makeCachedStyles } from 'common/inline-styles'

const useDialogStyles = makeCachedStyles((theme) => ({
    elideTitle: {
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
    },
    confirmButton: {
        fontWeight: 'bold',
    },
    basics: {
        fontFamily: theme.document?.form?.fontFamily || theme.document?.fontFamily || 'Montserrat, sans-serif',
        fontSize: theme.document?.form?.fontSize || theme.document?.fontSize || 16,
    },
}))

export function SelectorDialog({
    accept = 'Ok',
    canAccept = true,
    children,
    endAdornment,
    endButtonAdornment,
    onCancel,
    onOk,
    startAdornment,
    startButtonAdornment,
    contentProps,
    title,
    ...props
}) {
    const { basics, elideTitle, confirmButton } = useDialogStyles()
    return (
        <Box className={basics} display="flex" flexDirection="column" height="100%" overflow="hidden" {...props}>
            {title && (
                <Box
                    p={2}
                    display="flex"
                    width="100%"
                    flexDirection="row"
                    alignItems="center"
                    justifyContent="space-between"
                >
                    {startButtonAdornment && <Box>{startButtonAdornment}</Box>}
                    {onCancel ? (
                        <Box mr={2}>
                            <IconButton size="small" aria-label="close" onClick={onCancel}>
                                <MdClose />
                            </IconButton>
                        </Box>
                    ) : null}
                    {startAdornment && <Box>{startAdornment}</Box>}
                    <Box className={elideTitle} flexGrow={1}>
                        {title}
                    </Box>
                    {endAdornment && <Box>{endAdornment}</Box>}
                    {onOk && (
                        <Button
                            className={confirmButton}
                            data-testid="dialog-ok"
                            disabled={!resolveValue(canAccept)}
                            color="primary"
                            onClick={onOk}
                        >
                            {accept}
                        </Button>
                    )}
                    {endButtonAdornment && <Box>{endButtonAdornment}</Box>}
                </Box>
            )}
            <DialogContent {...{ dividers: true, ...contentProps }}>{children}</DialogContent>
        </Box>
    )
}
export function StandardDialog({
    accept = 'Ok',
    canAccept = true,
    cancel = 'Cancel',
    children,
    endAdornment,
    endButtonAdornment,
    onCancel,
    onOk,
    startAdornment,
    startButtonAdornment,
    hideCancel,
    contentProps,
    refresh,
    title,
}) {
    const { elideTitle } = useDialogStyles()
    refresh && refresh.useRefresh()
    return (
        <>
            {title && (
                <DialogTitle disableTypography>
                    <Box
                        display="flex"
                        width="100%"
                        flexDirection="row"
                        alignItems="center"
                        justifyContent="space-between"
                    >
                        {startAdornment && <Box>{startAdornment}</Box>}
                        <Box className={elideTitle} flexGrow={1}>
                            {title}
                        </Box>
                        {endAdornment && <Box>{endAdornment}</Box>}
                        {onCancel ? (
                            <Box>
                                <IconButton size="small" aria-label="close" onClick={onCancel}>
                                    <CloseIcon />
                                </IconButton>
                            </Box>
                        ) : null}
                    </Box>
                </DialogTitle>
            )}
            <DialogContent {...{ dividers: true, ...contentProps }}>{children}</DialogContent>
            {(onOk || (onCancel && !hideCancel) || startButtonAdornment || endButtonAdornment) && (
                <DialogActions>
                    {startButtonAdornment && (
                        <Box width="100%" flexGrow={1}>
                            {startButtonAdornment}
                        </Box>
                    )}
                    {onCancel && !hideCancel && (
                        <Button color="secondary" data-testid="dialog-cancel" onClick={onCancel}>
                            {cancel}
                        </Button>
                    )}
                    {onOk && (
                        <Button
                            data-testid="dialog-ok"
                            disabled={!resolveValue(canAccept)}
                            color="primary"
                            onClick={onOk}
                        >
                            {accept}
                        </Button>
                    )}
                    {endButtonAdornment && <Box>{endButtonAdornment}</Box>}
                </DialogActions>
            )}
        </>
    )
}

StandardDialog.propTypes = {
    accept: PropTypes.string,
    canAccept: PropTypes.bool,
    cancel: PropTypes.string,
    children: PropTypes.any,
    contentProps: PropTypes.object,
    endAdornment: PropTypes.any,
    endButtonAdornment: PropTypes.any,
    isOk: PropTypes.any,
    onCancel: PropTypes.func,
    onOk: PropTypes.any,
    startAdornment: PropTypes.any,
    startButtonAdornment: PropTypes.any,
    title: PropTypes.any,
}
