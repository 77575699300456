import { store } from 'common/global-store'
import { createWrapped } from 'dynamic/awe-library/runtime/contexts'
import React, { useContext, useEffect, useLayoutEffect, useRef } from 'react'
import noop from 'common/noop'

const shipped = new Map()

export const ShippingContainer = React.createContext(null)

export function ship(contents, toPort, permanent = false, volatile = true, instant = false) {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const setVisible = useRef(noop)
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const currentContents = useRef(createWrapped(contents, false, (sv) => (setVisible.current = sv)))
    // eslint-disable-next-line react-hooks/rules-of-hooks
    useLayoutEffect(() => {
        if (contents === false) return
        if (contents?.props?.id) {
            if (shipped.get(toPort) === contents.props.id) {
                setVisible.current(true)
                return
            }
            shipped.set(toPort, contents.props.id)
        }
        store.set({ [`port-${toPort}`]: currentContents.current })
        store.flush()
    }, [])
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const container = useContext(ShippingContainer)
    if (container && !volatile) {
        container(remove)
    } else {
        if (instant) {
            // eslint-disable-next-line react-hooks/rules-of-hooks
            useLayoutEffect(() => {
                return remove
            }, [])
        } else {
            // eslint-disable-next-line react-hooks/rules-of-hooks
            useEffect(() => {
                return () => setTimeout(remove, 10)
            }, [])
        }
    }

    function remove() {
        if (!permanent && store.get(`port-${toPort}`).value === currentContents.current) {
            setVisible.current(false)
            store.set({ [`port-${toPort}`]: null })
            shipped.delete(toPort)
        }
    }
}

export const PortEnabledContext = React.createContext(true)

export function DestinationPort({ name, children = null, fallback = children, onChanged = noop }) {
    const port = store.get(`port-${name}`)

    port?.useChange(changed)
    const contents = port?.useValue()
    const current = useRef(contents)
    const portEnabled = useContext(PortEnabledContext)
    if (!portEnabled) return null

    let result = contents || fallback || null
    if (typeof result === 'function') {
        const Fn = result
        return <Fn />
    }
    return result
    function changed() {
        let newValue = store.get(`port-${name}`).value
        if (current.current !== newValue) {
            onChanged(newValue)
        }
    }
}
