import get from 'lodash/get'
import { captureException } from '@sentry/browser'
import { signOut, tokensRefreshed } from 'utils/authenticationService'

import { store } from 'common/global-store'
import { define, process } from 'common/process'

import { setItem, setItemWithStringify, setUnprefixedItemWithStringify } from 'common/using-local-storage-key'

import { getUser } from 'framework/login'
import { startTracking } from 'common/remote-start'
import { handle, raise, raiseAsync, raiseLater } from 'common/events'
import { generate } from 'packages/identifiers'
import { getLocalItem, storeLocalItem } from 'common/local-storage/local-store'
import { getActiveUser, getCurrentAuthenticatedUser } from 'common/global-store/api'
import { guard } from 'common/guard'

const retrieveUser = define('users.get', function ({ offlineCache, cacheResult }) {
    cacheResult(async (result) => {
        await storeLocalItem('cache_users', result)
    })
    offlineCache(async function () {
        return await getLocalItem('cache_users', null)
    })
})

async function setUserInfo() {
    try {
        await tokensRefreshed()
        const getUser = await retrieveUser()
        setItemWithStringify('user', get(getUser, 'client.user', {}))
        // dataEvents.emit('local-storage-updated', 'user')
        setItem('activeClient', get(getUser, 'client.user.activeClient', null))
        // dataEvents.emit('local-storage-updated', 'activeClient')
    } catch (err) {
        console.error(`Error requesting user: ${err}`)
        throw err
    }
}

handle('auth.login-success', async () => {
    await raise('refresh-apps', null)
})

export const windowId = generate()

window.signIn = function (user) {
    raise('result-from-login', null)
    raiseLater('sign-in-event', JSON.parse(user))
    raiseLater('refresh-apps', null)
}

handle('sign-in-event', async (user) => {
    await setUserOnStore(user)
    try {
        await process({ type: 'emulate.stop', realId: getActiveUser() })
    } catch (e) {
        console.error('Unable to remove emulation')
        captureException(e)
    }
})

handle('sign-out-event', async () => {
    await signOut()
})

export const setUserOnStore = guard(async function setUserOnStore(user) {
    try {
        if (user && user.id) {
            const userData = {
                id: user.id,
                accessToken: user.access_token,
                sessionToken: user.refresh_token,
                accessTokenExpiry: user.expires_at,
                sessionTokenExpiry: user.refresh_expires_at,
                name: user.name,
                email: user.email,
                isAnonymous: user.isAnonymous || user.email.includes('anonymouslogin'),
                migratedToPortal: user.migratedToPortal,
                portalId: user.portalId,
            }
            setUnprefixedItemWithStringify('currentAuthenticatedUser', userData)
            await startTracking()
            // eslint-disable-next-line no-console
            console.log('get user')
            let profile = await getUser(user.id)
            // eslint-disable-next-line no-console
            console.log('got user')
            store.set({ profile })
            await raiseAsync('refresh-apps')
            await raiseAsync('auth.user-stored', null)
            raise('secure-token-ready', null)
        }
    } catch (err) {
        console.error('setUserError', err)
    }
})

handle('refresh-profile', async () => {
    const authUser = getCurrentAuthenticatedUser()
    if (authUser && authUser.id && store.get().profile) {
        const profile = await getUser(authUser.id)
        if (profile) {
            Object.assign(store.get()?.profile || {}, profile)
        }
    }
})

handle('auth.user-stored', setUserInfo)
