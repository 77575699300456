import events from 'packages/alcumus-local-events'
import { validateSecurity } from 'common/secure/validate'
import { handle, raiseAsync } from 'common/events'
import { parameter } from 'common/query-params'
import { makeReady, notReady, waitForReady } from 'common/route-refresh'

export const applications = {}
export let appVersion = 0

const loaded = []

//Retrieve the apps to run in the framework
export async function getApps() {
    if (parameter('noLoad')) return
    try {
        notReady('all-apps')
        let apps = []
        // eslint-disable-next-line no-console
        console.log('loadApps::get')
        await events.emitAsync('get-applications', apps)
        // eslint-disable-next-line no-console
        console.log('loadApps::got', apps)
        apps = apps.map((app) => {
            if (typeof app === 'string') {
                return { app, demands: [] }
            } else {
                return app
            }
        })

        const toLoad = apps.filter((app) => !loaded.includes(app.app) && validateSecurity(app.demands)).unique('app')
        loaded.push(...toLoad.map('app'))
        // eslint-disable-next-line no-console
        console.log('loadApps::should', toLoad)
        let results = await Promise.all(
            toLoad.map(async ({ app: applicationName, dynamic }) => {
                try {
                    // eslint-disable-next-line no-console
                    console.log('load:start', applicationName)
                    applicationName = applicationName.endsWith('-plugin')
                        ? applicationName.replace(/-plugin/gi, '')
                        : applicationName

                    let module
                    if (!dynamic) {
                        module = await import(
                            /* webpackInclude: /-plugin/ */
                            /* webpackChunkName: "[request]" */
                            `plugins/${applicationName}-plugin/plugin-main.js`
                        )
                    } else {
                        module = await import(
                            /* webpackInclude: /-plugin/ */
                            /* webpackChunkName: "dyn[request]" */
                            `dynamic/${applicationName}-plugin/plugin-main.js`
                        )
                    }
                    let fn = (applications[applicationName] = module.default)
                    if (typeof fn === 'function') {
                        let result = fn()
                        if (result.then) {
                            waitForReady(result)
                        }
                    }
                    // eslint-disable-next-line no-console
                    console.log('load:end', applicationName)
                    // raise('apps-changed', applications)
                } catch (error) {
                    console.error(`Failed to load application ${applicationName} with error ${error}`, error)
                }
            })
        )
        appVersion++
        if (toLoad.length) {
            await raiseAsync('apps-loaded', results)
        }
        return results
    } finally {
        makeReady('all-apps')
    }
}

// eslint-disable-next-line no-console
handle('routes-updated', () => console.log('Routes updated'))
