import React, { useEffect, useState } from 'react'

import PropTypes from 'prop-types'

import events from 'packages/alcumus-local-events'

import uniq from 'lodash/uniq'

import { Box } from '@material-ui/core'
import Chip from '@material-ui/core/Chip'
import MenuItem from '@material-ui/core/MenuItem'

import { isAdmin } from 'common/global-store/api'
import TextField from 'common/TextField'
import { getRegisteredDemands } from './request-selectors'
import { makeCachedStyles } from 'common/inline-styles'

const useStyles = makeCachedStyles((theme) => ({
    chip: {
        margin: theme.spacing(0.5),
        color: 'white !important',
    },
}))

const getRevisedDemandName = (d) => {
    if (d === 'upload-client-documents') return 'client-documents'
    if (d === 'upload-contractor-documents') return 'contractor-documents'
    return d
}

export function Demands({ onChange, value, readonly, label, ...props }) {
    const [demands, setDemands] = useState([])
    const [openMenu, setOpenMenu] = useState(false)
    const classes = useStyles()

    useEffect(() => {
        let availableDemands = events.modify('get-demands', [])
        let registeredDemands = uniq(availableDemands)
        getRegisteredDemands(registeredDemands).then((allowedDemands) => {
            if (!isAdmin()) {
                allowedDemands = allowedDemands.filter((d) => d !== 'manage-clients')
            }
            setDemands(allowedDemands)
        })
    }, [])

    return (
        <TextField
            disabled={readonly}
            onChange={onChange}
            value={value}
            variant="outlined"
            select
            SelectProps={{
                multiple: true,
                open: openMenu,
                onClick: () => setOpenMenu(!openMenu),
                renderValue: (selected) => (
                    <Box display="flex" flexWrap="wrap">
                        {readonly &&
                            selected
                                .sort()
                                .map((value) => (
                                    <Chip
                                        key={value}
                                        label={getRevisedDemandName(value)}
                                        color="primary"
                                        className={classes.chip}
                                    />
                                ))}
                        {!readonly &&
                            selected
                                .sort()
                                .map((value) => (
                                    <Chip
                                        key={value}
                                        label={getRevisedDemandName(value)}
                                        color="primary"
                                        className={classes.chip}
                                        onDelete={removeChip(value)}
                                    />
                                ))}
                    </Box>
                ),
                ...props,
            }}
            fullWidth
            label={label}
        >
            {demands
                .sortBy((v) => getRevisedDemandName(v).toLowerCase())
                .map((demand) => (
                    <MenuItem key={demand} value={demand}>
                        {getRevisedDemandName(demand)}
                    </MenuItem>
                ))}
        </TextField>
    )

    function removeChip(target) {
        return () => {
            value = value.filter((r) => r !== target)
            onChange({ target: { value } })
        }
    }
}

Demands.propTypes = {
    label: PropTypes.string,
    onChange: PropTypes.any,
    readonly: PropTypes.bool,
    value: PropTypes.any,
}
