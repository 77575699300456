import React from 'react'
import { lightenDarkenColor, COLOURS } from 'common/colors'

import { MdClose } from '@react-icons/all-files/md/MdClose'
import { MdSecurity } from '@react-icons/all-files/md/MdSecurity'
import { MdSentimentDissatisfied } from '@react-icons/all-files/md/MdSentimentDissatisfied'
import { MdSentimentNeutral } from '@react-icons/all-files/md/MdSentimentNeutral'
import { MdSentimentSatisfied } from '@react-icons/all-files/md/MdSentimentSatisfied'
import { MdToys } from '@react-icons/all-files/md/MdToys'
import { MdToday } from '@react-icons/all-files/md/MdToday'
import { SupplierIcon } from 'common/icons/SupplierIcon'
import { ContractorIcon } from 'common/icons/ContractorIcon'

export default {
    coref: {
        caption: 'Membership Number',
    },
    suspended: {
        color: COLOURS.RED,
    },
    accredited: {
        icon: <ContractorIcon fill={COLOURS.GREEN} width="1em" height="1em" />,
        sort: '001',
        color: COLOURS.GREEN,
        caption: 'Accredited',
    },
    atrisk: {
        icon: <ContractorIcon fill={COLOURS.RED} width="1em" height="1em" />,
        sort: '005',
        color: COLOURS.RED,
        caption: 'At Risk',
    },
    insurancerisk: {
        icon: <ContractorIcon fill={COLOURS.YELLOW} width="1em" height="1em" />,
        sort: '003',
        color: COLOURS.YELLOW,
        caption: 'At Risk - Insurances',
    },
    renewalexpiredrisk: {
        icon: <ContractorIcon fill={COLOURS.PURPLE} width="1em" height="1em" />,
        sort: '004',
        color: COLOURS.PURPLE,
        caption: 'At Risk - Payment Overdue',
    },
    inprogress: {
        icon: <ContractorIcon fill={COLOURS.ORANGE} width="1em" height="1em" />,
        sort: '002',
        color: COLOURS.ORANGE,
        caption: 'In Progress',
    },
    clientapproved: {
        icon: <ContractorIcon fill={COLOURS.BLUE} width="1em" height="1em" />,
        sort: '006',
        color: COLOURS.BLUE,
        caption: 'Client Approved',
    },
    prospect: {
        icon: <ContractorIcon fill={COLOURS.GREY} width="1em" height="1em" />,
        sort: '008',
        color: COLOURS.GREY,
        caption: 'Not Registered',
    },
    retired: {
        icon: <MdClose />,
        sort: '007',
        color: COLOURS.GREY,
        caption: 'Cancelled',
        map: 'cancelled',
    },
    assets: {
        icon: <MdToys />,
        color: '#009fda',
    },
    gold: {
        icon: <MdSecurity color={COLOURS.GOLD} />,
        sort: '001',
        color: 'gold',
    },
    silver: {
        icon: <MdSecurity color={COLOURS.SILVER} />,
        sort: '002',
        color: 'silver',
    },
    bronze: {
        icon: <MdSecurity color={COLOURS.BRONZE} />,
        sort: '003',
        color: '#cd7f32',
    },
    notverified: {
        icon: <MdSecurity />,
        sort: '004',
        color: '#294A76',
        caption: 'Not Verified',
    },
    expired: {
        icon: <MdToday />,
        sort: '00001',
        color: '#FF0000',
        caption: 'Expired',
    },
    '£50m+': {
        icon: <MdSecurity />,
        sort: '00002',
        color: lightenDarkenColor(COLOURS.GREEN, 20),
    },
    '£25m+': {
        icon: <MdSecurity />,
        sort: '00003',
        color: lightenDarkenColor(COLOURS.GREEN, 20),
    },
    '£10m+': {
        icon: <MdSecurity />,
        sort: '00004',
        color: lightenDarkenColor(COLOURS.GREEN, 20),
    },
    '£5m+': {
        icon: <MdSecurity />,
        sort: '00005',
        color: COLOURS.GREEN,
    },
    '£1m+': {
        icon: <MdSecurity />,
        sort: '00006',
        color: lightenDarkenColor(COLOURS.GREEN, -20),
    },
    insured: {
        icon: <MdSecurity />,
        sort: '00007',
        color: lightenDarkenColor(COLOURS.GREEN, -40),
        caption: 'Insured',
    },
    uninsured: {
        icon: <MdSecurity />,
        sort: '00008',
        color: COLOURS.RED,
        caption: 'Uninsured',
    },
    '[1] micro (0-9)': {
        color: COLOURS.BLUE,
    },
    '[2] small (10-49)': {
        color: COLOURS.PURPLE,
    },
    '[3] medium (50-249)': {
        color: COLOURS.YELLOW,
    },
    '[4] large (250+)': {
        color: COLOURS.ORANGE,
    },
    ragred: {
        icon: <MdSentimentDissatisfied />,
        color: COLOURS.RED,
        theme: true,
        sort: '01',
        caption: 'Red',
    },
    ragamber: {
        icon: <MdSentimentNeutral />,
        color: COLOURS.ORANGE,
        theme: true,
        sort: '02',
        caption: 'Amber',
    },
    raggreen: {
        icon: <MdSentimentSatisfied />,
        color: COLOURS.GREEN,
        theme: true,
        sort: '03',
        caption: 'Green',
    },
    ragnone: {
        icon: <MdSentimentNeutral />,
        color: COLOURS.GREY,
        theme: true,
        sort: '04',
        caption: 'None',
    },
    high: {
        color: COLOURS.RED,
    },
    medium: {
        color: COLOURS.YELLOW,
    },
    low: {
        color: COLOURS.GREEN,
    },
    antibribery: {
        sort: 2,
        caption: 'Anti-Bribery',
    },
    modernslavery: {
        sort: 1,
        caption: 'Modern Slavery',
    },
    gdpr: {
        sort: 3,
        caption: 'GDPR',
    },
    environmental: {
        sort: 4,
        caption: 'Environmental',
    },
    qualitymanagement: {
        sort: 5,
        caption: 'Quality Management',
    },
    antibriberyno: {
        sort: 2,
        caption: 'No Anti-Bribery',
    },
    modernslaveryno: {
        sort: 1,
        caption: 'No Modern Slavery',
    },
    gdprno: {
        sort: 3,
        caption: 'No GDPR',
    },
    environmentalno: {
        sort: 4,
        caption: 'No Environmental',
    },
    qualitymanagementno: {
        sort: 5,
        caption: 'No Quality Management',
    },
    ss_inprogress: {
        icon: <SupplierIcon fill={COLOURS.ORANGE} width="1em" height="1em" />,
        sort: '005',
        color: COLOURS.ORANGE,
        caption: 'In Progress',
    },
    ss_verified: {
        icon: <SupplierIcon fill={COLOURS.GREEN} width="1em" height="1em" />,
        sort: '005',
        color: COLOURS.GREEN,
        caption: 'Verified',
    },
    ss_atrisk: {
        icon: <SupplierIcon fill={COLOURS.RED} width="1em" height="1em" />,
        sort: '005',
        color: COLOURS.RED,
        caption: 'At Risk',
    },
    ss_prospect: {
        icon: <SupplierIcon fill={COLOURS.GREY} width="1em" height="1em" />,
        sort: '005',
        color: COLOURS.GREY,
        caption: 'Not Registered',
    },
    ss_insurancerisk: {
        icon: <SupplierIcon fill={COLOURS.YELLOW} width="1em" height="1em" />,
        sort: '005',
        color: COLOURS.YELLOW,
        caption: 'At Risk - Insurances',
    },
    ss_renewalexpiredrisk: {
        icon: <SupplierIcon fill={COLOURS.PURPLE} width="1em" height="1em" />,
        sort: '005',
        color: COLOURS.PURPLE,
        caption: 'At Risk - Payment Overdue',
    },
    ss_clientapproved: {
        icon: <SupplierIcon fill={COLOURS.BLUE} width="1em" height="1em" />,
        sort: '005',
        color: COLOURS.BLUE,
        caption: 'Client Approved',
    },
    supplierServices: {
        icon: <MdSentimentDissatisfied />,
        color: COLOURS.RED,
        theme: true,
        sort: '01',
        caption: 'Red',
    },
    'consultancy and professional': {
        caption: 'Consultancy and Professional',
        color: lightenDarkenColor(COLOURS.GREEN, 20),
    },
    'goods and materials': {
        caption: 'Goods and Materials',
        color: lightenDarkenColor(COLOURS.GREEN, 10),
    },
    'health and social': {
        caption: 'Health and Social',
        color: lightenDarkenColor(COLOURS.GREEN, 0),
    },
    'service provider': {
        caption: 'Service Provider',
        color: lightenDarkenColor(COLOURS.GREEN, -10),
    },
    'travel and accommodation': {
        caption: 'Travel and Accommodation',
        color: lightenDarkenColor(COLOURS.GREEN, -20),
    },
}
