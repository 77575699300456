import { list } from 'common/offline-data-service/functions/list'

export async function groupBy(database, table, where, column) {
    const groups = new Map()
    const rows = (await list(database, table, where)).map('data')
    for (let row in rows) {
        const value = Object.get(row, column, true)
        groups.set(value, (groups.get(value) || 0) + 1)
    }
    return groups
}
