/* eslint-disable no-console */
import { ready as requestReady } from 'common/request'
import * as JsStore from 'jsstore'
import constants from 'common/offline-data-service/constants.json'
import path from 'file-loader?name=scripts/[name].[hash].js!jsstore/dist/jsstore.worker.js'
import { handle, raise } from 'common/events'
import { defer } from 'common/deferred'

let _connection

function getInstance() {
    return _connection
}

export async function select(query) {
    return await getInstance().select(query)
}

export async function insert(query) {
    return await getInstance().insert(query)
}

export async function remove(query) {
    return await getInstance().remove(query)
}

export async function clean() {
    await webDatabaseInit().then(async (db) => {
        await db.clear('records')
    })
}

export async function dropDb() {
    raise('clear-db-cache')
    return await getInstance().clear('records')
}

handle('clear-local-db', async () => {
    await _connection.clear('records')
    await initializeOfflineStorage()
    raise('clear-db-cache')
})

let webDbInitialized = defer()
let initialized = false

async function webDatabaseInit() {
    if (initialized) {
        return webDbInitialized
    }
    initialized = true
    // eslint-disable-next-line no-console
    try {
        console.log('Start Database')
        await requestReady
        const worker = new Worker(path)
        _connection = new JsStore.Connection(worker)
        const id = setTimeout(() => {
            console.error('Database not started')
        }, 10000)
        // eslint-disable-next-line no-console
        console.log('Started Database')
        let wasDatabaseCreated = await _connection.initDb(constants.INITIAL_DB_STATE)
        // eslint-disable-next-line no-console
        console.log('Initialized Database', wasDatabaseCreated)
        await new Promise((resolve) => setTimeout(resolve, 300))
        await _connection.openDb(constants.INITIAL_DB_STATE.name)
        // eslint-disable-next-line no-console
        console.log('Database ready', constants.INITIAL_DB_STATE.name)
        clearTimeout(id)
        return _connection
    } catch (e) {
        console.error(e)
    } finally {
        webDbInitialized.resolve(_connection)
    }
}

export async function initializeOfflineStorage() {
    for (let i = 0; i < 10; i++) {
        try {
            await webDatabaseInit()
            return
        } catch (e) {
            console.error(e)
        }
    }
}

export function getJSStore() {
    if (_connection) {
        return _connection
    } else {
        return undefined
    }
}
