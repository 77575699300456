/**
 * @module dynamic/awe-library/runtime/create-document
 */
import { createDocument } from 'common/create-document'
import { prepareDocument } from 'common/offline-data-service'
import { showNotification } from 'common/modal'
import { getType } from 'dynamic/awe-library/runtime/records'
import { getUserContext } from 'dynamic/awe-library/runtime/user-context'
import { raiseAsync } from 'common/events'
import { generate } from 'packages/identifiers'
// import { documentChangesStore } from 'dynamic/awe-library/runtime/lib/simple-db'
import { commitDocument } from 'dynamic/awe-library/lib/api'
import { getCurrentVersionId, getVersion, getVersionId } from 'dynamic/awe-library/versions'
import { clone } from 'common/utils/deep-copy'
import { set } from 'common/offline-data-service/functions/set'
import { get } from 'common/offline-data-service/functions/get'
import { cacheLocalOnly, checkClient } from 'common/offline-data-service/utils'

/**
 * Creates an object provided with a document type
 * @param {Document} [parent=null] - the parent of the document being created
 * @param {string} typeId - the id of the document type to create
 * @param {object} [context={}] - default context (used by context fields) for the document being created
 * @param {boolean} [fromRaw=false] - if true use the latest version of the document, not the active published version
 * @param {string} [id] - if supplied use the id for the document, otherwise generate one
 * @param {object} [props={}] - any props to immediately set on the document
 * @param {boolean} [alwaysCreate=false] - documents are often not really created before the first submission, setting
 *     true here makes the document create immediately
 * @returns {Promise<Document>} a promise for the created document
 */
export async function createDocumentOfType(parent, typeId, context = {}, fromRaw, id, props = {}, alwaysCreate) {
    try {
        let versionId
        const type = await getType(typeId)
        if (!type) {
            console.info('Could not retrieve type', typeId)
            return null
        }
        let createdVersion
        let version
        if (fromRaw || !type.currentVersion) {
            delete type.versions
            delete type.currentVersion
            version = type
            createdVersion = `raw-${Date.now()}`
        } else {
            versionId = getCurrentVersionId(type)
            version = await getVersion(type, versionId)
            createdVersion = versionId
        }
        const newDocument = await createDocument(version, undefined, id)
        newDocument._version = createdVersion
        newDocument.__version = getVersionId(type, createdVersion)
        newDocument._parent = parent?._id
        newDocument._created = newDocument._modified = Date.now()
        Object.assign(newDocument, props)
        if (type.defaultState) {
            newDocument.behaviours.state = type.defaultState
        }
        context.$currentUser = await getUserContext()
        await raiseAsync('prepare-document-context', newDocument)
        await newDocument.sendMessage('setContext', context)
        if (context.initialize) {
            context.initialize(newDocument)
        }
        if (alwaysCreate || type.createOnNew) {
            newDocument.$created = true
            const __toSend = {
                id: newDocument._id,
                $trackId: generate(),
                instance: {},
                command: 'setData',
                controller: { notSet: true },
                toState: newDocument._behaviours._state,
            }

            await set(prepareDocument(newDocument), true)
            await commitDocument(newDocument._id)
            // await documentChangesStore(toSend, newDocument._id)
        } else {
            cacheLocalOnly(newDocument)
        }
        return newDocument
    } catch (e) {
        console.error(e)
        showNotification('Error: cannot create document')
    }
}

/**
 * Ensure that a document has been created with an id and a given
 * type.  This is predominantly used for applications whose
 * id is the user id (they have their own unique copy of the data)
 * @param {string} id - the id that must exist
 * @param {DocumentDefinition} type - the type that the id is to be created for
 * @returns {Promise<void>} a promise that is resolved when the id is known to exist
 */
export async function ensure(id, type) {
    let current = await get(id)
    if (!current) {
        // eslint-disable-next-line no-console
        console.log('cdot', type._id)
        await createDocumentOfType(null, type._id, {}, false, id, {}, true)
    } else if (checkClient(current)) {
        try {
            let versionId = getCurrentVersionId(type)
            if (versionId && versionId !== current._version) {
                // eslint-disable-next-line no-console
                console.log('upgrade', type._id)
                let version = await getVersion(type, versionId)
                current._behaviours = version._behaviours
                delete version._behaviours
                Object.assign(current._settings, version)
                current._version = versionId
                current.__version = getVersionId(type, versionId)
                await set(current, true, false)
            } else if (!versionId) {
                // eslint-disable-next-line no-console
                console.log('upgrade', type._id)
                let version = clone(type)
                current._behaviours = version._behaviours
                delete version._behaviours
                Object.assign(current._settings, version)
                current._version = versionId
                current.__version = getVersionId(type, versionId)
                await set(current, true, false)
            }
        } catch (e) {
            //Can't upgrade for some reason
            console.error("Can't upgrade" + e.message)
        }
    }
}
