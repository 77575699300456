import Events from 'packages/alcumus-events'
import debounce from 'lodash/debounce'
import isFunction from 'lodash/isFunction'
import isObject from 'lodash/isObject'
import { useEffect, useState } from 'react'

const state = Symbol('state')
const refresh = Symbol('refresh')
const list = Symbol('list')
const must = Symbol('must')
const configure = Symbol('configure')
const resolve = Symbol('resolve')
const watch = Symbol('watch')
const execute = Symbol('execute')
const clean = Symbol('clean')
const recursive = Symbol('recursive')
const idStore = Symbol('id')
const isRaw = Symbol('raw')
const isTracked = Symbol('track')
export const readOnly = Symbol('readOnly')

function clone(o, id) {
    if (!isObject(o)) return o
    id = id || Date.now() + Math.random()
    var newO, i
    if (typeof o !== 'object') {
        return o
    }
    if (!o) {
        return o
    }
    if (o[idStore] === id) {
        return o
    }
    o[idStore] = id
    if (Array.isArray(o)) {
        newO = []
        for (i = 0; i < o.length; i += 1) {
            newO[i] = clone(o[i])
        }
        return newO
    }

    newO = {}
    for (i in o) {
        newO[i] = clone(o[i])
    }
    if (o[isTracked]) newO[isTracked] = true
    if (o[isRaw]) newO[isRaw] = true
    return newO
}

let refreshId = 1

const ARRAY_MUTATORS = ['copyWithin', 'fill', 'pop', 'push', 'reverse', 'shift', 'sort', 'splice', 'unshift']

function getArgs(func) {
    // First match everything inside the function argument parens.
    const args = func.toString().match(/(?:function\s.*)?\(([^)]*)\)/)[1]
    // Split the arguments string into an array comma delimited.
    return args
        .split(',')
        .map(function (arg) {
            // Ensure no inline comments are parsed and trim the whitespace.
            return arg.replace(/\/\*.*\*\//, '').trim()
        })
        .filter(function (arg) {
            // Ensure no undefined values are added.
            return arg
        })
}

function noop() {}

const DUMMY_ARRAY = Object.freeze([])

export class ObservableStore extends Events {
    constructor(initial = {}, { recursiveObjects = true, readOnly = false } = {}) {
        super({ wildcard: true, maxListeners: 0 })
        this[recursive] = recursiveObjects
        this[list] = new Set()
        this[must] = new Set()
        this[watch] = {}
        this[state] = {}
        this[refresh] = debounce(this[refresh].bind(this))
        this.set(clone(initial))
        this[refresh].flush()
        this[readOnly] = readOnly
    }

    [refresh]() {
        const toUpdate = new Set()
        this[list].forEach((item) => {
            ;(this[watch][item] || DUMMY_ARRAY).forEach((watched) => {
                toUpdate.add(watched)
            })
        })
        toUpdate.forEach((update) => {
            Promise.resolve(this[update][execute]()).then(() => {}, console.error)
        })
        this[list].forEach((item) => {
            const value = this[state][item]
            this[item][resolve].forEach(({ resolve }) => {
                resolve(value)
            })
            this.emit(`changed.${item}`, value)
        })
        this[list].clear()
    }

    [configure](key) {
        if (this[key]) return
        let self = this

        let definition = Object.defineProperties(
            {
                [clean]: noop,
                [resolve]: [],
                on: (handler) => {
                    self.on(`changed.${key}`, handler)
                    return () => {
                        self.off(`changed.${key}`, handler)
                    }
                },
                off: (handler) => {
                    self.off(`changed.${key}`, handler)
                },
                useValue: () => {
                    try {
                        const [, forceRefresh] = useState(0)
                        let [result, setResult] = useState(self[state][key])

                        useEffect(() => {
                            function updateValues() {
                                let newValue = self[state][key]
                                if (window.__debugStore) {
                                    // eslint-disable-next-line no-console
                                    console.log(key, result, newValue)
                                }
                                setResult(newValue)
                                result = newValue
                                forceRefresh(refreshId++)
                                self[must].delete(key)
                            }

                            const update = () => {
                                updateValues()
                            }
                            definition.on(update)
                            return () => {
                                definition.off(update)
                            }
                        }, [])
                        return result
                    } catch (e) {
                        console.error(
                            `OBSERVABLE-STORE invocation error: ${key} - called using prefix notation outside of react component. Please replace with suffix notation ${key}$ instead of $${key}.`,
                            e
                        )
                        return self[state][key]
                    }
                },
                useChange: (fn) => {
                    let db = debounce(fn)
                    useEffect(() => {
                        db()
                        definition.on(db)
                        return () => {
                            definition.off(db)
                            db.cancel()
                        }
                    }, [])
                },
                toString() {
                    return isObject(self[state][key]) ? self[state][key].toString() : self[state][key]
                },
                valueOf() {
                    return isObject(self[state][key]) ? self[state][key].valueOf() : self[state][key]
                },
            },
            {
                value: {
                    get: () => {
                        return self[state][key]
                    },
                    set: async (value) => {
                        if (this[readOnly]) {
                            throw new Error('Is read only')
                        }
                        if (value !== null && isObject(value) && value.then) value = await value
                        definition[clean]()
                        if (
                            value !== null &&
                            this[recursive] &&
                            !isFunction(value) &&
                            isObject(value) &&
                            !Array.isArray(value) &&
                            value[isTracked] &&
                            !value[isRaw]
                        ) {
                            value = new ObservableStore(value)
                        } else if (value !== null && isFunction(value)) {
                            let fn = value
                            let args = getArgs(fn)
                            args.forEach((arg) => (self[watch][arg] = self[watch][arg] || []).push(key))
                            definition[clean] = function () {
                                args.forEach(
                                    (arg) => (self[watch][arg] = self[watch][arg].filter((name) => name !== key))
                                )
                            }
                            definition[execute] = async () => {
                                let params = args.map((arg) => self[state][arg])
                                try {
                                    let result = fn.apply(self, params)
                                    self[state][key] = result.then ? await result : result
                                    self[list].add(key)
                                    self[refresh]()
                                } catch (e) {
                                    self[state][key] = '<error>'
                                    self[list].add(key)
                                    self[refresh]()
                                }
                            }
                            definition[execute]()
                            return
                        }

                        // if (value !== null && isEqual(self[state][key], value)) return

                        if (Array.isArray(value) && value[isTracked]) {
                            value = value.map((v) =>
                                isObject(v) && !isFunction(v) && !v[isRaw] && v[isTracked] ? new ObservableStore(v) : v
                            )
                            ARRAY_MUTATORS.forEach((mutator) => {
                                let previous = value[mutator].bind(value)
                                value[mutator] = (...params) => {
                                    let result = previous(...params)
                                    value.forEach((item, index) => {
                                        if (
                                            isObject(item) &&
                                            !(item instanceof ObservableStore) &&
                                            !item[isRaw] &&
                                            item[isTracked]
                                        ) {
                                            value[index] = new ObservableStore(item)
                                        }
                                    })
                                    self[list].add(key)
                                    self[must].add(key)
                                    self[refresh]()
                                    return result
                                }
                            })
                        }
                        if (isObject(value) && value !== null) {
                            delete value[isRaw]
                            delete value[isTracked]
                        }
                        if (value !== self[state][key]) {
                            self[state][key] = value
                            self[list].add(key)
                            self[refresh]()
                        }
                    },
                },
                changed: {
                    get: () =>
                        new Promise(function (resolver, reject) {
                            definition[resolve].push({ resolve: resolver, reject })
                        }),
                },
            }
        )
        Object.defineProperty(self, key + '$', {
            get: () => definition.value,
            set: (value) => {
                if (this[readOnly]) throw new Error('Read only')
                definition.value = value
            },
        })
        Object.defineProperty(self, '$' + key, {
            get: () => definition.useValue(),
            set: (value) => {
                if (this[readOnly]) throw new Error('Read only')
                definition.value = value
            },
        })
        Object.defineProperty(self, key, {
            get() {
                return definition
            },
            set(value) {
                if (this[readOnly]) throw new Error('Read only')
                definition.value = value
            },
        })
        return definition
    }

    get(key) {
        if (!key) {
            return this[state]
        }
        if (!this[state][key]) {
            this.set({ [key]: undefined })
        }
        return this[key]
    }

    flush() {
        // ReactDOM.flushSync(() => {})
        this[refresh].flush()
    }

    set(update) {
        if (this[readOnly]) throw new Error('Read only')
        Object.keys(update).forEach((key) => {
            key = key.replace(/\$/, '')
            this[configure](key)
            this[key] = update[key]
        })
    }
}

export function raw(object) {
    object[isRaw] = true
    return object
}

export function tracked(object) {
    object[isTracked] = true
    return object
}

export default ObservableStore
