import React from 'react'

import PropTypes from 'prop-types'

import events from 'packages/alcumus-local-events'

import classNames from 'classnames'
import { FaRunning } from '@react-icons/all-files/fa/FaRunning'

import { Avatar, Tooltip } from '@material-ui/core'
import { Box } from 'common/styled-box'
import Fade from '@material-ui/core/Fade'
import List from '@material-ui/core/List'

import { raise } from 'common/events'
import { ListItemBox } from 'common/ListItemBox'
import { running } from 'common/process/index'
import { useRefreshWhen } from 'common/useRefresh'
import { resolveValue } from '../resolve-value'
import { makeCachedStyles } from 'common/inline-styles'

events.on('get-menu-items', function (event, items) {
    if (window.DEBUG_JOBS) {
        items['runningProcesses'] = {
            priority: 9000,
            title: 'Running jobs',
            id: 'runningJobs',
            aboveTheLine: true,
            bottom: true,
            icon: <FaRunning />,
            isActive() {
                return false
            },
            // eslint-disable-next-line react/prop-types
            Component: Running,
        }
    }
})

window.DEBUG_JOBS = process.env.REACT_APP_DEBUG_JOBS === 'on' || location.search.includes('debug_jobs')

function Running({ item, classes, selected }) {
    useRefreshWhen('update-jobs')
    const entries = Object.entries(running || {}).filter((job) => window.DEBUG_JOBS || job.important)

    const all = Object.entries(running || {})
    return (
        <Fade
            style={{ transitionDelay: all.length > 30 || entries.length !== 0 ? '0.5s' : 0 }}
            timeout={1000}
            in={entries.length !== 0 || all.length > 30}
        >
            <Tooltip title={<CurrentJobs entries={all.length > 30 ? all : entries} />} arrow placement="right">
                <Avatar
                    data-testid={`menu-belowBottom-item-${item.id || item.title}`}
                    aria-label={item.title}
                    className={classNames(classes.menuCircle, classes.belowItem, {
                        selected: item === selected,
                    })}
                >
                    {item.icon}
                </Avatar>
            </Tooltip>
        </Fade>
    )
}

const useStyles = makeCachedStyles(() => ({
    finished: {
        color: 'lightgreen',
    },
}))

let id = 0

function CurrentJobs({ entries = [] }) {
    useRefreshWhen('update-jobs')
    const classes = useStyles()
    if (entries.length === 0) return 'No running jobs'
    if (!id)
        id = setTimeout(() => {
            id = 0
            raise('update-jobs', running)
        }, 1000)
    return (
        <List>
            {entries.map(([key, item]) => {
                let seconds = new Date(item.start).secondsUntil(item.finished)
                return (
                    <ListItemBox key={key}>
                        <Box mr={3}>{resolveValue(item.name, item) || item.type.replace(/\./gi, ' ').titleize()}</Box>
                        <Box flexGrow={1} />
                        <Box mr={1} color="#ffffff50">
                            {item.id}
                        </Box>
                        {!item.finished && <Box>{new Date(item.start).relative()}</Box>}
                        {!!item.finished && !!seconds && <Box className={classes.finished}>Done in {seconds} sec</Box>}
                        {!!item.finished && !seconds && <Box className={classes.finished}>Done</Box>}
                    </ListItemBox>
                )
            })}
        </List>
    )
}

Running.propTypes = {
    classes: PropTypes.any,
    hide: PropTypes.any,
    item: PropTypes.any,
    selected: PropTypes.any,
}
