//import SSE handler
import { startTrackingSSE } from 'common/remote-messages'

//import SSE handler
import { startTrackingPolling } from 'common/remote-polling'
import { define } from 'common/process'
import { getToken } from 'common/request'
import { dontWaitForAsyncHandlerAndDefer, handle } from 'common/events'
import { parameter } from 'common/query-params'
import { once } from 'common/events'

export const ping = define('ping', () => {})
let currentToken = 'unlikely'

let promiseOfTracking = Promise.resolve(false)
let stopTracking = () => {}

window.useLongPolling = parameter('long_polling')

handle('auth.login-success', dontWaitForAsyncHandlerAndDefer(startTracking))

window.addEventListener('online', () => {
    promiseOfTracking = Promise.resolve(false)
    currentToken = 'unlikely'
    startTracking().catch(console.error)
})

window.addEventListener('offline', () => {
    try {
        stopTracking()
        currentToken = 'unlikely'
        promiseOfTracking = Promise.resolve(false)
    } catch (e) {
        console.error(e)
    }
})

let currentPromise = Promise.resolve(true)
let sseTimeout = 0
let sseRunning
handle('sse-init', () => (sseRunning = true))

export async function startTracking() {
    await new Promise((resolve) => setTimeout(resolve, 100))
    if (getToken() === currentToken) return currentPromise
    clearTimeout(sseTimeout)
    await currentPromise
    // eslint-disable-next-line no-async-promise-executor
    return await (currentPromise = new Promise(async (resolve) => {
        currentToken = getToken()
        // await promiseOfTracking
        let abort = { stop: false }
        let oldStop = stopTracking
        if (!window.useLongPolling) {
            //Initialise SSE
            sseTimeout = setTimeout(async () => {
                abort.stop = true
                window.useLongPolling = true
                if (location.href.includes('?')) {
                    location.href = `${location.href}&long_polling=true`
                } else {
                    location.href = `${location.href}?long_polling=true`
                }
            }, process.env.SSE_TIMEOUT || 12000)

            promiseOfTracking = startTrackingSSE()
            let stop = await promiseOfTracking
            //Initialise SSE timeout

            setTimeout(oldStop, 500)
            stopTracking = async () => {
                stop()
            }

            if (!abort.stop) {
                //Waiting for SSE ping
                await Promise.race([
                    ping(),
                    new Promise((resolve) => {
                        if (sseRunning) {
                            resolve()
                        } else {
                            once('sse-init', resolve)
                        }
                    }),
                ])
                clearTimeout(sseTimeout)
                //Received SSE ping
                if (window.useLongPolling !== true) {
                    console.info('SSE Started')
                }
            } else {
                // eslint-disable-next-line no-console
                console.log('Abort tracking with', currentToken)
                clearTimeout(sseTimeout)
            }
        } else {
            window.useLongPolling = true
            promiseOfTracking = startTrackingPolling()
            let stop = await promiseOfTracking
            stopTracking = async () => {
                stop()
            }
            console.info('LongPolling Started ')
        }
        resolve()
    }))
}
