import events from 'packages/alcumus-local-events'

const envApps = JSON.parse(process.env.REACT_APP_DEFAULT_APPS || '[]')
const skipApps = JSON.parse(process.env.REACT_APP_SKIP_APPS || '[]')
const APP_CANDIDATES = [
    'launcher',
    { app: 'client', demands: ['authenticated'] },
    { app: 'debug', demands: ['admin-debug'] },
    { app: 'client-approved-contractors', demands: ['client-portal'] },
    { app: 'client-portal', demands: ['client-portal'] },
    {
        app: 'security-management',
        demands: 'manage-security OR manage-invitations',
    },
    {
        app: 'system-management',
        demands: 'manage-system',
    },
]

envApps.forEach((app) => {
    const appType = Object.isObject(app)
    const appName = appType ? app.app : app

    const existing = APP_CANDIDATES.find((item) => {
        if (typeof item === 'string') {
            return item === appName
        } else {
            return item.app === appName
        }
    })

    if (existing) {
        return
    }

    if (appType) {
        APP_CANDIDATES.unshift({
            dynamic: true,
            ...app,
        })
    } else {
        APP_CANDIDATES.unshift({
            app,
            dynamic: true,
        })
    }
})

// configure apps to ignore from loading the for mobile app builds
const isMobileApp = !!process.env.REACT_APP_PLATFORM
const ignoreApps = !isMobileApp
    ? []
    : [
          'client-approved-contractors',
          'client-portal',
          'client',
          'security-management',
          'system-management',
          'release-notes',
      ]
const excludeApps = [...new Set(skipApps.concat(ignoreApps))] // discard duplicates
const FINAL_APPS = APP_CANDIDATES.filter((f) => !excludeApps.includes(f) && !excludeApps.includes(f.app))
events.on('get-applications', function (event, apps) {
    apps.push.apply(apps, FINAL_APPS)
})
