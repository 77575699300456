import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { Box, TextField } from '@material-ui/core'

import { process as _process } from 'common/process'
import { StandardDialog } from 'common/Dialog'
import { showNotification } from 'common/modal'
import { setFromEvent } from 'common/set-from-event'
import AsyncSelect from 'react-select/async'

import {
    canLinkToClient,
    createClient,
    createClientWithCustomId,
    createClientWithoutId,
    migrateClient,
    updateClient,
} from '../request-selectors'
import makeStyles from '@material-ui/core/styles/makeStyles'
import { Case, Switch } from 'common/switch'
import Button from '@material-ui/core/Button'

export function CreateClient({ ok, cancel }) {
    const [id, setId] = useState('')
    const [error, setError] = useState(false)
    const setIdLocal = (id) => {
        setError(null)
        setId(id)
    }

    async function createClientNow() {
        const clientExistsResult = await canLinkToClient(id)

        if (clientExistsResult?.client?.exists) {
            const createClientResult = await createClient(id, name)

            if (createClientResult?.client?.error) {
                showNotification(`Error: ${createClientResult?.client?.error}`)
            }

            ok()
        } else {
            setError(true)
        }
    }

    return (
        <StandardDialog
            onOk={createClientNow}
            onCancel={cancel}
            canAccept={id.length >= 1 && !error}
            title="Create A New Client Record"
        >
            <Box mb={2}>
                <TextField
                    data-testid="clients-add-client-identifier"
                    fullWidth
                    variant="outlined"
                    label="Identifier"
                    value={id}
                    onChange={setFromEvent(setIdLocal)}
                    helperText={error ? `A client with this ID doesn't exist` : ''}
                    error={error}
                />
            </Box>
        </StandardDialog>
    )
}

CreateClient.propTypes = {
    cancel: PropTypes.any,
    ok: PropTypes.any,
}

const useStyle = makeStyles({
    linkStyle: {
        color: '#03a9f4',
        textDecoration: 'none',
        '&:hover': {
            cursor: 'pointer',
        },
    },
    selectStyle: {
        '& div[class*="-menu"]': {
            zIndex: 100000,
        },
    },
})

export function CreateClientWithoutId({ ok, cancel }) {
    const [name, setName] = useState('')
    const [error, setError] = useState('')
    const [customClientId, setCustomClientId] = useState('')
    const [customId, setCustomId] = useState(false)
    const { linkStyle } = useStyle()

    React.useEffect(() => {
        if (error !== '') {
            setError('')
        }
    }, [customClientId])

    async function createClientNow() {
        if (customId) {
            const { client } = await createClientWithCustomId(name, customClientId)
            if (client?.success) {
                ok()
                showNotification(`${client.message}`)
            } else {
                setError(`Error: ${client.error}`)
            }
        } else {
            await createClientWithoutId(name)
            ok()
        }
    }

    const enableCustomId = () => {
        setCustomId(true)
    }

    const nameConstraint = name.length > 3
    const customIdConstraint = customClientId.length >= 6
    const canAccept = !customId ? nameConstraint : nameConstraint && customIdConstraint

    return (
        <StandardDialog
            onOk={createClientNow}
            onCancel={cancel}
            canAccept={canAccept} // && !error
            title="Create A New Client Record"
        >
            <Box mb={2}>
                <TextField
                    data-testid="clients-without-id-add-client-name"
                    fullWidth
                    variant="outlined"
                    label="Name"
                    value={name}
                    onChange={setFromEvent(setName)}
                />
            </Box>
            <Switch value={customId}>
                <Case when={true}>
                    <Box display="flex" mt={2} mb={2} width="100%">
                        <TextField
                            data-testid="clients-without-id-add-client-name-custom-id"
                            fullWidth
                            variant="outlined"
                            label="Custom ID (must be 6 characters or greater)"
                            value={customClientId}
                            onChange={setFromEvent(setCustomClientId)}
                            error={error !== ''}
                            helperText={error !== '' ? error : ' '}
                        />
                    </Box>
                </Case>
                <Case when={false}>
                    <Box display="flex" mt={2} mb={2} width="100%" alignItems="flex-end" justifyContent="flex-end">
                        <small>
                            <span
                                className={linkStyle}
                                data-testid="use-custom-client-id"
                                color="primary"
                                onClick={enableCustomId}
                            >
                                Custom ID?
                            </span>
                        </small>
                    </Box>
                </Case>
            </Switch>
        </StandardDialog>
    )
}

CreateClientWithoutId.propTypes = {
    cancel: PropTypes.any,
    ok: PropTypes.any,
}

export function UpdateClient({ ok, cancel, _id, name, ssClientId }) {
    const [newName, setNewName] = useState(name)
    const [safeSupplierClientId, setSafeSupplierClientId] = useState(ssClientId)
    const [deLink, setDeLink] = useState(false)
    const [SSClientName, setSSClientName] = useState('')

    useEffect(() => {
        if (safeSupplierClientId && safeSupplierClientId.length > 0) {
            ssClientsList(safeSupplierClientId).then((results) => {
                const supplierClientName =
                    results?.client?.result.find((c) => c.id === safeSupplierClientId)?.name || ''
                setSSClientName(supplierClientName)
            })
        } else {
            setSSClientName('')
        }
    }, [safeSupplierClientId])

    async function updateClientNow() {
        const updateClientResult = await updateClient(_id, newName, safeSupplierClientId)

        if (updateClientResult?.client?.error) {
            showNotification(`Error: ${updateClientResult?.client?.error}`)
        } else {
            ok({ newName, safeSupplierClientId })
        }
    }

    const deLinkSSClient = () => {
        setDeLink(!deLink)
        setSafeSupplierClientId('')
    }

    return (
        <StandardDialog
            onOk={updateClientNow}
            onCancel={cancel}
            canAccept={newName.length > 3 && _id.length > 1}
            title="Update Client Record"
        >
            <Box mb={2}>
                <TextField
                    data-testid="clients-update-client-identifier"
                    fullWidth
                    variant="outlined"
                    label="Identifier (Read Only)"
                    value={_id}
                    disabled
                    readOnly
                />
            </Box>
            <Box mb={2}>
                <TextField
                    data-testid="clients-update-client-name"
                    fullWidth
                    variant="outlined"
                    label="Name"
                    value={newName}
                    onChange={setFromEvent(setNewName)}
                />
            </Box>

            <Box mb={2}>
                <span data-testid="use-custom-client-id" color="primary">
                    Safe Supplier Client ID
                </span>

                <Box display="flex" alignItems="center" justifyContent="space-between">
                    <Box m2={2} width="78%">
                        <SSClientSelector onOptionSelected={setSafeSupplierClientId} deLink={deLink} />
                        <TextField
                            data-testid="clients-add-ss-client-id"
                            fullWidth
                            value={SSClientName + ' ' + safeSupplierClientId}
                            disabled
                            style={{
                                color: 'red',
                            }}
                        />
                    </Box>
                    <Box>
                        <Button
                            disabled={!safeSupplierClientId || safeSupplierClientId.length === 0}
                            data-testid="clients-de-link-ss-client-id"
                            color="primary"
                            onClick={deLinkSSClient}
                        >
                            De-link
                        </Button>
                    </Box>
                </Box>
            </Box>
        </StandardDialog>
    )
}

UpdateClient.propTypes = {
    cancel: PropTypes.any,
    ok: PropTypes.any,
    _id: PropTypes.string,
    name: PropTypes.string,
    safeContractorClientId: PropTypes.string,
}

export function MigrateClient({ ok, cancel, _id }) {
    async function migrateClientNow() {
        await migrateClient(_id)
        ok()
    }

    return (
        <StandardDialog
            onOk={migrateClientNow}
            onCancel={cancel}
            title="Are you sure you want to migrate this client?"
        />
    )
}

MigrateClient.propTypes = {
    cancel: PropTypes.any,
    ok: PropTypes.any,
    _id: PropTypes.string,
}

const fetchOptionsFromServer = async (searchValue) => {
    let results = await ssClientsList(searchValue)

    return results?.client?.result.map((c) => ({ value: c.id, label: `${c.name} - ${c.id_crm_account}` })) || []
}

const ssClientsList = async (searchValue) => {
    return await _process({
        type: 'ssclients.list',
        options: { searchValue: searchValue?.toLowerCase() },
    })
}

export function SSClientSelector({ onOptionSelected, deLink }) {
    const { selectStyle } = useStyle()

    return (
        <AsyncSelect
            key={deLink}
            cacheOptions
            defaultOptions
            className={selectStyle}
            placeholder="Type in CRM ID or Client Name"
            isMulti={false}
            loadOptions={fetchOptionsFromServer}
            onChange={(ev) => {
                onOptionSelected(ev.value)
            }}
            menuPlacement="auto"
            maxMenuHeight={117}
            styles={{
                options: {
                    textTransform: 'capitalize',
                },
            }}
        />
    )
}

SSClientSelector.propTypes = {
    onOptionSelected: PropTypes.func.isRequired,
    optionValue: PropTypes.bool,
}
