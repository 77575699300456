/**
 * @module common/menu
 */
import events from 'packages/alcumus-local-events'
import { raw } from 'packages/observable-store'

import debounce from 'lodash/debounce'
import map from 'lodash/map'
import merge from 'lodash/mergeWith'

import { inPriorityOrder } from 'packages/al-react-ioc-widgets'

import store from 'common/menu/store'
import { useStableLocalEvent } from 'common/use-event'
import { useEffect, useRef } from 'react'
import { handle, raiseLater } from 'common/events'

export const menu = store.menu.value

export function setMenuItems(menuItems) {
    menu.$items = raw(
        map(menuItems, (item, key) => {
            item.id = item.id || key
            return raw(item)
        }).sort(inPriorityOrder)
    )
}

export const refresh = debounce(
    async function refresh() {
        let menuItems = {}
        await events.emitAsync('get-menu-items', menuItems)
        setMenuItems(menuItems)
    },
    1,
    { maxWait: 5 }
)

let lastUrl = ''

const refreshIfNecessary = debounce(function refreshIfNecessary() {
    if (window.location.href !== lastUrl) {
        lastUrl = window.location.href
        refresh()
    }
})

events.on('app.navigate', refresh)
events.on('auth.user-stored', refresh)
events.on('sign-in-event', refresh)
events.on('sign-out-event', refresh)

events.on('check-url-redirect', refreshIfNecessary)

setTimeout(() => {
    refresh()
}, 10)

export function closeMenu() {
    menu.$selected = null
    menu.$showMain = false
}

function mergeArrayCustomiser(objValue, srcValue) {
    if (Array.isArray(objValue)) {
        return objValue.concat(srcValue)
    }
}

/**
 * @callback IsActiveFunction
 * @global
 * @description a function that indicates if a menu should be shown
 * as active
 * @param {string} location - the current route
 * @returns {boolean} true if the menu should be active
 */

/**
 * @callback SecurityFunction
 * @global
 * @description a function that returns if the current user has permission for an action.
 *
 * Frequently one uses the <code>secureRoute('demand', ...)</code> to
 * create a function to supply this need.
 */

/**
 * @callback MenuClickFunction
 * @global
 * @description A function called when a menu item is clicked
 * @param {function():void} hide - a function to call to hide the menu
 */

/**
 * @interface MenuItem
 * @global
 * @description A menu item entry
 * @property {string} id - the id of the menu item
 * @property {string} title - the title of the item, on the main menu this is a tooltip
 * @property {React.Component} icon - the icon to use for the menu item
 * @property {string|IsActiveFunction} [isActive] - either a path that must be included in the route to indicate
 *     activity or a function to return if the menu item is active
 * @property {SecurityFunction} [secure] - a function that returns whether the current user has permission to access
 *     the path
 * @property {MenuClickFunction} [click] - a function to be run when clicking the item
 * @property {Array<MenuItem>} [children] - a list of children of this item
 * @example
   {
 title: 'PowerSearch',
 icon: <MdSearch />,
 secure: secureRoute('power-search'),
 isActive: '/search',
 click(hide) {
        navigate('/search')
        hide()
   }
}
 */

/**
 * @typedef {Object<string, MenuItem>} MenuItems
 * @description A dictionary of menu items
 * @global
 */

/**
 * @callback MenuItemFunction
 * @global
 * @description A function that returns menu items
 * @param {MenuItems} items - the current items
 * @returns {MenuItems} the items to be added to the menu
 */

/**
 * Provide a list of menu items.  This is a convenience
 * function for handling the 'get-menu-items' event.  You
 * provide a handler that takes the existing items and return
 * an object containing keys which have unique menu item ids and
 * values which are an object containing the data for the
 * menu item to show.
 *
 * menuItems refers to the main left hand icon based menu.  Sub items
 * can be shown on a menu shown when the top level is selected.  These
 * sub items can be resolved using the <code>subItems()</code> function
 * or by supplying <code>children</code> to the main menu.
 * @param {MenuItemFunction} menuItemFn - a function that provides menu items
 */
export function menuItems(menuItemFn) {
    events.on('get-menu-items', function (event, items) {
        let result = menuItemFn(items)
        if (result) {
            Object.keys(result).forEach((key) => {
                items[key] = merge(items[key] || {}, result[key], mergeArrayCustomiser)
            })
        }
    })
}

/**
 * @interface SubMenuInfo
 * @global
 * @description Describes a sub menu
 * @property {MenuItem} selected - the currently selected main menu
 * @property {string} id - the id of the main menu item selected
 * @property {string} title - the title of the menu item selected
 * @property {React.Component} header - the header used for the sub menu
 * @property {Array<MenuItem>} children - the current children that will form the sub menu
 */

/**
 * @callback SubMenuProvider
 * @description A function to provide sub menu items and/or modify
 * information about the sub menu
 * @global
 * @param {SubMenuInfo} info - information about the currently selected menu
 * @returns {MenuItems} the items to be added to the sub menu
 */

/**
 * Provide a list of submenu items for a parent.  You can
 * use this method to provide an on-demand context specific list
 * of children for any menu item.
 *
 * @param {string} parentMenuId - the menuId of the parent currently selected
 * @param {SubMenuProvider} subMenuFn - the function that will provide sub menu items
 */
export function subMenuItems(parentMenuId, subMenuFn) {
    events.on(`sub-menu-items.${parentMenuId}`, (event, { parameters: settings }) => {
        let result = subMenuFn(settings)
        Object.keys(result).forEach((key) => {
            settings.children.push(merge({ id: key }, result[key]))
        })
    })
}

/**
 * @callback SubMenuHeaderProvider
 * @global
 * @description Provides a header for a sub menu
 * @param {SubMenuInfo} info - info about the currently selected menu and sub menu items
 * @returns {React.Component} the header to use
 */

/**
 * Provide a header for a submenu given a parent.
 * @param {string} parentMenuId - the id that will be used for the header
 * @param {SubMenuHeaderProvider} subMenuFn - a function to provide the sub menu header
 */
export function subMenuHeader(parentMenuId, subMenuFn) {
    events.on(`sub-menu-items.${parentMenuId}`, (event, { parameters: settings }) => {
        settings.header = subMenuFn(settings)
    })
}

const handlers = new Set()

export function useSubMenuItem(menuId, subItem) {
    useStableLocalEvent(`sub-menu-items.${menuId}`, ({ parameters: { children } }) => {
        children.push(subItem)
    })
    useEffect(() => {
        raiseLater('refresh-menu')
    }, [subItem.id])
}

const permanentMenuItems = new Map()

export function usePermanentSubMenuItem(menuId, subItem, dedupId) {
    useEffect(() => {
        raiseLater('refresh-menu')
    }, [subItem.id])
    const handler = useRef()

    if (handlers.has(subItem.id)) return
    if (handler.current) handler.current()
    if (dedupId && permanentMenuItems.has(dedupId)) {
        permanentMenuItems.get(dedupId)()
    }

    handlers.add(subItem.id)
    handler.current = handle(`sub-menu-items.${menuId}`, ({ parameters: { children } }) => {
        children.push(subItem)
    })
    if (dedupId) {
        permanentMenuItems.set(dedupId, handler.current)
    }
}
