import { useEffect } from 'react'

import events from 'packages/alcumus-local-events'

import decamelize from 'decamelize'
import isFunction from 'lodash/isFunction'
import isObject from 'lodash/isObject'

const hasUppercase = /[A-Z]+/
const hasDot = /[^a-zA-Z]+/

export function eventName(name) {
    if (hasDot.test(name)) return name
    if (!hasUppercase.test(name)) return name
    let parts = name.replace(/(?!\.)\$/g, '.*').split('.')
    return [decamelize(parts[0], { separator: '.' }), ...parts.slice(1)].join('.')
}

export function dontWaitForAsyncHandler(fn) {
    return function (...params) {
        fn(...params)
    }
}

export function dontWaitForAsyncHandlerAndDefer(fn) {
    return function (...params) {
        setTimeout(() => fn(...params))
    }
}

export function eventRaised(type) {
    return new Promise((resolve) => {
        events.on(type, handler)
        function handler() {
            resolve()
            events.off(type, handler)
        }
    })
}

export function handle(type, fn) {
    let pairs = []
    let result = () => {
        pairs.forEach((handler) => events.off(...handler))
    }
    if (isFunction(type) || isObject(type)) {
        let props = methods(type)
        for (let [definedType, fn] of props) {
            const toProcessType = clean(definedType)
            const handler = function (event, ...params) {
                let result = fn.call(type, ...params)
                if (result === false) {
                    event.preventDefault()
                }
                return result
            }
            pairs.push([toProcessType, handler])
            events.on(toProcessType, handler)
            result[definedType] = declareAsync(definedType)
        }
    } else {
        const handler = function (event, ...params) {
            let result = fn.call(this, ...params)
            if (result === false) {
                event.preventDefault()
            }
            return result
        }
        type = eventName(type)
        pairs.push([type, handler])
        events.on(type, handler)
    }
    return result
}

export function useHandler(handler) {
    useEffect(() => {
        return handle(handler)
    })
}

export function handler(pattern, handler) {
    const process = (event, ...params) => {
        let result = (handler || pattern)(...params)
        if (result === false) event.preventDefault()
    }
    if (!handler && typeof pattern === 'function') {
        return events.on(eventName(pattern.name), process)
    }
    events.on(pattern, process)
}

export function once(pattern, handler) {
    const process = (event, ...params) => {
        let result = (handler || pattern)(...params)
        if (result === false) event.preventDefault()
    }
    if (!handler && typeof pattern === 'function') {
        return events.once(eventName(pattern.name), process)
    }
    events.once(pattern, process)
}

events.handle = handle

export function raise(event, ...params) {
    events.emit(eventName(event), ...params)
    return params[0]
}

export function raiseLater(event, ...params) {
    setTimeout(() => {
        events.emit(eventName(event), ...params)
    })
}

const running = new Map()

export function raiseOnce(event, ...params) {
    if (running.get(event)) return
    // clearTimeout(running.get(event))
    running.set(
        event,
        setTimeout(() => {
            running.delete(event)
            events.emit(eventName(event), ...params)
        }, 20)
    )
}

export function raiseOnceDelay(delay, event, ...params) {
    if (running.get(event)) return
    // clearTimeout(running.get(event))
    running.set(
        event,
        setTimeout(() => {
            running.delete(event)
            events.emit(eventName(event), ...params)
        }, delay)
    )
}

export function byId(q) {
    return q?.id
}

export function byName(q) {
    return q?.name
}

export function raiseOnceDedupe(event, keyFn, ...params) {
    const key = `${event}:${JSON.stringify(keyFn(...params))}`
    if (running.get(key)) return
    // clearTimeout(running.get(key))
    running.set(
        key,
        setTimeout(() => {
            running.delete(key)
            events.emit(eventName(event), ...params)
        }, 20)
    )
}

export function willRaise(event, ...params) {
    return function () {
        raise(event, ...params)
    }
}

export async function raiseAsync(event, ...params) {
    await events.emitAsync(eventName(event), ...params)
    return params[0]
}

export function declare(event) {
    return (...params) => raise(event, ...params)
}

export function declareAsync(event) {
    return (...params) => raiseAsync(event, ...params)
}

export { events }
export default events

function methods(klass) {
    let properties = []
    for (let item of Object.getOwnPropertyNames(klass)) {
        if (typeof klass[item] === 'function') {
            properties.push([item, klass[item]])
        }
    }
    if (klass.prototype) {
        for (let item of Object.getOwnPropertyNames(klass.prototype)) {
            if (item === 'constructor') continue
            if (typeof klass.prototype[item] === 'function') {
                properties.push([item, klass.prototype[item]])
            }
        }
    }

    return properties
}

function clean(name) {
    return eventName(name).replace(/\$/g, '*').replace(/_/g, '-')
}
