export function resolveValue(value, ...params) {
    if (typeof value === 'function') {
        return value(...params)
    }
    return value
}

export function resolveValueAsFunction(value, params) {
    if (typeof value === 'function') {
        return () => value(params)
    }
    return () => value
}
