let _navigator = navigator

/**
 * @interface Navigator
 * @global
 * @description wraps the  global navigator variable.
 */
export default class Navigator {
    static hasFlagEnabled(path) {
        return !!(_navigator[path] !== undefined && _navigator[path])
    }
}
