import events from 'packages/alcumus-local-events'
import { alert, showNotification } from 'common/modal'
import { define, process as _process } from 'common/process'
import { fetch, setToken } from 'common/request'
//import { navigate } from 'common/routing'
import { isDebugger } from 'common/debugger'
import { getItem, setItem, setUnprefixedItemWithStringify } from 'common/using-local-storage-key'
import { ONLINE } from 'common/globals'
import { performLogout } from 'common/session-timeout'
import { dontWaitForAsyncHandlerAndDefer, handle, raiseAsync } from 'common/events'
import { ping, startTracking } from 'common/remote-start'
//import { loginAsAnonymous } from 'framework/login'
import { clearCurrentAuthenticatedUser, getCurrentAuthenticatedUser } from 'common/global-store/api'
import { store } from 'common/global-store'
import { generate } from 'packages/identifiers'

let __lastLoggedIn = 0

async function doFetch(url, options) {
    try {
        let response = await fetch(url, options)
        let body = await response.json()
        if (!response.ok) {
            throw body
        } else {
            return body
        }
    } catch (e) {
        console.error(e)
        throw e
    }
}

const signInAsAnonymous = async () => {
    let anonId = getItem('anon-user-id') || generate()
    await setItem('anon-user-id', anonId)
    let authResult = await _process(
        {
            type: 'authentication.loginAsAnonymousUser',
            anonId,
        },
        60000 * 5,
        undefined,
        undefined,
        true
    )
    const [error] = authResult.errors
    if (error) {
        if (error.startsWith('401')) {
            throw new Error('Unable to create anonymous user')
        } else if (error.startsWith('403')) {
            throw new Error('User is not confirmed.')
        }
    }
    const user = authResult?.client?.data
    return user
}

const signIn = async (username, password) => {
    let authResult = await _process(
        {
            type: 'authentication.login',
            username,
            password,
        },
        60000 * 5,
        undefined,
        undefined,
        true
    )
    const [error] = authResult.errors
    if (error) {
        if (error.startsWith('401')) {
            throw new Error('Incorrect username or password.')
        } else if (error.startsWith('403')) {
            throw new Error('User is not confirmed.')
        }
    }
    const user = authResult?.client?.data
    __lastLoggedIn = Date.now()
    return user
}

export const signInSSO = async (key, token) => {
    let ssoToken = ''
    try {
        ssoToken = getItem('ssoToken')
    } catch (e) {
        console.error(e)
    }

    let authResult = await _process(
        {
            type: 'authentication.loginWithSSO',
            key,
            token,
            ssoToken,
        },
        60000 * 5,
        undefined,
        undefined,
        true
    )
    const [error] = authResult.errors
    if (error) {
        if (error.startsWith('401')) {
            throw new Error('Incorrect SSO credentials')
        }
    }

    if (authResult?.client?.confirmRequired) {
        return { confirmRequired: true }
    } else if (authResult?.client?.data) {
        const user = authResult?.client?.data
        __lastLoggedIn = Date.now()
        return user
    }
}

export const confirmSSO = async (code) => {
    let authResult = await _process(
        {
            type: 'authentication.confirmSSO',
            code,
        },
        60000 * 5,
        undefined,
        undefined,
        true
    )
    const [error] = authResult.errors
    if (error) {
        if (error.startsWith('401')) {
            throw new Error('Incorrect SSO credentials')
        }
    }

    if (authResult?.client?.confirmRequired) {
        return { confirmRequired: true }
    } else if (authResult?.client?.data) {
        const user = authResult?.client?.data
        setItem('ssoToken', authResult?.client?.data?.ssoToken)
        __lastLoggedIn = Date.now()
        return user
    } else {
        return false
    }
}

/**
 * Login details
 * @typedef Login
 * @property {string} username - email address, phone number, etc
 * @property {"phoneNumber" | "email"} type
 */

/**
 * @typedef LoginWithVerificationCode
 * @extends LoginForm
 * @property {string} verificationCode
 */

/**
 * Register a user with multiple usernames
 * @param {Login} newLogin
 * @param {LoginWithVerificationCode} existingLogin
 */
const addUsername = async (newLogin, existingVerifiedLogin, replaceLogin) => {
    const addUsernameResult = await _process(
        {
            type: 'authentication.addUsername',
            newLogin,
            existingVerifiedLogin,
            replaceLogin,
        },
        60000 * 5,
        undefined,
        undefined,
        true
    )

    const [error] = addUsernameResult.errors
    if (error && error.startsWith('401')) {
        throw new Error('Cannot add additional username until email log in is verified.')
    } else if (error) {
        throw new Error('This user is already registered, please use the log in link instead.')
    }
}

const register = async (username, password) => {
    const registerResult = await _process(
        {
            type: 'authentication.register',
            username,
            password,
        },
        60000 * 5,
        undefined,
        undefined,
        true
    )
    const [error] = registerResult.errors
    if (error) {
        throw new Error('This user is already registered, please use the log in link instead.')
    }
}

export const registerAndLogin = async (username, password) => {
    const registerAndLoginResult = await _process(
        {
            type: 'authentication.registerAndLogin',
            username,
            password,
        },
        60000 * 5,
        undefined,
        undefined,
        true
    )

    const [error] = registerAndLoginResult.errors
    if (error) {
        throw new Error('This user is already registered, please use the log in link instead.')
    }

    const user = registerAndLoginResult?.client?.data
    return user
}

//TODO - check what calls this?
export const shouldVerify = async (email) => {
    const { verify } = await doFetch(`/shouldVerify`, {
        method: 'POST',
        body: JSON.stringify({ user: email }),
        headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
        },
    })
    return verify
}

const verify = async (userName, verificationCode) => {
    const verifyResponse = await _process(
        {
            type: 'authentication.verifyUsername',
            //phone number needs to be a number including area code without the plus on the front
            username: userName,
            code: verificationCode.trim(),
        },
        60000 * 5,
        undefined,
        undefined,
        true
    )
    const [error] = verifyResponse.errors
    if (error) {
        throw new Error('Invalid verification code provided, please try again.')
    }
}

const forgotPassword = async (email) => {
    if (!email) {
        alert('You must specify a username before clicking on forgot password!', 'Error')
        throw new Error('No username')
    } else {
        await _process(
            {
                type: 'authentication.initiateForgottenPassword',
                username: email,
            },
            60000 * 5,
            undefined,
            undefined,
            true
        )
    }
}

/**
 * Resend verification code for a username
 * @param {string} username
 */
const resendVerificationCode = async (username) => {
    if (!username) {
        alert('You must specify an username before resending the verification code', 'Error') //this should probably be handled closer to the view, no?
        throw new Error('No log in')
    } else {
        await _process(
            {
                type: 'authentication.resendVerification',
                username: username,
            },
            60000 * 5,
            undefined,
            undefined,
            true
        )
    }
}

export async function forgotPasswordSubmit(email, code, password) {
    const resetResult = await _process(
        {
            type: 'authentication.resetForgottenPassword',
            username: email,
            code,
            password,
        },
        60000 * 5,
        undefined,
        undefined,
        true
    )
    const [error] = resetResult.errors
    if (error) {
        if (error.startsWith('409')) {
            throw new Error("The password does not meet Alcumus's security policy.")
        }
        throw new Error('Invalid verification code provided, please try again.')
    }
}

export const invalidateToken = define('authentication.invalidatetoken', function ({ doNotBatch, required }) {
    required('token')
    doNotBatch()
})

const signOut = async (destination = '/') => {
    console.info('Signing out...')

    //  ---------  Logout via Alcumus Portal
    if (process.env.REACT_APP_SWITCH_TO_PORTAL === 'true') {
        const currentAuthenticatedUser = getCurrentAuthenticatedUser()

        const body = {
            refreshToken: currentAuthenticatedUser?.sessionToken,
            accessToken: currentAuthenticatedUser?.accessToken,
        }
        const response = await window.fetch(`/api/auth/logout`, {
            method: 'POST',
            body: JSON.stringify(body),
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json',
            },
        })

        if (response?.status !== 200) {
            console.error('error')
        }
    }

    clearCurrentAuthenticatedUser()
    store.set({ profile: {} })
    // if (!window.location.pathname.startsWith('/login')) {
    //     sessionStorage.setItem('intendedDest', '/')
    // }
    events.emit('signed-out-event', destination)
}

export const changePassword = async ({ newPassword }) => {
    const currentAuthenticatedUser = getCurrentAuthenticatedUser()
    const changeResult = await _process({
        type: 'authentication.changePassword',
        userId: currentAuthenticatedUser.id,
        newPassword,
    })
    const [error] = changeResult.errors
    if (error) {
        if (error.startsWith('409')) {
            showNotification("Error: The password does not meet Alcumus's security policy.")
        } else {
            showNotification('Error: Unable to change password')
        }
        return false
    }
    showNotification(`Password updated`)
    return true
}

export function tokensRefreshed() {
    return ONLINE.isEnabled() ? refreshPromise : Promise.resolve(true)
}

let refreshPromise = Promise.resolve(true)

setTimeout(refreshSessionTokens, 200)

let refreshing = Promise.resolve(true)

export async function tokenRefreshing() {
    try {
        await refreshing
    } catch (e) {
        //
    }
    return true
}

export async function authenticate() {
    const currentAuthenticatedUser = getCurrentAuthenticatedUser()
    if (!currentAuthenticatedUser) return false
    if (currentAuthenticatedUser.isAnonymous) return true
    if (currentAuthenticatedUser.accessTokenExpiry < (Date.now() + 60 * 1000 * 10) / 1000) {
        // 10 minutes difference
        if (currentAuthenticatedUser.refreshTokenExpiry < (Date.now() + 20000) / 1000) {
            let result = await authenticateWithRefreshToken()
            if (!result) clearCurrentAuthenticatedUser()
            return result
        } else {
            clearCurrentAuthenticatedUser()
            return false
        }
    }
    return true
}

export async function authenticateWithRefreshToken() {
    const currentAuthenticatedUser = getCurrentAuthenticatedUser()

    const expiryCheck =
        (currentAuthenticatedUser.migratedToPortal && process.env.REACT_APP_SWITCH_TO_PORTAL === 'true') ||
        currentAuthenticatedUser.accessTokenExpiry * 1000 < Date.now() + 1000 * 60 * 5 // 5 minutes

    if (ONLINE.isEnabled() && !isDebugger() && !window._useToken && currentAuthenticatedUser && expiryCheck) {
        await refreshing
        // eslint-disable-next-line no-async-promise-executor
        return await (refreshing = new Promise(async (resolve) => {
            try {
                const body = {
                    refreshToken: currentAuthenticatedUser?.sessionToken,
                    accessToken: currentAuthenticatedUser?.accessToken,
                }
                const updatedInfo = await window.fetch(`/tokenrefresh`, {
                    method: 'POST',
                    body: JSON.stringify(body),
                    headers: {
                        'Content-Type': 'application/json',
                        Accept: 'application/json',
                    },
                })
                if (updatedInfo.ok) {
                    const user = (await updatedInfo.json())?.data

                    if (user && user.access_token && user.expires_at && user.refresh_token && user.refresh_expires_at) {
                        currentAuthenticatedUser.accessToken = user.access_token
                        currentAuthenticatedUser.accessTokenExpiry = user.expires_at
                        currentAuthenticatedUser.sessionToken = user.refresh_token
                        currentAuthenticatedUser.sessionTokenExpiry = user.refresh_expires_at

                        setUnprefixedItemWithStringify('currentAuthenticatedUser', currentAuthenticatedUser)
                        setToken(currentAuthenticatedUser.accessToken)
                        events.emitAsync('refresh-token-event', currentAuthenticatedUser)
                        return true
                    }
                } else {
                    if (updatedInfo.status === 403) {
                        await signOut('/login')
                    }

                    console.error('Could not refresh tokens')
                    showNotification(
                        `Error: Could not refresh tokens - ${updatedInfo.status}/${updatedInfo.statusText}`
                    )
                }
            } finally {
                resolve()
            }
        }))
    }
}

handle('refresh-token-event', dontWaitForAsyncHandlerAndDefer(startTracking))

async function authenticateAndPing() {
    if (ONLINE.isEnabled() && !isDebugger() && !window._useToken) {
        // await startTracking()
        // await authenticateWithRefreshToken()
        const id = setTimeout(() => {
            showNotification('Error: Not connected to server')
        }, 15000)
        await raiseAsync('ready-to-call')
        await ping()
        clearTimeout(id)
    }
}

handle('activated', authenticateAndPing)

export async function refreshSessionTokens() {
    if (isDebugger()) return
    if (window._useToken) return
    const currentAuthenticatedUser = getCurrentAuthenticatedUser()
    await refreshPromise
    if (currentAuthenticatedUser && currentAuthenticatedUser.accessToken && currentAuthenticatedUser.sessionToken) {
        await (refreshPromise = update())
    }

    async function update() {
        // eslint-disable-next-line no-console
        console.log('Will refresh session tokens')
        try {
            await authenticateWithRefreshToken()
            setTimeout(refreshSessionTokens, 1000 * 60 * 4) // 4 minutes
        } catch (e) {
            if (
                currentAuthenticatedUser.sessionTokenExpiry &&
                currentAuthenticatedUser.sessionTokenExpiry * 1000 < Date.now()
            ) {
                performLogout().catch(console.error)
            } else {
                setTimeout(refreshSessionTokens, 1000 * 30)
            }
        }
    }
}

export { forgotPassword, signIn, register, addUsername, signOut, resendVerificationCode, verify, signInAsAnonymous }
