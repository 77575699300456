import events from 'packages/alcumus-local-events'

import { fetch } from 'common/request'

events.on('_once', async function () {
    let response = await fetch('/once')
    let result = (await response.json()) || []
    result.forEach((packet) => {
        events.emit(packet.event, packet.message)
    })
})
