import { raiseAsync } from 'common/events'
import { hydrateDocument, securityReady } from 'common/offline-data-service/hydrate'
import { OfflineFirstRetriever } from 'common/offline-data-service/offlineFirstRetriever'
import { getActiveClient, getNamespace } from 'common/global-store/api'
import constants from 'common/offline-data-service/constants.json'
import { clone } from 'common/utils/deep-copy'
import { navigate } from 'common/routing'
import { showNotification } from 'common/modal'
import * as LocalStorageFacade from 'common/offline-data-service/localStorageFacade'
import { isOnline } from 'common/utils'
import * as InternalEvents from 'common/offline-data-service/events'
import { setVersion } from 'common/offline-data-service/document-versions'
import { mobile, offlineDataStorageReady, prepareDocument } from 'common/offline-data-service'
import { checkClient, isDatabaseId } from 'common/offline-data-service/utils'
import { onlyOne } from 'common/guard'

/**
 * Retrieve a document.  The primary retrieval order will be: local memory
 * cache, local db cache, the server unless overridden using the <code>localOnly</code>
 * parameter.
 * @param {string} id - the id of the record to retrieve
 * @param {boolean} [track=true] - whether to cache the record and then track it as it changes on the server
 * @param {boolean} [raw=false] - if true the retrieved document is NOT hydrated, otherwise it is prepared for execution
 * @param {string|boolean} [localOnly=false] -
 * a flag to override the processing of the documents location.
 *
 * Possible values are 'false' the document will be retrieved locally
 * if available, 'server' the document must be retrieved from the server
 * and if we are offline it will fail and 'server-preferred' we retrieve
 * from the server if we are online, otherwise a local copy will do.
 * @returns {Promise<Document|null>} a promise for the retrieved document
 */
export const get = onlyOne(
    async function get(id, track = true, raw = false, localOnly = false) {
        const { result } = await raiseAsync('get-record', { id, result: null })
        if (result) return result
        if (!id || !isDatabaseId(id)) {
            throw new Error(`Invalid id: ${JSON.stringify(id)}`)
        }
        await securityReady
        await offlineDataStorageReady
        if (!id) {
            throw new Error('Cannot request a null id')
        }
        let existing = OfflineFirstRetriever.getLocal(id)

        let activeClient = getActiveClient()

        if (localOnly !== constants.SERVER_ONLY && existing && !existing.$invalid) {
            existing = clone(existing)
            if (checkClient(existing)) {
                if (raw) {
                    return existing
                }
                await hydrateDocument(existing)
                return existing
            } else {
                navigate('/')
                showNotification(`Error: Cannot access with active client ${activeClient} !== ${existing._client}`)
            }
        }
        try {
            let results
            try {
                results =
                    getActiveClient() === '<test>'
                        ? []
                        : (
                              await LocalStorageFacade.select({
                                  from: 'records',
                                  where: {
                                      id,
                                  },
                              })
                          ).filter((r) => r && r.data)
            } catch (e) {
                results = []
            }
            if (
                (localOnly !== constants.SERVER_PREFERRED || !isOnline()) &&
                localOnly !== constants.SERVER_ONLY &&
                results?.length &&
                results[0].data
            ) {
                existing = results[0].data
                if (existing.__namespace === getNamespace()) {
                    if (!existing.$invalid || !isOnline()) {
                        OfflineFirstRetriever.setLocal(id, results[0].data)

                        if (checkClient(existing)) {
                            if (raw) {
                                return existing
                            }
                            await hydrateDocument(existing)
                            return existing
                        } else {
                            navigate('/').catch(console.error)
                            console.error(`Cannot access with active client ${activeClient} !== ${existing._client}`)
                            return null
                        }
                    }
                }
            }
            let record
            if (
                isOnline() &&
                (!localOnly || localOnly === constants.SERVER_ONLY || localOnly === constants.SERVER_PREFERRED)
            ) {
                record = await InternalEvents.getRecord(id, track)
                if (record && record.data) {
                    record.data.__namespace = getNamespace()
                }
                if (localOnly !== constants.SERVER_ONLY && record) {
                    setVersion(record.id, record.data.__)
                }
                if (track && record) {
                    OfflineFirstRetriever.setLocal(id, record.data)
                    try {
                        await LocalStorageFacade.insert({
                            into: 'records',
                            upsert: true,
                            values: [record]
                                .map((d) => ({
                                    ...d,
                                    data: mobile ? JSON.stringify(prepareDocument(d.data)) : prepareDocument(d.data),
                                }))
                                .filter((r) => r && r.data && !r.data?.serverOnly),
                        })
                    } catch (e) {
                        console.error('Update record error', record)
                        console.error(e)
                    }
                }
            }
            if (!record) return null
            if (raw) {
                record = record.data
            } else {
                record = record.data
                await hydrateDocument(record)
                await raiseAsync(`hydrate.${id.split(':')[1]}`, record)
            }
            return record
        } catch (e) {
            console.error(e)
            return null
        }
    },
    { primitive: true, promise: true, maxAge: 60000 }
)

// handle('data.updated.*', () => {
//     get.clear()
// })
