import React, { useRef, useState } from 'react'

import PropTypes from 'prop-types'

import events from 'packages/alcumus-local-events'

import debounce from 'lodash/debounce'
import isEqual from 'lodash/isEqual'

import { inPriorityOrder } from 'packages/al-react-ioc-widgets'

import { store } from 'common/global-store'
import { useEvent } from 'common/use-event'

import { sendHelp } from './api'
import { getActiveUser } from 'common/global-store/api'
import { importComponent } from 'common/use-async'

const EVERYWHERE = 'everywhere'

const ReactJoyride = importComponent(async () => import('react-joyride'))

export default function Tour({ disabled }) {
    Tour.propTypes = {
        disabled: PropTypes.bool,
    }
    const debounced = useRef(
        debounce(() => {
            const done = store.profile$?.help || {}
            const { setRun, setIndex, setSteps, steps } = runner.current
            let seen = (done[EVERYWHERE] = done[EVERYWHERE] || {})
            const allSteps = events.modify('get-tour', []).sort(inPriorityOrder)

            const toSend = []
            for (const step of allSteps) {
                let target = document.querySelector(step.target)
                if (step.target && target && !seen[encodeURIComponent(step.target)] && !!target.offsetParent) {
                    toSend.push({
                        ...step,
                        originalTarget: step.target,
                        target,
                    })
                }
            }
            if (!isEqual(steps, toSend)) {
                setRun(false)
                setSteps(toSend)
                setIndex(0)
                setRun(true)
            }
        }, 300)
    )
    useEvent(events, 'reset-tour', () => {
        setIndex(0)
        events.emit('redrawn', true)
    })
    const runner = useRef(null)
    const [steps, setSteps] = useState([])
    const [run, setRun] = useState(false)
    const [stepIndex, setIndex] = useState(0)
    runner.current = { setRun, setSteps, setIndex, steps }
    useEvent(events, 'redrawn', function () {
        debounced.current()
    })
    const allSteps = events.modify('get-tour', []).sort(inPriorityOrder)
    const done = store.profile$?.help || {}
    let seen = (done[EVERYWHERE] = done[EVERYWHERE] || {})
    const toSend = []
    for (const step of allSteps) {
        let target = document.querySelector(step.target)
        if (step.target && target && !seen[encodeURIComponent(step.target)] && !!target.offsetParent) {
            toSend.push({
                ...step,
                originalTarget: step.target,
                target,
            })
        }
    }
    return (
        <>
            {!disabled ? (
                <ReactJoyride
                    styles={{ options: { zIndex: 1230 } }}
                    stepIndex={stepIndex}
                    callback={handleSteps}
                    steps={toSend}
                    hideBackButton={false}
                    run={run}
                    disableScrollParentFix={true}
                    disableScrolling={true}
                    showProgress={false}
                    continuous={false}
                />
            ) : null}
        </>
    )

    function handleSteps(data) {
        const done = store.$profile?.help || {}
        if (data.action === 'close' && data.lifecycle === 'complete') {
            let seen = (done[EVERYWHERE] = done[EVERYWHERE] || {})
            events.emit('update-help')
            seen[encodeURIComponent(steps[stepIndex].originalTarget)] = true
            updateUserHelp()
            setIndex(stepIndex + 1)
        }
    }

    async function updateUserHelp() {
        const done = store.$profile?.help || {}
        await sendHelp(getActiveUser(), done)
    }
}
