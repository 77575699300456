import { raise, raiseAsync } from 'common/events'
import { isDebugger } from 'common/debugger'
import { clone } from 'common/utils/deep-copy'
import { getNamespacedKey } from 'common/namespaced-key'

export async function storeLocalItem(key, value) {
    await raiseAsync('local-store-setItem', getNamespacedKey(key), value)
    if (!isDebugger()) {
        await raiseAsync('local-store-setItem', `debugger-${getNamespacedKey(key)}`, value)
    }
}

export function storeLocalItemAndDontWait(key, value) {
    raise('local-store-setItem', getNamespacedKey(key), value)
    if (!isDebugger()) {
        raise('local-store-setItem', `debugger-${getNamespacedKey(key)}`, value)
    }
}

export async function storeLocalItemWithStringify(key, value) {
    await raiseAsync('local-store-setItem', getNamespacedKey(key), value)
    if (!isDebugger()) {
        await raiseAsync('local-store-setItem', `debugger-${getNamespacedKey(key)}`, JSON.stringify(value))
    }
}

export async function getLocalItem(key, defaultValue) {
    const context = { key: getNamespacedKey(key), defaultValue }
    await raiseAsync('local-store-getItem', context)
    return context.value || defaultValue
}

export async function getLocalItemAndParse(key, defaultValue) {
    const context = { key: getNamespacedKey(key), defaultValue }
    await raiseAsync('local-store-getItem', context)
    return JSON.parse(context.value || defaultValue || 'null')
}

export async function removeLocalItem(key) {
    await raiseAsync('local-store-removeItem', getNamespacedKey(key))
    if (!isDebugger()) await raiseAsync('local-store-removeItem', `debugger-${getNamespacedKey(key)}`)
}

export async function usingLocalItem(key, fn, defaultValue) {
    let item = await getLocalItem(key)
    item = item ? item : clone(defaultValue)
    let result = await Promise.resolve(fn(item))
    item = result || item
    if (Object.isEqual(item, defaultValue)) {
        await removeLocalItem(key)
    } else {
        item && (await storeLocalItem(key, item))
    }
}
