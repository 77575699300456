// const Events = require('eventemitter2')
const Events = require('packages/alcumus-events/index.js')
const emit = Events.prototype.emit
const emitAsync = Events.prototype.emitAsync

class HookedEvents extends Events {
    async emitAsync(...parms) {
        const context = {
            cancel: false,
            preventDefault() {
                context.cancel = true
            },
        }
        const delim = this.delimiter || ':'
        let eventName = parms[0]
        parms.splice(1, 0, context)
        if (this.all) {
            parms[0] = `early${delim}${delim}${eventName}`
            await emitAsync.apply(this, parms)
            if (context.cancel) return false
        }
        parms[0] = `before${delim}${eventName}`
        await emitAsync.apply(this, parms)

        if (context.cancel) return false
        if (this.all) {
            for (let i = 0; i < 2; i++) {
                parms[0] = `pre${delim}${i}${delim}${eventName}`
                await emitAsync.apply(this, parms)
                if (context.cancel) return false
            }
        }
        parms[0] = eventName
        await emitAsync.apply(this, parms)
        if (context.cancel) return false
        if (this.all) {
            for (let i = 0; i < 2; i++) {
                parms[0] = `post${delim}${i}${delim}${eventName}`
                await emitAsync.apply(this, parms)
                if (context.cancel) return false
            }
        }
        parms[0] = `after${delim}${eventName}`
        await emitAsync.apply(this, parms)
        if (context.cancel) return false
        if (this.all) {
            parms[0] = `late${delim}${delim}${eventName}`
            await emitAsync.apply(this, parms)
            if (context.cancel) return false
        }
        return true
    }

    emit(...parms) {
        const context = {
            cancel: false,
            preventDefault() {
                context.cancel = true
            },
        }
        const delim = this.delimiter || ':'
        let eventName = parms[0]
        parms.splice(1, 0, context)
        if (this.all) {
            parms[0] = `early${delim}${delim}${eventName}`
            emit.apply(this, parms)
            if (context.cancel) return false
        }
        parms[0] = `before${delim}${eventName}`
        emit.apply(this, parms)
        if (context.cancel) return false
        if (this.all) {
            for (let i = 0; i < 2; i++) {
                parms[0] = `pre${delim}${i}${delim}${eventName}`
                emit.apply(this, parms)
                if (context.cancel) return false
            }
        }
        parms[0] = eventName
        emit.apply(this, parms)
        if (context.cancel) return false
        if (this.all) {
            for (let i = 0; i < 2; i++) {
                parms[0] = `post${delim}${i}${delim}${eventName}`
                emit.apply(this, parms)
                if (context.cancel) return false
            }
        }
        parms[0] = `after${delim}${eventName}`
        emit.apply(this, parms)

        if (context.cancel) return false
        if (this.all) {
            parms[0] = `late${delim}${delim}${eventName}`
            emit.apply(this, parms)
            if (context.cancel) return false
        }
        return true
    }

    bindEvent(event, async) {
        return (...parameters) => {
            if (async) {
                emitAsync(event, ...parameters)
            } else {
                emit(event, ...parameters)
            }
        }
    }

    return(event, fn) {
        if (!fn) {
            return (fn) => {
                if (typeof fn !== 'function') {
                    let v = fn
                    fn = () => v
                }
                this.on(event, eventUsingParameter(fn))
            }
        }
        this.on(event, eventUsingParameter(fn))
    }

    returnAsync(event, fn) {
        if (!fn) {
            return (fn) => {
                if (typeof fn !== 'function') {
                    let v = fn
                    fn = () => Promise.resolve(v)
                }
                this.on(event, asyncEventUsingParameter(fn))
            }
        }
        this.on(event, asyncEventUsingParameter(fn))
    }

    modify(event, modify, ...params) {
        if (!modify) {
            return (modify) => {
                return modifyValueUsingEvent(event, modify, this, ...params)
            }
        }
        return modifyValueUsingEvent(event, modify, this, ...params)
    }

    async modifyAsync(event, modify, ...params) {
        if (!modify) {
            return async (modify) => {
                return await modifyValueUsingEventAsync(event, modify, this, ...params)
            }
        }
        return await modifyValueUsingEventAsync(event, modify, this, ...params)
    }

    constructor(props = {}) {
        props.maxListeners = 1000000000
        props.wildcard = props.wildcard !== false ? true : false
        super(props)
    }

    onAll(events, fn) {
        if (typeof events === 'string')
            events = events
                .split(' ')
                .map((e) => e.trim())
                .filter((f) => !!f)
        for (let type of events) {
            this.on(type, fn)
        }
    }

    offAll(events, fn) {
        if (typeof events === 'string')
            events = events
                .split(' ')
                .map((e) => e.trim())
                .filter((f) => !!f)
        for (let type of events) {
            this.off(type, fn)
        }
    }

    use(handler) {
        for (let [event, fn] of methods(handler)) {
            this.on(clean(event), fn)
        }
    }

    discard(handler) {
        for (let [event, fn] of methods(handler)) {
            this.off(clean(event), fn)
        }
    }
}

function clean(name) {
    return name.replace(/_/g, '.').replace(/\$/g, '*')
}

function methods(klass) {
    let properties = []
    for (let item of Object.getOwnPropertyNames(klass)) {
        if (typeof klass[item] === 'function') {
            properties.push([item, klass[item]])
        }
    }
    return properties
}

function processResult(fn, resultObject, result) {
    if (fn.length >= 1) {
        resultObject.parameters = result || resultObject.parameters
    } else {
        if (result) {
            if (Array.isArray(resultObject.parameters)) {
                if (Array.isArray(result)) {
                    resultObject.parameters.push.apply(resultObject.parameters, result)
                } else {
                    resultObject.parameters.push(result)
                }
            } else if (typeof resultObject.parameters === 'object') {
                Object.assign(resultObject.parameters, result)
            } else {
                resultObject.parameters = result || resultObject.parameters
            }
        }
    }
    resultObject.updated = (resultObject.updated || 0) + 1
}

function eventUsingParameter(fn) {
    return function (event, resultObject) {
        let result = fn(resultObject.parameters, resultObject.updated)
        processResult(fn, resultObject, result)
    }
}

function asyncEventUsingParameter(fn) {
    return async function (event, resultObject) {
        let result = await fn(resultObject.parameters, resultObject.updated)
        processResult(fn, resultObject, result)
    }
}

function modifyValueUsingEvent(event, parameters, eventEmitter, ...params) {
    const resultObject = { parameters }
    eventEmitter.emit(event, resultObject, ...params)
    return resultObject.parameters
}

async function modifyValueUsingEventAsync(event, parameters, eventEmitter, ...params) {
    const resultObject = { parameters }
    await eventEmitter.emitAsync(event, resultObject, ...params)
    return resultObject.parameters
}

module.exports = HookedEvents
