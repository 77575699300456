import React from 'react'

import PropTypes from 'prop-types'
import { makeCachedStyles } from 'common/inline-styles'

//TODO: This will pull in styles from theme provider - probably
// e.g. Cut paste all these objects into a typography block in main provider section.
// const theme = createMuiTheme({
// 	typography: {
//    Tell Material-UI what's the font-size on the html element is.
// 	  htmlFontSize: 10,
// 	},
//   });

const useStyles = makeCachedStyles((__theme) => ({
    h1: {
        fontFamily: 'Montserrat Bold, sans-serif',
        fontWeight: 700,
        fontSize: '2.8em',
    },
}))

export const H1 = ({ children, ...rest }) => {
    const classes = useStyles()
    return (
        <h1 className={classes.h1} {...rest}>
            {children}
        </h1>
    )
}

H1.propTypes = {
    children: PropTypes.node.isRequired,
    rest: PropTypes.any,
}
