let _processEnv = process.env

/**
 * @interface ProcessEnv
 * @global
 * @description wraps process.env
 */
export default class ProcessEnv {
    static hasFlagEnabled(path) {
        if (_processEnv[path] === undefined) return false
        return _processEnv[path] !== 'false'
    }

    static getProperty(path) {
        return _processEnv[path]
    }
}
