import React from 'react'
import useAsync from 'common/use-async'
import events from 'packages/alcumus-local-events'

import { setFromEvent } from 'common/set-from-event'

import { LoginTemplate } from './login-template'
import { ErrorIcon, InfoBoxWithLeading } from './standard-login-components'
import { MdRepeat } from '@react-icons/all-files/md/MdRepeat'
import { MdVpnKey } from '@react-icons/all-files/md/MdVpnKey'
import { Box, Button, TextField, Typography } from '@material-ui/core'

import { confirmSSO, signInSSO } from 'utils/authenticationService'
import { useLoginStyles } from './login-styles'
import { InlineLoader } from 'common/utils/centered-loader'
import { useTheme } from '@material-ui/core/styles'

const INLINE_SIZE = 20

let sso_initialised = false

const SSOLogin = React.memo(
    ({ ssoKey, ssoToken }) => {
        const { typography } = useTheme()
        const { link } = useLoginStyles()

        const [code, setCode] = React.useState('')
        const [status, setStatus] = React.useState({ signOnStatus: '', signOnIcon: undefined })
        const [TBC, setTBC] = React.useState(false)
        const unmounted = React.useRef(false)
        const setSignOnStatus = (signOnStatus, signOnIcon = <ErrorIcon />) => {
            setStatus({ signOnStatus, signOnIcon })
        }

        let user = useAsync(async () => {
            if (sso_initialised) {
                return
            }
            sso_initialised = true
            setSignOnStatus('Logging in with SSO. Please wait...', <InlineLoader size={INLINE_SIZE} offset={false} />)
            const user = await signInSSO(ssoKey, ssoToken)

            if (!user) {
                if (!unmounted.current) {
                    setSignOnStatus('This link is invalid...')
                }
            } else {
                return user
            }
        })

        React.useEffect(() => {
            if (user) {
                if (user.confirmRequired) {
                    setSignOnStatus('Please enter a valid code...', <MdVpnKey color="#009fda" size={25} />)
                    setTBC(true)
                } else {
                    signOnSuccess(user)
                }
            }
            return () => {
                unmounted.current = true
            }
        }, [user])

        const { signOnStatus, signOnIcon } = status

        return (
            <LoginTemplate label="Alcumus Single Sign On">
                {signOnStatus && signOnStatus !== '' && <InfoBoxWithLeading leading={signOnIcon} text={signOnStatus} />}
                {TBC ? (
                    <>
                        <Box mt={2} mb={2}>
                            <Typography style={{ color: '#263836', ...typography.body4 }}>
                                We don&apos;t recognise this browser. For your security we&apos;ve sent you an email
                                with a confirmation code. <strong>Please enter it here:</strong>
                            </Typography>
                        </Box>

                        <Box width="100%" mt={2} mb={2}>
                            <TextField
                                data-testid="sso-confirm-code-textfield"
                                fullWidth
                                variant="outlined"
                                label="Enter SSO Code"
                                value={code}
                                onChange={setFromEvent(setCode)}
                            />
                        </Box>

                        <Box display="flex" mt={3} mb={2} width="100%" justifyContent="space-between">
                            <Box display="flex" alignItems="center" justifyContent="center">
                                <Button
                                    onClick={async () => {
                                        await signInSSO(ssoKey, ssoToken)
                                        setSignOnStatus(
                                            `We've sent you an email with a new code.`,
                                            <MdRepeat color="#009fda" size={25} />
                                        )
                                    }}
                                    data-testid="sso-resend-confirm-code-button"
                                >
                                    <Typography className={link} variant="overline">
                                        Resend code
                                    </Typography>
                                </Button>
                            </Box>
                            <Box display="flex" alignItems="center" justifyContent="center">
                                <Button
                                    onClick={verifySSOCode}
                                    variant="contained"
                                    data-testid="sso-confirm-code-button"
                                    color="primary"
                                    disabled={code.length === 0}
                                >
                                    <Typography variant="overline">Submit code</Typography>
                                </Button>
                            </Box>
                        </Box>
                    </>
                ) : (
                    <Box mb={2} />
                )}
            </LoginTemplate>
        )

        async function signOnSuccess(user) {
            if (user.access_token && user.id && user.email) {
                await events.emitAsync('sign-in-event', user)
                await events.emitAsync('auth.login-success', null)
                window.location.href = '/'
            } else {
                console.warn('invalid user record', user)
                setSignOnStatus('SSO login failed. Code: 1')
            }
        }

        async function verifySSOCode() {
            setSignOnStatus('Verifying code, please wait...', <InlineLoader size={INLINE_SIZE} offset={false} />)
            let user = await confirmSSO(code)
            if (user) {
                signOnSuccess(user)
            } else {
                setSignOnStatus('SSO login failed - unable to verify code')
            }
        }
    },
    () => true
)
export default SSOLogin
