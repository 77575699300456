import { guard } from 'common/guard'
import { delay } from 'common/utils/delay'
import { setItem, setUnprefixedItemWithStringify } from 'common/using-local-storage-key'
import { getSessionUser, getUser, loginAsAnonymous } from 'framework/login'
import { getApps } from 'common/apps'
import noop from 'common/noop'
import { handle, raise, raiseAsync, raiseLater, raiseOnce } from 'common/events'
import { parameter } from 'common/query-params'
import { offlineDataStorageReady } from 'common/offline-data-service'
import { loadRoles } from 'common/secure/validate'
import { singleton } from 'js-coroutines'
import { store } from 'common/global-store'
import events from 'packages/alcumus-local-events'
import { startTracking } from 'common/remote-start'

import { getCurrentAuthenticatedUser, getCurrentUser, getCurrentClient } from 'common/global-store/api'
import { getUniversal } from 'common/offline-data-service/universal'

let _appsLoadingPromise = Promise.resolve(true)
let canLoad = false

function initializePendo(user, client) {
    /* eslint-disable no-undef */
    /* The pendo library is loaded from the script on the root html file */
    pendo.initialize({
        visitor: {
            id: user.id, // Required if user is logged in, default creates anonymous ID

            // You can add any additional visitor level key-values here,
            // as long as it's not one of the above reserved names.
        },

        account: {
            id: client.id, // Required if using Pendo Feedback, default uses the value 'ACCOUNT-UNIQUE-ID'
            // name:    , // Optional
            // is_paying:    // Recommended if using Pendo Feedback
            // monthly_value:// Recommended if using Pendo Feedback
            // planLevel:    // Optional
            // planPrice:    // Optional
            // creationDate: // Optional
            // You can add any additional account level key-values here,
            // as long as it's not one of the above reserved names.
        },
        location: {
            transforms: [
                {
                    attr: 'search',
                    action: 'ExcludeKeys',
                    data: function () {
                        return ['username', 'oneTimePassword']
                    },
                },
            ],
        },
    })
    /* eslint-enable no-undef */
}

const loadApps = guard(async function loadApps() {
    if (parameter('noLoad')) {
        raiseLater('routes-updated')
        return
    }
    if (!canLoad) return
    await _appsLoadingPromise

    const [currentUser, currentClient] = await Promise.all([getCurrentUser(), getCurrentClient()])

    if (currentUser.id && currentClient?.id !== '0' && currentClient?.id !== undefined) {
        initializePendo(currentUser, currentClient)
    }
    return (_appsLoadingPromise = getApps())
})

export async function appLoadingComplete() {
    await delay(0.05)
    await _appsLoadingPromise
}

let userLoadedFromLocalStorage = false

events.on('reinitialise-user-loaded', (_, val) => {
    setUserLoaded(val)
})

export function setUserLoaded(val) {
    userLoadedFromLocalStorage = val
}

export function getUserLoaded() {
    return userLoadedFromLocalStorage
}

async function getStoredUser() {
    if (userLoadedFromLocalStorage) return
    if (window._useToken) return
    let user = getCurrentAuthenticatedUser()

    if (!user || user.isAnonymous) {
        // step 2 check the session
        const sessionUser = await getSessionUser()
        if (sessionUser && sessionUser?.access_token) {
            user = sessionUser
        } else {
            user = await loginAsAnonymous() // creates a token in local storage as well as in Cookie
        }
    }
    let profile = await getUser(user.id)
    if (profile.profile.forceLongPolling === true && !location.href.includes('long_polling')) {
        location.href = `${location.href}?long_polling=true`
    }
    await startTracking()
    // eslint-disable-next-line no-console
    console.log('get user')
    // eslint-disable-next-line no-console
    console.log('got user')
    store.set({ profile })
    userLoadedFromLocalStorage = true
    await raiseAsync('refresh-apps')
    events.emit('secure-token-ready', null)
    raise('user-is-ready')
}

handle('refresh-apps', loadApps)
handle('security-updated', loadApps)

let isInitializing = false
handle('app-start-init', function () {
    if (!isInitializing) {
        isInitializing = true
        // notReady()
    }
})

handle('app-start-end', function () {
    // makeReady()
    isInitializing = false
})

export const initializeApp = singleton(function* initializeApp(onSuccess = noop) {
    try {
        yield offlineDataStorageReady
        // eslint-disable-next-line no-console
        console.log('getUser')
        if (!window._useToken) {
            yield startTracking()
            yield getStoredUser() // This is the initial
        } else {
            store.set({ profile: null })
            // yield startTracking()
            setItem('token', 'magic')

            setUnprefixedItemWithStringify('currentAuthenticatedUser', {
                id: 'dummy_user',
                accessToken: 'magic',
                name: 'Dummy User',
                email: 'dummy_user@alcumusgroup.com',
                isAnonymous: false,
            })
            store.set({
                demands: ['super-admin', 'authenticated'],
            })
            // eslint-disable-next-line no-console
            yield startTracking()
            let profile = yield getUser('dummy_user')
            // eslint-disable-next-line no-console
            console.log('got user', profile)
            if (profile) store.set({ profile })
            yield events.emitAsync('auth.user-stored', null)
            events.emit('secure-token-ready', null)
            raise('user-is-ready')
        }
        canLoad = true
        // eslint-disable-next-line no-console
        console.log('Load apps')
        yield getUniversal()
        yield loadApps()
        // eslint-disable-next-line no-console
        console.log('Load apps:resetState')
        yield resetState()
        // eslint-disable-next-line no-console
        console.log('Load apps:success')
        if (typeof onSuccess === 'function') {
            yield onSuccess()
        }
        // eslint-disable-next-line no-console
        console.log('Load apps:done')
        raiseOnce('initialized-app')
        raiseOnce('app-start-ready')

        return true
    } catch (e) {
        console.error('INITIALISE APP FAILED', e)
    }
}, false)

handle('roles-updated', async () => {
    await loadRoles()
    await initializeApp()
})

export async function resetState() {
    raise('disable-app', true)
    await raiseAsync('refresh-roles', null)
    await raiseAsync('refresh-profile', null)
    await raiseAsync('refresh-apps', null)
    raise('disable-app', false)
    raiseLater('update-all')
}
