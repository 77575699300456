import React from 'react'
import { useWidgetContext, WidgetContext } from './contexts'
import PropTypes from 'prop-types'
import { globalWidgets } from './events'
import { processRenderFunctions } from './utilities'

let nextId = 101

function Widget({ children, design = {}, type = 'unknown', ...props }) {
    const context = useWidgetContext()
    const widgetContext = {
        ...context,
        ...props,
        design,
        widgets: globalWidgets,
        type,
        _type: 'render',
    }

    return (
        <WidgetContext.Provider value={widgetContext}>
            <Render type={type} widgetContext={widgetContext} embed={children || null} />
        </WidgetContext.Provider>
    )
}

function Render({ type, widgetContext, embed }) {
    let toRender
    widgetContext.content = embed ? [embed] : []
    globalWidgets.emit('prepare-render', null)
    if (Array.isArray(type)) {
        for (let t of type) {
            globalWidgets.emit(`render.${t}`, widgetContext)
        }
    } else {
        globalWidgets.emit(`render.${type}`, widgetContext)
    }
    toRender = processRenderFunctions(widgetContext.content)
    return <List key={toRender.length} toRender={toRender} />
}

const ids = new WeakMap()

function List({ toRender }) {
    return toRender.map((Item) => {
        if (typeof Item === 'function') {
            Item.__id = Item.__id || nextId++
            return <Item key={Item.__id} />
        } else {
            if (!ids.has(Item)) {
                ids.set(Item, nextId++)
            }
            return <Child key={ids.get(Item)} item={Item} />
        }
    })
}

function Child({ item }) {
    return item
}

export default Widget

Widget.propTypes = {
    design: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
    type: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]).isRequired,
}

export { Widget }
