import React, { useEffect, useRef, useState } from 'react'
import { generate } from 'packages/identifiers'

function isAsync(fn, name) {
    if (typeof fn !== 'function') return false
    if (fn.constructor.name === 'AsyncFunction') return true
    if (fn._isAsync) return true
    if (fn._isAsync === false) return false
    return !!(name && !name.startsWith('on') && fn.length === 0)
}

export function withAsyncProps(Component, Fallback) {
    let id = 1
    return (props) => {
        const results = useRef({})
        const total = useRef(0)

        function Embedded() {
            const ongoingProps = { ...props }
            let [, refresh] = useState(0)
            const done = useRef(false)
            const toRemove = useRef([])
            useEffect(() => {
                return () => {
                    refresh = null
                }
            }, [])
            if (done.current === false) {
                done.current = generate()
                let async = Object.entries(props).filter(
                    ([name, value]) => name !== 'refresh' && typeof value === 'function' && isAsync(value, name)
                )
                total.current = total.current || async.length
                toRemove.current = async.map(([name]) => name)
                for (const [name, value] of async) {
                    let promise = value()
                    if (!promise) {
                        console.error('No result', name, Component)
                    } else if (!promise.then) {
                        console.error('Function is not thenable')
                    } else {
                        promise.then(
                            (result) => {
                                total.current = total.current - 1
                                results.current[name] = result
                                refresh && refresh(id++)
                            },
                            (err) => {
                                total.current = total.current - 1
                                results.current[name + 'Error'] = err
                                refresh && refresh(id++)
                            }
                        )
                    }
                }
            }
            for (const remove of toRemove.current) {
                delete ongoingProps[remove]
            }
            if (Fallback !== undefined && total.current > 0) {
                if (!Fallback) return null
                return <Fallback key={done.current} {...ongoingProps} {...results.current} />
            } else {
                return <Component key={done.current} {...ongoingProps} {...results.current} />
            }
        }

        return <Embedded />
    }
}
