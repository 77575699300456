import React from 'react'
import events from 'packages/alcumus-local-events'

import { routes } from 'common/routing'

events.return('get-demands', () => ['edit-profile'])

const AccountMenuLayout = React.lazy(() => import('./account-menu-layout'))
const AccountMenuProfile = React.lazy(() => import('./account-menu-profile'))

routes(() => {
    return [
        {
            path: '/user/profile',
            title: 'Profile',
            secure: false,
            parts: {
                main: AccountMenuLayout,
                content: AccountMenuProfile,
            },
        },
    ]
})
