import React, { Fragment, useContext, useEffect, useRef, useState } from 'react'

import PropTypes from 'prop-types'
import events from 'packages/alcumus-local-events'
import { raw } from 'packages/observable-store'

import omit from 'lodash/omit'
import { MdCheckCircle } from '@react-icons/all-files/md/MdCheckCircle'
import { MdWarning } from '@react-icons/all-files/md/MdWarning'
import { MdError } from '@react-icons/all-files/md/MdError'
import { MdInfo } from '@react-icons/all-files/md/MdInfo'
import { generate } from 'packages/identifiers'

import { Dialog, DialogActions, DialogContent, IconButton, Snackbar, SnackbarContent } from '@material-ui/core'
import { makeStyles, ThemeProvider, useTheme } from '@material-ui/core/styles'

import Button from '@material-ui/core/Button'
import { amber, green } from '@material-ui/core/colors'
import DialogTitle from '@material-ui/core/DialogTitle'
import Slide from '@material-ui/core/Slide'

import { makeTestId } from 'common/component-utilities'

import { store } from './store'
import { Box } from 'common/styled-box'
import { raise, raiseLater, raiseOnce } from 'common/events'
import { useLocalEvent, useStableLocalEvent } from 'common/use-event'
import { useRefreshWhen } from 'common/useRefresh'
import { getDocumentDimensions, getWindowDimensions } from 'common/responsive'
import { getActiveClient } from 'common/global-store/api'
import { MdClose } from '@react-icons/all-files/md/MdClose'
import { makeLazy } from 'common/lazy'
import { useCurrentState } from 'common/use-async'
import { makeCachedStyles } from 'common/inline-styles'
import { theme } from 'common/theme'
import clsx from 'clsx'
import { UISocket } from 'common/widgets'

const GenericFormDialog = makeLazy(() => import('framework/experimental/formik'), 'GenericFormDialog')

const variantIcon = {
    success: MdCheckCircle,
    warning: MdWarning,
    error: MdError,
    info: MdInfo,
}

const useSnackbarStyles = makeCachedStyles((theme) => ({
    success: {
        backgroundColor: green[600],
    },
    error: {
        backgroundColor: theme.palette.error.dark,
    },
    info: {
        backgroundColor: theme.palette.primary.main,
    },
    warning: {
        backgroundColor: amber[700],
    },
    icon: {
        fontSize: '30px',
    },
    iconVariant: {
        opacity: 0.9,
        marginRight: theme.spacing(1),
    },
    message: {
        display: 'flex',
        alignItems: 'center',
    },
    snackBarMessageContainer: {
        minWidth: '220px',
    },
}))

function MySnackbarContentWrapper(props) {
    const classes = useSnackbarStyles()
    const { className, message, variant, ...other } = props
    const Icon = variantIcon[variant]

    return (
        <SnackbarContent
            className={`${classes[variant]} ${className} ${classes.snackBarMessageContainer}`}
            aria-describedby="client-snackbar"
            message={
                <span id="client-snackbar" className={classes.message}>
                    <Icon className={`${classes.icon} ${classes.iconVariant}`} />
                    {message}
                </span>
            }
            action={props.action || []}
            {...other}
        />
    )
}

MySnackbarContentWrapper.propTypes = {
    action: PropTypes.any,
    className: PropTypes.string,
    message: PropTypes.string,
    onClose: PropTypes.func,
    variant: PropTypes.oneOf(['error', 'info', 'success', 'warning']).isRequired,
}

/**
 * Inserted into the main application UI to provide a location to
 * display Modal dialogs
 * @returns {Component}
 */
const useStyles = makeCachedStyles(
    {
        ensureDimensions: {
            '& .MuiPaper-root': {
                width: '100% !important',
                // overflow: 'auto',
                position: 'relative',
            },
            width: (props) => (props.maxWidth ? props.maxWidth : undefined),
            transition: `width 0.3s ease-in-out`,
            transitionDelay: 100,
        },
        modal: {
            position: 'fixed',
            left: 0,
            top: 0,
            width: 0,
            height: 0,
        },
    },
    'maxWidth'
)

const useDialogContainerStyles = makeCachedStyles(
    {
        '@global': {
            '[class*=MuiDialog-root]': {
                transition: `width 0.3s ease-in-out`,
                transitionDelay: 100,
                width: (props) =>
                    props.maxWidth && props.maxWidth < Infinity
                        ? `${props.maxWidth}px`
                        : getWindowDimensions().innerWidth,
            },
            '[class*=MuiDialog-paper]': {
                transition: `width 0.3s ease-in-out`,
                transitionDelay: 100,
                width: (props) =>
                    props.maxWidth && props.maxWidth < Infinity
                        ? `${props.maxWidth}px`
                        : getWindowDimensions().innerWidth,
            },
            '[class*=MuiPickersBasePicker-pickerView]': {
                justifyItems: 'center',
                width: '100%',
                maxWidth: 'inherit',
                alignItems: 'stretch',
            },
            '[class*=MuiDialog-container]': {
                width: '100%',
                // transition: `width 0.3s ease-in-out`,
                // transitionDelay: 100,
                // width: (props) => {
                //     return props.maxWidth && props.maxWidth < Infinity ? `${props.maxWidth}px` : getWindowDimensions().innerWidth
                // },
            },
        },
        container: {
            transition: `width 0.3s ease-in-out`,
            transitionDelay: 100,
            width: (props) =>
                props.maxWidth && props.maxWidth < Infinity ? `${props.maxWidth}px` : getWindowDimensions().innerWidth,
        },
    },
    'maxWidth'
)

const ModalContext = React.createContext({})
let currentModal

export function displayModal(Component, initialProps) {
    const add = currentModal
    if (Component) {
        return function (props) {
            return new Promise((resolve) => {
                add({ Component, props: { ...initialProps, ...props }, resolve, id: generate() })
            })
        }
    }
    let result = function (Component, props) {
        return new Promise((resolve) => {
            add({ Component, props: { ...initialProps, ...props }, resolve, id: generate() })
        })
    }
    result.dialog = async function (title, descriptors = [], state = {}, options = {}, dataTestId) {
        dataTestId = dataTestId || makeTestId(title)
        return await result(
            ({ ok, cancel }) => (
                <GenericFormDialog
                    initialState={state}
                    descriptors={descriptors}
                    onOk={ok}
                    onCancel={cancel}
                    title={title}
                    submitLabel={options.submitLabel || 'Save'}
                    dataTestId={dataTestId}
                />
            ),
            { maxWidth: options.maxWidth || 'md' }
        )
    }

    return result
}

const useDialogStyles = makeCachedStyles(
    () => {
        return {
            dialog: {
                '& [role=dialog]': {
                    transform: (props) => `translate3d(${props._offset_left}, ${props._offset_top}, 0)`,
                },
            },
        }
    },
    '_offset_left',
    '_offset_top'
)

export function DialogTheme({ theme, children }) {
    useEffect(() => {
        const id = generate()
        ;({ theme } = raise('custom-theme', { theme }))
        raise('set-theme', theme, id)
        return () => {
            raise('unset-theme', id)
        }
    }, [])
    return children
}

const useThemeStyles = makeCachedStyles(
    () => {
        return {
            '@global': {
                '.mbsc-err-msg': {
                    fontFamily: (props) => props.document?.form?.fontFamily || props.document?.fontFamily,
                    fontSize: (props) => ((props.document?.form?.fontSize || props.document?.fontSize || 16) * 12) / 16,
                },
                '.awe-document-base': {
                    '& .mbsc-input input, & .mbsc-input select, & .mbsc-input textarea': {
                        fontFamily: (props) => props.document?.form?.fontFamily || props.document?.fontFamily,
                        fontSize: (props) => props.document?.form?.fontSize || props.document?.fontSize,
                    },
                    fontFamily: (props) => props.document?.fontFamily,
                    fontSize: (props) => props.document?.fontSize,
                    '& .mobi-child': {
                        fontFamily: (props) => props.document?.child?.fontFamily || props.document?.fontFamily,
                        fontSize: (props) => props.document?.child?.fontSize || props.document?.fontSize,
                    },
                    '& .MuiInputBase-root input,  & .MuiInputBase-input input': {
                        fontFamily: (props) =>
                            (props.document?.form?.fontFamily || props.document?.fontFamily) &&
                            `${props.document?.form?.fontFamily || props.document?.fontFamily} !important`,
                        fontSize: (props) =>
                            (props.document?.form?.fontSize || props.document?.fontSize) &&
                            `${props.document?.form?.fontSize || props.document?.fontSize}px !important`,
                    },
                    '& .mbsc-form, .mbsc-control-w, .mbsc-btn, .mbsc-segmented': {
                        fontFamily: (props) =>
                            (props.document?.form?.fontFamily || props.document?.fontFamily) &&
                            `${props.document?.form?.fontFamily || props.document?.fontFamily} !important`,
                        fontSize: (props) =>
                            (props.document?.form?.fontSize || props.document?.fontSize) &&
                            `${props.document?.form?.fontSize || props.document?.fontSize}px !important`,
                    },
                },
            },
        }
    },
    'document.child.fontFamily',
    'document.child.fontSize',
    'document.form.fontFamily',
    'document.fontFamily',
    'document.form.fontSize',
    'document.fontSize'
)

export function DialogThemer({ children, defaultTheme }) {
    const [currentTheme, setTheme, getTheme] = useCurrentState({ theme: defaultTheme, id: Date.now() })
    useStableLocalEvent('set-theme', function (theme, id) {
        setTheme((props) => {
            if (props.id === id) return props
            return { theme, id }
        })
    })
    useStableLocalEvent('unset-theme', function (id) {
        if (getTheme()?.id === id) {
            setTheme({ theme: defaultTheme, id: Date.now() })
        }
    })

    const { theme: usedTheme } = raise('custom-theme', { theme: currentTheme.theme })
    useThemeStyles(usedTheme)
    return currentTheme && getActiveClient() !== 'test' ? (
        <ThemeProvider theme={usedTheme || theme}>{children}</ThemeProvider>
    ) : (
        children
    )
}

export function ModalPlaceholder({ children }) {
    const classes = useStyles()
    const [list, updateList] = useCurrentState([])
    currentModal = add
    return (
        <ModalContext.Provider value={{ updateList, list, add }}>
            <Box className={`${classes.modal} `}>
                {list.map((dialog) =>
                    dialog.props.theme ? (
                        <ThemeProvider theme={dialog.props.theme}>
                            <Inner key={dialog.id} dialog={dialog} removeDialog={removeDialog} />
                        </ThemeProvider>
                    ) : (
                        <Inner key={dialog.id} dialog={dialog} removeDialog={removeDialog} />
                    )
                )}
            </Box>
            {children}
        </ModalContext.Provider>
    )

    function removeDialog(dialog) {
        updateList(list.filter((f) => f !== dialog))
    }

    function add(modal) {
        updateList([...list, modal])
        return () => {
            updateList((list) => list.filter((i) => i !== modal))
        }
    }
}

const Inner = React.memo(
    function Inner({ dialog, removeDialog }) {
        const classes = useStyles()
        let props = {}
        const showAsFullscreen = getWindowDimensions().innerWidth < 760
        for (const [name, value] of Object.entries(dialog.props)) {
            if (typeof value !== 'function' && name.toLowerCase() === name) {
                props[name] = value
            }
        }
        const dialogClasses = useDialogStyles(props)
        return (
            <Dialog
                fullScreen={showAsFullscreen}
                TransitionComponent={dialog.props.slide || showAsFullscreen ? Transition : undefined}
                {...props}
                key={dialog.id}
                open={!closed[dialog.id]}
                className={`${classes.ensureDimensions} ${!showAsFullscreen && dialogClasses.dialog}`}
                fullWidth={true}
                maxWidth={dialog.props.maxWidth || 'md'}
            >
                <dialog.Component
                    ok={(results) => {
                        dialog.resolve(results !== undefined ? results : true)
                        removeDialog(dialog)
                    }}
                    {...omit(dialog.props, 'ok', 'cancel')}
                    cancel={() => {
                        dialog.resolve(false)
                        removeDialog(dialog)
                    }}
                />
            </Dialog>
        )
    },
    (a, b) => a.dialog?.id === b.dialog?.id
)

export function useModal(Component, initialProps = {}) {
    const theme = useTheme()
    const { add } = useContext(ModalContext)
    if (Component) {
        if (typeof Component === 'function') {
            return function (props) {
                let resolver
                let promise = new Promise((resolve) => {
                    resolver = add({
                        Component: (props) => (
                            <ThemeProvider theme={theme}>
                                <Component {...props} />
                            </ThemeProvider>
                        ),
                        props: { ...initialProps, ...props },
                        resolve,
                        id: generate(),
                    })
                })
                promise.resolve = resolver
                return promise
            }
        } else {
            const Item = Component.type
            const itemProps = Component.props
            return function (props) {
                let resolver
                let promise = new Promise((resolve) => {
                    resolver = add({
                        Component: (props) => (
                            <ThemeProvider theme={theme}>
                                <Item {...props} {...itemProps} />
                            </ThemeProvider>
                        ),
                        props: { ...initialProps, ...props },
                        resolve,
                        id: generate(),
                    })
                    return resolve
                })
                promise.resolve = resolver
                return promise
            }
        }
    }
    let result = function (Component, props) {
        let resolver
        let promise = new Promise((resolve) => {
            resolver = add({
                Component: (props) => (
                    <ThemeProvider theme={theme}>
                        <Component {...props} />
                    </ThemeProvider>
                ),
                props: { ...initialProps, ...props },
                resolve,
                id: generate(),
            })
        })
        promise.resolve = resolver
        return promise
    }
    result.dialog = async function (title, descriptors = [], state = {}, options = {}, dataTestId) {
        dataTestId = dataTestId || makeTestId(title)
        return await result(
            ({ ok, cancel }) => (
                <GenericFormDialog
                    initialState={state}
                    descriptors={descriptors}
                    onOk={ok}
                    onCancel={cancel}
                    title={title}
                    submitLabel={options.submitLabel || 'Save'}
                    dataTestId={dataTestId}
                />
            ),
            { maxWidth: options.maxWidth || 'md' }
        )
    }

    return result
}

// This is used to work out where to centre things, like modals
// and action sheets
export function ModalCenter() {
    const left = useRef()
    const ref = useRef()
    useLocalEvent('get-middle', (info) => {
        left.current = left.current ?? ref.current.getBoundingClientRect().left
        info.middle = Math.min(info.middle, left.current)
    })
    useEffect(() => {
        raise('recalculate-middle')
        return () => {
            raiseOnce('recalculate-middle')
        }
    }, [])
    return (
        <Box
            ref={ref}
            style={{
                flexGrow: 0,
                flexShrink: 0,
                maxHeight: 2,
                position: 'absolute',
                overflow: 'visible',
                left: '50%',
                width: 0,
                background: 'red',
                zIndex: 10000,
                height: 0,
            }}
        />
    )
}

const useMiddleStyles = makeStyles(() => {
    return {
        middle: {
            position: 'absolute',
            bottom: 0,
            left: (props) => props?.middle ?? getWindowDimensions().innerWidth / 2,
            overflow: 'visible',
            transform: `translateX(-50%)`,
            zIndex: (props) => props?.zIndex ?? 200,
        },
        leftOffset: {
            left: () => getDocumentDimensions().x + getDocumentDimensions().documentWidth * 0.5,
            zIndex: (props) => props?.zIndex ?? 200,
        },
    }
}, ['window.innerWidth', 'zIndex', 'middle'])

export function Middle({ children, zIndex, keepCenterToDocument }) {
    useRefreshWhen('recalculate-middle')
    let { middle } = raise('get-middle', { middle: Infinity })

    const classes = keepCenterToDocument
        ? useMiddleStyles({ middleClass: 0, zIndex })
        : useMiddleStyles({ middleClass: middle > 100000 ? getWindowDimensions().innerWidth : middle, zIndex })

    return <Box className={clsx(classes.middle, 'middle', keepCenterToDocument && classes.leftOffset)}>{children}</Box>
}

const snackPositionStyles = makeCachedStyles({
    topPosition: {
        top: '-88%',
    },
    bottomPosition: {
        top: 'inherit',
    },
})

export function ModalHolder() {
    const container = useContext(ModalContainerContext)
    const refresh = useRefreshWhen('recalculate-middle')
    const [closed, setClosed] = useState({})
    let dialogs = store.$stack
    let snacks = store.$snacks || []
    let { middle } = raise('get-middle', { middle: getWindowDimensions().innerWidth / 2 })
    if (!container || !container.current) {
        setTimeout(refresh)
    }
    const { ensureDimensions } = useStyles()
    const classesPosition = snackPositionStyles(middle)
    useDialogContainerStyles({ maxWidth: middle * 2 })
    const showAsFullscreen = getWindowDimensions().innerWidth < 760
    return (
        <Box>
            {dialogs.map((dialog) => {
                let props = {}
                for (const [name, value] of Object.entries(dialog.props)) {
                    if (typeof value !== 'function' && name.toLowerCase() === name) {
                        props[name] = value
                    }
                }
                return dialog.props.theme ? (
                    <ThemeProvider key={dialog.id} theme={dialog.props.theme}>
                        <Dialog
                            fullScreen={showAsFullscreen}
                            TransitionComponent={dialog.props.slide || showAsFullscreen ? Transition : undefined}
                            PaperProps={dialog.props?.PaperProps}
                            {...props}
                            open={!closed[dialog.id]}
                            className={`${ensureDimensions} ${dialog.props?.className}`}
                            maxWidth={dialog.props.maxWidth || 'sm'}
                        >
                            <dialog.Component
                                ok={(results) => {
                                    dialog.resolve(results !== undefined ? results : true)
                                    removeDialog(dialog)
                                }}
                                {...omit(dialog.props, 'ok', 'cancel')}
                                cancel={() => {
                                    dialog.resolve(false)
                                    removeDialog(dialog)
                                }}
                            />
                        </Dialog>
                    </ThemeProvider>
                ) : (
                    <Dialog
                        fullScreen={showAsFullscreen}
                        TransitionComponent={dialog.props.slide || showAsFullscreen ? Transition : undefined}
                        PaperProps={dialog.props?.PaperProps}
                        {...props}
                        key={dialog.id}
                        open={!closed[dialog.id]}
                        className={`${ensureDimensions} ${dialog.props?.className}`}
                        maxWidth={dialog.props.maxWidth || 'sm'}
                    >
                        <dialog.Component
                            ok={(results) => {
                                dialog.resolve(results !== undefined ? results : true)
                                removeDialog(dialog)
                            }}
                            {...omit(dialog.props, 'ok', 'cancel')}
                            cancel={() => {
                                dialog.resolve(false)
                                removeDialog(dialog)
                            }}
                        />
                    </Dialog>
                )
            })}
            {snacks.map(({ 'data-testid': dataTestId, ...snack }) => {
                return (
                    <Snackbar
                        key={snack.lid}
                        open={!closed[snack.lid]}
                        className={
                            snack.position === 'top'
                                ? `${classesPosition.topPosition}`
                                : `${classesPosition.bottomPosition}`
                        }
                        autoHideDuration={snack.dismissTime || 5000}
                        data-testid={dataTestId || 'notification-popup-snack'}
                        onClose={() => {
                            const currentSnacks = [...store.snacks$]
                            const lastSnack = currentSnacks[currentSnacks.length - 1]
                            removeSnack(lastSnack)
                        }}
                    >
                        <MySnackbarContentWrapper
                            variant={
                                snack.variant
                                    ? snack.variant
                                    : snack.message.match(/error/gi)
                                    ? 'error'
                                    : snack.message.match(/warning/gi)
                                    ? 'warning'
                                    : 'info'
                            }
                            message={snack.message}
                            action={(snack.action || []).concat([
                                <IconButton key="close" onClick={() => removeSnack(snack)}>
                                    <MdClose />
                                </IconButton>,
                            ])}
                            {...(snack.options || {})}
                        />
                    </Snackbar>
                )
            })}
            <UISocket type="modal.top" />
        </Box>
    )

    function removeDialog(dialog) {
        setClosed({ ...closed, [dialog.id]: true })
        setTimeout(() => {
            store.set({ stack: dialogs.filter((d) => d !== dialog) })
        }, 5)
    }

    function removeSnack(snack) {
        if (snack) {
            setClosed({
                ...closed,
                [snack.lid]: true,
            })
            store.set({
                snacks: [...store.snacks$].filter((f) => f.lid !== snack.lid),
            })
        }
    }
}

/**
 * Adds a modal component to the display queue, the
 * component will be shown as a popup and will be
 * passed properties for "ok" and "cancel" that dismiss the
 * displayed box.  The ok method can be passed a value to return.
 * @param {Function} Component - the component to be displayed
 * @param {Object} props - the properties to pass to the created component
 * @returns {Promise<any>} A promise for the returned value or null if cancelled
 * @example
 * async function alert(message, title, caption = 'OK') {
    return await showModal(({ok}) => (
        <>
            {!!title && <ModalHeader>{title}</ModalHeader>}
            <ModalBody>{message}</ModalBody>
            <ModalFooter>
                <Button color="primary" onClick={() => ok()}>
                    {caption}
                </Button>
            </ModalFooter>
        </>
    ));
}
 */
export function showModal(Component, props = {}) {
    return new Promise(function (resolve) {
        setTimeout(() => {
            store.set({
                stack: [...store.stack$, raw({ Component, props, resolve, id: generate() })],
            })
        })
    })
}

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />
})

/**
 * Display an alert to the user
 * @param {String} message - the message to display
 * @param {String} title - the title of the message
 * @param {String} caption - the caption of the alert close button
 * @returns {Promise<any>} resolved when the box is closed
 */
export async function alert(message, title, caption = 'OK') {
    return await showModal(({ ok }) => (
        <Fragment>
            {!!title && <DialogTitle disableTypography>{title}</DialogTitle>}
            <DialogContent>{message}</DialogContent>
            <DialogActions>
                <Button color="primary" data-testid="alertOk" onClick={() => ok()}>
                    {caption}
                </Button>
            </DialogActions>
        </Fragment>
    ))
}

/**
 * Display a confirmation box to the user
 * @param {String} message - the message to display
 * @param {String} title - the title of the confirmation box
 * @param {String} okMessage - the text to be placed on the Confirm Ok button - defaults to OK
 * @param {String} cancelMessage - the text to be place on the Cancel button - defaults to Cancel
 * @returns {Promise<Boolean>} - a promise for the true or false value of the user's boundInput
 */
export async function confirm(
    message,
    title,
    okMessage = 'Ok',
    cancelMessage = 'Cancel',
    toggleOrder = false,
    config = { okStyle: {}, cancelStyle: {} }
) {
    return await showModal(({ ok, cancel }) => {
        const ConfirmButton = () => (
            <Button
                variant="contained"
                color="primary"
                data-testid="confirmOk"
                onClick={() => ok(true)}
                style={config.okStyle}
            >
                {okMessage}
            </Button>
        )
        const CancelButton = () => (
            <Button data-testid="confirmCancel" onClick={() => cancel()} style={config.cancelStyle}>
                {cancelMessage}
            </Button>
        )

        return (
            <Fragment>
                {!!title && <DialogTitle disableTypography>{title}</DialogTitle>}
                <DialogContent>{message}</DialogContent>
                <Box p={1}>
                    <DialogActions>
                        {!toggleOrder ? (
                            <>
                                <CancelButton />
                                <ConfirmButton />
                            </>
                        ) : (
                            <>
                                <ConfirmButton />
                                <CancelButton />
                            </>
                        )}
                    </DialogActions>
                </Box>
            </Fragment>
        )
    })
}

window.alert = alert
window.confirm = confirm

export async function dialog(title, descriptors = [], state = {}, options = {}, dataTestId) {
    dataTestId = dataTestId || makeTestId(title)
    return await showModal(
        ({ ok, cancel }) => (
            <GenericFormDialog
                {...options}
                initialState={state}
                descriptors={descriptors}
                onOk={ok}
                onCancel={cancel}
                title={title}
                submitLabel={options.submitLabel || 'Save'}
                dataTestId={dataTestId}
            />
        ),
        { ...options, maxWidth: options.maxWidth || 'md' }
    )
}

events.on('show-notification', function (__event, notification) {
    if (notification.message && notification.message.length > 0) {
        notification.lid = generate()
        if (!notification.title) {
            store.set({ snacks: [...(store.snacks$ || []), raw(notification)] });
        } else {
            alert(notification.message, notification.title, notification.caption)
        }
    }
})

export function showNotification(notification) {
    if (typeof notification === 'string') {
        notification = { message: notification }
    }
    raiseLater('show-notification', notification)
}

export function showNotificationWithType(notification, type) {
    if (typeof notification === 'string') {
        notification = { message: notification, variant : type }
    }
    raiseLater('show-notification', notification)
}

export function showNotificationAction(notification, action) {
    if (typeof notification === 'string') {
        notification = { message: notification, action: action ? [action] : [] }
    }
    raiseLater('show-notification', notification)
}

export function removeNotification(message) {
        store.set({
            snacks: (store.snacks$ || []).filter(snack => snack.message !== message)
        });
}

export const ModalContainerContext = React.createContext(null)
