import { ObservableStore, tracked } from 'packages/observable-store'
import { ensureArray } from 'common/ensure-array'
import { useRefresh } from 'common/useRefresh'
import { getItemAndParse, setUnprefixedItemWithStringify } from 'common/using-local-storage-key'
import { isDebugger } from 'common/debugger'
import { REPORT_TYPES } from 'plugins/client-portal-plugin/reporting/report-types.js'

export const store = new ObservableStore()
const initialState = {
    user: null,
    currentClientTab: 0,
    profile: getItemAndParse('stored-profile') || {},
    available: 0,
    currentClientName: '',
    currentReportFilter: REPORT_TYPES.ALL,
    identityId: '',
    inProgress: {},
    brandX: 0,
    brandY: 0,
    brandZoom: 1,
    brandColor: '',
    options: {},
    metadata: {},
    editorOverride: null,
    maximised: null,
    tabWrapper: null,
}
store.set(initialState)
store.get('profile').on((v) => {
    if (Object.isObject(v)) {
        if (!isDebugger()) {
            setUnprefixedItemWithStringify('stored-profile', v)
            setUnprefixedItemWithStringify('debugger-stored-profile', v)
        }
    }
})
export { tracked }
export function GlobalStore({ variable, children }) {
    const refresh = useRefresh().debounce(5)
    const values = []
    for (let item of ensureArray(variable)) {
        let storedItem = store.get(item)
        storedItem.useChange(refresh)
        values.push(storedItem.value)
    }
    return children(...values)
}
