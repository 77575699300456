import { Avatar } from '@material-ui/core'

import classNames from 'classnames'
import { Box } from 'common/styled-box'
import PropTypes from 'prop-types'
import React, { useState } from 'react'
import events from 'packages/alcumus-local-events'

import { setItem } from 'common/using-local-storage-key'
import { FaUserClock } from '@react-icons/all-files/fa/FaUserClock'

import { plug } from 'common/widgets'
import { handle, raise, raiseOnce } from 'common/events'
import { parse } from 'query-string'
import { useLocalEvent } from 'common/use-event'
import { useRefresh } from 'common/useRefresh'
import { makeLazy } from 'common/lazy'
import { getLocation } from 'common/routing'

const DebugPanel = makeLazy(() => import('./renderer'))

handle('initialized-app', () => {
    const { location } = getLocation()
    const params = parse(location.search)
    if (params.debug_document) {
        let eventData = { documentId: params.debug_document }
        console.info('raised debug-document', eventData)

        raiseOnce('debug-document', eventData)
    }
})

events.on('get-menu-items', function (event, items) {
    if (window.DEBUG && window.DEBUG.VISIBLE) {
        items['processMetrics'] = {
            priority: 9001,
            title: 'Process Metrics',
            id: 'processMetrics',
            aboveTheLine: true,
            bottom: true,
            icon: <FaUserClock />,
            isActive() {
                return false
            },
            // eslint-disable-next-line react/prop-types
            Component: DebugIcon,
        }
    }
})

function DebugIcon({ item, classes, selected }) {
    return (
        <Box>
            <Avatar
                onClick={toggleDebugPanel}
                data-testid={`menu-belowBottom-item-${item.id || item.title}`}
                aria-label={item.title}
                className={classNames(classes.menuCircle, classes.belowItem, {
                    selected: item === selected,
                })}
            >
                {item.icon}
            </Avatar>
        </Box>
    )

    function toggleDebugPanel() {
        raise('debug-open', {})
    }
}

let isOpen = false

function OuterDebugPanel() {
    const refresh = useRefresh()
    const [openDocument, setOpenDocument] = useState()
    useLocalEvent('debug-open', () => {
        isOpen = !isOpen
        setOpenDocument('')
        refresh()
    })
    useLocalEvent('debug-document', (params) => {
        console.info('handled debug-document', params)
        setItem('currentDebugTab', 0)
        setOpenDocument(params.documentId)
        refresh()
    })

    if (!isOpen) {
        return null
    }
    return <DebugPanel document={openDocument} />
}

plug('main-after', function () {
    if (window.DEBUG && window.DEBUG.VISIBLE) {
        return <OuterDebugPanel />
    }
    return null
})

DebugPanel.propTypes = {
    classes: PropTypes.any,
    hide: PropTypes.any,
    item: PropTypes.any,
    selected: PropTypes.any,
}
