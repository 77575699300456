import React from 'react'

import { Chip } from '@material-ui/core'

import { refreshIfNeeded } from 'common/component-utilities'
import { resolveValue } from 'common/resolve-value'
import { ONLINE } from 'common/globals'

export function hasQuestionText(question) {
    return question?.question && question.question?.trim() !== '<p></p>'
}

let div

export function toText(html) {
    div = div || document.createElement('div')
    div.innerHTML = html
    return div.innerText.trim()
}

export function returns(fn) {
    if (typeof fn !== 'function') {
        return Object.keys(fn).reduce((c, a) => {
            c[a] = returns(fn[a])
            return c
        }, {})
    }
    return function (array, ...params) {
        let value = fn.call(this, ...params)
        if (Array.isArray(value)) {
            array.push(...value)
        } else {
            if (value) array.push(value)
        }
    }
}

export function decorate(target, withProps) {
    if (!Object.isObject(target)) {
        return
    }
    for (let key of Object.keys(withProps)) {
        Object.defineProperty(target, key, {
            get() {
                return withProps[key]
            },
            configurable: true,
        })
    }
    return target
}

export function idOptions(options, multiple, label = 'name', id = 'id') {
    if (multiple) {
        return {
            multiple: true,
            options,
            onChanged: doRefresh,
            getOptionLabel(v) {
                if (typeof label === 'function') {
                    return label(v)
                } else if (label) {
                    return v[label]
                } else {
                    return v
                }
            },
            transformIn(v) {
                return v.map((v) => options.find((o) => o[id] === v))
            },
            transformOut(v) {
                return v.map(id)
            },
            renderTags(value, getTagProps) {
                return value.map((option, index) => (
                    <Chip
                        key={index}
                        onClick={doRefresh}
                        label={resolveValue(option.label, option)}
                        {...getTagProps({ index })}
                    />
                ))
            },
        }
    }
    return {
        options,
        getOptionLabel(v) {
            if (typeof label === 'function') {
                return label(v)
            } else if (label) {
                return v[label]
            } else {
                return v
            }
        },
        transformIn(v) {
            return options.find((o) => o[id] === v)
        },
        transformOut(v) {
            return v[id]
        },
    }

    function doRefresh() {
        refreshIfNeeded()
    }
}

/**
 * Retrieve online state from Navigator, or override for testing
 *
 * @returns {boolean} Either isOnline() or a fake value for testing
 * document
 */

export function isOnline() {
    return ONLINE.isEnabled()
}
