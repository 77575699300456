import { LinearProgress } from '@material-ui/core'
import noop from 'common/noop'
import React, { useContext, useEffect, useRef, useState } from 'react'
import { defer } from 'common/deferred'
import { handle, raiseLater } from 'common/events'
import { useStableLocalEvent } from 'common/use-event'
import { useWaitUntilDrawn } from 'common/use-async'
import Box from 'common/styled-box'

export const AsyncWaitContext = React.createContext({
    count: 0,
    time: noop,
    increment: noop,
    decrement: noop,
    debounce: 200,
})
let runningLoaders = 0
const LOADER_STYLE = { width: '100%' }
const FLEX_STYLE = { flexGrow: 1 }
const HOLDER_STYLE = {
    alignItems: 'center',
    width: '100%',
    display: 'none',
    textAlign: 'center',
}

handle('app.navigate', () => {
    setTimeout(() => {
        runningLoaders = 0
        raiseLater('running-changed')
    }, 10)
})

export function LoaderUntilAsyncComplete({
    children,
    newContext = false,
    loadingContent = <LinearProgress />,
    restart: masterRestart = false,
    restartParent = false,
    debounce = 50,
    name = 'n/a',
    onComplete = noop,
    ...props
}) {
    const context = useContext(AsyncWaitContext)
    const raw = useRef(true)
    const active = useRef(defer())
    const loader = useRef()
    const provider = useRef({ count: 1, increment, decrement, time })
    const block = useRef()

    useWaitUntilDrawn()
    useState(() => {
        runningLoaders++
        makeVisible()
        context.increment(false, `Creating loader ${name}`)
    })
    const incremented = useRef(true)
    useEffect(() => {
        return () => {
            if (incremented.current) {
                runningLoaders = Math.max(0, runningLoaders - 1)
                raiseLater('running-changed')
                incremented.current = false
                context.decrement(`Loader ${name} has been mounted`)
            }
        }
    }, [])

    useStableLocalEvent('app.navigate', () => {
        raw.current = true
        provider.current.count = 0
        decrement()
    })
    useEffect(() => {
        decrement(`Loader ${name} is mounted`)
    }, [])
    return (
        <AsyncWaitContext.Provider value={provider.current}>
            <Box {...props} className="awe-document-loader" ref={loader} style={HOLDER_STYLE}>
                <div style={FLEX_STYLE}>{loadingContent}</div>
            </Box>
            <div ref={block} style={LOADER_STYLE}>
                {children}
            </div>
        </AsyncWaitContext.Provider>
    )

    function makeVisible() {
        if (loader.current) {
            loader.current.style.display = 'flex'
        }
        if (block.current) {
            // block.current.style.height = 0
            block.current.style.opacity = 0
        }
    }

    function increment(restart, __because = 'n/a') {
        raw.current = false
        restart = restart || masterRestart
        provider.current.count++
        if (!incremented.current && !newContext) {
            incremented.current = true
            runningLoaders++
            context.increment(restartParent, `Incrementing loader ${name}`)
        }
        if (block.current && restart) {
            active.current = defer()
            // block.current.style.height = 0
            block.current.style.opacity = 0

            makeVisible()
        }
        if (provider.current.timer) {
            clearTimeout(provider.current.timer)
        }
        provider.current.timer = 0
    }

    function decrement(__because = 'n/a') {
        provider.current.count = Math.max(0, provider.current.count - 1)
        if (provider.current.timer) {
            clearTimeout(provider.current.timer)
        }
        provider.current.timer = 0
        if (provider.current.count === 0) {
            if (raw.current) {
                zero()
            } else {
                provider.current.timer = setTimeout(zero, debounce)
            }
        }
    }

    function time(name) {
        let now = Date.now()
        return function () {
            // eslint-disable-next-line no-console
            console.log('LOG>>', name, ((Date.now() - now) / 1000).toFixed(2) + ' ms')
        }
    }

    function zero() {
        active.current.resolve()
        if (incremented.current) {
            runningLoaders = Math.max(0, runningLoaders - 1)
            raiseLater('running-changed')
            incremented.current = false
            context.decrement(`Loader ${name} is finished`)
        }
        if (block.current) {
            block.current.style.height = '100%'
            block.current.style.opacity = 1
            if (loader.current && loader.current.style) {
                loader.current.style.display = 'none'
            }
        }
        onComplete()
    }
}

let navigated = new Promise((resolve) => setTimeout(resolve, 500))

export async function loaderFinished() {
    await navigated
    if (!runningLoaders) return true
    return new Promise((resolve) => {
        let remove = handle('running-changed', function () {
            if (runningLoaders === 0) {
                resolve()
                remove()
            }
        })
        if (!runningLoaders) {
            remove()
            resolve()
        }
    })
}

handle('app.navigate', function () {
    navigated = new Promise((resolve) => {
        setTimeout(resolve, 500)
    })
})
