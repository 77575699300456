// @flow
import './polyfill'
import 'common/tokens-and-eventsource'
import 'common/request'
import 'common/local-storage/local-forage-store'
import './sugar-init'
import { asyncWithLDProvider } from 'launchdarkly-react-client-sdk'
import * as serviceWorker from './serviceWorker'
import './index.scss'
import '@mobiscroll/react/dist/css/mobiscroll.scss'

// import 'common/tag-manager' // Disabling this as it causes app load issue.
import 'framework/remote-events'

import React from 'react'
import ReactDOM from 'react-dom'
import 'common/offline-data-service'
import queryString from 'query-string'

//Security token trapping for fetch and EventSource
import * as Sentry from '@sentry/browser'
import { startTracking } from 'common/remote-start'
import 'common/offline-data-service/worker-helper'
import ResizeObserver from 'resize-observer-polyfill'
import { getDemands } from 'framework/account-menu'
import { useInternalEngine } from 'js-coroutines'
import './offline-documents'
import App from './AppRoot'
import { parameter as queryParameter, parameter } from 'common/query-params'
import useAsync from 'common/use-async'
import { raise, raiseAsync } from 'common/events'
import { defer } from 'common/deferred'
import { OfflineFirstRetriever } from 'common/offline-data-service/offlineFirstRetriever'
import { clean } from 'common/offline-data-service/utils'
import { StylesProvider } from '@material-ui/core/styles'
import { jss } from 'common/jss'

document.body.style.zoom = parameter('zoom') || undefined
document.body.style.background = parameter('background') || undefined

getDemands('debugmode')
// Configure js-coroutines to use it's internal engine
// eslint-disable-next-line react-hooks/rules-of-hooks
useInternalEngine(false)

// DEBUG Tools
window.DEBUG_CALLS = window.DEBUG_CALLS || process.env.REACT_APP_DEBUG_CALLS === 'on'
window.SHOW_METRICS = window.SHOW_METRICS || process.env.REACT_APP_SHOW_METRICS === 'on'
window.SHOW_DEBUG = window.SHOW_DEBUG || process.env.REACT_APP_SHOW_DEBUG === 'on'
window.FAKE_NAVIGATOR_ONLINE = window.FAKE_NAVIGATOR_ONLINE || process.env.REACT_APP_FAKE_NAVIGATOR_ONLINE

window.ResizeObserver = ResizeObserver

let { app_env_url = process.env.REACT_APP_ENV_URL } = queryString.parse(window.location.search)
//Setup standard variables usable everywhere
window.mode = 'live'
window.app_env_url = app_env_url
window.RELEASE_VERSION = process.env.RELEASE_VERSION

const sentryInitHostExclusions = process.env.SENTRY_INIT_HOST_EXCLUSIONS
    ? JSON.parse(process.env.SENTRY_INIT_HOST_EXCLUSIONS)
    : ['127.0.0.1', '192.168', '10.0.', 'localhost']

// setRemoteDataUrl('/api')
// window.LOG_LEVEL = 'DEBUG' // Cognito logging variable
if (!sentryInitHostExclusions.find((host) => location.href.includes(host)) && process.env.NODE_ENV !== 'development') {
    Sentry.init({
        release: process.env.RELEASE_VERSION,
        dsn: 'https://2cc4a7a9f89c4cb69cad5fae006558c4@sentry.io/5187295',
        ignoreErrors: ['ResizeObserver loop limit exceeded'], //https://forum.sentry.io/t/resizeobserver-loop-limit-exceeded/8402
    })
}

export const starting = defer()

function Wrapper() {
    const go = useAsync(async () => {
        try {
            if (queryParameter('dropAll')) {
                await clean()
                await raiseAsync('clear-forage')
                OfflineFirstRetriever.resetInstance()
                await raiseAsync('clear-db-cache')
                await raiseAsync('writing.data')
                await raiseAsync('clear-async')
            }
            await raiseAsync('initialize-app')
            if (!window.TRACKING) {
                window.TRACKING = true
                startTracking().catch(console.error)
            }
            return true
        } finally {
            raise('app-ready')
            starting.resolve()
        }
    }, false)

    return <StylesProvider jss={jss}>{go && <App />}</StylesProvider>
}

;(async () => {
    const LDProvider = await asyncWithLDProvider({
        clientSideID: process.env.REACT_APP_LAUNCHDARKLY_CLIENT_ID,
        reactOptions: {
            useCamelCaseFlagKeys: false,
        },
    })
    ReactDOM.render(
        <LDProvider>
            <Wrapper />
        </LDProvider>, // __flow_ignore
        document.getElementById('root')
    )
})()

if (
    process.env.NODE_ENV === 'production' &&
    (process.env.ENABLE_SERVICE_WORKER === 'true' || parameter('serviceWorker'))
) {
    // eslint-disable-next-line no-console
    console.log('Register service worker')
    serviceWorker.register()
} else {
    serviceWorker.unregister()
}
export { useClients } from 'common/clients'
export { jss } from 'common/jss'
export { InlinePopper } from 'common/inline-popper'
