import React, { useRef, useState } from 'react'

import PropTypes from 'prop-types'

import events from 'packages/alcumus-local-events'
import { raw } from 'packages/observable-store'

import clsx from 'clsx'
import debounce from 'lodash/debounce'
import keyBy from 'lodash/keyBy'
import map from 'lodash/map'
import uniqBy from 'lodash/uniqBy'
import { MdArrowDropDown } from '@react-icons/all-files/md/MdArrowDropDown'
import { MdMenu as MenuIcon } from '@react-icons/all-files/md/MdMenu'

import { inPriorityOrder } from 'packages/al-react-ioc-widgets'
import { Avatar, Tooltip } from '@material-ui/core'
import IconButton from '@material-ui/core/IconButton'
import { capitalize } from '@material-ui/core/utils'

import { makeInitials } from 'common/make-initials'
import { menu } from 'common/menu'
import { resolveValue } from 'common/resolve-value'
import { getWindowDimensions, isMobile } from 'common/responsive'
import { getLocation, navigate, useLocation } from 'common/routing'
import { useWindowSize } from 'common/use-event'

import icon2023 from 'assets/images/2023-medium-alcumus-shields-blue.svg'
import { useReady } from 'common/route-refresh'
import { loaderFinished, useCachedAsync } from 'common/use-async'
import { If } from 'common/switch'
import { raiseLater } from 'common/events'
import { useRefreshWhen } from 'common/useRefresh'
import { MdArrowDropUp } from '@react-icons/all-files/md/MdArrowDropUp'
import { makeCachedStyles } from 'common/inline-styles'

const useStyles = makeCachedStyles((theme) => ({
    '@global': {
        '.menu-header': {
            cursor: 'default',
            marginLeft: -16,
            marginBottom: -8,
            marginRight: -16,
            marginTop: -16,
            paddingLeft: 16,
            paddingRight: 16,
            paddingBottom: 8,
            paddingTop: 16,
            background: 'white !important',
            color: 'black !important',
            '&:hover': {
                background: 'white',
                color: 'black',
            },
        },
    },
    icon: {
        verticalAlign: 'text-top !important',
    },
    mainMenu: {
        width: () => (isMobile() ? 62 : 54),
        background: '#263836',
        overflow: 'hidden',
        overflowY: 'auto',
        color: 'white',
        height: '100%',
        flexGrow: 0,
        alignItems: 'center',
        // justifyContent: 'center',
        flexShrink: 0,
        padding: '.375em',
        display: 'flex',
        flexDirection: 'column',
        '& *': {
            userSelect: 'none',
        },
    },
    sideBar: {
        '& *': {
            userSelect: 'none',
        },
        overflow: 'hidden',
        background: 'blue',
        display: 'flex',
        flexDirection: 'row',
        height: '100%',
        flexGrow: 0,
        flexShrink: 0,
        zIndex: 100,
        // boxShadow: '0 0 5px rgba(60,60,60,0.4)',
    },
    menuLogo: {
        width: 'calc(48px - 1em)',
        height: 'calc(48px - 1em)',
    },
    touchOnly: {
        cursor: 'default !important',
    },
    mainMenuTopSpacer: {
        height: 80,
    },
    mainMenuSpacer: {
        flexGrow: 1,
    },
    mainMenuItem: {
        // width: '100%',
        flexShrink: 0,
        textAlign: 'center',
        cursor: 'pointer',
    },
    mainMenuLine: {
        borderBottom: '1px solid #e4e7e8',
        marginBottom: '1.1em',
        marginTop: '.1em',
    },
    mainMenuBottomLine: {
        borderBottom: '1px solid #5b6867',
        marginLeft: '-.5em',
        marginRight: '-.5em',
        marginBottom: '.5em',
        width: 'calc(100% + 1em)',
    },
    menuCircle: {
        zIndex: 2,
        fontSize: 16,
        position: 'relative',
        borderRadius: '100%',
        margin: '-.125em',
        width: 'calc(48px - .75em)',
        height: 'calc(48px - .75em)',
        '&.MuiAvatar-colorDefault': {
            background: 'white !important',
            color: '#444 !important',
        },
        '& svg': {
            fontSize: '150%',
            // marginTop: '.25em',
        },
        '& div': {
            textTransform: 'uppercase',
            fontWeight: 400,
            fontSize: '125%',
            paddingTop: '.15em',
        },
    },
    selectedItem: {
        height: 70,
        position: 'relative',
        '& *:hover': {
            boxShadow: 'none !important',
        },
    },
    selector: {
        background: '#52605e',
        borderBottomLeftRadius: 10,
        borderBottomRightRadius: 10,
        height: 50,
        width: 26,
        position: 'absolute',
        left: 3,
        top: 10,
        overflow: 'hidden',
        zIndex: 1,
    },
    aboveItem: {
        marginTop: '1em',
        marginBottom: '1em',
        '&:hover:not(.menu_selected)': {
            boxShadow: '0 0 0 2.5px #009fda',
        },
        '&.menu_selected.MuiAvatar-colorDefault': {
            color: '#364846 !important',
            background: `${theme.palette.primary.main} !important`,
            boxShadow: '0 0 0 1.5px #009fda',
        },
        '&.MuiAvatar-colorDefault': {
            background: '#364846 !important',
            color: '#777 !important',
        },
    },
    belowItem: {
        marginBottom: '1em',
        background: 'white',
        color: '#5b6867',
        '&:hover:not(.menu_selected)': {
            boxShadow: '0 0 0 2.5px #009fda',
        },
    },
    bottomItemAbove: {
        marginBottom: '1em',
        background: 'transparent',
        color: 'white',
        boxSizing: 'content-box',
        '&:hover:not(.menu_selected)': {
            boxShadow: '0 0 0 2.5px #009fda',
        },
        '&.menu_selected': {
            color: '#009fda',
        },
    },
    bottomItemBelow: {
        marginBottom: '.5em',
        marginTop: '.5em',
        background: 'transparent',
        color: 'white',
        boxSizing: 'content-box',
        '&:hover:not(.menu_selected)': {
            boxShadow: '0 0 0 2.5px #009fda',
        },
        '&.menu_selected': {
            color: '#009fda',
        },
    },
    dot: {
        marginTop: 30,
        marginLeft: 7,
        borderRadius: '100%',
        width: 13,
        height: 13,
        background: '#009fda',
    },
    subMenu: {
        width: 228,
        overflowY: 'auto',
        background: theme.palette.background.paper,
        color: theme.palette.text.primary,
        borderRight: `1px solid ${theme.palette.background.paper}`,
        display: 'flex',
        flexDirection: 'column',
        '& > *': {
            width: '100%',
        },
    },
    menuItem: {
        position: 'relative',
        cursor: 'pointer',
        display: 'flex',
        alignItems: 'center',
        paddingRight: '1em',
        flexShrink: 0,
        paddingBottom: '.5em',
        paddingTop: '.5em',
        '& > *': {
            paddingLeft: '1em',
        },
        '&:hover': {
            background: '#009fda',
            color: 'white',
        },
        '&[class*=disabled]:hover': {
            background: 'inherit',
            color: 'inherit',
        },
    },
    activeMenuItem: {
        bottom: 2,
        right: 2,
        top: 2,
        width: '4px',
        padding: 0,
        margin: 0,
        background: '#009fda',
        position: 'absolute',
    },
    inactiveMenuItem: {
        cursor: 'default !important',
        '&:hover': {
            background: 'inherit',
            color: 'inherit',
        },
    },
    disabled: {
        opacity: 0.5,
        filter: `grayscale(1)`,
    },
    menuItemText: {
        flexGrow: 1,
    },
    subMenuItems: {
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        marginLeft: '2em',
        '& > *': {
            width: '100%',
        },
    },
    slidingMenu: {
        position: 'absolute',
        zIndex: 1500,
        height: () => getWindowDimensions().innerHeight,
        maxWidth: 256,
        transform: 'translateX(-100%)',
        transition: 'transform 0.3s ease-out',
    },
    mobileSlidingMenu: {
        maxWidth: '350px',
    },
    visible: {
        transform: 'translateX(0) !important',
    },
    menuButton: {
        // borderRadius: '100%',
        // userSelect: 'none',
        position: 'absolute',
        // background: 'white',
        // boxSizing: 'content-box',
        // border: '2px solid white',
        zIndex: 50,
        top: -6,
        // left: '.5em',
        // width: '2em',
        // height: '2em',
        // textAlign: 'center',
        // '& img': {
        //     width: '2em',
        //     height: '2em',
        // },
    },
    menuButtonIcon: {
        zIndex: 99999,
        top: '10px',
    },
    menuItemIcon: {
        width: 48,
        flexShrink: 0,
        flexGrow: 0,
        fontSize: '150%',
        display: 'flex',
        justifyContent: 'space-around',
    },
}))

export { useStyles as useMenuStyles }

const hide = debounce(function hide() {
    events.emit('hide-main-menu', null)
}, 10)

function closeMenu() {
    menu.$selected = null
    menu.$visible = false
}

events.on('hide-main-menu', closeMenu)
events.on('clicked.background', closeMenu)

const getMenuIcon = events.modify('main-menu-icon')

export function checkActive(item) {
    const { location } = getLocation()
    if (item.isActive && typeof item.isActive === 'function') {
        return item.isActive(location.pathname)
    } else if (typeof item.isActive === 'string') {
        return !!location.pathname.match(item.isActive)
    }
    return item.isActive
}

function SubMenuItem({ item }) {
    const classes = useStyles()

    function toggle() {
        if (disabled) return
        if (!canExpand) {
            if (item.click) item.click(hide)
            return
        }
        setExpanded(!expanded)
    }

    function checkActive() {
        if (item.isActive && typeof item.isActive === 'function') {
            return item.isActive(location.pathname)
        } else if (typeof item.isActive === 'string') {
            return !!location.pathname.match(item.isActive)
        }
        return item.isActive
    }

    let disabled = resolveValue(item.disabled || false)
    let isActive = checkActive()
    let children = item.children ? item.children : null
    const canExpand = children && children.length
    if (children) children = processMenuItems(children)
    const [expanded, setExpanded] = useState(false)
    return (
        <>
            <div
                data-testid={`subMenu-item-${item.id || item.title}`}
                onClick={toggle}
                className={clsx(classes.menuItem, item.inactive ? classes.inactiveMenuItem : '', {
                    [classes.disabled]: disabled,
                })}
            >
                {isActive && <div className={classes.activeMenuItem} />}
                <If truthy={item.icon}>
                    <div data-testid={`subMenu-item-${item.id || item.title}-icon`} className={classes.menuItemIcon}>
                        {item.icon}
                    </div>
                </If>
                <div data-testid={`subMenu-item-${item.id || item.title}-title`} className={classes.menuItemText}>
                    {item.title}
                </div>
                {!canExpand ? null : (
                    <div data-testid={`subMenu-item-${item.id || item.title}-expand`} className={classes.dropDown}>
                        {!expanded ? <MdArrowDropDown /> : <MdArrowDropUp />}
                    </div>
                )}
            </div>
            {!expanded || !children ? null : (
                <div data-testid={`subMenu-item-${item.id || item.title}-subItems`} className={classes.subMenuItems}>
                    {children.map((item, index) => {
                        return <SubMenuItem item={item} key={item.id || index} />
                    })}
                </div>
            )}
        </>
    )
}

function SubMenu({ selected }) {
    const classes = useStyles()
    const wasShowingSub = useRef(false)
    selected = selected || {}
    let children = selected.children || []
    let settings = events.modify(`sub-menu-items.${selected.id || 'none'}`, {
        children,
        selected,
        id: selected.id,
        title: selected.title,
        header: selected.header || null,
    })
    let visible = menu.visible.value
    children = processMenuItems(children)
    let showSubMenu = settings.header || settings.children.length
    let Header = settings.header
    wasShowingSub.current = false
    if (!showSubMenu) {
        return null
    }
    if (isMobile() && !visible && !wasShowingSub.current) return null
    wasShowingSub.current = true
    return (
        <div data-testid="menu-subMenu" className={classes.subMenu}>
            {!settings.header ? null : <Header />}
            {children
                .filter((child) => !child.bottom)
                .map((item, index) => {
                    return <SubMenuItem key={item.id || index} item={item} />
                })}
            <div className={classes.mainMenuSpacer} />
            {children
                .filter((child) => child.bottom)
                .map((item, index) => {
                    return <SubMenuItem key={item.id || index} item={item} />
                })}
        </div>
    )
}

function processMenuItems(items) {
    items.forEach((item) => {
        if (typeof item.click === 'string') {
            let target = item.click
            item.click = (hide) => {
                navigate(target)
                hide()
            }
            item.isActive = item.isActive || target
        }

        item.title = resolveValue(item.title)
        item.id = resolveValue(item.id)

        item.id = item.id || (item.title || '').toLowerCase().replace(/[_\s.]+/g, '-')
        item.title = item.title || capitalize((item.id || '').replace(/[_\-.\s]+/g, ' '))
        item.icon = item.icon || (typeof item.title === 'string' ? <div>{makeInitials(item.title)}</div> : null)
    })

    let result = items
        .unique('id')
        .filter((i) => !i.secure || i.secure() === null)
        .sort(inPriorityOrder)
    return result
}

function processAdoption(menu) {
    let lookup = keyBy(menu, 'id')
    for (let value of menu) {
        if (value.adopt) {
            let children = (value.children = value.children || [])
            for (let adoptee of value.adopt) {
                children.push(lookup[adoptee])
                delete lookup[adoptee]
            }
            value.children = uniqBy(
                value.children.filter((f) => !!f),
                'id'
            )
            value.originalIsActive = value.originalIsActive || value.isActive
            value.isActive = (path) => {
                return (
                    !!path.match(value.originalIsActive) ||
                    value.children.filter((c) => c.isActive).some((c) => !!path.match(c.isActive))
                )
            }
        }
    }
    return Object.values(lookup)
}

export function MenuBar({ refresh }) {
    let ready = [useReady(), useReady('menu')].every(Boolean)
    let selected = menu.$selected
    let visible = menu.$visible
    const info = { selected, visible }
    const items = menu.$items
    events.emit('menu-selected', info)
    selected = info.selected
    visible = info.visible
    raiseLater('recalculate-middle')
    useWindowSize()
    const classes = useStyles()
    useRefreshWhen('apps-loaded', 'initialized-app')

    const menuItems = useCachedAsync(
        'menuBar',
        async () => {
            await loaderFinished()
            let items = map(processAdoption([...menu.items$]), (item) => item).sort(inPriorityOrder)
            //Process menu helper shortcuts
            items = processMenuItems(items)
            selected = selected ? items.find((item) => item.id === selected.id) : null
            let aboveTop = items.filter((item) => item.aboveTheLine && !item.bottom)
            let belowTop = items.filter((item) => !item.aboveTheLine && !item.bottom)
            let aboveBottom = items.filter((item) => item.aboveTheLine && item.bottom)
            let belowBottom = items.filter((item) => !item.aboveTheLine && item.bottom)
            return <Component />

            function Component() {
                const classes = useStyles()
                const { location } = useLocation()
                return (
                    <div key="result" data-testid="menu-sideBar" className={classes.sideBar}>
                        <div data-testid="menu-mainMenu" className={classes.mainMenu}>
                            <div className={`${classes.mainMenuItem} ${classes.touchOnly}`}>
                                <img alt="icon" className={classes.menuLogo} src={getMenuIcon(icon2023)} />
                            </div>
                            <div className={classes.mainMenuTopSpacer} />
                            <div className={classes.mainMenuItem}>
                                {aboveTop.map((item, index) => {
                                    return item.Component ? (
                                        <item.Component
                                            item={item}
                                            hide={hide}
                                            selected={selected}
                                            classes={classes}
                                            key={item.id || index}
                                        />
                                    ) : (
                                        <Tooltip title={item.title} key={item.id || index}>
                                            <Avatar
                                                data-testid={`menu-aboveTop-item-${item.id || item.title}`}
                                                aria-label={item.title}
                                                onClick={() => {
                                                    selected = menu.$selected = raw(item)
                                                    item.click && item.click(hide)
                                                }}
                                                className={clsx(classes.menuCircle, classes.aboveItem, {
                                                    menu_selected: item === selected,
                                                })}
                                            >
                                                {item.icon}
                                            </Avatar>
                                        </Tooltip>
                                    )
                                })}
                            </div>
                            <div className={`${classes.mainMenuLine} ${classes.mainMenuItem}`} />
                            <div className={classes.mainMenuItem}>
                                {belowTop.map((item, index) => {
                                    function checkActive() {
                                        if (item.isActive && typeof item.isActive === 'function') {
                                            return item.isActive(location.pathname)
                                        } else if (item.isActive) {
                                            return !!location.pathname.match(item.isActive)
                                        }
                                        return false
                                    }

                                    const icon = item.Component ? (
                                        <item.Component
                                            item={item}
                                            hide={hide}
                                            selected={selected}
                                            classes={classes}
                                            key={item.id || index}
                                        />
                                    ) : (
                                        <Tooltip title={item.title} key={item.id || index}>
                                            <Avatar
                                                data-testid={`menu-belowTop-item-${item.id || item.title}`}
                                                aria-label={item.title}
                                                onClick={() => {
                                                    selected = menu.$selected = raw(item)
                                                    item.click && item.click(hide)
                                                }}
                                                className={clsx(classes.menuCircle, classes.belowItem, {
                                                    menu_selected: item === selected,
                                                })}
                                            >
                                                {item.icon}
                                            </Avatar>
                                        </Tooltip>
                                    )

                                    const active = checkActive()
                                    if (active) selected = selected || item

                                    return active ? (
                                        <div
                                            data-testid={`menu-aboveTop-item-${item.id || item.title}-active`}
                                            key={item.id || index}
                                            className={classes.selectedItem}
                                        >
                                            <div className={classes.selector}>
                                                <div className={classes.dot} />
                                            </div>
                                            {icon}
                                        </div>
                                    ) : (
                                        icon
                                    )
                                })}
                            </div>
                            <div className={classes.mainMenuSpacer} />
                            <div className={classes.mainMenuItem}>
                                {aboveBottom.map((item, index) => {
                                    return item.Component ? (
                                        <item.Component
                                            item={item}
                                            hide={hide}
                                            selected={selected}
                                            classes={classes}
                                            key={item.id || index}
                                        />
                                    ) : (
                                        <Tooltip title={item.title} key={item.id || index}>
                                            <Avatar
                                                data-testid={`menu-aboveBottom-item-${item.id || item.title}`}
                                                aria-label={item.title}
                                                onClick={() => {
                                                    selected = menu.$selected = raw(item)
                                                    item.click && item.click(hide)
                                                }}
                                                className={clsx(classes.menuCircle, classes.bottomItemAbove, {
                                                    menu_selected: item === selected,
                                                })}
                                            >
                                                {item.icon}
                                            </Avatar>
                                        </Tooltip>
                                    )
                                })}
                            </div>
                            {!!belowBottom.length && <div className={classes.mainMenuBottomLine} />}
                            <div className={classes.mainMenuItem}>
                                {belowBottom.map((item, index) => {
                                    return item.Component ? (
                                        <item.Component
                                            item={item}
                                            hide={hide}
                                            selected={selected}
                                            classes={classes}
                                            key={item.id || index}
                                        />
                                    ) : (
                                        <Tooltip title={item.title} key={item.id || index}>
                                            <Avatar
                                                data-testid={`menu-belowBottom-item-${item.id || item.title}`}
                                                aria-label={item.title}
                                                onClick={() => {
                                                    selected = menu.$selected = raw(item)
                                                    item.click && item.click(hide)
                                                }}
                                                className={clsx(classes.menuCircle, classes.bottomItemBelow, {
                                                    menu_selected: item === selected,
                                                })}
                                            >
                                                {item.icon}
                                            </Avatar>
                                        </Tooltip>
                                    )
                                })}
                            </div>
                        </div>
                        <SubMenu selected={selected} />
                    </div>
                )
            }
        },
        null,
        'menu',
        [refresh.id, selected, visible, items.map('id')]
    )
    return (
        ready && (
            <>
                {!isMobile() && menuItems}
                {isMobile() && !!menuItems && (
                    <>
                        <div
                            data-testid="menu-showMenu"
                            onClick={showMenu}
                            className={clsx(classes.menuButton, 'mobileMenuButton', 'do-not-remove-button-menu')}
                        >
                            <IconButton
                                onClick={showMenu}
                                data-testid="menu-showMenu"
                                className={clsx(classes.menuButtonIcon, 'menuButtonIcon')}
                            >
                                <MenuIcon />
                            </IconButton>
                        </div>
                        <div
                            data-testid="menu-slidingMenu"
                            className={clsx(
                                classes.slidingMenu,
                                classes.mobileSlidingMenu,
                                'mobileSlidingMenu',
                                'sliding-menu',
                                {
                                    [classes.visible]: visible,
                                }
                            )}
                        >
                            {menuItems}
                        </div>
                    </>
                )}
            </>
        )
    )

    function showMenu() {
        menu.$visible = true
    }
}

SubMenuItem.propTypes = {
    item: PropTypes.any,
}

SubMenu.propTypes = {
    selected: PropTypes.any,
}
