import './App.scss'
import './help'
import './index.scss'
// import 'common/render-trap'
import 'configuration/default-apps'
import 'framework/account-menu'
import 'framework/LoginPage'
import 'common/offline-data-service'
import React, { useEffect, useRef, useState } from 'react'
import { HelpDrawer } from 'help/drawer'

import { CssBaseline } from '@material-ui/core'
import { Box } from 'common/styled-box'
import { raise } from 'common/events'
import { store } from 'common/global-store'
import { refresh as menuRefresh } from 'common/menu'
import {
    DialogThemer,
    Middle,
    ModalCenter,
    ModalContainerContext,
    ModalHolder,
    ModalPlaceholder,
    showModal,
} from 'common/modal'
import { Routes, useLocation } from 'common/routing'
import { useLocalEvent, useStableLocalEvent } from 'common/use-event'
import { useRefresh, useRefreshWhen } from 'common/useRefresh'
import { CenteredLoader } from 'common/utils/centered-loader'

import { Notification } from 'framework/notifications'
import { AppContainer } from './AppContainer'

import { UIStableSocket } from 'common/widgets'
import { initializeApp } from './app-loading'
import { SuspendedUser } from './suspended-user'
import { useDebounced } from 'common/use-debounced'
import { EmulatedBanner } from 'common/emulated-banner'
import { InputFile } from './import-file-component'
import { theme } from 'common/theme'
// Plugins
import 'help/tour'
import './debug-window'
import './menu-bar'
import 'help/help-menu'
import './app-helper'
import './test-events'
import 'dynamic/awe-library/runtime/embed-data-functions'
import { DestinationPort } from 'common/shipping'
import { AlcumusLocation } from 'common/history'
import { getWindowDimensions } from 'common/responsive'
import { makeCachedStyles, purgeUnusedStyles } from 'common/inline-styles'
import { useCurrentState } from 'common/use-async'

setInterval(purgeUnusedStyles, 1000 * 30)

const useStyles = makeCachedStyles(
    () => {
        return {
            toolTip: {
                textTransform: 'capitalize',
            },
            main: {
                position: 'relative',
                height: (props) => props.innerHeight,
                display: 'flex',
                flexDirection: 'column',
                flexGrow: 1,
                alignSelf: 'stretch',
            },
            bottom: {
                marginLeft: '-1em',
                marginRight: '-1em',
            },
            routes: {
                overflow: 'hidden',
                padding: 0,
                flexGrow: 1,
                display: 'flex',
                height: '100%',
            },
            top: {
                marginLeft: '-1em',
                marginRight: '-1em',
            },
            bottomTools: {
                position: 'absolute',
                width: '100%',
                left: '50%',
                transform: 'translateX(-50%)',
                bottom: 0,
                zIndex: 200,
                display: 'flex',
            },
            bottomComponent: {
                position: 'absolute',
                left: '50%',
                transform: 'translateX(-50%)',
                bottom: 0,
                zIndex: 201,
            },
            documentBottomTools: {
                opacity: 0,
                transform: 'translateX(-1000%)',
            },
            bottomToolsSpacer: {
                flexGrow: 1,
                maxWidth: 64,
            },
            bottomToolsTop: {
                maxWidth: 1000,
                flexGrow: 1000,
            },
            bottomComponentNode: {
                position: 'relative',
            },
            floating: {
                height: '100%',
                pointerEvents: 'none',
                position: 'absolute',
                width: '100%',
            },
            floatRight: {
                alignItems: 'center',
                display: 'flex',
                height: '100%',
                justifyContent: 'flex-end',
                width: '100%',
            },
        }
    },
    'innerHeight',
    'zoom'
)

let globalLoaded = 1

function App() {
    const [zoom, setZoom] = useCurrentState(1)
    const refresh = useRefresh()
    const { navigate } = useLocation()
    const classes = useStyles({ innerHeight: getWindowDimensions().innerHeight, zoom })
    useRefreshWhen('components-mounted')
    useEffect(() => {
        initializeApp()
    }, [])
    useStableLocalEvent('prefer-zoom', setZoom)
    const [ready, setReady] = useState(false)
    const modalActive = useRef(false)
    const [loaded, setLoaded] = useState(0)
    const doRefresh = useDebounced(refreshAll, 100)

    useLocalEvent('auth.login-success', () => {
        setReady(false)
        initializeApp()
    })
    useLocalEvent('initialized-app', () => {
        setLoaded((loaded) => loaded + 1)
        setReady(true)
    })

    useLocalEvent('reinitialize-app', () => {
        navigate('/')
        initializeApp()
    })

    useLocalEvent('orientationchange', 'resize', doRefresh, window)
    useLocalEvent('update-all', doRefresh)
    useLocalEvent('change-active-client', changeActiveClient)
    useLocalEvent('update-brand-color', handleBrandUpdate)
    useLocalEvent('user-suspended', suspendUser)
    useLocalEvent('apps-loaded', () => {
        setLoaded(globalLoaded++)
    })

    useEffect(() => {
        setLoaded(globalLoaded++)
    }, [])
    const ref = useRef()
    if (!ready) {
        return <CenteredLoader />
    } else {
        return (
            <ModalContainerContext.Provider value={ref}>
                <CssBaseline />
                <DialogThemer defaultTheme={theme}>
                    <AlcumusLocation>
                        <AppContainer>
                            <InputFile />
                            <DialogThemer defaultTheme={theme}>
                                <ModalHolder />
                            </DialogThemer>
                            <ModalCenter />
                            <UIStableSocket track={loaded} type="main-before" />
                            <ModalPlaceholder>
                                <Box ref={ref} className={`${classes.main} main`}>
                                    <UIStableSocket track={loaded} type="main-contents" />
                                    <Notification />
                                    <Box className={classes.top}>
                                        <UIStableSocket track={loaded} type="topApps" />
                                    </Box>
                                    <Box className={classes.routes}>
                                        <React.Suspense fallback={<CenteredLoader />}>
                                            <Routes component={Empty} purpose="main" />
                                        </React.Suspense>
                                    </Box>
                                    <Box className={classes.bottom}>
                                        <UIStableSocket track={loaded} type="bottomApps" />
                                    </Box>
                                </Box>
                                <Middle container={ref} zIndex={201} keepCenterToDocument={true}>
                                    <DialogThemer defaultTheme={theme}>
                                        <DestinationPort name="DocumentBottomTools" />
                                    </DialogThemer>
                                </Middle>
                                <Box className={classes.floating}>
                                    <Box className={classes.floatRight}>
                                        <DestinationPort name="floatRight" />
                                    </Box>
                                </Box>
                            </ModalPlaceholder>
                            <HelpDrawer />
                            <UIStableSocket track={loaded} type="main-after" />
                            <EmulatedBanner />
                        </AppContainer>
                    </AlcumusLocation>
                </DialogThemer>
            </ModalContainerContext.Provider>
        )
    }

    async function suspendUser(__, message) {
        if (!message) return

        const userEmail = store.profile.value.profile.email
        const isSuspended = (str) => str?.includes('SUSPEND')

        if (isSuspended(message.message) && userEmail === message.userId?.email) {
            if (!modalActive.current) {
                modalActive.current = true
                await showModal(SuspendedUser)
                modalActive.current = false
            }
        }
        initializeApp()
    }

    function refreshAll() {
        raise('resize-vp', Date.now())
        refresh()
        menuRefresh()
    }

    function handleBrandUpdate(_, client) {
        store.$brandColor = client?.color || '#009fda'
    }

    function changeActiveClient(...params) {
        const [client] = params
        if (client) {
            handleBrandUpdate(null, client)
            raise('clear-all-caches')
            initializeApp()
        }
    }
}

// eslint-disable-next-line react/prop-types
function Empty({ children }) {
    return <>{children}</>
}

export default App
