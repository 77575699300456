import memoize from 'memoizee'
import { handle, raiseAsync } from 'common/events'
import { isOnline } from 'common/utils'
import { getActiveClient } from 'common/global-store/api'
import { cleanKeys, getStandardMappings, isDatabaseId } from 'common/offline-data-service/utils'
import { prepareQuery } from 'common/offline-data-service/query'
import * as LocalStorageFacade from 'common/offline-data-service/localStorageFacade'
import { universal } from 'common/offline-data-service/universal'
import isEmpty from 'lodash/isEmpty'
import { filterAsync } from 'js-coroutines'
import { getListByIds, offlineDataStorageReady } from 'common/offline-data-service'
import { getVersionOfId } from 'common/offline-data-service/document-versions'
import * as InternalEvents from '../events'
import { cacheRecords } from 'common/offline-data-service/caching'
import { get } from 'common/offline-data-service/functions/get'

handle('data.changed', (id) => {
    if (isDatabaseId(id)) {
        clearMemos()
    }
})
handle('data.updated.**', (id) => {
    if (isDatabaseId(id)) {
        clearMemos()
    }
})
handle('writing.data', clearMemos)
handle('app.navigate', clearMemos)
handle('published', clearMemos)

function clearMemos() {
    list.clear()
}

/**
 * Retrieves a list of matching documents.  This function prefers
 * the server if we are online and uses a local backup if we are not.
 *
 * Note that this retrieves the outer wrapper of the record, the
 * actual record is contained in the <code>data</code> property of the record
 * @param {string} database - the database of the table
 * @param {string} table - the table to retrieve data from
 * @param {WhereDefinition} [where={}] - a query to apply
 * @param {ListOptions} [options={}] - options for the retrieval
 * @returns {Promise<Array<DocumentRecord>>}
 */
export const list = memoize(
    async function list(database, table, where = {}, options = {}) {
        // Get a list of all of the ids and their current version
        // Request all of the versions we don't have
        // return the list

        await offlineDataStorageReady
        where = options.includeDeleted
            ? where
            : { $and: [{ $or: [{ _deleted: null }, { _deleted: 0 }, { _deleted: false }] }, where] }
        const { result } = !options.noCustom
            ? await raiseAsync('get-list', { database, table, where, options, result: null })
            : {}
        if (result) return result
        let { track = true } = options
        if (options.fields) track = false
        if (!top.forceOffline && isOnline()) {
            const records =
                (await getListByIds(database, table, where, {
                    ...options,
                    track,
                })) || []

            const localVersions = await Promise.all(
                records.map(async (row) => ({
                    id: row.id,
                    __: await getVersionOfId(row.id),
                    remote: row.data.__,
                }))
            )
            let lookup = {}
            const toRetrieve = localVersions.filter((v) => !v.__ || v.__ !== v.remote)
            if (toRetrieve.length) {
                const retrieved = await InternalEvents.getRecords(toRetrieve, false)
                await cacheRecords(retrieved)
                for (let record of retrieved) {
                    if (record) {
                        lookup[record.id] = record.data
                    }
                }
            }
            const output = []
            for (let record of records) {
                const data = lookup[record.id] || (await get(record.id))
                if (data) {
                    output.push({ ...record, data: data })
                }
            }
            return output
        } else {
            const client = getActiveClient()
            const tag = where.$tag
            const clauses = { ...cleanKeys(where) }
            delete clauses.$tag
            let query = await prepareQuery(clauses, getStandardMappings())

            const scan = {
                typeForClient: [client, `${database}/${table}`],
            }
            if (tag) scan.tag = tag
            let records = await LocalStorageFacade.select({
                from: 'records',
                where: scan,
            })
            for (let additionalClient of universal) {
                const scan = {
                    typeForClient: [additionalClient || 0, `${database}/${table}`],
                }
                if (tag) scan.tag = tag
                try {
                    records.push(
                        ...(await LocalStorageFacade.select({
                            from: 'records',
                            where: scan,
                        }))
                    )
                } catch (e) {
                    console.error(e)
                }
            }
            if (!isEmpty(clauses)) {
                records = await filterAsync(records, (record) => query.test(record.data))
            }
            for (let record of records) {
                await raiseAsync(`hydrate.${database}:${table}`, record)
            }
            return records
        }
    },

    { promise: true, primitive: true, maxAge: 0.25 * 1000 * 60, normalizer: (args) => JSON.stringify(args) }
)
