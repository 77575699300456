import React from 'react'
import { plug } from 'common/widgets'
import { dontWaitForAsyncHandler, handle, raise } from 'common/events'
import { isLoggedIn } from 'common/global-store/api'
import { MenuBar } from 'common/menu/MenuBar'
import { useRefresh } from 'common/useRefresh'
import { useStableLocalEvent } from 'common/use-event'
import { parameter } from 'common/query-params'
import debounce from 'lodash/debounce'
import { loaderFinished } from 'common/use-async'

if (!parameter('noLoad')) {
    plug('main-before', Menu)
}

function Menu() {
    const refresh = useRefresh()
    useStableLocalEvent(
        'sign-in-event',
        'auth.user-stored',
        'refresh-menu-visible',
        'refresh-menu',
        'apps-loaded',
        'routes-updated',
        debounce(refresh, 5)
    )
    const { showMenu } = raise('shouldShowMenu', { showMenu: true })
    return !!showMenu && !!isLoggedIn() && <MenuBar refresh={refresh} />
}

handle(
    'app.navigate',
    dontWaitForAsyncHandler(async () => {
        await loaderFinished()
        raise('refresh-menu')
    })
)
