import { handle } from 'common/events'
import * as localForage from 'localforage'

localForage.config()

handle('local-store-getItem', async function retrieveFromLocalForage(context) {
    context.value = JSON.parse((await localForage.getItem(context.key)) || 'null')
})

handle('local-store-setItem', async function storeToLocalForage(key, value) {
    await localForage.setItem(key, JSON.stringify(value))
})

handle('local-store-removeItem', async function removeFromLocalForage(key) {
    await localForage.removeItem(key)
})

handle('clear-local-system', async function () {
    await localForage.clear()
})

handle('clear-forage', async function () {
    try {
        await localForage.clear()
    } catch (e) {
        console.error(e)
    }
})

handle('local-store-getKeys', async function (context) {
    context.keys = await localForage.keys()
})
