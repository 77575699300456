import { generate } from 'packages/identifiers'
// import {
//     documentChangesApply,
//     documentChangesStore,
//     documentContextRemove,
//     documentContextRetrieve,
//     documentContextStore,
//     documentRetrieve,
// } from 'dynamic/awe-library/runtime/lib/simple-db'
import { raise, raiseAsync } from 'common/events'
import { batch } from 'common/batched'
import { ensureArray } from 'common/ensure-array'

import { initialize, initializeSync } from 'common/offline-data-service/behaviour-cache'
import { run } from 'js-coroutines'
import { clone } from 'common/utils/deep-copy'
import { hydrateDocument } from 'common/offline-data-service/hydrate'
import { get } from 'common/offline-data-service/functions/get'
import { onlyOne } from 'common/guard'

let updating = Promise.resolve(true)

export function waitForUpdates() {
    return new Promise((resolve) => {
        setTimeout(async () => {
            updating.finally(resolve)
        }, 100)
    })
}

let number = 0

export function trackAsync(fn) {
    return async function (...params) {
        let promise = fn(...params)
        trackPromise(promise)
        return await promise
    }
}

export function trackPromise(promise) {
    const e = new Error()
    const current = updating
    let finished = false
    const tracked = waitForPromise().finally(() => (finished = true))
    updating = Promise.race([
        tracked,
        new Promise((resolve) => {
            setTimeout(() => {
                if (!finished) {
                    // eslint-disable-next-line no-console
                    console.warn('Never finished promise', e.stack)
                }
                resolve()
            }, 30000)
        }),
    ])
    number++
    raise('tracking-promise')
    promise.then(
        () => number--,
        () => number--
    )
    return promise

    function waitForPromise() {
        return current.then(
            () => promise,
            () => promise
        )
    }
}

export async function updateDocumentIdle(documents) {
    return await run(updater(documents))
}

export async function updateDocument(documents) {
    return await updateDocumentIdle(documents) //await Promise.all(ensureArray(documents).map(updateSingleDocument))
}

const updated = Symbol('updated')

export async function immediateUpdateDocument(documents) {
    const steps = { hydrate: 0, applyChanges: 0, count: 0, hydrated: 0, hasHydrated: 0 }
    documents = await Promise.resolve(documents)
    for (let document of ensureArray(documents).compact(true)) {
        if (document[updated]) continue
        try {
            steps.count++
            document[updated] = true
            let time = performance.now()
            await hydrateDocument(document)
            steps.hydrate += performance.now() - time
            const info = { id: document._id, document }
            if (!info.document.sendMessageAsync) continue
            time = performance.now()
            // await documentChangesApply(info)
            steps.applyChanges += performance.now() - time
            time = performance.now()
            await info.document.sendMessageAsync('hydrated')
            steps.hydrated += performance.now() - time
            // time = performance.now()
            // await info.document.sendMessageAsync('hasHydrated')
            // steps.hasHydrated += performance.now() - time
        } catch (e) {
            console.error(e)
        }
    }
    // eslint-disable-next-line no-console
    console.log(steps)
    return documents
}

export async function updateDocumentsNone(documents) {
    return documents
}

export async function updateDocumentsLive(documents) {
    for (let document of ensureArray(documents).compact(true)) {
        try {
            await hydrateDocument(document)
            const info = { id: document._id, document }
            if (!info.document.sendMessageAsync) continue
            // await documentChangesApply(info)
            await info.document.sendMessageAsync('hydrated')
        } catch (e) {
            console.error(e)
        }
    }
}

function* updater(documents) {
    documents = yield Promise.resolve(documents)
    for (let document of ensureArray(documents).compact(true)) {
        try {
            if (document[updated]) continue
            document[updated] = true
            yield hydrateDocument(document)
            const info = { id: document._id, document }
            if (!info.document.sendMessageAsync) continue
            // yield documentChangesApply(info)
            yield info.document.sendMessageAsync('hydrated')
            // yield info.document.sendMessageAsync('hasHydrated')
        } catch (e) {
            console.error(e)
        }
    }
    return documents
}

export async function hydrateIfNecessary(document) {
    if (document?._settings?.$id) {
        await hydrateDocument(document)
    } else {
        if (!document.behaviours) {
            initializeSync(document)
        }
    }
    return document
}

export const getDocumentWithChanges = onlyOne(async function getDocumentWithChanges(id) {
    const info = { id }
    // try {
    //     await documentRetrieve(info)
    // } catch (e) {
    //     info.document = {}
    // }
    if (!info.document) return null
    // await documentChangesApply(info)
    await initialize(info.document)
    await info.document.sendMessageAsync('hydrated')
    await info.document.sendMessageAsync('hasHydrated')

    return info.document
})

export const getDocumentAndInitialize = onlyOne(async function getDocumentAndInitialize(id) {
    const document = await get(id)
    await hydrateDocument(document)
    await document.sendMessageAsync('hydrated')
    await document.sendMessageAsync('hasHydrated')
    return document
})

function convertUndefinedTo(object, replace) {
    return Object.fromEntries(
        Object.entries(object).map(([key, value]) => {
            if (value === undefined) {
                return [key, replace]
            }
            return [key, value]
        })
    )
}

export async function getInstanceController(id, actionId, document) {
    const info = {
        id,
        document,
    }
    if (!info.document) {
        // await documentRetrieve(info)
        if (!info.document) {
            info.document = {}
        }
        await initialize(info.document)
        // await documentChangesApply(info)
    }
    const actionInfo = { ...info, context: {}, actionId }
    // await documentContextRetrieve(actionInfo)
    actionInfo.context.$trackId = actionInfo.context.$trackId || generate()
    const action = info.document.behaviours.instances.formAction?.find((i) => i.id === actionId)
    let baseInstance = info.document
    if (action?.storeIn) {
        baseInstance = Object.get(info.document, action.storeIn, true) || {}
        Object.set(info.document, action.storeIn, baseInstance)
    }
    Object.setPrototypeOf(actionInfo.context, baseInstance)

    const instanceController = {
        processDifferences,
        instance: actionInfo.context,
        save: () => {},
        async reset() {
            instanceController.save.flush()
            for (let key of Object.keys(actionInfo.context)) {
                delete actionInfo.context[key]
            }
            // await documentContextStore(actionInfo)
        },
        document: info.document,
        async commit(toState, __command = 'setData', __controller = { notSet: true }, $create, notificationToastObj) {
            try {
                raise('instance.committed', instanceController)
                delete instanceController.instance['']
                if ($create === undefined) {
                    if (!instanceController.instance.$created) {
                        $create = instanceController.document
                        instanceController.instance.__created = instanceController.instance.$created = Date.now()
                        info.document.$created = Date.now()
                    }
                } else {
                    instanceController.instance.__created = instanceController.instance.$created = Date.now()
                    info.document.$created = Date.now()
                }
                toState = toState || instanceController.instance._behaviours._state
                instanceController.save.flush()
                const toSend = clone(convertUndefinedTo(instanceController.instance, ''))
                toSend.SHARED = info.document.SHARED
                delete toSend.$originals
                delete toSend.$conflicts
                delete instanceController.instance.$originals
                delete instanceController.instance.$conflicts

                // await documentChangesStore(
                //     {
                //         $trackId: instanceController.instance.$trackId,
                //         $create: prepareDocument($create),
                //         actionId,
                //         id,
                //         instance: toSend,
                //         toState,
                //         command,
                //         controller,
                //     },
                //     id
                // )

                await cleanUpInstance(id, actionId)
                await raiseAsync('flush-contexts')

                if (notificationToastObj?.notificationToast) {
                    raise('controller.instance.committed', notificationToastObj)
                }

                batch(() => {
                    raise(`controller.instance.committed.${id}`, id)
                    raise(`data.updated.${id}`, id)
                })
            } catch (e) {
                console.error('Error committing')
                if (notificationToastObj?.notificationToast) {
                    raise('controller.instance.committed.error', notificationToastObj)
                }
            }
        },
    }
    return instanceController

    function processDifferences(document = baseInstance) {
        const current = actionInfo.context
        const originals = (current.$originals = current.$originals || cloneTopLevel(document, current))
        const conflicts = (current.$conflicts = {})
        const fields = Object.entries(current).filter(validFields)
        // Phase 1 update the current value, if the current value = the original
        // but the base value has changes
        for (let [key, contextValue] of fields) {
            let documentValue = document[key]
            let originalValue = originals[key]
            if (compare(originalValue, contextValue) && !compare(contextValue, documentValue)) {
                conflicts._updated = true
                current[key] = documentValue
                originals[key] = cloneLocal(documentValue)
            }
        }

        // Phase 2 identify conflicts
        for (let [key] of fields) {
            const value = originals[key]
            let baseValue = baseInstance[key]
            if (!compare(value, baseValue) && !compare(baseValue, current[key])) {
                conflicts[key] = [baseValue, current[key]]
            }
        }

        return conflicts
    }

    function validFields(r) {
        return r[0] && !r[0].startsWith('$') && !r[0].startsWith('_')
    }
}

const UNDEFINED = '!&-!!udf'

function cloneLocal(item) {
    if (!item) return UNDEFINED
    return clone(item)
}

function cloneTopLevel(item, ref) {
    let output = {}
    for (let key in item) {
        if (key && !key.startsWith('$') && !key.startsWith('_')) {
            output[key] = item[key] !== undefined ? item[key] : UNDEFINED
        }
    }
    for (let key in ref) {
        // eslint-disable-next-line no-prototype-builtins
        if (key && !key.startsWith('$') && !key.startsWith('_') && ref.hasOwnProperty(key)) {
            output[key] = item[key] !== undefined ? item[key] : UNDEFINED
        }
    }
    return output
}

function compare(v1, v2, noNullables) {
    if (!noNullables && nullable(v1) && nullable(v2)) return true
    if (v1 == v2) return true
    return Object.isEqual(v1, v2)
}

function nullable(v) {
    return !v || v === UNDEFINED
}

export async function cleanUpInstance(__id, __actionId) {
    // const info = {
    //     id,
    //     actionId,
    // }
    // await documentContextRemove(info)
}
