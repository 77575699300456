import { raise } from 'common/events'
import * as LocalStorageFacade from 'common/offline-data-service/localStorageFacade'
import { OfflineFirstRetriever } from 'common/offline-data-service/offlineFirstRetriever'
import { isDebugger } from 'common/debugger'
import { offlineDataStorageReady } from 'common/offline-data-service/index'
import { getActiveClient, getActiveProfile } from 'common/global-store/api'
import { universal } from 'common/offline-data-service/universal'

export function cleanKeys(where) {
    let output = { ...where }
    for (let key of Object.keys(where).filter((k) => k.includes('+'))) {
        output[key.replace(/\+/g, '.')] = output[key]
        delete output[key]
    }
    return output
}

export function getStandardMappings() {
    let standardMappings = {}
    raise('get-query-fn-mappings', standardMappings)
    return standardMappings
}

export function cacheLocalOnly(document) {
    OfflineFirstRetriever.setLocal(document._id, document)
}

export async function clean() {
    await LocalStorageFacade.clean()
}

export function getDatabaseAndTable(id) {
    return id.split(':')[1].split('/')
}

export function extractIdParts(id) {
    if (isDatabaseId(id)) {
        const parts = id.split(':')
        return [parts[0], ...parts[1].split('/')]
    }
    return [undefined, undefined, undefined]
}

export function isDatabaseId(id = '') {
    if (typeof id !== 'string') return false
    const colon = id.indexOf(':')
    const lastColon = id.lastIndexOf(':')
    const slash = id.indexOf('/')
    const lastSlash = id.lastIndexOf('/')
    const isTag = id.trim().startsWith('<') && id.trim().endsWith('>')
    return colon !== -1 && colon === lastColon && slash !== -1 && lastSlash === slash && slash > colon && !isTag
}

export function isTableId(id = '') {
    if (typeof id !== 'string') return false
    const colon = id.indexOf(':')
    const slash = id.indexOf('/')
    const lastSlash = id.lastIndexOf('/')
    return colon === -1 && slash !== -1 && lastSlash === slash
}

export async function clearAll() {
    if (isDebugger()) return
    // eslint-disable-next-line no-console
    await offlineDataStorageReady
    if (isDebugger()) return
    offlineDataStorageReady = runRestartDb()

    async function runRestartDb() {
        OfflineFirstRetriever.resetInstance()
        // await LocalStorageFacade.dropDb()
        // await LocalStorageFacade.initializeOfflineStorage()
    }
}

export async function listAll() {
    await new Promise((resolve) => setTimeout(resolve, 100))
    try {
        return await window.indexedDB.databases()
    } catch (e) {
        console.error(e)
    }
}

export function checkInequality(one, two) {
    const oneSerialized =
        JSON.stringify(one, replacer) +
        JSON.stringify({
            $invalid: one?.$invalid,
            $state: one?._settings?.$state || one?._behaviours._state,
        })
    const twoSerialized =
        JSON.stringify(two, replacer) +
        JSON.stringify({
            $invalid: two?.$invalid,
            $state: two?._settings?.$state || two?._behaviours?._state,
        })
    return oneSerialized !== twoSerialized

    function replacer(k, v) {
        if (k.startsWith('$')) return undefined
        if (k === '_behaviours') return undefined
        if (k === '_settings') return undefined
        if (k === '_stateChangedAt') return undefined
        return v
    }
}

export function checkClient() {
    return true
}

export function checkActualClient(record) {
    const profile = getActiveProfile()
    if (profile.fundamental.includes('super-admin') || profile.fundamental.includes('all-clients')) return true
    if (profile.clients.some((c) => c.id === record._client)) return true
    const activeClient = getActiveClient()
    return (
        (activeClient === '<test>' && window._useToken) ||
        record._client === activeClient ||
        record.client === activeClient ||
        (record._client === undefined && record.client === undefined) ||
        (record.specificUrl &&
            record.domain &&
            !record.domain?.startsWith('localhost') &&
            !record.domain?.startsWith('127.0') &&
            (location.hostname === record.domain || location.hostname === `www.${record.domain}`)) ||
        universal.includes(record._client)
    )
}
