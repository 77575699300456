import React from 'react'

import { Avatar as InternalAvatar } from '@material-ui/core'
import clsx from 'clsx'
import { makeCachedStyles } from 'common/inline-styles'

const useStyles = makeCachedStyles((__theme) => ({
    avatar: {
        '& img': {
            height: 'auto !important',
        },
    },
}))

export function Avatar({ src, children, className, ...options }) {
    const classes = useStyles()
    if (src) {
        return (
            <InternalAvatar
                className={clsx(classes.avatar, className, 'avatar')}
                src={typeof src === 'string' ? src : undefined}
                {...options}
            />
        )
    }
    return (
        <InternalAvatar className={clsx(className, 'avatar')} {...options}>
            {children}
        </InternalAvatar>
    )
}

export default Avatar
