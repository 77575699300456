/**
 * @module common/use-event
 */

import { useEffect, useLayoutEffect } from 'react'

import events from 'packages/alcumus-local-events'
import { ensureArray } from 'common/ensure-array'
import { useRefreshWhen } from 'common/useRefresh'
import { globalWidgets } from 'common/widgets'
import { getWindowDimensions } from 'common/responsive'
import { eventName } from 'common/events'

let old, old2, emit, emit2
window.trackEvents = function () {
    let myEvents = events
    old = myEvents.addListener.bind(myEvents)
    myEvents.addListener = function (...params) {
        // eslint-disable-next-line no-console
        console.trace(params[0])
        old(...params)
    }
    emit = myEvents.emit.bind(myEvents)
    myEvents.emit = function (...params) {
        // eslint-disable-next-line no-console
        console.trace('emit ' + params[0])
        emit(...params)
    }
    myEvents = globalWidgets
    old2 = myEvents.addListener.bind(myEvents)
    myEvents.addListener = function (...params) {
        // eslint-disable-next-line no-console
        console.trace(params[0])
        old2(...params)
    }
    emit2 = myEvents.emit.bind(myEvents)
    myEvents.emit = function (...params) {
        // eslint-disable-next-line no-console
        console.trace('emit ' + params[0])
        emit2(...params)
    }
}

/**
 * Returns the window size of the current viewport and
 * refreshes (using debounce) should it change
 * @returns {{width: number, height: number}}
 */
export function useWindowSize() {
    useRefreshWhen('resize-vp')
    return { width: getWindowDimensions().innerWidth, height: getWindowDimensions().innerHeight }
}

/**
 * @callback EventHandler
 * @global
 * @description An event handling function
 * @param {...*} params - the parameters passed to the event
 * @returns {boolean} - <code>false</code> to stop further processing of the event
 */

/**
 * @interface EventSource
 * @global
 * @description A source of events
 */

/**
 * @function EventSource#addListener
 * @description Add an event listener
 * @param {string} event - name of the event
 * @param {function} handler - the function to handle the event
 */

/**
 * @function EventSource#removeListener
 * @description Remove an event listener
 * @param {string} event - name of the event
 * @param {function} handler - the function that handles the event
 */

/**
 * Adds an event handler for an alcumus-local-event sourced event.
 * The handler does NOT receive the first <code>event</code> parameter
 * that would be passed to a normal handler, but may return <code>false</code>
 * to prevent any further handling of the event.
 *
 * The handler is added in a React useEffect, this may miss immediately
 * raised events - for that use <code>useLocalEventImmediate</code>.
 *
 * Event names use "." to separate parts and can use "*" and "**" to handle
 * multiple events.
 *
 * @param {...string} events - a list of events to be handled
 * @param {EventHandler} handler - the handler for the event(s)
 * @param {EventSource} [source=alcumusLocalEvents] - the emitter of the events (e.g. window)
 * @example
 * useLocalEvent('orientationchange', 'resize', doRefresh, window)
 * useLocalEvent( 'errors.changed.**', localRefresh ) // Handle everything that starts errors.changed.
 * useLocalEvent('data.updated.*', check) // Handle all data updated events
 */
export function useLocalEvent(...params) {
    let pattern = []
    let source = events
    let handler
    for (let i = 0; i < params.length; i++) {
        let param = params[i]
        if (typeof param === 'string') {
            pattern.push(param)
        } else if (Array.isArray(param)) {
            pattern.push(...param)
        } else if (typeof param === 'function') {
            handler = param
            source = params[i + 1] || events
            break
        }
    }
    const fn = useEvent
    function eventWrapper(event, ...params) {
        let result = (handler || pattern).apply(this, params)
        if (result === false) event.preventDefault()
    }
    if (!handler && typeof pattern === 'function') {
        return fn(source, eventName(pattern.name), eventWrapper)
    }

    fn(source, pattern, eventWrapper)
}

export function useStableLocalEvent(...params) {
    let pattern = []
    let source = events
    let handler
    for (let i = 0; i < params.length; i++) {
        let param = params[i]
        if (typeof param === 'string') {
            pattern.push(param)
        } else if (Array.isArray(param)) {
            pattern.push(...param)
        } else if (typeof param === 'function') {
            handler = param
            source = params[i + 1] || events
            break
        }
    }

    function eventWrapper(event, ...params) {
        let result = (handler || pattern).apply(this, params)
        if (result === false) event.preventDefault()
    }
    if (!handler && typeof pattern === 'function') {
        // eslint-disable-next-line react-hooks/rules-of-hooks
        return useEvent(source, eventName(pattern.name), eventWrapper, useStable())
    }

    // eslint-disable-next-line react-hooks/rules-of-hooks
    useEvent(source, pattern, eventWrapper)
}

export function useDependentLocalEvent(deps, ...params) {
    let pattern = []
    let source = events
    let handler
    for (let i = 0; i < params.length; i++) {
        let param = params[i]
        if (typeof param === 'string') {
            pattern.push(param)
        } else if (Array.isArray(param)) {
            pattern.push(...param)
        } else if (typeof param === 'function') {
            handler = param
            source = params[i + 1] || events
            break
        }
    }

    function eventWrapper(event, ...params) {
        let result = (handler || pattern).apply(this, params)
        if (result === false) event.preventDefault()
    }
    if (!handler && typeof pattern === 'function') {
        // eslint-disable-next-line react-hooks/rules-of-hooks
        return useEvent(source, eventName(pattern.name), eventWrapper, useStable(deps))
    }

    // eslint-disable-next-line react-hooks/rules-of-hooks
    useEvent(source, pattern, eventWrapper, useStable(deps))
}

function useStable(deps = []) {
    return function (fn) {
        // eslint-disable-next-line react-hooks/rules-of-hooks
        return useImmediateEffect(fn, deps)
    }
}

/**
 * Adds an event handler for an alcumus-local-event sourced event.
 * The handler does NOT receive the first <code>event</code> parameter
 * that would be passed to a normal handler, but may return <code>false</code>
 * to prevent any further handling of the event.
 *
 * The handler is added in a React useLayoutEffect, this will catch rapidly
 * raised events, but causes many additional calls to add and remove
 * listeners - if you don't need this immediate handling,
 * use <code>useLocalEvent</code>.
 *
 * Event names use "." to separate parts and can use "*" to handle
 * multiple events.
 *
 * @param {...string} events - a list of events to be handled
 * @param {EventHandler} handler - the handler for the event(s)
 * @param {EventSource} [source=alcumusLocalEvents] - the emitter of the events (e.g. window)
 */ export function useLocalEventImmediate(...params) {
    let pattern = []
    let source = events
    let handler
    for (let i = 0; i < params.length; i++) {
        let param = params[i]
        if (typeof param === 'string') {
            pattern.push(param)
        } else if (Array.isArray(param)) {
            pattern.push(...param)
        } else if (typeof param === 'function') {
            handler = param
            source = params[i + 1] || events
            break
        }
    }
    const fn = useEvent
    function eventWrapper(event, ...params) {
        let result = (handler || pattern).apply(this, params)
        if (result === false) event.preventDefault()
    }
    if (!handler && typeof pattern === 'function') {
        return fn(source, eventName(pattern.name), eventWrapper, useLayoutEffect)
    }

    fn(source, pattern, eventWrapper, useImmediateEffect)
}

export function useImmediateEffect(fn, deps = []) {
    useEffect(() => {
        return fn()
    }, deps)
}

export function useLocalEventDeferred(pattern, handler, source) {
    useLocalEvent(pattern, handler.debounce(), source)
}
export function useLocalEventAsync(pattern, handler) {
    const fn = useEventAsync
    const eventWrapper = async (event, ...params) => {
        let result = await (handler || pattern)(...params)
        if (result === false) event.preventDefault()
    }
    if (!handler && typeof pattern === 'function') {
        return fn(events, eventName(pattern.name), eventWrapper)
    }

    fn(events, pattern, eventWrapper)
}

export function useEvent(emitter, patterns, handler, method = useEffect) {
    if (!handler) {
        ;[emitter, patterns, handler] = [events, emitter, patterns]
    }
    method(() => {
        for (let pattern of ensureArray(patterns)) {
            if (emitter) {
                if (emitter.on) {
                    emitter.on(eventName(pattern), runner)
                } else if (emitter.addEventListener) {
                    emitter.addEventListener(eventName(pattern), runner)
                } else {
                    emitter.addListener(eventName(pattern), runner)
                }
            }
        }
        return () => {
            for (let pattern of ensureArray(patterns)) {
                if (emitter.off) {
                    emitter.off(eventName(pattern), runner)
                } else if (emitter.removeEventListener) {
                    emitter.removeEventListener(eventName(pattern), runner)
                } else {
                    emitter.removeListener(eventName(pattern), runner)
                }
            }
        }
    })
    function runner(...params) {
        handler.apply(this, params)
    }
}
export function useEventAsync(emitter, patterns, handler) {
    if (!handler) {
        ;[emitter, patterns, handler] = [events, emitter, patterns]
    }
    let runner = async (...params) => {
        await handler(...params)
    }
    useEffect(() => {
        for (let pattern of ensureArray(patterns)) {
            if (emitter) {
                if (emitter.addEventListener) {
                    emitter.addEventListener(eventName(pattern), runner)
                } else {
                    emitter.addListener(eventName(pattern), runner)
                }
            }
        }
        return () => {
            for (let pattern of ensureArray(patterns)) {
                if (emitter.removeEventListener) {
                    emitter.removeEventListener(eventName(pattern), runner)
                } else {
                    emitter.removeListener(eventName(pattern), runner)
                }
            }
        }
    })
}
