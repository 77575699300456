import events from 'packages/alcumus-local-events'
import queryString, { parse } from 'query-string'
import { generate } from 'packages/identifiers'
import { clientId } from 'common/client-id'
import { deviceId, tabId } from 'common/remote-ids'
import { addTokenToUrl, fetch } from 'common/request'
import { raise } from 'common/events'
import debounce from 'lodash/debounce'
import { isDebugger } from 'common/debugger'
import { getItem, setItem } from 'common/using-local-storage-key'
import { getActiveClient } from 'common/global-store/api'

export function UrlEventSource(url, options = {}) {
    options.withCredentials = false
    return new EventSource(addTokenToUrl(url), options)
}

export const originalTime = Math.min(+(getItem(`messages.lastId.client`) || 0))

const lastIdRef = isDebugger() ? 'debugger' : 'client'

UrlEventSource.prototype = window.EventSource
let lastId = 0

export function ack(client, id) {
    lastId = id
    sender()
}

function send() {
    fetch(`/ack?lastId=${lastId}`).catch(console.error)
}

const sender = debounce(send, 1000, { maxWait: 10000 })

let restartDelay = 1000
let restartCount = 0

function trackEvents() {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async function (resolve) {
        const params = parse(location.search)
        let lastId = getItem(`messages.lastId.${lastIdRef}`)
        let eventSource = new UrlEventSource(
            `/messages?${queryString.stringify({
                lastEventId: !params.isDebugger ? (getActiveClient() === '<test>' ? Date.now() : lastId) : Date.now(),
                listen: [deviceId, tabId, clientId, 'atWorkNetwork'],
            })}`,
            { withCredentials: false }
        )

        eventSource.id = generate()
        eventSource.addEventListener('error', function (e) {
            resolve(e)
            console.warn('EventSource Messages Listener Error', eventSource.id, e)
            setTimeout(
                () => trackEvents().catch(() => console.warn('EventSource restart #', restartCount++)),
                restartDelay
            )
            restartDelay = Math.min(60000, restartDelay * 2)
            eventSource.close()
            eventSource.removeEventListener('message', handleMessage)
        })

        eventSource.addEventListener('message', handleMessage)

        async function handleMessage(message) {
            const data = JSON.parse(message.data)
            if (data.event === 'initialize') {
                raise('sse-init')
                // eslint-disable-next-line no-console
                console.log('SSE Is initialised')
                resolve(() => {
                    raise('stop-listening', Date.now())
                    eventSource.removeEventListener('message', handleMessage)
                    eventSource.close()
                })
                return
            }
            restartDelay = 1000
            if (message.lastEventId) {
                setItem(`messages.lastId.${lastIdRef}`, message.lastEventId)
            }
            let packet = data.message
            eventSource.id = eventSource.id || Date.now()
            ack(clientId, message.lastEventId)
            events.emit(`${packet.event}`, packet.data, message.data?.length)
            if (!packet.event.startsWith('jobDone')) {
                // eslint-disable-next-line no-console
                console.log(
                    '%cServer Event %s %O',
                    'background: darkgreen;color: white;display: inline-block;padding: 2px',
                    packet.event,
                    packet.data
                )
            }
            window.useLongPolling = false
        }
    })
}

export function startTrackingSSE() {
    return trackEvents()
}
