import React from 'react'

import events from 'packages/alcumus-local-events'

import config from 'configuration/lookup'
import merge from 'lodash/merge'
import randomColor from 'randomcolor'
import { MdFiberManualRecord } from '@react-icons/all-files/md/MdFiberManualRecord'
import { raise } from 'common/events'

let myConfig = { ...config }
events.on('newConfig', function (_, config) {
    merge(myConfig, config)
})

export const getConfig = function getConfig(name = '') {
    name = name?.toLowerCase().trim()
    if (!myConfig[name]) {
        const definition = {}
        raise('lookup-definition', { type: name, definition })
        myConfig[name] = definition
    }
    return myConfig[name] || {}
}

export function color(name = '', options = { luminosity: 'bright' }) {
    let c = getConfig(name)
    return (
        c.color ||
        randomColor({
            ...options,
            seed: name,
        })
    )
}

export function contrastColor(name = '', options = null) {
    let c = getConfig(name)
    let color =
        c.color ||
        randomColor({
            ...options,
            seed: name,
        })

    return contrastColor(color)

    function contrastColor(hex) {
        if (hex.indexOf('#') === 0) {
            hex = hex.slice(1)
        }
        // convert 3-digit hex to 6-digits.
        if (hex.length === 3) {
            hex = hex[0] + hex[0] + hex[1] + hex[1] + hex[2] + hex[2]
        }
        if (hex.length !== 6) {
            throw new Error('Invalid HEX color.')
        }

        let colorTotal = parseInt(hex.slice(0, 2), 16) + parseInt(hex.slice(2, 4), 16) + parseInt(hex.slice(4, 6), 16)

        let str = (colorTotal < 450 ? 255 : 0).toString(16)

        // pad each with zeros and return
        return '#' + padZero(str) + padZero(str) + padZero(str)
    }

    function padZero(str, len) {
        len = len || 2
        var zeros = new Array(len).join('0')
        return (zeros + str).slice(-len)
    }
}

export function initials(name = '', number = 2, skip) {
    let caps = []
    let take = true
    let taken = false
    let num = false
    let firstWord = true
    name = name.replace(/\s+and\s+/g, ' ') //.replace(/(Limited|ltd)/ig,'')
    for (let i = 0; i < name.length; i++) {
        if (take && name[i].toLowerCase() >= 'a' && name[i].toLowerCase() <= 'z') {
            caps.push(name[i])
            take = false
            taken = true
        } else if (!num && firstWord && name[i] >= 'A' && name[i] <= 'Z') {
            caps.push(name[i])
            take = false
            taken = true
        } else if (name[i] === ' ') {
            take = true
            num = false
            firstWord = false
        } else if (!taken && name[i] >= '0' && name[i] <= '9') {
            caps.push(name[i])
            take = false
            num = true
        }
    }
    let candidate = caps.join('').toUpperCase()

    if (skip && candidate.startsWith(skip)) {
        candidate = candidate.slice(skip.length)
    }
    candidate = candidate.slice(0, number)
    return candidate
}

export function capitalize(string = '') {
    return string
        .trim()
        .split(' ')
        .map((s) => (s[0] ?? '').toUpperCase() + s.slice(1))
        .join(' ')
}

export function icon(name = '', defaultIcon = <MdFiberManualRecord />) {
    let c = getConfig(name)
    return c.icon || defaultIcon || name
}

export function mapValue(name = '') {
    let c = getConfig(name)
    return c.map || name
}

export function sortOrder(name = '') {
    let c = getConfig(name)
    return c.sort || name
}

export function caption(name = '') {
    let c = getConfig(name)
    let result = c.caption || name.replace(/_/g, '&')
    result = result.replace(/\[[0-9 ]*]\s*/, '')
    return result
}
