import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'

import { makeTestId } from 'utils/Utils'

import { Box, CardContent } from '@material-ui/core'
import Button from '@material-ui/core/Button'
import Card from '@material-ui/core/Card'
import CardHeader from '@material-ui/core/CardHeader'

import { setFromEvent } from 'common/set-from-event'
import TextField from 'common/TextField'

import { getClientSecret } from '../request-selectors'
import { makeCachedStyles } from 'common/inline-styles'

const useApiStyles = makeCachedStyles({
    wrapToken: {
        width: '100%',
        overflowWrap: 'anywhere',
        userSelect: 'all',
    },
})

export function ApiToken({ selectedClient }) {
    const [secret, setSecret] = useState('')
    const [token, setToken] = useState('')

    const { wrapToken } = useApiStyles()

    const getToken = async () => {
        if (secret.length) {
            const { client: { token = '' } = {} } = await getClientSecret({ selectedClient, secret })
            setToken(token)
        }
    }

    useEffect(() => {
        setToken('')
        setSecret('')
    }, [selectedClient])

    return (
        <Card data-testid={`apitoken-${makeTestId(selectedClient.name)}`}>
            <CardHeader title={`Get api token for ${selectedClient.name}`} />
            <CardContent>
                <Box display="flex" flexDirection="row" alignItems="center" justifyContent="center">
                    <Box width="100%" flexDirection="column">
                        <TextField
                            data-testid="clients-add-client-secret"
                            fullWidth
                            variant="outlined"
                            label="Enter Secret"
                            value={secret}
                            onChange={setFromEvent(setSecret)}
                        />
                    </Box>

                    <Box ml={1} alignItems="center" justifyContent="center">
                        <Button
                            onClick={getToken}
                            color="primary"
                            data-testid="clients-generate-secret-client"
                            disabled={secret.length < 3}
                        >
                            Generate
                        </Button>
                    </Box>
                </Box>

                {token && (
                    <Box mt={1} p={1}>
                        <fieldset style={{ minWidth: 0 }}>
                            <legend>Token</legend>
                            <p data-testid="clients-generated-token" className={wrapToken}>
                                {token}
                            </p>
                        </fieldset>
                    </Box>
                )}
            </CardContent>
        </Card>
    )
}

ApiToken.propTypes = {
    selectedClient: PropTypes.object,
}
