import React, { useContext } from 'react'

import PropTypes from 'prop-types'

import { useBoundContext } from 'common/component-utilities'
import { ensureArray } from 'common/ensure-array'
import { noop } from 'common/noop'

const SwitchContext = React.createContext()

export function Switch({ value, field, children }) {
    const { target } = useBoundContext()
    value = value ? value : field ? Object.get(target, field, true) : value
    return <SwitchContext.Provider value={{ value, cases: {} }}>{children}</SwitchContext.Provider>
}

export function If({ value, truthy, field, children, equals, not, ...props }) {
    value = value || truthy
    const { target } = useBoundContext()
    props.then = props.then || children
    value = value ? value : field ? Object.get(target, field, true) : value
    let condition = equals ? value == equals : !!value
    return not
        ? !condition
            ? props.then || null
            : props.else || null
        : condition
        ? props.then || null
        : props.else || null
}

export function Case({ when, children, execute = noop }) {
    const toCheck = ensureArray(when)
    const { target } = useBoundContext()
    const { value, cases } = useContext(SwitchContext)
    let condition = toCheck.some((when) => {
        if (typeof when === 'function') {
            return when(value, target)
        } else {
            return when === value
        }
    })

    cases['' + when] = condition
    if (condition) {
        execute(target)
        return <>{children}</>
    } else {
        return null
    }
}

export function CaseElse({ children }) {
    const { cases } = useContext(SwitchContext)
    if (!Object.values(cases).some((v) => !!v)) {
        return <>{children}</>
    }
    return null
}

Switch.propTypes = {
    children: PropTypes.any,
    field: PropTypes.any,
    value: PropTypes.any,
}

Case.propTypes = {
    children: PropTypes.any,
    when: PropTypes.any,
}

CaseElse.propTypes = {
    children: PropTypes.any,
}
