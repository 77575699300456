import React, { useEffect, useRef } from 'react'

export function genericFileInput() {
    return _genericFileInput
}

let _genericFileInput = {}

export function InputFile() {
    const input = useRef()
    useEffect(() => {
        _genericFileInput = input.current
    })
    return <input ref={input} style={{ display: 'none' }} type="file" />
}
