import { parse } from 'query-string'

const vars = parse(location.search)

export function isDebugger() {
    return vars.isDebugger || vars.noLoad || window.name.startsWith('awe-debugger')
}

if (isDebugger()) {
    document.title = 'DEBUGGER WINDOW'
}
