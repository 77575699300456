import { once, raise, raiseLater } from 'common/events'
import { useRefreshWhen } from 'common/useRefresh'
import { useRef } from 'react'

const startedAt = Date.now()

let ready = {}
notReady()
setTimeout(() => makeReady(), 100)

function incrementReady(name, amount) {
    ready[name] = Math.max(0, (ready[name] || 0) + amount)
}

export function waitForReady(promise, name = 'default') {
    if (promise && promise.finally) {
        notReady(name)

        return promise.finally(() => {
            makeReady(name)
        })
    }
    return promise
}

export function isReady(name = 'default') {
    return !ready[name]
}

export function notReady(name = 'default') {
    incrementReady(name, 1)
    if (Date.now() - startedAt < 10000) {
        raise(`ready-changed-${name}`, ready)
    }
}

export function awaitReady(name = 'default') {
    return new Promise((resolve) => {
        setTimeout(check, 20)

        function check() {
            if (!ready[name]) return resolve()
            once(`ready-changed-${name}`, check)
        }
    })
}

export function makeReady(name = 'default') {
    incrementReady(name, -1)
    raiseLater(`ready-changed-${name}`, ready)
}

export function useReady(name = 'default') {
    const ready = isReady(name)
    const everReady = useRef(ready)
    everReady.current = everReady.current || ready
    useRefreshWhen(`ready-changed-${name}`)
    return true
}
