/**
 * @module common/use-async
 */
import React, { useContext, useRef, useState } from 'react'

import isFunction from 'lodash/isFunction'
import { useRefresh } from 'common/useRefresh'
import LRU from 'lru-cache'
import { raise } from 'common/events'
import { useLocalEvent } from 'common/use-event'
import { AsyncWaitContext } from 'common/use-async/loader-until-async-complete'
import { CancelAsync, createKey, simpleOnly, useCurrentState } from 'common/use-async/utilities'
import { nextRefreshId } from 'common/use-async'

export * from './utilities'
export * from './async'
export * from './step-by-step'
export * from './withAsyncProps'
export * from './loader-until-async-complete'

export const DO_NOT_WAIT = 'noCount'

const cache = new LRU(150)

export function useAsyncCached(promiseProducingFunction, defaultValue = null, cacheIds = [], refreshIds = []) {
    const cacheId = createKey(cacheIds)
    const refreshId = createKey(refreshIds, cacheIds)

    return useAsync(
        async () => {
            const result = (await promiseProducingFunction()) || cache.get(cacheId)
            cache.set(cacheId, result)
            return result
        },
        cacheIds.length ? cache.get(cacheId) || defaultValue : defaultValue,
        refreshId
    )
}

const asyncCache = new LRU(1000)

export function usePreferCachedAsync(purpose, promiseProducer, defaultValue, refId, runId) {
    const key = `${JSON.stringify(refId, simpleOnly)}:${purpose}`
    const runKey = JSON.stringify([runId, key])
    const refresh = useRefresh()
    const executionId = useRef()
    useLocalEvent(`refresh-async-${runKey}`, refresh)
    if (asyncCache.has(runKey)) {
        return asyncCache.get(runKey)
    }
    const value = asyncCache.get(key) || defaultValue
    asyncCache.set(runKey, value)
    executionId.current = runKey
    runFunctions()
    return value

    function runFunctions() {
        let currentExecutionId = executionId.current
        setTimeout(async () => {
            try {
                let result = (await promiseProducer()) || defaultValue
                if (result === CancelAsync) return
                if (executionId.current === currentExecutionId) {
                    asyncCache.set(runKey, result)
                    asyncCache.set(key, result)
                    raise(`refresh-async-${currentExecutionId}`)
                }
            } catch (e) {
                console.error(e)
            }
        })
    }
}

/**
 * A hook to use async data, with additional caching.  This
 * caching is designed to reduce flicker in the UI.  The
 * specific async is given a name and the results will
 * be cached globally and be supplied as the initial result
 * when it is used again.  Data may then be refreshed depending
 * on a separate run id.
 *
 * useCachedAsync needs handling with care so that you don't
 * accidentally use cached data from a different purpose. When
 * used appropriately it makes the UI much more responsive.
 *
 * @param {string} purpose - this should be a unique string to cache
 * data of a particular type
 * @param {function(...*) : Promise} promiseProducer - the function
 * that will retrieve the async data
 * @param {*} [defaultValue] - a value to use when there is no cached data
 * @param {any} [refId=standard] - an id that indicates the cache key to use, this should
 * is used in conjunction with `purpose` to create a cache key
 * @param {any} [runId] - an id that indicates that even if the keys match
 * that the function should be run again and the data updated when
 * it is available
 * @returns {*} the results of the function, the cached value or the default value
 * as appropriate
 * @example
 export function useRecordsForType(type, where, sort, options) {
    return useCachedAsync(
        'getRecords', // purpose of the async
        async () => { // function to get the records
            if (!type) return []
            const typeDef = Object.isObject(type) ? type : await get(type)
            await initialize(typeDef)
            await raiseAsync('update-records', [typeDef])
            return getRecords(typeDef?.database, typeDef?.table, {}, sort, options)
        },
        [], // default value if no cache
        JSON.stringify({ type, where, sort, options }) // unique cache key when added to purpose
    )
}
 */
export function useCachedAsync(purpose, promiseProducer, defaultValue, refId, runId) {
    const [instanceId] = useState(nextRefreshId())
    const key = `${JSON.stringify(refId, simpleOnly)}:${purpose}`
    const runKey = JSON.stringify([runId, key, instanceId])
    const refresh = useRefresh().debounce(5)
    const executionId = useRef()
    useLocalEvent(`refresh-async-${runKey}`, refresh)
    if (asyncCache.has(runKey)) {
        return asyncCache.get(runKey)
    }
    const value = asyncCache.get(key) || defaultValue
    asyncCache.set(runKey, value)
    executionId.current = runKey
    runFunctions(runKey).catch(console.error)
    return value

    async function runFunctions(currentExecutionId) {
        try {
            let result = (await promiseProducer()) || defaultValue
            if (result === CancelAsync) return
            if (executionId.current === currentExecutionId) {
                asyncCache.set(runKey, result)
                asyncCache.set(key, result)
                raise(`refresh-async-${currentExecutionId}`)
                refresh()
            }
        } catch (e) {
            console.error(e)
        }
    }
}

function useAsync(promiseProducer, defaultValue = null, refId = 'standard', dontCountMe) {
    const context = useContext(AsyncWaitContext)
    promiseProducer = !isFunction(promiseProducer) ? async () => promiseProducer : promiseProducer
    const executionId = useRef()
    const key = typeof refId === 'object' ? JSON.stringify(refId) : refId
    const value = useRef(defaultValue)
    const [, setResult] = useCurrentState(0)
    if (key !== executionId.current) {
        runFunctions()
    }
    // useEffect(runFunctions, [key])
    return value.current

    function runFunctions() {
        executionId.current = key
        value.current = defaultValue
        if (dontCountMe !== DO_NOT_WAIT) {
            context.increment(false, `Running async ${refId}`)
        }
        runMe().catch(console.error)

        async function runMe() {
            try {
                let result = await promiseProducer()
                if (result === CancelAsync) return
                if (Object.isEqual(value.current, result || defaultValue || result)) return
                if (executionId.current === key) {
                    value.current = result || defaultValue || result
                    setResult((prev) => prev + 1)
                }
            } catch (e) {
                console.error(e)
            } finally {
                if (dontCountMe !== DO_NOT_WAIT) context.decrement(`Finished async ${refId}`)
            }
        }
    }
}

useAsync.bind = function (fn, defaultValue = null, refId = 'standard') {
    return function (...params) {
        return useAsync(
            async () => {
                return fn(...params)
            },
            defaultValue,
            refId
        )
    }
}

export { useAsync }
export default useAsync
