import events from 'packages/alcumus-local-events'

import { signIn, signInAsAnonymous, tokensRefreshed } from 'utils/authenticationService'

import { define } from 'common/process'
import 'common/session-timeout'
import { getLocalItem, storeLocalItem } from 'common/local-storage/local-store'
import { appLoadingComplete } from '../app-loading'
import axios from "axios";

export async function login(username, password) {
    let user = await signIn(username, password)

    if (user /*&& user.authenticated*/) {
        return user
    }
}

export async function loginAsAnonymous() {
    await appLoadingComplete()
    let user = await signInAsAnonymous()
    await events.emitAsync('sign-in-event', user)

    if (user /*&& user.authenticated*/) {
        return user
    }
}

const retrieveUser = define('user.get', function ({ offlineCache, cacheResult, required }) {
    required('userId')
    cacheResult(async (result, { userId }) => {
        await storeLocalItem(`user_cache_${userId}`, result)
    })
    offlineCache(async ({ userId }) => {
        return await getLocalItem(`user_cache_${userId}`)
    })
})

export const getUserById = define('user.getById', function ({ required }) {
    required('userId')
})

export async function getUser(id) {
    await tokensRefreshed()
    return await retrieveUser(id)
}

//TODO: refactor - Ozgur
export const getSessionUser = async () => {
    let user
    try {
        const response = await axios.get('/api/auth/login/session')
        user = response.data
        if(user && user?.access_token) {
            await events.emitAsync('sign-in-event', user)
            await events.emitAsync('auth.login-success', null)

            // if (store.intendedDest$) {
            //     sessionStorage.removeItem('intendedDest')
            //     let destination = store.intendedDest$ === '/login' ? '/' : store.intendedDest$
            //     store.set({ intendedDest: null })
            //     window.location.href = destination
            // } else {
            //     const intendedDest = sessionStorage.getItem('intendedDest')
            //     sessionStorage.removeItem('intendedDest')
            //     if (intendedDest) {
            //         let destination = intendedDest === '/login' ? '/' : intendedDest
            //         store.set({ intendedDest: null })
            //         window.location.href = destination
            //     } else {
            //         window.location.href = '/'
            //     }
            // }
        }
        return user
    } catch (e) {
        console.error("Get session failed.")
    }
}
