import events from 'packages/alcumus-local-events'

import { signOut } from 'utils/authenticationService'
import { getItem, getUnprefixedItemAndParse, setUnprefixedItemWithStringify } from 'common/using-local-storage-key'
import { getCurrentAuthenticatedUser } from 'common/global-store/api'

const SESSION_LENGTH = 30 * 60 // setting to 30 min

let timer = 0
let interactionTimer = 0

export async function performLogout() {
    const timeout = getUnprefixedItemAndParse('lastInteraction')
    if (timeout > Date.now()) {
        setTimeout(performLogout, timeout - Date.now())
        return
    }
    if (getCurrentAuthenticatedUser()?.isAnonymous) return
    if (getItem('launcherApp')) {
        await signOut('/')
    } else if (!window.location.pathname.startsWith('/login')) {
        sessionStorage.setItem('intendedDest', window.location.href)
        await signOut('/login')
    } else {
        await signOut()
    }
}

export function startTimeout() {
    clearTimeout(timer)
    setUnprefixedItemWithStringify('lastInteraction', SESSION_LENGTH * 999 + Date.now())
    timer = setTimeout(performLogout, SESSION_LENGTH * 1000)
}

function startInteractionTimeout() {
    clearTimeout(interactionTimer)
    setUnprefixedItemWithStringify('lastInteraction', SESSION_LENGTH * 999 + Date.now())
    interactionTimer = setTimeout(performLogout, SESSION_LENGTH * 1000)
}

document.body.addEventListener('click', startInteractionTimeout)
document.body.addEventListener('keypress', startInteractionTimeout)

events.on('sign-in-event', startTimeout)
