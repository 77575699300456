/**
 * Waits for an amount of idle time to be ready before running the function
 * @param {Function} fn - the function to run
 * @param [minimumTime=5] - the amount of time that should be free in ms
 * @returns {(function(): void)|*} A function to call to invoke the supplied function when idle
 */
export function waitForIdle(fn, minimumTime = 5) {
    return function () {
        if (window.requestIdleCallback) {
            window.requestIdleCallback(run)
        } else {
            fn()
        }
    }

    function run(api) {
        if (api.timeRemaining() > minimumTime) {
            fn()
        } else {
            window.requestIdleCallback(run)
        }
    }
}
