import { handle, raise } from 'common/events'
import { getItem, setItem } from 'common/./using-local-storage-key'

window.DEBUG = JSON.parse(getItem('DEBUG')) || {}

window.AVAILABLE_DEBUG_SETTINGS = {}

function saveDebug() {
    setItem('DEBUG', JSON.stringify(window.DEBUG))
}

handle('debug.set', async (setting, value) => {
    window.DEBUG[setting] = value
    saveDebug()
})

handle('debug.save', async () => {
    saveDebug()
})

handle('debug.set.default', async (setting, value, description = '') => {
    if (!window.DEBUG[setting]) {
        window.DEBUG[setting] = value
        saveDebug()
    }
    window.AVAILABLE_DEBUG_SETTINGS[setting] = { description }
})

// how we change a settings
// raise('debug.set', 'mysetting', 'myvalue')

raise('debug.set.default', 'VISIBLE', false, 'Control visibility of debug panel') // controls visibility of debug panel
raise('debug.set.default', 'CALLS', false, 'Debug performance metrics') //DEBUG_CALLS
raise('debug.set.default', 'JOBS', false, 'window.DEBUG_JOBS') // DEBUG_JOBS
raise('debug.set.default', 'LOGS', false, 'window.DEBUG_LOGS') //pi / DEBUG_LOGS
raise('debug.set.default', 'MISSING_MESSAGES', false, 'Debug messages without listeners') // DEBUG_MISSING_MESSAGES

try {
    if (window) {
        if (location.search.includes('debug_off')) {
            raise('debug.set', 'VISIBLE', false)
            console.info('DEBUG DEACTIVATED')
        }
        if (location.search.includes('debug_on')) {
            raise('debug.set', 'VISIBLE', true)
        }

        // consider ability to set any debug flag via url param....

        saveDebug()
    }
} catch (e) {
    //Swallow
}
const oldError = console.error
console.error = function (...params) {
    try {
        if (params[0]?.startsWith('Warning:')) {
            // eslint-disable-next-line no-console
            console.groupCollapsed(
                '%cWarning: %s',
                'font-weight: 200;color: white;display: inline-block;padding: 4px;background: darkred;',
                params[0].split('.')[0].slice(9)
            )
            // eslint-disable-next-line no-console
            console.trace(...params)
            // eslint-disable-next-line no-console
            console.groupEnd()
        }
    } catch (e) {
        oldError(...params)
    }
}
