export const REPORT_TYPES = Object.freeze({
    ALL: 'all',
    CONTRACTORS: 'contractors',
    SUPPLIERS: 'suppliers',
    OTHER: 'other',
})

export const REPORT_TYPE_OPTIONS = [
    { label: 'ALL', value: REPORT_TYPES.ALL },
    { label: 'CONTRACTORS', value: REPORT_TYPES.CONTRACTORS },
    { label: 'SUPPLIERS', value: REPORT_TYPES.SUPPLIERS },
    { label: 'OTHER', value: REPORT_TYPES.OTHER },
]

export const REPORT_FILTERS = {
    [REPORT_TYPES.CONTRACTORS]: [
        'Contractors and Supplier - Comprehensive',
        'Contractors - Comprehensive',
        'Contractors - Onboarding',
        'Contractors - Insurance Expiry',
        'Contractors - Employee Banding',
        'Contractors - Sublist',
        'Contractors - Sustainability',
        'Contractors - Status Change',
        'Contractors - Policy Breakdown', // this information is displayed in the Sustainability Report
    ],
    [REPORT_TYPES.SUPPLIERS]: [
        'Contractors and Supplier - Comprehensive',
        'Suppliers - Comprehensive',
        'Suppliers - Onboarding',
        'Suppliers - Insurance',
        'Suppliers - Sublist', // sublists are not yet functional for suppliers
        'Suppliers - Status Change',
        'Suppliers - Policy Breakdown',
        'Suppliers - ISO Certification',
    ],
    [REPORT_TYPES.OTHER]: [
        'Session Activity',
        'Session Activity All', // this report should only be visible internally
        'System Anchoring',
    ],
}

export function filterReportByType(reports = [], type) {
    if (type === REPORT_TYPES.ALL) {
        return reports.filter((report) => report.reportId !== 'masterlist-custom-download-reports')
    }

    return reports.filter((report) => {
        const validReportNames = REPORT_FILTERS[type]
        return validReportNames.includes(report.name)
    })
}
