import React from 'react'

import PropTypes from 'prop-types'
import { makeCachedStyles } from 'common/inline-styles'

//TODO: This will pull in styles from theme provider - probably
const useStyles = makeCachedStyles((__theme) => ({
    h4: {
        fontFamily: 'Montserrat',
        fontWeight: 500,
        fontSize: '1.667em',
    },
}))

export const H4 = ({ children, ...rest }) => {
    const classes = useStyles()
    return (
        <h4 className={classes.h4} {...rest}>
            {children}
        </h4>
    )
}

H4.propTypes = {
    children: PropTypes.node.isRequired,
    rest: PropTypes.any,
}
