import React, { useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import events from 'packages/alcumus-local-events'
import { MdArrowBack } from '@react-icons/all-files/md/MdArrowBack'
import { IconButton } from '@material-ui/core'
import * as utils from '@reach/router/lib/utils'
import { raise } from 'common/events'
import { store } from 'common/global-store'
import { resolveValueAsFunction } from 'common/resolve-value'
import { isMobile } from 'common/responsive'
import { getLocation, RoutePartials, useLocation } from 'common/routing'
import { useLocalEvent, useStableLocalEvent, useWindowSize } from 'common/use-event'
import { useRefresh } from 'common/useRefresh'
import { parameter } from 'common/query-params'
import { useEmbeddedContext } from 'common/embedded-context'
import { DestinationPort } from 'common/shipping'
import useAsync, { loaderFinished } from 'common/use-async'
import { ModalCenter, showNotification } from 'common/modal'
import { guaranteedAppUrl } from 'dynamic/awe-library/runtime/app-urls'
import { getType } from 'dynamic/awe-library/runtime/records'
import { getDocument } from 'dynamic/awe-library/runtime/use-document-and-instance'
import clsx from 'clsx'
import { MdClose } from '@react-icons/all-files/md/MdClose'
import { makeCachedStyles } from 'common/inline-styles'

const { match } = utils

export const STANDARD_PAGE_HEADER_HEIGHT = 84
export const SHORT_PAGE_HEADER_HEIGHT = 56

store.set({ pageHeaderHeight: SHORT_PAGE_HEADER_HEIGHT })

const useStyles = makeCachedStyles(
    (theme) => ({
        header: {
            position: 'relative',
            overflow: 'visible',
            flexShrink: 0,
            backgroundSize: 'cover !important',
            color: 'white',
            '& .tab-item[aria-selected=false] a': {
                color: 'white',
            },
            '& .tab-item[aria-selected=true] a': {
                position: 'relative',
                background: '#eff1f2',
                borderColor: '#eff1f2',
            },
            '& .tab-item[aria-selected=true] a::after': {
                content: 'this is xxx-content',
                borderBottom: '1px solid red',
                width: '30px',
                height: '2px',
            },
            height: parameter('noLoad') ? 0 : STANDARD_PAGE_HEADER_HEIGHT,
        },
        standardPageHeader: {
            background: (props) => props.background,
            color: (props) => props.color,
            height: (props) => props.height,
        },
        mobile: {
            left: '48px !important',
            fontSize: '120% !important',
            top: '16px !important',
        },
        title: {
            position: 'absolute',
            display: 'flex',
            alignItems: 'center',
            left: 24,
            top: 4,
            whiteSpace: 'nowrap',
            right: 24,
            fontSize: 32,
            fontFamily: 'Montserrat',
            textTransform: 'capitalize',
            '& p': {
                marginTop: 1,
                marginBottom: 1,
            },
        },
        titleBarTitleText: {
            opacity: (props) => props.opacity,
            transition: 'opacity 0.15s linear',
        },
        crumbs: {
            paddingTop: '1em',
            textTransform: 'capitalize',
            marginLeft: 24,
            '& .MuiBreadcrumbs-separator': {
                color: 'rgba(255,255,255,0.8) !important',
            },
        },
        current: {
            color: 'rgba(255,255,255,0.8) !important',
            fontSize: 14,
        },
        backIcon: {
            color: 'white',
            zIndex: 1250,
        },
        link: {
            color: 'white !important',
            fontSize: 14,
        },
        extra: {
            position: 'absolute',
            left: 24,
            bottom: 0,
            right: 0,
            overflow: 'visible',
            minHeight: 32,
        },
        content: {
            position: (props) => (props.noRelative ? '' : 'relative'),
            // padding: '0em',
            display: 'flex',
            flexDirection: 'column',
            flexGrow: 1,
            background: theme.palette.background.default,
            overflowY: 'auto',
            height: '100%',
        },
        page: {
            display: 'flex',
            flexDirection: 'column',
            flexGrow: 1,
            overflow: 'hidden',
            height: '100%',
        },
    }),
    'noRelative',
    'opacity',
    'background',
    'color',
    'height'
)

async function appUrlFetcher({ openType, location, replaceCurrentUrl }) {
    const type = await getType(openType)
    const url = await guaranteedAppUrl(type)
    const { navigate } = getLocation()
    const fetchedDocument = await getDocument(url)
    if (fetchedDocument) {
        navigate(`/sys/${encodeURIComponent(fetchedDocument._id)}`, {
            state: !replaceCurrentUrl ? { returnTo: location.href, previous: location.state } : {},
            replace: replaceCurrentUrl,
        })
    }
}

export function StandardBreadcrumb() {
    const classes = useStyles()
    let routes = []
    events.emit('get-routes', routes)
    const { location, navigate } = useLocation()
    const embedded = useEmbeddedContext()
    useLocalEvent('navigate.back.now', goBack)
    useStableLocalEvent('navigate.back.application', appUrlFetcher)
    location.state = location.state || {}
    const backState = { ...(location?.state?.previous || {}) }
    const path = location.pathname.split('/').reduce(
        (result, current) => {
            let matched = routes
                .map((r) => {
                    let n = match(r.path, result.path + current)
                    if (n) n.routeObject = r
                    return n
                })
                .filter((r) => !!r)[0]
            if (matched) result.parts.push(matched)
            result.path += current + '/'
            return result
        },
        { parts: [], path: '' }
    ).parts
    let matchingRoutes = path
        .map((match) => {
            match.Caption = resolveValueAsFunction(match.routeObject.title, match.params)
            return match
        })
        .filter((match) => !!match.Caption && match.routeObject.path.length > 1)
    let item = location.state.returnTo ? { uri: location.state.returnTo } : matchingRoutes[matchingRoutes.length - 2]
    return !item ? null : (
        <IconButton
            size={isMobile() ? 'small' : 'medium'}
            className={clsx(classes.backIcon, 'backIcon')}
            onClick={goBack}
        >
            {/* MikeT: Please note, the close icon is required when closing a screen not related to the previous one. Contact me before changing. */}
            {location?.state?.previous && location?.state?.returnTo?.split('?')[0] !== location.href.split('?')[0] ? (
                <MdClose />
            ) : (
                <MdArrowBack />
            )}
        </IconButton>
    )

    async function goBack(event) {
        const canGoBack = { back: true }
        event && event.preventDefault()
        await events.emitAsync('navigate.back', canGoBack)
        if (canGoBack.back) {
            if (embedded) {
                raise('close-modal')
            } else if (item) {
                navigate(item.uri, {
                    state: backState,
                    replace: true,
                })
            }
        }
    }
}

// https://ecompliance.atlassian.net/browse/SCP-1810
export const Title = (props) => {
    if (props.override) {
        if (typeof props.override === 'function') return <props.override {...props} />
        return props.override
    }

    let routes = []
    events.emit('get-routes', routes)
    const { location } = getLocation()
    const path = location.pathname.split('/').reduce(
        (result, current) => {
            let matched = routes
                .map((r) => {
                    let n = match(r.path, result.path + current)
                    if (n) n.routeObject = r
                    return n
                })
                .filter((r) => !!r)[0]
            if (matched) result.parts.push(matched)
            result.path += current + '/'
            return result
        },
        { parts: [], path: '' }
    ).parts
    const matchingRoutes = path
        .map((match) => {
            match.Caption = resolveValueAsFunction(match.routeObject.title, match.params)
            return match
        })
        .filter((match) => !!match.Caption && match.routeObject.path.length > 1)

    if (matchingRoutes.length === 0) return null
    const lastMatch = matchingRoutes[matchingRoutes.length - 1]
    return lastMatch && lastMatch.routeObject ? lastMatch.routeObject.Caption || null : null
}

export function goBack() {
    raise('navigate.back.now', null)
}

export const StandardPage = function StandardPage({
    contentClass,
    pageClass,
    additionalContent,
    background = '#ddd',
    color = '#444',
    noRelative,
    height = STANDARD_PAGE_HEADER_HEIGHT,
    ...props
}) {
    const classes = useStyles({
        noRelative,
    })
    if (height === SHORT_PAGE_HEADER_HEIGHT && isMobile()) {
        height = STANDARD_PAGE_HEADER_HEIGHT
    }
    const refresh = useRefresh()
    const shared = useRef({ refresh })
    if (isMobile()) height = 54
    store.set({ pageHeaderHeight: height })
    useWindowSize()

    return (
        <div data-testid="page-standardPage" className={clsx(classes.page, pageClass)}>
            <TitleBar {...props} shared={shared} background={background} color={color} height={height} />
            <div data-testid="page-standardPage-content" className={clsx(classes.content, contentClass)}>
                <DestinationPort name="content-before" />
                {additionalContent}
                <RoutePartials key="content" purpose="content" refresh={refresh} shared={shared.current} />
                <DestinationPort name="content-after" />
                <ModalCenter />
            </div>
        </div>
    )
}

function TitleBar(props) {
    let refresh = useRefresh()
    useLocalEvent('update-page-title', refresh)

    const finished = useAsync(
        async () => {
            await loaderFinished()
            return true
        },
        props.immediateTitle !== false,
        [refresh.id]
    )
    return !!finished && <InnerTitle {...props} />
}

function InnerTitle({
    background: initialBackground,
    hideTitle,
    shared,
    titlebarClass,
    height,
    children,
    color: initialColor,
    title,
    TitleComponent = null,
}) {
    if (typeof TitleComponent !== 'function' && typeof title !== 'function' && title) {
        let value = TitleComponent ?? title
        TitleComponent = () => value
    }
    const refresh = useRefresh()
    const opacity = useRef(0)
    const titleRef = useRef()
    useEffect(() => {
        window.pageTitle = titleRef.current
    })
    useLocalEvent('controller.instance.committed', (event) => {
        setTimeout(
            () =>
                showNotification({
                    message: event.successMessage,
                    variant: 'success',
                    position: event.position,
                }),
            2000
        )
    })

    useLocalEvent('controller.instance.committed.error', (event) => {
        setTimeout(
            () =>
                showNotification({
                    message: event.errorMessage,
                    variant: 'error',
                    position: event.position,
                }),
            2000
        )
    })

    const { background, color } = raise('get-page-title-colors', {
        background: initialBackground,
        color: initialColor,
    })
    const classes = useStyles({ background, color, height, opacity: opacity.current })
    useEffect(() => {
        if (opacity.current) return
        let timer = setTimeout(async () => {
            await loaderFinished()
            opacity.current = 1
            if (titleRef.current) {
                titleRef.current.style.opacity = 1
            }
        }, 200)
        return () => {
            clearTimeout(timer)
        }
    })
    return (
        <>
            {!hideTitle && (
                <DestinationPort name="titlebar">
                    <div
                        data-testid="page-standardPage-header"
                        className={clsx(classes.header, classes.standardPageHeader, 'page-header')}
                    >
                        <div
                            data-testid="page-standardPage-header-title"
                            className={clsx(
                                { [classes.mobile]: isMobile(), mobileTitleBar: isMobile() },
                                classes.title,
                                'title'
                            )}
                        >
                            <StandardBreadcrumb />
                            <span ref={titleRef} className={clsx(classes.titleBarTitleText, 'titleBarTitleText')}>
                                <DestinationPort name="title" fallback={<Title override={TitleComponent || title} />} />
                            </span>
                        </div>
                        {!isMobile() && (
                            <div
                                data-testid="page-standardPage-header-titlebar"
                                className={clsx(classes.extra, 'titleBarExtras')}
                            >
                                {children}
                                <div className={titlebarClass}>
                                    <DestinationPort name="title-bar-before" />
                                    <DestinationPort name="title-bar">
                                        <RoutePartials purpose="titlebar" refresh={refresh} shared={shared.current} />
                                    </DestinationPort>
                                    <DestinationPort name="title-bar-after" />
                                </div>
                            </div>
                        )}
                    </div>
                </DestinationPort>
            )}
            {isMobile() && (
                <DestinationPort name="mobile-titlebar">
                    <div data-testid="page-standardPage-header-titlebar" className="mobileTitleBarContainer">
                        {children}
                        <div className={clsx(titlebarClass, 'mobileTitleBar')}>
                            <DestinationPort name="title-bar-before" />
                            <DestinationPort name="title-bar">
                                <RoutePartials purpose="titlebar" refresh={refresh} shared={shared.current} />
                            </DestinationPort>
                            <DestinationPort name="title-bar-after" />
                        </div>
                    </div>
                </DestinationPort>
            )}
        </>
    )
}

StandardPage.propTypes = {
    additionalContent: PropTypes.any,
    background: PropTypes.string,
    children: PropTypes.any,
    classes: PropTypes.any,
    color: PropTypes.string,
    contentClass: PropTypes.any,
    height: PropTypes.any,
    pageClass: PropTypes.any,
    TitleComponent: PropTypes.any,
    titlebarClass: PropTypes.any,
}
