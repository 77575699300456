import { define, parameter, send } from 'common/process'
import { getLocalItem, storeLocalItem } from 'common/local-storage/local-store'

export const getHelpFromServer = define('help.getHelp', function ({ returns, offlineCache, cacheResult }) {
    returns('help')
    offlineCache(async () => {
        return await getLocalItem('cache_help')
    })
    cacheResult(async (result) => {
        await storeLocalItem('cache_help', result)
    })
})
export const setTourStep = send('help.setTourStep', parameter('step'))
export const setHelpEntry = send('help.setHelpEntry', parameter('entry'))
export const deleteTourStep = send('help.deleteTourStep', parameter('stepId'))
export const deleteHelpEntry = send('help.deleteHelpEntry', parameter('helpId'))
export const sendHelp = send('user.set', parameter('userId'), parameter('help'))
