import React from 'react'

import PropTypes from 'prop-types'

import { MdPlayArrow } from '@react-icons/all-files/md/MdPlayArrow'

import { Button, DialogActions, DialogContent, DialogTitle } from '@material-ui/core'
import { Box } from 'common/styled-box'

import { showVideo } from 'common/video'

export function HelpViewer({ help, cancel }) {
    HelpViewer.propTypes = {
        cancel: PropTypes.func,
        help: PropTypes.object,
    }

    return (
        <>
            <DialogTitle>{help.title}</DialogTitle>
            <DialogContent>
                <Box width={1} dangerouslySetInnerHTML={{ __html: help.help }} />
            </DialogContent>
            <DialogActions>
                {!!help.videoUrl && (
                    <Button onClick={playVideo} color="secondary" variant="contained">
                        <MdPlayArrow /> Video
                    </Button>
                )}
                <Button color="primary" onClick={cancel}>
                    Close
                </Button>
            </DialogActions>
        </>
    )

    function playVideo() {
        cancel()
        showVideo(help.videoUrl)
    }
}
