/**
 * @module dynamic/awe-library/question-type-def
 */

import events from 'packages/alcumus-local-events'
import noop from 'common/noop'
import { raise } from 'common/events'

export const allSeen = {}

/**
 * @interface QuestionTypeDef
 * @global
 * @description
 * A type definition for a question, includes whether this item
 * is stored, the icon etc.
 *
 * All properties are NOT fully documented below.
 *
 * @property {string} [group=""] - the group for the question in the ui, organises the ui into sections
 * The groups can be: "control", "layout", "create", "display" - no group puts
 * the question in the data capture section.
 * @property {JSX.Element} icon - an icon to use for the question
 * @property {function(instance):string} [caption] - a function to extract a caption for the question for display in the editor
 * @property {ConfigFunction} [config] - a config function to initialise an instance
 * @property {string} value - the type of the question
 * @property {string} label - the label to use for the question in lists
 * @property {string} color - the colour to use for the question icon
 * @property {string} description - a long description of the function of the question
 * @property {boolean} isSearchable - set to <code>false</code> to disable searching on the field or <code>true</code> to allow it
 * @property {boolean} [notRequired=false] - set to true if the field type cannot be required
 * @property {boolean} [stored=true] - must be <code>false</code> not falsey to disable storing the question (an instance doesn't have a name in this case)
 * @property {boolean} [showQuestion=true] - adds "Text", "Text Height", and "Spacing" as fields to the question
 * @property {Array} availableTo - allows this question to be embedded into other question types with specialized inline editors
 * @property {boolean} [excludeFromMainMenu=false] - this question cannot be added to a form unless it is embedded into another question.
 * @property {boolean} [ignoreForCompleteChecks=false] - this question should not be used towards a count of completeness for a form
 * @property {boolean} topic - ????
 * @property {boolean} query - allows this question type to be used within a larger query as a subquery
 * @property {boolean} layout - ????
 * @property {boolean} inlineGroup - ????
 */

const typeDefCache = new Map()

/**
 * Given a question string type or FieldDefinition, returns the object that
 * describes that type
 * @param {string|FieldDefinition} type - the type of question to retrieve
 * @returns {QuestionTypeDef}
 */
export function questionTypeDef(type) {
    if (Object.isObject(type)) type = type.type
    if (typeDefCache.has(type)) return typeDefCache.get(type)
    const types = []
    let result = events.modify('awe.question-types', types)
    let definition = result.find((t) => t.value === type)
    if (!definition) {
        definition = definition || {}
        raise('lookup-definition', { type, definition })
    } else {
        typeDefCache.set(type, definition)
    }
    definition.config = definition.config || noop
    return definition
}

/**
 * @function
 * @name allTypes
 * @description A function to retrieve all current question types
 * @returns {QuestionTypeDef} the currently available questions
 */
export function allTypes() {
    const types = []
    let result = events.modify('awe.question-types', types)
    result.forEach((type) => (allSeen[type.value] = type))
    return result
}
