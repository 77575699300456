import { store } from 'common/global-store'
import { validateSecurity } from 'common/secure/validate'
import { parameter, send } from 'common/process'
import { clientId } from 'common/client-id'
import events from 'packages/alcumus-local-events'
import { getUnprefixedItemAndParse, setUnprefixedItemWithStringify } from 'common/using-local-storage-key'
import { getClientRoles } from 'plugins/security-management-plugin/request-selectors'

export const isLoggedIn = () => !!getCurrentAuthenticatedUser()?.accessToken

export const isLoggedInAndAuthenticated = () =>
    getCurrentAuthenticatedUser()?.accessToken && validateSecurity('authenticated')

export const hasActiveClient = (selectedClient) => {
    const client = selectedClient || store?.profile?.value?.activeClient
    return client !== '0' && client !== 'undefined'
}

let overrideActiveClient = undefined

export const getActiveClient = () => overrideActiveClient || store?.profile?.value?.activeClient

export const updateActiveClient = send('users.updateActiveClient', parameter('activeClient'))

export function clearCurrentAuthenticatedUser() {
    setUnprefixedItemWithStringify('currentAuthenticatedUser', null)
    store.set({ profile: null })
}

export function setActiveClient(client) {
    if (store.profile?.value?.activeClient !== client) {
        overrideActiveClient = client
        if (store.profile?.value) {
            store.profile.value.activeClient = client
        }
        events.emit('change-active-client', client)
    }
}

export function getActiveProfile() {
    return store?.profile?.value
}

export function getLocale() {
    return store?.profile?.value?.profile?.locale
}

export function getNamespace() {
    return store?.profile?.value?.namespace
}

export function getCurrentUserEmail() {
    return getCurrentAuthenticatedUser()?.email
}

export function isAnonymous() {
    return getCurrentAuthenticatedUser()?.isAnonymous ?? false
}

export function getCurrentAuthenticatedUser() {
    return getUnprefixedItemAndParse('currentAuthenticatedUser')
}

export const getActiveUser = () => getCurrentAuthenticatedUser()?.id || clientId
export const getActiveUserProfile = () => store?.profile$

export const isAdmin = () => store?.profile?.value?.fundamental?.includes('super-admin')

export async function getCurrentUser() {
    const { profile, fundamental } = getActiveProfile()
    const roles = await getClientRoles()
    const hasAllPermission = (role) => role.authorise.every((permission) => fundamental.includes(permission))
    const userRoles = roles.filter(hasAllPermission).map((role) => role.name)

    return {
        id: getCurrentAuthenticatedUser()?.id,
        email: profile.email,
        name: profile.name,
        roles: isAdmin ? ['super-admin'] : userRoles,
    }
}

export async function getCurrentClient() {
    const clientProfile = getActiveProfile()

    return {
        id: clientProfile.activeClient,
        name: clientProfile.name,
    }
}
