import React from 'react'

import PropTypes from 'prop-types'

import { Box, Card, Typography } from '@material-ui/core'

import { parameter } from 'common/query-params'

import { getWindowDimensions } from 'common/responsive'
import { AlcumusLogo } from 'framework/alcumusLogo'
import { makeCachedStyles } from 'common/inline-styles'

const useStyles = makeCachedStyles(() => ({
    back: {
        background: 'white !important',
    },
    labelStyle: {
        fontFamily: 'Montserrat',
        letterSpacing: ' 0',
        fontSize: '22px',
        color: '#000000D9',
    },
    logo: {
        height: 120,
    },
    page: {
        background: 'white',
        margin: 'auto',
    },
    title: {
        color: 'black !important',
        textAlign: 'center',
    },
    template: {
        boxShadow: '0px 3px 6px #eeeeee',
        margin: 'auto',
    },
}))

export const LoginTemplate = ({ label, headerAlign = 'left', children }) => {
    LoginTemplate.propTypes = {
        label: PropTypes.any,
        headerAlign: PropTypes.string,
        children: PropTypes.any,
    }

    const { template, labelStyle } = useStyles()
    const [mdDown, setMdDown] = React.useState(getWindowDimensions().innerWidth <= 900)

    React.useEffect(() => {
        const resize = function () {
            setMdDown(getWindowDimensions().innerWidth <= 960)
        }

        window.addEventListener('resize', resize)

        return () => {
            window.removeEventListener('resize', resize)
        }
    }, [])

    const bannerStyle = {
        width: '100%',
        height: '.4vh',
        backgroundColor: 'rgb(0,159,218)',
    }

    if (mdDown) {
        bannerStyle.left = '0px'
        bannerStyle.top = '0px'
        bannerStyle.position = 'absolute'
    }

    const logo = parameter('logo') ? <img alt="logo" src={parameter('logo')} /> : <AlcumusLogo width="100%" />

    const innerCard = (
        <Box width="100%">
            <Box style={bannerStyle} />
            <Box m={{ xs: 1, md: 2 }} display="flex" flexDirection="column" alignItems="stretch">
                <input type="hidden" value="nocomplete" />

                <Box position="relative" display="flex" overflow="hidden" mt={5}>
                    <Box width={1 / 4} />
                    <Box flexGrow={1} textAlign="center">
                        {logo}
                    </Box>
                    <Box width={1 / 4} />
                </Box>

                <Box mt={10} ml={2} mr={2}>
                    <Box mb={3} width="100%" style={{ textAlign: headerAlign }}>
                        <Typography className={labelStyle}>{label}</Typography>
                    </Box>
                    {children}
                </Box>
            </Box>
        </Box>
    )

    return (
        <Box display="flex" alignItems="center" justifyContent="center" flexDirection="row" width="100%">
            <Box width="100%" maxWidth={540}>
                {!mdDown ? <Card className={template}>{innerCard}</Card> : innerCard}
            </Box>
        </Box>
    )
}
