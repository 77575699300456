import React from 'react'

import classNames from 'classnames'

import { Drawer } from '@material-ui/core'

import { store } from 'common/global-store'
import { useEvent } from 'common/use-event'
import { useStyles } from './styles'
import { makeLazy } from 'common/lazy'

const HelpSystem = makeLazy(() => import('./help-system'))

export function HelpDrawer() {
    const classes = useStyles()
    let open = store.get('authorHelp').useValue()
    useEvent(document, 'keypress', handleKey)
    return (
        <Drawer
            className={classNames(classes.drawer, { [classes.hidden]: !open })}
            classes={{ paper: classes.drawerPaper }}
            variant="persistent"
            open={open}
            anchor="right"
        >
            {open && <HelpSystem />}
        </Drawer>
    )

    function handleKey(e) {
        if (e.code === 'KeyA' && e.ctrlKey) {
            let element = Array.from(document.querySelectorAll('[data-testid]:hover')).slice(-1)[0]

            store.set({
                currentHelpElement: element.getAttribute('data-testid'),
            })
        }
    }
}
