import React from 'react'
import PropTypes from 'prop-types'
import { Box } from 'common/styled-box'
import clsx from 'clsx'
import { makeCachedStyles } from 'common/inline-styles'

let styles = {
    listItemBox: {
        display: 'flex',
        lineHeight: (props) => props.lineHeight || 1.2,
        flexWrap: (props) => props.flexWrap || 'nowrap',
        alignItems: (props) => props.alignItems || 'center',
        justifyContent: (props) => props.justifyContent || 'flex-start',
        maxWidth: '100%',
        width: '100%',
    },
    listItemColumn: {
        display: 'flex',
        flexWrap: 'nowrap',
        flexGrow: 1,
        width: '100%',
        maxWidth: '100%',
        flexDirection: 'column',
        alignItems: 'stretch',
        justifyContent: 'flex-start',
    },
}
const useStyles = makeCachedStyles(() => styles, 'lineHeight', 'flexWrap', 'alignItems', 'justifyContent')

export function ListItemBox({ children, className, ...props }) {
    const classes = useStyles(props)
    return (
        <Box {...props} className={clsx(classes.listItemBox, className, 'listItemBox')}>
            {children}
        </Box>
    )
}

ListItemBox.propTypes = {
    children: PropTypes.node.isRequired,
    className: PropTypes.string,
}

ListItemBox.defaultProps = {
    className: undefined,
}

export function ListItemColumn({ children, className, ...props }) {
    const classes = useStyles(props)

    return (
        <Box className={clsx(classes.listItemColumn, className, 'listItemColumn')} {...props}>
            {children}
        </Box>
    )
}

ListItemColumn.propTypes = {
    children: PropTypes.node.isRequired,
}
