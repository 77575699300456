import { ensureArray } from 'common/ensure-array'
import { clone } from 'common/utils/deep-copy'
import { getActiveClient, getNamespace } from 'common/global-store/api'
import { OfflineFirstRetriever } from 'common/offline-data-service/offlineFirstRetriever'
import { setVersion } from 'common/offline-data-service/document-versions'
import * as LocalStorageFacade from 'common/offline-data-service/localStorageFacade'
import { mobile } from 'common/offline-data-service/index'

export async function cacheRecords(records, toDb = true) {
    records = ensureArray(records)
        .unique((r) => r._id || r.id)
        .compact(true)
    for (let i = 0; i < records.length; i++) {
        const record = records[i]
        if (record._id) {
            records[i] = {
                id: record._id,
                data: clone(record),
                type: record._id.split(':')[1],
                client: '' + (record._client || getActiveClient()),
                tags: record._tags || mobile ? null : [],
                parent: record._parent || null,
            }
        }
    }

    records.forEach((record) => {
        OfflineFirstRetriever.setLocal(record.id, record.data)
    })

    if (toDb) {
        records.forEach(
            (record) => record && record.data && !record.data.serverOnly && setVersion(record.data._id, record.data.__)
        )
        return await LocalStorageFacade.insert({
            into: 'records',
            upsert: true,
            values: records
                .filter((r) => r && r.data && !r.data.serverOnly)
                .map((r) => {
                    const toStore = clone(r)
                    toStore.data.__cached = Date.now()
                    toStore.data.__size = JSON.stringify(toStore).length
                    toStore.data.__namespace = getNamespace()
                    return toStore
                }),
        })
    }
}
