import React, { useRef, useState } from 'react'

import events from 'packages/alcumus-local-events'

import { MdHelp } from '@react-icons/all-files/md/MdHelp'

import { inPriorityOrder } from 'packages/al-react-ioc-widgets'
import { ClickAwayListener, Divider, IconButton, MenuList, Paper, Popper, Typography } from '@material-ui/core'
import { Box } from 'common/styled-box'
import MenuItem from '@material-ui/core/MenuItem'

import { store } from 'common/global-store'
import { process } from 'common/process'
import { useEvent } from 'common/use-event'

import { useStyles } from './styles'
import { parameter } from 'common/query-params'
import { MdSettings } from '@react-icons/all-files/md/MdSettings'

const updateUserTourSetting = async (tourActive) => {
    await process({
        type: 'users.setTourActive',
        tourActive: tourActive,
    })
}

export function HelpMenu() {
    const pageHeaderHeight = store.$pageHeaderHeight
    const button = useRef()
    const [id, refresh] = useState(0)
    const [open, setOpen] = useState(false)
    const [tourActive, setTourActive] = React.useState(store.profile$?.profile?.tourActive || true)

    const classes = useStyles()
    const items = events
        .modify('get-help', [])
        .filter((f) => !!f)
        .sort(inPriorityOrder)

    const initialStateTourActive = store.profile$?.profile?.tourActive

    React.useEffect(() => {
        setTourActive(initialStateTourActive)
    }, [initialStateTourActive])

    useEvent(events, 'update-help', () => refresh(id + 1))

    const toggleTour = async () => {
        setTourActive(!tourActive)
        await updateUserTourSetting(!tourActive)
    }

    if (!items.length) return null
    let info = { buttons: [] }
    events.emit('topbuttons', info)

    return (
        <>
            <Box className={classes.helpButtonHolder} style={{ top: pageHeaderHeight / 2 }}>
                <IconButton
                    size="small"
                    ref={button}
                    className={classes.removeOutline}
                    data-testid="help-menu-button"
                    onClick={toggle}
                    color="inherit"
                >
                    <MdHelp />
                </IconButton>
                {info.buttons.map((ExtraButton, index) => {
                    return <ExtraButton key={index} />
                })}
            </Box>
            <Popper style={{ zIndex: 1250 }} open={open} anchorEl={button.current} transition placement="left-end">
                <ClickAwayListener onClickAway={toggle}>
                    <Paper>
                        <MenuList>
                            {items.map((item, index) => {
                                return (
                                    <MenuItem key={index} onClick={handleClick(item)}>
                                        <Box display="flex" alignItems="center">
                                            {!!item.icon && (
                                                <Box mr={1}>
                                                    <MdSettings />
                                                </Box>
                                            )}
                                            {!item.icon && <Box mr={2} className={classes.emptyIcon} />}
                                            <Box>
                                                <Typography component="div" variant="subtitle1">
                                                    {item.caption}
                                                </Typography>
                                            </Box>
                                        </Box>
                                    </MenuItem>
                                )
                            })}
                            <Divider />
                            <MenuItem onClick={toggleTour} data-testid="toggle-help-tour">
                                <Box display="flex" alignItems="center" justifyContent="flex-start">
                                    <Box mr={1}>
                                        <MdSettings />
                                    </Box>
                                    <Box>
                                        <Typography component="div" variant="subtitle1">
                                            {tourActive ? 'Disable' : 'Enable'} Tour
                                        </Typography>
                                    </Box>
                                </Box>
                            </MenuItem>
                        </MenuList>
                    </Paper>
                </ClickAwayListener>
            </Popper>
        </>
    )

    function toggle() {
        setOpen(!open)
    }

    function handleClick(item) {
        return function () {
            setOpen(false)
            item.click?.()
        }
    }
}

if (!parameter('noLoad')) {
    // plug('main-contents', HelpMenu)
}
