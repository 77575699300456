import React from 'react'

import PropTypes from 'prop-types'
import { makeCachedStyles } from 'common/inline-styles'

//TODO: This will pull in styles from theme provider - probably
const useStyles = makeCachedStyles((__theme) => ({
    h2: {
        fontFamily: 'Montserrat',
        fontWeight: 400,
        fontSize: '2.3em',
    },
}))

export const H2 = ({ children, ...rest }) => {
    const classes = useStyles()
    return (
        <h2 className={classes.h2} {...rest}>
            {children}
        </h2>
    )
}

H2.propTypes = {
    children: PropTypes.node.isRequired,
    rest: PropTypes.any,
}
