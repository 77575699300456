import React, { useEffect, useRef, useState } from 'react'
import { CenteredLoader } from 'common/utils/centered-loader'
import { useRefresh } from 'common/useRefresh'
import PropTypes from 'prop-types'

function returnNull() {
    return null
}

export function Async({ children, component, load, error, fallback, sequence = Date.now(), ...props }) {
    let exception, setException
    let refresh
    const results = useRef(new Array(load.length))
    const isLoaded = useRef(true)
    useEffect(() => {
        return () => {
            isLoaded.current = false
        }
    }, [])
    const rendered = useRef()
    children =
        children ||
        component ||
        function () {
            return <span>Nothing to do</span>
        }

    function Content({ results }) {
        return children(results)
    }

    function Embedded() {
        const Fallback = fallback === null ? returnNull : fallback || (() => <CenteredLoader />)
        const DefaultError = error || returnNull
        refresh = useRefresh()
        ;[exception, setException] = useState(null)
        load = Array.isArray(load) ? load : [load]
        if (exception) return <DefaultError error={exception} {...props} />
        if (Fallback && !results.current.wasSet) return <Fallback {...props} />
        return <Content results={results.current} />
    }

    useEffect(() => {
        ;(async function processAsync() {
            try {
                if (rendered.current !== sequence) {
                    results.current = await Promise.all(load.map((l) => Promise.resolve(l(props))))
                }
                results.current.wasSet = true
                isLoaded.current && refresh()
            } catch (e) {
                setException(e)
                console.error('Error in <Async>: ' + e?.toString?.())
                //eslint-disable-next-line no-console
                console.log(component?.name, component, children)
                //eslint-disable-next-line no-console
                console.trace('error in async')
            }
        })()
    })
    return <Embedded />
}

Async.propTypes = {
    children: PropTypes.any,
    component: PropTypes.any,
    error: PropTypes.any,
    fallback: PropTypes.any,
    load: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.func), PropTypes.func]).isRequired,
    sequence: PropTypes.any,
}
