import PropTypes from 'prop-types'
import { StandardDialog } from 'common/Dialog'
import { Box } from 'common/styled-box'
import Typography from '@material-ui/core/Typography'
import React from 'react'

export function SuspendedUser({ ok }) {
    SuspendedUser.propTypes = {
        ok: PropTypes.func,
    }
    return (
        <StandardDialog onOk={ok} title="Suspended user" accept="OK">
            <Box>
                <Typography>
                    Your user for this client has been suspended, please contact Client Services: Email:{' '}
                    <a href="mailto:sc.clientservices@safecontractor.com">sc.clientservices@safecontractor.com</a> or
                    Call: 02920 266715
                </Typography>
            </Box>
        </StandardDialog>
    )
}
