import React, { useState } from 'react'

import events from 'packages/alcumus-local-events'

import flatten from 'lodash/flatten'
import isEmpty from 'lodash/isEmpty'
import { MdEdit } from '@react-icons/all-files/md/MdEdit'

import { inPriorityOrder } from 'packages/al-react-ioc-widgets'
import { Typography } from '@material-ui/core'
import { Box } from 'common/styled-box'
import Button from '@material-ui/core/Button'

import { store } from 'common/global-store'
import { showModal } from 'common/modal'
import { validateSecurity } from 'common/secure/validate'
import { getHelp, getTour } from 'common/tour'
import { showVideo } from 'common/video'

import { getHelpFromServer, sendHelp } from './api'
import { HelpViewer } from './help-viewer'
import { getActiveUser, getCurrentAuthenticatedUser } from 'common/global-store/api'
import memoize from 'memoizee'
import { MdHelp } from '@react-icons/all-files/md/MdHelp'
import { parameter } from 'common/query-params'
import { plug } from 'common/widgets'
import { useLocalEvent } from 'common/use-event'
import { makeLazy } from 'common/lazy'

const Tour = makeLazy(() => import('./tour'))

let help = {}
let tourItems = []

if (!parameter('noLoad')) {
    plug('main-before', function () {
        const [disabled, setDisabled] = useState(true)
        useLocalEvent('disable-app', setDisabled)
        return store.profile$?.profile?.tourActive === true && <Tour disabled={disabled} />
    })
}

const readHelp = memoize(
    async function readHelp() {
        try {
            help = await getHelpFromServer()
            if (help && help.tour && help.tour.items) {
                tourItems = help.tour.items.map((item) => {
                    return {
                        styles: {
                            options: {
                                width: item.width,
                            },
                        },

                        target: `[data-testid^="${item.selector}"]`,
                        priority: item.priority || 10,
                        content: (
                            <Box textAlign="left">
                                {!!item.title && !!item.title.trim() && (
                                    <Typography paragraph variant="subtitle1">
                                        {item.title}
                                    </Typography>
                                )}
                                <Typography paragraph component="div">
                                    <div dangerouslySetInnerHTML={{ __html: item.help }} />
                                </Typography>
                                {!!item.videoUrl && (
                                    <Box display="flex" alignItems="center">
                                        <Box mt={2} mr={2}>
                                            <Button
                                                color="secondary"
                                                variant="contained"
                                                onClick={() => showVideo(item.videoUrl)}
                                            >
                                                Play Video
                                            </Button>
                                        </Box>
                                    </Box>
                                )}
                            </Box>
                        ),
                    }
                })
            }
        } catch (e) {
            console.error("couldn't get help")
        }
    },
    { maxAge: 1000 * 60 * 10 }
)

events.on('refreshHelp', readHelp.debounce(1500))
events.on('refresh-apps', readHelp.debounce(1500))

getHelp(() => {
    if (getCurrentAuthenticatedUser() && validateSecurity('author-help')) {
        return {
            icon: <MdEdit />,
            caption: <strong>Author help</strong>,
            priority: Number.POSITIVE_INFINITY,
            async click() {
                store.set({
                    authorHelp: true,
                    containerClasses: [...store.$containerClasses, 'help-highlight'],
                })
            },
        }
    }
})

getHelp(() => {
    const done = store.$profile?.help || {}
    if (!isEmpty(done[location.pathname])) {
        return {
            icon: <MdHelp />,
            caption: 'Restart the tour',
            async click() {
                const done = store.$profile?.help || {}
                done[location.pathname] = {}
                await sendHelp(getActiveUser(), done)
                events.emit('reset-tour', null)
            },
        }
    }
})

getHelp(() => {
    try {
        const inContext = flatten(
            Object.entries(help.pages || {})
                .filter(([name]) => {
                    return location.pathname.startsWith(name)
                })
                .map(([, value]) => value)
        ).sort(inPriorityOrder)
        return inContext.map((help) => {
            return {
                caption: help.title,
                click: click(help),
            }
        })
    } catch (e) {
        return null
    }

    function click(help) {
        return function () {
            if (!help.help || help.help.trim() === '<p></p>') {
                if (help.videoUrl) {
                    showVideo(help.videoUrl)
                }
            } else {
                showModal(HelpViewer, { help, fullWidth: true })
            }
        }
    }
})

getTour(() => {
    return tourItems
})
