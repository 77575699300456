import { useRefresh } from 'common/useRefresh'
import { useRef } from 'react'
import { getActiveClient } from 'common/global-store/api'
import useAsync from 'common/use-async'
import { define, retrieve } from 'common/process'

const getClients = retrieve('user.clients.get')
const setActiveClient = define('users.updateActiveClient', ({ required }) => {
    required('activeClient')
})

function makeRealIdItems(item) {
    return { userSwitcher: item._id.split(':')[0], ...item }
}

export function useClients(method = getClients) {
    const refresh = useRefresh()
    const localRefresh = useRefresh()
    const selected = useRef(getActiveClient())
    const { active, clients, suspendedClients, activeClientName } = useAsync(
        async function () {
            let result = await method()
            const { clients = [], suspendedClients = [] } = result.clients ? result : result.client
            const mappedClients = clients.map(makeRealIdItems)
            const mappedSuspended = suspendedClients.map(makeRealIdItems)
            let active = (mappedClients.find((cl) => cl.userSwitcher === getActiveClient()) || mappedClients[0])
                ?.userSwitcher

            return {
                clients: mappedClients,
                active: active,
                suspendedClients: mappedSuspended,
                activeClientName: active?.name,
            }
        },
        {},
        [refresh.id]
    )
    return {
        clients,
        selected: selected.current,
        active,
        activeClientName,
        setSelected: updateSelectedClient,
        suspendedClients,
        setActiveClient: updateActiveClient,
        refetch: refresh,
    }

    async function updateActiveClient(id) {
        selected.current = id
        await setActiveClient(id)
        refresh()
    }

    function updateSelectedClient(id) {
        selected.current = id
        localRefresh()
    }
}
