export function makeInitials(string = '') {
    if (typeof string !== 'string') return '--'
    let parts = string.split(' ')
    if (parts.length === 0) {
        return '--'
    }
    if (parts.length === 1) {
        parts.push(string.slice(1))
    }
    return `${parts[0][0]}${parts[1][0]}`.toUpperCase()
}
