import React from 'react'

import { StandardPage } from 'framework/StandardPage'
import { makeCachedStyles } from 'common/inline-styles'

const useStyles = makeCachedStyles({
    back: {
        background: 'white !important',
    },

    page: {
        overflowY: 'hidden',
        display: 'block',
    },
})

const LoginLayout = ({ ...props }) => {
    const classes = useStyles()
    return (
        <StandardPage
            background="white"
            contentClass={classes.back}
            pageClass={classes.page}
            color="white"
            height={56}
            title=""
            {...props}
        />
    )
}

export default LoginLayout
