import React from 'react'

import PropTypes from 'prop-types'
import events from 'packages/alcumus-local-events'
import { captureException } from '@sentry/browser'

import sortBy from 'lodash/sortBy'
import { FaUsers } from '@react-icons/all-files/fa/FaUsers'
import { MdVpnKey } from '@react-icons/all-files/md/MdVpnKey'
import { MdCreditCard } from '@react-icons/all-files/md/MdCreditCard'
import { MdLock } from '@react-icons/all-files/md/MdLock'
import { MdSubdirectoryArrowRight } from '@react-icons/all-files/md/MdSubdirectoryArrowRight'

import { MdPerson } from '@react-icons/all-files/md/MdPerson'
import { Box } from 'common/styled-box'

import { inPriorityOrder } from 'packages/al-react-ioc-widgets'
import MenuItem from '@material-ui/core/MenuItem'

import { Avatar } from 'common/Avatar'
import { StandardDialog } from 'common/Dialog'
import { store } from 'common/global-store'

import { showModal } from 'common/modal'
import { command, parameter, process } from 'common/process'
import { navigate } from 'common/routing'
import { validateSecurity } from 'common/secure/validate'
import { setFromEvent } from 'common/set-from-event'
import TextField from 'common/TextField'
import secureRoute from 'common/secure/secureRoute'

import uniq from 'lodash/uniq'

import { handleInvite, InviteAccept } from 'plugins/launcher-plugin/launcher-invite'
import { useClients } from 'plugins/security-management-plugin/clients'

import { cleanEmail } from 'framework/cleanEmail'
import { isEmulated } from './utils'

import './profile'
import './styles.scss'
import { getActiveUser, getCurrentAuthenticatedUser, getCurrentUserEmail } from 'common/global-store/api'

export const getDemands = events.return('get-demands')

const MANAGE_CLIENTS = 'manage-clients'
const ANONYMOUS_PERMISSION = 'anonymous'
const AUTHENTICATED_PERMISSION = 'authenticated'
const SC_BDM_PERMISSION = 'safecontractor-bdm'

getDemands(MANAGE_CLIENTS, SC_BDM_PERMISSION, ANONYMOUS_PERMISSION, AUTHENTICATED_PERMISSION)

export function getAvailableDemands(param) {
    if (typeof param === 'function') {
        getDemands(param)
    } else {
        param = Array.isArray(param) ? param : [param]
        getDemands(() => param)
    }
}

const getRegisteredDemands = command('demands.get', 'demands', parameter('registeredDemands'))
const SCCP_PRIORITISED_DEMANDS = [
    { path: '/search', demand: 'power-search' },
    { path: '/search', demand: 'power-search-my-list' },
    { path: '/dashboard', demand: 'dashboard' },
    { path: '/safesearch', demand: 'safesearch' },
    { path: '/reporting', demand: 'reporting' },
    { path: '/client-portal', demand: 'client-portal' },
]

export async function getFirstAvailableSCCDemand() {
    let availableDemands = events.modify('get-demands', [])
    let registeredDemands = uniq(availableDemands)
    return getRegisteredDemands(registeredDemands).then((demands) => {
        const _firstAvailableDemand = SCCP_PRIORITISED_DEMANDS.find((v) => demands.indexOf(v.demand) !== -1)
        return _firstAvailableDemand?.path || '/'
    })
}

export function SwitchClient({ ok, cancel }) {
    const { selected, setSelected, clients, suspendedClients, setActiveClient } = useClients()
    return (
        <StandardDialog accept="Switch" onOk={switchClient} onCancel={cancel} title="Switch Active Client">
            {clients?.length > 0 && (
                <TextField
                    select
                    fullWidth
                    data-testid="client-selection"
                    variant="outlined"
                    label="Client"
                    value={selected}
                    onChange={setFromEvent(setSelected)}
                >
                    {sortBy(clients, 'name').map((client, index) => {
                        return (
                            <MenuItem
                                key={index}
                                value={client.userSwitcher}
                                data-testid={`client-option-${client.userSwitcher}`}
                            >
                                {client.name}
                            </MenuItem>
                        )
                    })}
                </TextField>
            )}
            {suspendedClients.length > 0 && (
                <Box mt={2}>
                    <TextField
                        select
                        fullWidth
                        data-testid="suspended-client-selection"
                        variant="outlined"
                        label="Suspended Clients (cannot be selected)"
                    >
                        {sortBy(suspendedClients, 'name').map((client, index) => {
                            return (
                                <MenuItem
                                    key={index}
                                    value={client.userSwitcher}
                                    data-testid={`suspended-client-option-${client.userSwitcher}`}
                                >
                                    {client.name}
                                </MenuItem>
                            )
                        })}
                    </TextField>
                </Box>
            )}
        </StandardDialog>
    )

    async function switchClient() {
        await setActiveClient(selected)
        ok(selected)

        getFirstAvailableSCCDemand().then((firstAvailableDemand) => {
            setTimeout(() => {
                navigate(firstAvailableDemand?.path || '/')
                window.location.reload()
            }, 500)
        })
    }
}

events.on('get-menu-items', function (event, items) {
    if (getCurrentAuthenticatedUser() && validateSecurity(AUTHENTICATED_PERMISSION)) {
        let accountMenuItems = []
        events.emit('get-account-menu-items', accountMenuItems)
        let defaultLogoutMenuItems = [
            {
                priority: -Infinity,
                bottom: true,
                title: (
                    <span style={{ textTransform: 'capitalize' }}>
                        {store.profile$?.profile?.name || cleanEmail(getCurrentUserEmail() || '')}
                    </span>
                ),
                id: 'userName',
                inactive: true,
                icon: <MdPerson />,
            },

            {
                priority: 1,
                title: 'Profile',
                id: 'profile',
                icon: (
                    <Avatar src={store.profile?.value?.profile?.picture}>
                        <MdPerson />
                    </Avatar>
                ),
                async click(hide) {
                    navigate('/user/profile')
                    hide()
                },
            },
            {
                title: 'Accept Invitation',
                id: 'accept',
                icon: <MdCreditCard />,
                secure: secureRoute(AUTHENTICATED_PERMISSION),
                async click(hide) {
                    hide()
                    let code = await showModal(InviteAccept)
                    if (code) {
                        handleInvite({ code })
                    }
                },
            },
            // {
            //     title: 'Release Notes',
            //     id: 'release-notes-menu',
            //     icon: <MdNewReleases />,
            //     async click(hide) {
            //         hide()
            //         navigate('/release-notes')
            //     },
            // },

            {
                priority: Infinity,
                bottom: true,
                title: 'Sign Out',
                id: 'signOut',
                icon: <MdLock />,
                async click(hide) {
                    events.emit('sign-out-event', true)
                    hide()
                },
            },
        ]

        if (store.profile$?.clients?.length > 1 && !validateSecurity(MANAGE_CLIENTS)) {
            defaultLogoutMenuItems.push({
                title: 'Switch Client',
                icon: <MdSubdirectoryArrowRight />,
                async click(hide) {
                    hide()
                    await showModal(SwitchClient, { fullWidth: true })
                },
                id: `switch-client`,
                priority: 20,
            })
        }
        if (isEmulated()) {
            defaultLogoutMenuItems.push({
                title: 'Stop Emulation',
                icon: <MdPerson />,
                async click(hide) {
                    hide()
                    try {
                        await process({ type: 'emulate.stop', realId: getActiveUser() })
                        location.reload()
                    } catch (e) {
                        console.error('Unable to remove emulation')
                        captureException(e)
                    }
                },
                id: `stop-emulation`,
                priority: 20,
            })
        }
        if (store.profile?.value?.clients?.length > 1 && !validateSecurity(MANAGE_CLIENTS)) {
            items['switchClient'] = {
                priority: 1000000,
                title: 'Switch Client',
                id: 'switchUser',
                aboveTheLine: true,
                bottom: true,
                icon: <FaUsers />,

                isActive() {
                    return false
                },
                async click() {
                    await showModal(SwitchClient, { fullWidth: true })
                },
            }
        }
        items['logout'] = {
            priority: Infinity,
            title: 'Account',
            id: 'account',
            bottom: true,
            security: secureRoute(AUTHENTICATED_PERMISSION),
            icon: (
                <Avatar className="avatar-menu" src={store.profile?.value?.profile?.picture}>
                    <MdPerson />
                </Avatar>
            ),
            children: defaultLogoutMenuItems.concat(accountMenuItems).sort(inPriorityOrder),
        }
    } else {
        items['login'] = {
            priority: Infinity,
            title: 'Sign In',
            id: 'signIn',
            bottom: true,
            isActive: '/login',
            icon: <MdVpnKey />,
            async click(hide) {
                navigate('/login')
                hide()
            },
        }
    }
})

/* SCP-838 / SCP 768 */
// import { refresh as menuRefresh } from 'common/menu'
// events.on('auth.*', function() {
//     menuRefresh()
// })

InviteAccept.propTypes = {
    cancel: PropTypes.any,
    ok: PropTypes.any,
}

SwitchClient.propTypes = {
    cancel: PropTypes.any,
    ok: PropTypes.any,
}
