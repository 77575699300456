import React, { useCallback, useContext, useRef } from 'react'

import PropTypes from 'prop-types'

import decamelize from 'decamelize'
import omit from 'lodash/omit'

import { raise, raiseLater } from 'common/events'
import noop from 'common/noop'
import { useLocalEvent } from 'common/use-event'
import { noThrottle, useRefresh } from 'common/useRefresh'

export const BoundContext = React.createContext({})

export function useBoundContext() {
    return useContext(BoundContext)
}

const removeSegmentsWithPeriods = (testId) => testId.replace(/[A-Z]+\./gi, '')
export function testId(...params) {
    const items = params
        .flatten()
        .map((v) => v.id || v._id || v.toString())
        .compact()
        .reverse()
        .join('-')
    return {
        'data-testid': makeTestId(items),
    }
}

export function makeTestId(name = '') {
    if (typeof name === 'object') {
        return name?.type?.name
    }
    if (typeof name !== 'string') return 'title'
    name = name.replace(/(™|®|©|&trade;|&reg;|&copy;)/g, '')

    let parts = name.split(' ')
    return (
        parts[0].toLowerCase() +
        parts
            .slice(1)
            .map((item) => (item[0] ?? '').toUpperCase() + item.slice(1).toLowerCase())
            .join('')
    )
}

export function useCurrentRefresh() {
    const { refresh } = useContext(BoundContext)
    return refresh
}

export function Bound({ target, refresh, save, field, document, children, ...props }) {
    if (save && typeof save !== 'function') {
        console.warn(`Warning - wrong type of save passed`)
    }
    const existing = useContext(BoundContext)
    let params = [...(existing?.refresh?.functions || []), save || existing.save]
    const localRefresh = useRefresh(noThrottle, ...params)
    localRefresh.target = target || existing.target
    refresh = refresh || existing.refresh || localRefresh
    return (
        <BoundContext.Provider
            value={Object.assign({}, existing, {
                ...props,
                target: target || existing.target,
                refresh: refresh || existing.refresh,
                microRefresh: localRefresh,
                save: save || existing.save,
                field: field || existing.field,
                document: document || existing.document,
            })}
        >
            {children}
        </BoundContext.Provider>
    )
}

export function Track({ field, children }) {
    const refresh = useRefresh()
    const { target } = useBoundContext()
    useLocalEvent(`bound-change.${field}`, (t) => t === target && refresh())
    if (typeof children === 'function') {
        return children(Object.get(target, field, true))
    } else {
        return <>{children}</>
    }
}

export function refreshIfNeeded() {
    setTimeout(() => raise('refresh-if-needed', null), 10)
}

export const boundInput = innerInput()

function innerInput(props = {}) {
    let result = function (innerProps) {
        return innerInput({ ...innerProps, ...props })
    }

    let value = undefined
    let onChange = noop
    let label = undefined
    let error = undefined
    let dataTestId = props['data-testid']
    let onBlur

    if (props.set) {
        value = props.value || ''
        onChange = (...params) => {
            let value
            if (props.get) {
                value = props.get(...params)
            } else if (props.raw) {
                value = params[0]
            } else {
                value = params[0].target[props.property || 'value']
            }
            value =
                typeof props.transform === 'function' && value !== ''
                    ? props.transform(value, Object.get(props.target, props.field, true))
                    : value
            props.set(value)
        }
    } else if (props.refresh && props.target && props.field) {
        value = Object.get(props.target, props.field, true) ?? props.default ?? ''
        Object.set(props.target, props.field, value)
        if (props.transformIn) {
            value = props.transformIn(value, props)
        }

        label =
            props.label !== false
                ? typeof props.label === 'string'
                    ? props.label
                    : typeof props.label === 'function'
                    ? props.label(props)
                    : makeLabelFrom(props.field)
                : undefined

        dataTestId =
            dataTestId ||
            removeSegmentsWithPeriods(
                `input-${props.field}-${props.target.id || props.target._id || props.target.name || ''}`
            )

        if (props.transformOnBlur && !props.sideEffectsOnBlur) {
            let activeRefresh = props.myRefresh
            onBlur = activeRefresh.planRefresh((...params) => {
                if (!props.bag._changed) return false
                props.bag._changed = false
                let value
                if (props.get) {
                    value = props.get(...params)
                } else if (props.raw) {
                    value = params[0]
                } else {
                    value = params[0].target[props.property || 'value']
                }

                value =
                    typeof props.transformOut === 'function'
                        ? props.transformOut(value, Object.get(props.target, props.field, true), props)
                        : value
                Object.set(props.target, props.field, value ?? '', true)

                if (props.onChanged) {
                    props.onChanged(value, props.field)
                }
            })
        }

        if (props.sideEffectsOnBlur) {
            let activeRefresh = props.refresh
            onBlur = activeRefresh.planRefresh((...params) => {
                if (!props.bag._changed) return false
                props.bag._changed = false

                let value
                if (props.get) {
                    value = props.get(...params)
                } else if (props.raw) {
                    value = params[0]
                } else {
                    value = params[0].target[props.property || 'value']
                }

                value =
                    typeof props.transformOut === 'function'
                        ? props.transformOut(value, Object.get(props.target, props.field, true), props)
                        : value
                Object.set(props.target, props.field, value ?? '', true)

                if (props.onChanged) {
                    props.onChanged(value, props.field)
                }
            })
        }
        const currentRefresh = props.myRefresh
        // const currentRefresh = props.miniRefresh
        onChange = currentRefresh.planRefresh((...params) => {
            let value
            if (props.get) {
                value = props.get(...params)
            } else if (props.raw) {
                value = params[0]
            } else {
                value = params[0].target[props.property || 'value']
            }
            if (!props.transformOnBlur) {
                value =
                    typeof props.transformOut === 'function'
                        ? props.transformOut(value, Object.get(props.target, props.field, true), props)
                        : value
            }
            if (Object.get(props.target, props.field, true) === (value ?? '')) {
                return false
            }
            props.bag._changed = true
            Object.set(props.target, props.field, value ?? '')
            raiseLater(`bound-change.${props.field}`, props.target)
            if (props.onChanged) {
                props.onChanged(value, props.field)
            }

            if (!value && props.onBlur) {
                props.onBlur(...params)
            }
        })
    }
    if (props.error) {
        error = props.error(value, props)
    }

    Object.assign(result, {
        value,
        label,
        onChange,
        onBlur,
        error,
        'data-testid': dataTestId, //
        default: props.default,
    })
    return result
}

const PROPS = [
    'transformIn',
    'defaultValue',
    'transformOut',
    'transformOnBlur',
    'onChanged',
    'refresh',
    'sideEffects',
    'sideEffectsOnBlur',
    'target',
    'get',
    'property',
    'field',
    'set',
]

export function useDerivation(child, transform) {
    const Type = child.type
    let refreshId = 0
    let result = useCallback(function ({ children, ...props }) {
        let extraProps = {}
        // eslint-disable-next-line react-hooks/rules-of-hooks
        const context = useContext(BoundContext)
        let refresh = context.refresh
        if (!props.sideEffects) {
            // eslint-disable-next-line react-hooks/rules-of-hooks
            refresh = useRefresh(noThrottle, ...(refresh?.functions ?? []))
            refresh.target = context.target
            refresh.local = true
        }
        if (transform) {
            extraProps = transform({
                ...context,
                ...child.props,
                refresh,
                ...props,
            })
        }
        const { id, name, type } = context.target || {}
        return (
            <Type
                {...omit(
                    {
                        'data-testid': makeTestId(`${id} ${name ? name : type}-${props.field}`),
                        ...child.props,
                        ...extraProps,
                        ...props,
                        'data-refid': refreshId++,
                    },
                    !Type._INNER ? PROPS : []
                )}
            >
                {children}
            </Type>
        )
    }, [])
    result._INNER = true

    return result
}

export function derived(child, transform) {
    const Type = child.type

    function DComponent({ children = null, ...props }) {
        let extraProps = {}
        const bag = useRef({})
        const context = useContext(BoundContext)
        let onBlur = noop
        let refresh = context.refresh
        if (!props.sideEffects) {
            // onBlur = refresh
            // eslint-disable-next-line react-hooks/rules-of-hooks
            refresh = useRefresh(noThrottle, context?.refresh?.functions)
            refresh.__child = child
            refresh.target = context.target
        }

        if (transform) {
            extraProps = transform({ myRefresh: refresh, refresh, ...context, bag: bag.current, ...props })
        }

        const { id, name, type } = context.target || {}
        return (
            <Type
                {...omit(
                    {
                        'data-testid': makeTestId(`${id} ${name ? name : type}-${props.field}`),
                        ...child.props,
                        ...extraProps,
                        ...props,
                    },
                    ['save', ...(!Type._INNER ? PROPS : [])]
                )}
                onBlur={(e) => {
                    onBlur(e)
                    props.onBlur?.(props.onBlur(e))
                    extraProps.onBlur && extraProps.onBlur(e)
                    child.props?.onBlur && child.props.onBlur(e)
                }}
            >
                {children}
            </Type>
        )
    }

    DComponent.propTypes = {
        children: PropTypes.any,
        field: PropTypes.any,
        sideEffects: PropTypes.any,
    }

    let result = React.memo(DComponent)
    result._INNER = true
    return result
}

export function makeLabelFrom(fieldName) {
    fieldName = fieldName.split('.').slice(-1)[0]
    return decamelize(fieldName).titleize()
}

Bound.propTypes = {
    children: PropTypes.any.isRequired,
    document: PropTypes.any,
    field: PropTypes.any,
    refresh: PropTypes.func,
    save: PropTypes.func,
    target: PropTypes.object,
}

String.prototype.in = function (array, id = 'id') {
    return array.find((i) => i[id] === this)
}

Track.propTypes = {
    children: PropTypes.any.isRequired,
    field: PropTypes.any.isRequired,
}
