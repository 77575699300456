import memoize from 'memoizee'
import { handle } from 'common/events'

export const enabledQuestions = memoize(
    function enabledQuestions(instance) {
        if (!instance?.sendMessage) return new Set()
        return new Set(instance.sendMessage('allActiveQuestions', [], instance).map('name'))
    },
    { maxAge: 250, primitive: true }
)

handle('question.exit.*', () => enabledQuestions.clear())

export function isEnabled(question, instance) {
    if (!instance?.sendMessage) return true
    const name = typeof question === 'string' ? question : question.name
    return enabledQuestions(instance).has(name.split('.')[0])
}
