const HookedEvents = require('./hooked-events')

let start = Date.now()

if (global.__LOCAL_EVENTS && global.__LOCAL_EVENTS !== start) {
    // console.error("LOCAL EVENTS LOADED MORE THAN ONCE")
}

global.__LOCAL_EVENTS = start

const events = (module.exports = global.events = global.events || new HookedEvents())
module.exports.events = module.exports

function raise(event, ...params) {
    return events.emit(event, ...params)
}

async function raiseAsync(event, ...params) {
    return await events.emitAsync(event, ...params)
}

function handle(event, handler) {
    events.on(event, async (event, ...params) => {
        if (false === (await handler(...params))) {
            event.preventDefault()
        }
    })
}

function when(event, handler) {
    events.once(event, async (event, ...params) => {
        if (false === (await handler(...params))) {
            event.preventDefault()
        }
    })
}

events.raise = raise
events.raiseAsync = raiseAsync
events.handle = handle
events.when = when
