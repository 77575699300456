import React, { useContext } from 'react'
import { globalWidgets } from './events'

const WidgetContext = React.createContext({
    design: null,
    document: null,
    parentHandlers: {},
    handlers: {},
    widgets: globalWidgets,
})

function useWidgetContext() {
    return useWidgetContext.override || useContext(WidgetContext)
}

useWidgetContext.with = function (context, fn) {
    useWidgetContext.override = context
    fn()
    useWidgetContext.override = null
}

/**
 * This hook is used to add items that render the content of a Widget.
 * It is the primary function of the widget to display this content
 * outside of edit mode
 *
 * @param {ReactComponent} [newContent] - optional component to add to the list
 * @returns {(ComponentListReturn|Array)}
 * @example
 * widgets.render("dashboardItem.pie", () => {
 *    useContent(Render)
 * })
 * //or
 * widgets.render("dashboardItem.pie", () => {
 *    const [existingContents, addContent] = useContent()
 *    if(existingContents.length < 2) addContent(Render)
 * })
 */

let _content
let _widgetContext

globalWidgets.on('prepare-render', function () {
    _widgetContext = useWidgetContext()
    _content = useContent()
})

export function getContent() {
    return _content
}

export function getWidgetContext() {
    return _widgetContext
}

function useContent(newContent) {
    const context = useWidgetContext()
    let { content } = context
    let result = [
        content,
        function addItem(newContent) {
            content.push(newContent)
        },
    ]
    if (newContent) {
        result[1](newContent)
    }
    return result
}

export { WidgetContext, useContent, useWidgetContext }
