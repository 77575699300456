import React from 'react'

import PropTypes from 'prop-types'

import {
    arrayMoveInPlace,
    globalWidgets,
    thereCanBeOnlyOne,
    useContent,
    useWidgetContext,
    Widget,
} from 'packages/al-react-ioc-widgets'
import { generate } from 'packages/identifiers'

import { ensureArray } from 'common/ensure-array'

const dedupe = {}

/**
 *
 * @param {String} type - the name of the socket to be plugged
 * @param {any} Component - the component to insert into the socket
 * @param {boolean} replace - should the plug replace current contents
 * @param {Number} priority - the priority of the plug in the socket
 */
export function plug(type, Component, replace = false, priority = undefined) {
    if (Array.isArray(type)) console.error('type must be a string')
    const components = (dedupe[type] = dedupe[type] || {})
    Component._name = Component._name || Component.name || generate()
    if (components[Component._name]) {
        // eslint-disable-next-line no-console
        // console.trace(Component._name, 'Was already seen', type)
        return components[Component._name]
    }

    function InnerPlug() {
        const context = useWidgetContext()
        return <Component {...context} />
    }

    components[Component._name] = InnerPlug

    InnerPlug.onBeforeRender = Component.onBeforeRender
    InnerPlug.priority = priority || Component.priority || -1

    InnerPlug.replace = function (fn) {
        InnerPlug.onBeforeRender = (list) => {
            let result = fn(list, InnerPlug)
            if (result === true) {
                const index = list.findIndex((i) => i === InnerPlug)
                if (index !== -1) {
                    list.length = index + 1
                }
            } else if (result === false) {
                const index = list.findIndex((i) => i === InnerPlug)
                if (index !== -1) {
                    list.splice(index, 1)
                }
            }
        }
        return InnerPlug
    }
    if (replace) {
        if (typeof replace === 'function') {
            InnerPlug.replace(replace)
        } else {
            InnerPlug.replace(() => true)
        }
        globalWidgets.render(type, () => useContent(InnerPlug))
    }
    ensureArray(type).forEach((type) => globalWidgets.render(type, () => useContent(InnerPlug)))
    return InnerPlug
}

export function pluggable(withName, fn) {
    plug(withName, fn)
    return function (props) {
        return <UISocket {...props} type={withName} />
    }
}

export { globalWidgets }

export function priority(priority, fn) {
    fn.priority = priority
    return fn
}

export function replace(fn) {
    fn.onBeforeRender = (list) => (list.length = list.findIndex((i) => i === fn) + 1)
    return fn
}

export function UISocket(props) {
    return <Widget {...props} />
}

// export function UIDummySocket({ children, ...props }) {
//     return <DummyWidget {...props}>{children}</DummyWidget>
// }

export const UIStableSocket = React.memo(UISocket, (prev, next) => prev.track === next.track)

export { useContent, useWidgetContext, thereCanBeOnlyOne, Widget, arrayMoveInPlace }

export function widgetSettings(Component, priority, replace, options) {
    Component.priority = priority
    Component.replace = replace
    Object.assign(Component, options)
    return Component
}

UISocket.propTypes = {
    children: PropTypes.any,
    type: PropTypes.string.isRequired,
}
