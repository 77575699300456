import React, { useCallback, useEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import ResizeObserver from 'resize-observer-polyfill'
import { heightCalculator } from './height-calculator'
import { useCurrentState, useDeferredState } from './use-current-state'
import { DefaultWrapper } from './default-wrapper'
import { noop } from './noop'
import { useMeasurement } from './use-measurement'
import { ScrollIndicatorHolder } from './scroll-indicator'
import debounce from 'lodash/debounce'

export { useMeasurement, useCurrentState, ScrollIndicatorHolder }

const MEASURE_LIMIT = 3

let id = 0
const scrollEventParams = {
    items: null,
    scrollTop: 0,
    start: 0,
    last: 0,
    index: 0,
    max: 0,
    scroller: null,
    scrollTo: () => {},
}

let uqId = 0
let seq = 1

export const Virtual = React.forwardRef(function Virtual(
    {
        items,
        scrollToItem,
        useAnimation = true,
        onInit = noop,
        expectedHeight = 64,
        scrollTop = 0,
        onScroll = noop,
        renderItem,
        className,
        overscan = 1,
        onSize = noop,
        Holder = DefaultWrapper,
        Wrapper = DefaultWrapper,
        adjustHeight = noop,
        ...props
    },
    passRef
) {
    const holder = useRef()
    let [state] = useState(() => {
        scrollEventParams.items = items
        scrollEventParams.getPositionOf = getPositionOfItem
        scrollEventParams.getHeightOf = getHeightOf
        scrollEventParams.getItemFromPosition = getItemFromPosition
        scrollEventParams.scrollTo = scrollTo
        const cache = (scrollEventParams.itemCache = new Map())
        onInit(scrollEventParams)
        return {
            cache,
            seq: seq++,
            positions: [],
            render: 0,
            hc: heightCalculator(getHeightOf),
            heights: [],
            measured: 1,
            redraw: false,
            scroll: scrollTop,
            refresh: noop,
            forceHeight: props.height,
            scrollUpdate: noop,
            expectedHeight,
            measuredHeights: expectedHeight,
            itemHeight: expectedHeight,
            componentHeight: 1000,
            item: -1,
            id: 0,
            counter: 0,
            renders: [],
            others: [],
        }
    })
    if (!Array.isArray(items)) {
        items = { length: items, useIndex: true }
    }
    items._id = items._id || props.tag || id++
    if (items._id !== state.lastId || items.length !== state.lastLength) {
        state.lastId = items._id
        state.lastLength = items.length
        state.cache.clear()
        state.hc.invalidate(-1)
        state.redraw = true
    }
    useAnimation = useAnimation || scrollToItem
    adjustHeight(adjust)

    const [{ height: currentHeight }, attach] = useMeasurement()
    state.componentHeight = state.forceHeight || currentHeight || props.height
    if (props.height === undefined && state.currentHeight !== currentHeight) {
        state.redraw = true
        state.currentHeight = currentHeight
    }

    const scrollInfo = useRef({ lastItem: 0, lastPos: scrollTop })
    const scrollPos = state.scroll
    const endRef = useRef()

    let offset = Math.min(
        10000000,
        scrollInfo.current.lastPos + (items.length - scrollInfo.current.lastItem) * state.itemHeight
    )
    useEffect(() => {
        state.scroller.scrollTop = scrollPos
        if (!useAnimation) return
        const control = { running: true, beat: 0 }
        requestAnimationFrame(animate(control))
        return () => {
            control.running = false
        }
    }, [useAnimation, scrollPos])
    if (state.redraw) {
        state.refresh()
    }
    return (
        <Holder
            {...props}
            className={className}
            state={state}
            ref={componentHeight}
            style={{
                ...props,
                ...props.style,
                position: 'relative',
                display: props.display,
                width: props.width || '100%',
                height: props.height,
                flexGrow: props.flexGrow,
                overflow: 'hidden',
                minHeight: props.minHeight,
                maxHeight: props.maxHeight,
            }}
        >
            <div
                onScroll={scroll}
                ref={attachScroller}
                style={{
                    WebkitOverflowScrolling: 'touch',
                    scrollTop: state.scroll,
                    position: 'absolute',
                    left: 0,
                    top: 0,
                    right: 0,
                    bottom: 0,
                    overflowY: 'auto',
                }}
            >
                <PhysicalItems
                    end={endRef}
                    state={state}
                    onSize={onSize}
                    getHeightOf={getHeightOf}
                    getItemFromPosition={getItemFromPosition}
                    getPositionOf={getPositionOfItem}
                    overscan={overscan}
                    currentHeight={state.componentHeight}
                    items={items}
                    onScroll={onScroll}
                    Wrapper={Wrapper}
                    scrollTo={scrollTo}
                    renderItem={renderItem}
                    from={scrollPos}
                    scrollInfo={scrollInfo.current}
                />
                <div ref={endRef} style={{ marginTop: offset - 1, height: 1 }} />
            </div>
        </Holder>
    )

    function adjust(newHeight) {
        state.currentHeight = newHeight
        state.forceHeight = newHeight
        setTimeout(() => {
            if (holder.current) {
                holder.current.style.maxHeight = holder.current.style.height = `${newHeight}px`
            }
        })
    }

    function attachScroller(target) {
        state.scroller = target
    }

    function componentHeight(target) {
        if (target) {
            holder.current = target
            requestAnimationFrame(() => {
                passRef && passRef(target)
                attach && attach(target)
                target._component = true
                target.scrollTop = state.scroll
            })
        }
    }

    function getPositionOfItem(item) {
        if (item < 0 || item > items.length - 1) return 0
        if (state.measured < MEASURE_LIMIT) {
            return state.itemHeight * item
        }
        return state.hc(item)
    }

    function getItemFromPosition(from) {
        if (from < 0) return 0

        let start = 0
        let end = items.length
        if (end === 0) {
            return 0
        }
        const max = Math.abs(Math.log2(end) + 1)
        let c = 0
        let middle
        do {
            middle = (((end - start) / 2) | 0) + start
            let posStart = getPositionOfItem(middle)
            let posEnd = posStart + getHeightOf(middle)
            if (posStart <= from && posEnd > from) return middle
            if (posStart > from) {
                end = middle
            } else {
                start = middle
            }
        } while (c++ < max && max < Number.POSITIVE_INFINITY)
        return middle
    }

    function getHeightOf(item) {
        let height = state.heights[item]
        return height !== undefined ? height : state.itemHeight
    }
    function animate(control) {
        control.count = 0
        function inner() {
            if (state.scroller && state.scroller.scrollTop !== 0) {
                state.scroll = state.scroller.scrollTop
            }

            control.beat++
            if (control.count < 5) {
                // state.scroller.scrollTop = state.scroll
            }
            if (scrollToItem && control.count++ < 8) {
                let pos = getPositionOfItem(scrollToItem)
                // state.scroller.scrollTop = pos
            } else {
                scrollToItem = undefined
            }
            if ((control.beat & 3) === 0) {
                // state.scroll = state.scroller.scrollTop
                // state.scrollUpdate(state.scroller.scrollTop)
            }
            if (control.running) requestAnimationFrame(inner)
        }

        return inner
    }

    function scroll(event) {
        state.scroll = event.target.scrollTop
        state.scrollUpdate(state.scroll)
    }

    function scrollTo(item) {
        const pos = getPositionOfItem(item)
        state.scroll = pos
        state.scroller.scrollTop = pos
    }
})

function PhysicalItems({
    from,
    scrollInfo,
    getPositionOf,
    state,
    getItemFromPosition,
    overscan,
    onScroll,
    renderItem,
    onSize,
    getHeightOf,
    scrollTo,
    currentHeight,
    Wrapper,
    end,
    items,
}) {
    const scroller = useCallback(debounce(onScroll), [])
    const [id, refresh] = useDeferredState(0)
    state.refresh = () => refresh(id + 1)
    let [, setScrollPos] = useCurrentState(from)
    let scrollPos = state.scroller?.scrollTop ?? state.scroll
    state.scroll = state.from = scrollPos
    state.scrollUpdate = setScrollPos
    let lookBehind = Math.min(state.itemHeight * 10, state.componentHeight * overscan)
    let item = Math.max(0, getItemFromPosition(scrollPos - lookBehind))
    let first = getItemFromPosition(scrollPos)

    if (end.current) {
        end.current.style.marginTop = `${getPositionOf(items.length - 1) + getHeightOf(items.length - 1)}px`
    }
    // if (state.index !== first || state.redraw) {
    state.redraw = false
    state.index = first
    state.item = item
    state.render++
    const renders = state.renders
    renders.length = 0
    let scan = item
    let maxY = scrollPos + currentHeight * (overscan + 1.1) + state.itemHeight * 2
    let y = getPositionOf(scan)
    while (y <= maxY && scan < items.length) {
        renders.push(
            <div
                key={scan}
                style={{
                    width: '100%',
                    position: 'absolute',
                    top: y,
                    left: 0,
                    right: 0,
                    display: 'flex',
                    alignItems: 'center',
                }}
            >
                {render(scan, items, onSize, renderItem, getPositionOf, state)}
            </div>
        )
        if (scan >= scrollInfo.lastItem) {
            scrollInfo.lastItem = scan
            scrollInfo.lastPos = y
        }
        scan++
        y = getPositionOf(scan)
    }

    state.scan = scan
    // }

    scrollEventParams.items = items
    scrollEventParams.scrollTop = scrollPos
    scrollEventParams.start = item
    scrollEventParams.index = first
    scrollEventParams.last = state.scan
    scrollEventParams.max = scrollInfo.lastItem
    scrollEventParams.scroller = state.scroller
    scrollEventParams.getPositionOf = getPositionOf
    scrollEventParams.getHeightOf = getHeightOf
    scrollEventParams.scrollTo = scrollTo
    scrollEventParams.getItemFromPosition = getItemFromPosition
    scrollEventParams.itemCache = state.cache
    scroller(scrollEventParams)

    return <Wrapper style={{ position: 'relative', overflow: 'visible' }}>{state.renders}</Wrapper>
}

function render(item, items, onSize, renderItem, getPositionOf, state) {
    const cache = state.cache
    if (cache.has(item)) return cache.get(item)
    const toRender = !items.useIndex ? items[item] : item
    let result =
        !!toRender || items.useIndex ? (
            <PhysicalItem
                state={state}
                onSize={onSize}
                renderItem={renderItem}
                getPositionOf={getPositionOf}
                item={item}
                key={`pi-${item}`}
                toRender={toRender}
            />
        ) : null
    cache.set(item, result)
    return result
}

function PhysicalItem({ state, item, toRender, getPositionOf, onSize, renderItem }) {
    let observer = new ResizeObserver((entries) => {
        const entry = entries[0]
        let height = entry.contentRect.height
        const itemToCheck = entry.target?._item
        // let differenceInHeight = height - (state.heights[itemToCheck] || 0)
        if (height > 8) {
            if (state.heights[itemToCheck] !== height) {
                let pos = getPositionOf(state.index)
                if (state.heights[itemToCheck]) {
                    state.measuredHeights -= state.heights[itemToCheck]
                    state.measured--
                }
                if (state.measured === 1) {
                    state.measuredHeights = height
                    state.measured++
                } else {
                    state.measuredHeights += height
                    state.measured++
                }
                state.itemHeight = state.measuredHeights / Math.max(1, state.measured - 1)
                state.heights[itemToCheck] = height
                if (state.measured < MEASURE_LIMIT) {
                    state.hc.invalidate(-1)
                } else {
                    state.hc.invalidate(itemToCheck)
                }
                let newpos = getPositionOf(state.index)
                let diff = newpos - pos
                if (state.scroller) {
                    state.scroller.scrollTop += diff
                }
                onSize({
                    averageHeight: state.itemHeight,
                    height,
                    item: itemToCheck,
                })
            }
        }

        state.redraw = true
        state.refresh()
    })
    useEffect(() => {
        return () => {
            observer.disconnect()
        }
    })

    return (
        <div style={{ flexGrow: 1, maxWidth: '100%' }} ref={observe}>
            {renderItem(toRender, item)}
        </div>
    )

    function observe(target) {
        if (target) {
            target._item = item
            observer.observe(target)
        }
    }
}

Virtual.propTypes = {
    Wrapper: PropTypes.func,
    display: PropTypes.any,
    expectedHeight: PropTypes.number,
    flexGrow: PropTypes.any,
    height: PropTypes.any,
    items: PropTypes.oneOfType([PropTypes.array, PropTypes.number]).isRequired,
    maxHeight: PropTypes.any,
    minHeight: PropTypes.any,
    onScroll: PropTypes.func,
    overscan: PropTypes.number,
    renderItem: PropTypes.func.isRequired,
    scrollTop: PropTypes.number,
    useAnimation: PropTypes.bool,
    width: PropTypes.any,
}
