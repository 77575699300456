import { handle, raise } from 'common/events'
import { loadComponent } from 'common/components'
import { decorate } from 'common/utils'
import { initializeSync } from 'common/offline-data-service/behaviour-cache'
import { getVersion } from 'dynamic/awe-library/versions'
import { get } from 'common/offline-data-service/functions/get'

export const securityReady = new Promise((resolve) => {
    handle('refresh-apps', resolve)
})
export let typeVersions = {}

handle('before.data.updated.**', (id) => {
    if (!id) return
    if (id.endsWith('versions') || id.endsWith('-types')) {
        typeVersions = {}
    }
})

/**
 * Given a record, that has previously been prepared for storage
 * (the behaviours removed and replaced with a small reference
 * to the underlying document type) this finds the type and
 * makes the document whole again ready for processing.
 *
 * The retrieved document is initialized and ready to
 * perform behaviour actions.
 *
 * @param {Document} record - the record to hydrate
 * @returns {Promise<Document>} a promise for the rehydrated
 * document
 */
export async function hydrateDocument(record) {
    if (!record) return null
    await securityReady
    const toHydrate = record._settings?.$id
    if (toHydrate) {
        const state = record._settings.$state
        let key = `${toHydrate}:${record._version}`
        let version = JSON.parse(await (typeVersions[key] = typeVersions[key] || retrieve())) || {}
        record._settings = version._settings || {}
        record._behaviours = version._behaviours || {}
        Object.defineProperty(record._settings, '$state', {
            get() {
                return record._behaviours?._state || ''
            },
        })
        delete record._settings._behaviours
        record._settings.$hydrate = toHydrate
        record._behaviours._state = state
    }
    if (record._id) {
        raise(`will-initialise-type.${record._id.split(':')[1]}`, record)
    }
    const errors = []
    const components = record._settings?.components || record.components || ''
    record._components = Promise.all(
        components
            .split(',')
            .filter((c) => c && typeof c === 'string' && c.trim())
            .map((c) => c.trim())
            .map(async (component) => {
                try {
                    const isAnEditorDocument = !record._settings
                    await loadComponent(component, isAnEditorDocument)
                } catch (e) {
                    errors.push(`Did not load "${component}"`)
                }
            })
    )
    if (errors.length) {
        decorate(record._settings || record, { $errors: errors.join(', ') })
    }
    initializeSync(record)
    return record

    async function retrieve() {
        let version
        const type = await get(toHydrate, true, true)
        if (!type) {
            console.error(`Hydration base "${toHydrate}" not found`)
            return null
        }
        const retrievedType = (await getVersion(type, record._version)) || type
        version = {
            _settings: retrievedType,
        }
        version._behaviours = version._settings?._behaviours || {}
        delete version._settings._behaviours

        return JSON.stringify(version)
    }
}

handle('published', () => {
    typeVersions = {}
})
