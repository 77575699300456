import React from 'react'

import PropTypes from 'prop-types'

import events from 'packages/alcumus-local-events'

import PopupState, { bindPopper, bindToggle } from 'material-ui-popup-state'

import Fade from '@material-ui/core/Fade'
import Popper from '@material-ui/core/Popper'

import { store } from 'common/global-store'
import { define } from 'common/process'
import useAsync from 'common/use-async'
import { useEvent } from 'common/use-event'
import { useRefresh } from 'common/useRefresh'

import { NotificationIcon } from './notification-icon'
import { NotificationList } from './notification-list'
import { getLocalItem, storeLocalItem } from 'common/local-storage/local-store'
import { delay } from 'common/utils/delay'
import { makeCachedStyles } from 'common/inline-styles'

const getNotifications = define('notifications.get', function ({ offlineCache, returns, cacheResult }) {
    cacheResult(async (result) => {
        await storeLocalItem('notifications_cache', result)
    })
    offlineCache(async () => {
        return await getLocalItem('notifications_cache')
    })
    returns('items')
})

const TRANSITION_TIME = 0

const useNotificationPopupStyles = makeCachedStyles((__theme) => ({
    removeOutline: {
        '&:focus': {
            outline: 'none',
        },
    },
    notificationBox: {
        marginTop: 5,
        zIndex: 1400,
    },
    root: {
        position: 'absolute',
        right: '5em',
        color: 'white',
        zIndex: 130000,
        transform: 'translateY(-50%)',
    },
}))

const POPUP_MODIFIERS = {
    flip: {
        enabled: false,
    },
    arrow: {
        enabled: true,
        element: '.arrowElement',
    },
}

const NotificationPopup = ({ items = [], transitionTime = TRANSITION_TIME, modifiers = POPUP_MODIFIERS }) => {
    const classes = useNotificationPopupStyles()
    const headerHeight = store.$pageHeaderHeight
    return (
        <PopupState variant="popper" popupId="notifications-popper">
            {(popupState) => (
                <div className={classes.root} style={{ top: headerHeight / 2 }}>
                    {!!items.length && (
                        <NotificationIcon
                            count={items.length}
                            data-testid="notifications-trigger"
                            {...bindToggle(popupState)}
                        />
                    )}
                    <Popper
                        transition
                        placement="bottom-end"
                        className={classes.notificationBox}
                        modifiers={modifiers}
                        {...bindPopper(popupState)}
                    >
                        {({ TransitionProps }) => (
                            <Fade {...TransitionProps} timeout={transitionTime}>
                                <>
                                    <span className="arrowElement" x-arrow="true" />
                                    <NotificationList items={items} {...popupState} />
                                </>
                            </Fade>
                        )}
                    </Popper>
                </div>
            )}
        </PopupState>
    )
}

NotificationPopup.propTypes = {
    items: PropTypes.array.isRequired,
    modifiers: PropTypes.object,
    transitionTime: PropTypes.number,
}

let notificationDelay = 5

export const Notification = React.memo(function Notification() {
    const refresh = useRefresh().debounce(100)
    useEvent(events, 'refresh-notifications', () => {
        refresh()
    })
    const notifications = useAsync(async () => {
        await delay(notificationDelay)
        notificationDelay = 0
        return await getNotifications()
    })
    return notifications && <NotificationPopup items={notifications} />
})
