import { LocationProvider } from '@reach/router'
import React from 'react'
import { raise } from 'common/events'

function getLocation(source) {
    const _source$location = source.location,
        pathname = _source$location.pathname,
        search = _source$location.search,
        hash = _source$location.hash,
        href = _source$location.href,
        origin = _source$location.origin,
        protocol = _source$location.protocol,
        host = _source$location.host,
        hostname = _source$location.hostname,
        port = _source$location.port

    return {
        pathname: pathname,
        search: search,
        hash: hash,
        href: href,
        origin: origin,
        protocol: protocol,
        host: host,
        hostname: hostname,
        port: port,
        state: source.history.state,
        key: (source.history.state && source.history.state.key) || 'initial',
    }
}

export let localHistory = createBlockableHistory(window)

export function AlcumusLocation({ children }) {
    return <LocationProvider history={localHistory}>{children}</LocationProvider>
}

function createBlockableHistory(source) {
    let listeners = []
    let location = getLocation(source)
    let transitioning = false
    let resolveTransition = function resolveTransition() {}

    return {
        get location() {
            return location
        },

        get transitioning() {
            return transitioning
        },

        _onTransitionComplete: function _onTransitionComplete() {
            transitioning = false
            resolveTransition()
        },
        listen: function listen(listener) {
            listeners.push(listener)

            function popstateListener() {
                location = getLocation(source)
                listener({ location: location, action: 'POP' })
            }

            source.addEventListener('popstate', popstateListener)

            return function () {
                source.removeEventListener('popstate', popstateListener)
                listeners = listeners.filter(function (fn) {
                    return fn !== listener
                })
            }
        },
        navigate: function navigate(to) {
            let _ref = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {},
                state = _ref.state,
                _ref$replace = _ref.replace,
                replace = _ref$replace === undefined ? false : _ref$replace

            if (typeof to === 'number') {
                source.history.go(to)
            } else {
                state = Object.assign({}, state, { key: Date.now() + '' })
                // try...catch iOS Safari limits to 100 pushState calls
                try {
                    if (transitioning || replace) {
                        source.history.replaceState(state, null, to)
                    } else {
                        source.history.pushState(state, null, to)
                    }
                } catch (e) {
                    source.location[replace ? 'replace' : 'assign'](to)
                }
            }

            location = getLocation(source)
            transitioning = true
            let transition = new Promise(function (res) {
                return (resolveTransition = res)
            })
            if (!_ref.noEvents) {
                listeners.forEach(function (listener) {
                    return listener({ location: location, action: 'PUSH' })
                })
            }
            raise('navigate.changed', location)
            setTimeout(resolveTransition)
            return transition
        },
    }
}
