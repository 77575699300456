import { define } from 'common/process'
import { getUnprefixedItemAndParse, setUnprefixedItemWithStringify } from 'common/using-local-storage-key'
import { initializeSync } from 'common/offline-data-service/behaviour-cache'
import { loadComponents } from 'common/components'

export let universal
try {
    universal = getUnprefixedItemAndParse('universal') || [0, null, '@WORK']
} catch (e) {
    universal = [0, null, '@WORK']
}

export let allProjects = []

export const getUniversal = define('universal.clients', function ({ returns, after, offlineCache, cacheResult }) {
    cacheResult(async (result) => {
        universal = result
        setUnprefixedItemWithStringify('universal', result)
    })
    offlineCache(async () => {
        return (universal = getUnprefixedItemAndParse('universal'))
    })
    after(async (result) => {
        if (result?.projects) {
            allProjects = await Promise.all(
                result.projects.map(async (project) => {
                    await loadComponents(true, project.components)
                    initializeSync(project)
                    project.sendMessage('loaded', Date.now())
                    return project
                })
            )
        }
    })
    returns('universal')
})

setInterval(getUniversal, 1000 * 60 * 5)
