import Window from 'common/globals/window'
import ProcessEnv from 'common/globals/processEnv'
import Navigator from 'common/globals/navigator'

function toProcessEnvPath(pathToWindowProperty) {
    // pascalCase -> SCREAMING_CAPS
    let suffix = pathToWindowProperty
        .replace(/(?:^|\.?)([A-Z])/g, function (x, y) {
            return '_' + y.toLowerCase()
        })
        .replace(/^_/, '')
        .toUpperCase()
    return 'REACT_APP_' + suffix
}

/**
 * @class StandardDebugFlag
 * @description
 * This is intended to be an enum value that lets you check quickly if something is on window or process.env
 * Process.env will allow you to override the value if desired.
 */
class StandardDebugFlag {
    constructor(path) {
        this._path = path
        this._processEnvPath = toProcessEnvPath(path)
    }

    isEnabled() {
        if (Window.hasProperty(this._path)) {
            return !!Window.getProperty(this._path)
        }
        return ProcessEnv.hasFlagEnabled(this._processEnvPath)
    }
}

class OnlineFlag {
    isEnabled() {
        if (Window.hasProperty('FAKE_NAVIGATOR_ONLINE')) {
            let windowValue = Window.getProperty('FAKE_NAVIGATOR_ONLINE')
            let overrideValue = windowValue === 'true' || windowValue === true
            console.info(`DEBUG: navigator.onLine override returning: ${overrideValue}`)
            return overrideValue
        }

        return Navigator.hasFlagEnabled('onLine')
    }
}

export const DEBUG_PROCESS = Object.freeze(new StandardDebugFlag('debugProcess'))
export const ONLINE = Object.freeze(new OnlineFlag())
export const NO_MOBILE_HTTPD = Object.freeze(new StandardDebugFlag('noMobileHttpd'))
