import qs from 'querystring'
import { getLocation } from 'common/routing'
import { handle } from 'common/events'

let parameters = qs.parse(getLocation().location.search.slice(1))
let originalParameters = parameters

export function getSearchParameters() {
    return parameters
}

export function mergeParameters(merge = {}) {
    return qs.stringify(Object.assign({}, parameters, merge))
}

export function parameter(name) {
    return parameters[name] || originalParameters[name]
}

export function currentParameter(name) {
    return parameters[name]
}

handle('app.navigate', () => {
    parameters = qs.parse(getLocation().location.search.slice(1))
})

handle('navigate.changed', () => {
    parameters = qs.parse(getLocation().location.search.slice(1))
})
