import { useMenuStyles } from 'common/menu/MenuBar'
import { store } from 'common/global-store'
import { Avatar, Tooltip } from '@material-ui/core'
import classNames from 'classnames'
import { MdSync } from '@react-icons/all-files/md/MdSync'
import React from 'react'
import { makeCachedStyles } from 'common/inline-styles'

const useStyles = makeCachedStyles(() => {
    return {
        '@keyframes rotation': {
            '0%': {
                transform: 'rotate(359deg)',
            },
            '100%': {
                transform: 'rotate(0deg)',
            },
        },
        rotate: {
            animation: `$rotation 2s infinite linear`,
        },
    }
})

export function Sync() {
    const classes = useStyles()
    const menuClasses = useMenuStyles()
    const number = store.$aweSyncNumber || '∞'
    const value = store.$aweSync
    return (
        !!value && (
            <Tooltip title={`Offline Sync: ${number} document(s)`}>
                <Avatar className={classNames(menuClasses.menuCircle, menuClasses.aboveItem)}>
                    <MdSync className={classes.rotate} />
                </Avatar>
            </Tooltip>
        )
    )
}
