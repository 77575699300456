import { useEffect, useRef, useState } from 'react'

export function useCurrentState(...params) {
    const [value, setValue] = useState(...params)
    let currentSetV = useRef()
    currentSetV.current = setValue
    useEffect(() => {
        return () => (currentSetV.current = null)
    }, [])
    return [
        value,
        function (...params) {
            currentSetV.current && currentSetV.current(...params)
        },
    ]
}

export function useDeferredState(...params) {
    const [value, setValue] = useState(...params)
    let currentSetV = useRef()
    currentSetV.current = setValue
    useEffect(() => {
        return () => (currentSetV.current = null)
    }, [])
    return [
        value,
        function (...params) {
            setTimeout(() => {
                currentSetV.current && currentSetV.current(...params)
            })
        },
    ]
}
