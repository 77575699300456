/* eslint-disable */

module.exports =
    /*
     * Generated by PEG.js 0.10.0.
     *
     * http://pegjs.org/
     */
    (function () {
        'use strict'

        function peg$subclass(child, parent) {
            function ctor() {
                this.constructor = child
            }

            ctor.prototype = parent.prototype
            child.prototype = new ctor()
        }

        function peg$SyntaxError(message, expected, found, location) {
            this.message = message
            this.expected = expected
            this.found = found
            this.location = location
            this.name = 'SyntaxError'

            if (typeof Error.captureStackTrace === 'function') {
                Error.captureStackTrace(this, peg$SyntaxError)
            }
        }

        peg$subclass(peg$SyntaxError, Error)

        peg$SyntaxError.buildMessage = function (expected, found) {
            var DESCRIBE_EXPECTATION_FNS = {
                literal: function (expectation) {
                    return '"' + literalEscape(expectation.text) + '"'
                },

                class: function (expectation) {
                    var escapedParts = '',
                        i

                    for (i = 0; i < expectation.parts.length; i++) {
                        escapedParts +=
                            expectation.parts[i] instanceof Array
                                ? classEscape(expectation.parts[i][0]) + '-' + classEscape(expectation.parts[i][1])
                                : classEscape(expectation.parts[i])
                    }

                    return '[' + (expectation.inverted ? '^' : '') + escapedParts + ']'
                },

                any: function (expectation) {
                    return 'any character'
                },

                end: function (expectation) {
                    return 'end of input'
                },

                other: function (expectation) {
                    return expectation.description
                },
            }

            function hex(ch) {
                return ch.charCodeAt(0).toString(16).toUpperCase()
            }

            function literalEscape(s) {
                return s
                    .replace(/\\/g, '\\\\')
                    .replace(/"/g, '\\"')
                    .replace(/\0/g, '\\0')
                    .replace(/\t/g, '\\t')
                    .replace(/\n/g, '\\n')
                    .replace(/\r/g, '\\r')
                    .replace(/[\x00-\x0F]/g, function (ch) {
                        return '\\x0' + hex(ch)
                    })
                    .replace(/[\x10-\x1F\x7F-\x9F]/g, function (ch) {
                        return '\\x' + hex(ch)
                    })
            }

            function classEscape(s) {
                return s
                    .replace(/\\/g, '\\\\')
                    .replace(/\]/g, '\\]')
                    .replace(/\^/g, '\\^')
                    .replace(/-/g, '\\-')
                    .replace(/\0/g, '\\0')
                    .replace(/\t/g, '\\t')
                    .replace(/\n/g, '\\n')
                    .replace(/\r/g, '\\r')
                    .replace(/[\x00-\x0F]/g, function (ch) {
                        return '\\x0' + hex(ch)
                    })
                    .replace(/[\x10-\x1F\x7F-\x9F]/g, function (ch) {
                        return '\\x' + hex(ch)
                    })
            }

            function describeExpectation(expectation) {
                return DESCRIBE_EXPECTATION_FNS[expectation.type](expectation)
            }

            function describeExpected(expected) {
                var descriptions = new Array(expected.length),
                    i,
                    j

                for (i = 0; i < expected.length; i++) {
                    descriptions[i] = describeExpectation(expected[i])
                }

                descriptions.sort()

                if (descriptions.length > 0) {
                    for (i = 1, j = 1; i < descriptions.length; i++) {
                        if (descriptions[i - 1] !== descriptions[i]) {
                            descriptions[j] = descriptions[i]
                            j++
                        }
                    }
                    descriptions.length = j
                }

                switch (descriptions.length) {
                    case 1:
                        return descriptions[0]

                    case 2:
                        return descriptions[0] + ' or ' + descriptions[1]

                    default:
                        return descriptions.slice(0, -1).join(', ') + ', or ' + descriptions[descriptions.length - 1]
                }
            }

            function describeFound(found) {
                return found ? '"' + literalEscape(found) + '"' : 'end of input'
            }

            return 'Expected ' + describeExpected(expected) + ' but ' + describeFound(found) + ' found.'
        }

        function peg$parse(input, options) {
            options = options !== void 0 ? options : {}

            var peg$FAILED = {},
                peg$startRuleFunctions = { Expression: peg$parseExpression },
                peg$startRuleFunction = peg$parseExpression,
                peg$c0 = function (text, param) {
                    return { text: text.join(''), parameter: param }
                },
                peg$c1 = function (param) {
                    return { text: '', parameter: param }
                },
                peg$c2 = function (text) {
                    return { text: text.join(''), parameter: null }
                },
                peg$c3 = function (param) {
                    return param
                },
                peg$c4 = /^[^{]/,
                peg$c5 = peg$classExpectation(['{'], true, false),
                peg$c6 = function (text) {
                    return text
                },
                peg$c7 = '"',
                peg$c8 = peg$literalExpectation('"', false),
                peg$c9 = function (chars) {
                    return chars.join('')
                },
                peg$c10 = "'",
                peg$c11 = peg$literalExpectation("'", false),
                peg$c12 = '\\',
                peg$c13 = peg$literalExpectation('\\', false),
                peg$c14 = peg$anyExpectation(),
                peg$c15 = function (char) {
                    return char
                },
                peg$c16 = function (sequence) {
                    return sequence
                },
                peg$c17 = peg$otherExpectation('term/function call'),
                peg$c18 = '(',
                peg$c19 = peg$literalExpectation('(', false),
                peg$c20 = ')',
                peg$c21 = peg$literalExpectation(')', false),
                peg$c22 = function (r, param) {
                    return { call: r.trim(), param: param || [] }
                },
                peg$c23 = function (cf) {
                    return cf
                },
                peg$c24 = function (t) {
                    return { field: t.trim() }
                },
                peg$c25 = function (field, cf) {
                    return { field: field.trim(), fns: [cf.field].concat(cf.fns) }
                },
                peg$c26 = function (field, fn) {
                    return { field: field.trim(), fns: [fn] }
                },
                peg$c27 = ':',
                peg$c28 = peg$literalExpectation(':', false),
                peg$c29 = '=',
                peg$c30 = peg$literalExpectation('=', false),
                peg$c31 = peg$otherExpectation('punc'),
                peg$c32 = ',',
                peg$c33 = peg$literalExpectation(',', false),
                peg$c34 = ' ',
                peg$c35 = peg$literalExpectation(' ', false),
                peg$c36 = peg$otherExpectation('vlist'),
                peg$c37 = function (value, list) {
                    return [value, ...list]
                },
                peg$c38 = function (value) {
                    return [value]
                },
                peg$c39 = peg$otherExpectation('value'),
                peg$c40 = /^[a-zA-Z\-0-9._$]/,
                peg$c41 = peg$classExpectation([['a', 'z'], ['A', 'Z'], '-', ['0', '9'], '.', '_', '$'], false, false),
                peg$c42 = function () {
                    return text().trim()
                },
                peg$c43 = peg$otherExpectation('simple'),
                peg$c44 = function (r, param) {
                    return { call: r.trim(), param: param || [] }
                },
                peg$c45 = peg$otherExpectation('field'),
                peg$c46 = /^[a-zA-Z0-9\-._$]/,
                peg$c47 = peg$classExpectation([['a', 'z'], ['A', 'Z'], ['0', '9'], '-', '.', '_', '$'], false, false),
                peg$c48 = function () {
                    return text()
                },
                peg$c49 = peg$otherExpectation('whitespace'),
                peg$c50 = /^[ \t\n\r]/,
                peg$c51 = peg$classExpectation([' ', '\t', '\n', '\r'], false, false),
                peg$c52 = peg$otherExpectation('openbracket'),
                peg$c53 = /^[{]/,
                peg$c54 = peg$classExpectation(['{'], false, false),
                peg$c55 = peg$otherExpectation('closebracket'),
                peg$c56 = /^[}]/,
                peg$c57 = peg$classExpectation(['}'], false, false),
                peg$currPos = 0,
                peg$savedPos = 0,
                peg$posDetailsCache = [{ line: 1, column: 1 }],
                peg$maxFailPos = 0,
                peg$maxFailExpected = [],
                peg$silentFails = 0,
                peg$result

            if ('startRule' in options) {
                if (!(options.startRule in peg$startRuleFunctions)) {
                    throw new Error('Can\'t start parsing from rule "' + options.startRule + '".')
                }

                peg$startRuleFunction = peg$startRuleFunctions[options.startRule]
            }

            function text() {
                return input.substring(peg$savedPos, peg$currPos)
            }

            function location() {
                return peg$computeLocation(peg$savedPos, peg$currPos)
            }

            function expected(description, location) {
                location = location !== void 0 ? location : peg$computeLocation(peg$savedPos, peg$currPos)

                throw peg$buildStructuredError(
                    [peg$otherExpectation(description)],
                    input.substring(peg$savedPos, peg$currPos),
                    location
                )
            }

            function error(message, location) {
                location = location !== void 0 ? location : peg$computeLocation(peg$savedPos, peg$currPos)

                throw peg$buildSimpleError(message, location)
            }

            function peg$literalExpectation(text, ignoreCase) {
                return { type: 'literal', text: text, ignoreCase: ignoreCase }
            }

            function peg$classExpectation(parts, inverted, ignoreCase) {
                return { type: 'class', parts: parts, inverted: inverted, ignoreCase: ignoreCase }
            }

            function peg$anyExpectation() {
                return { type: 'any' }
            }

            function peg$endExpectation() {
                return { type: 'end' }
            }

            function peg$otherExpectation(description) {
                return { type: 'other', description: description }
            }

            function peg$computePosDetails(pos) {
                var details = peg$posDetailsCache[pos],
                    p

                if (details) {
                    return details
                } else {
                    p = pos - 1
                    while (!peg$posDetailsCache[p]) {
                        p--
                    }

                    details = peg$posDetailsCache[p]
                    details = {
                        line: details.line,
                        column: details.column,
                    }

                    while (p < pos) {
                        if (input.charCodeAt(p) === 10) {
                            details.line++
                            details.column = 1
                        } else {
                            details.column++
                        }

                        p++
                    }

                    peg$posDetailsCache[pos] = details
                    return details
                }
            }

            function peg$computeLocation(startPos, endPos) {
                var startPosDetails = peg$computePosDetails(startPos),
                    endPosDetails = peg$computePosDetails(endPos)

                return {
                    start: {
                        offset: startPos,
                        line: startPosDetails.line,
                        column: startPosDetails.column,
                    },
                    end: {
                        offset: endPos,
                        line: endPosDetails.line,
                        column: endPosDetails.column,
                    },
                }
            }

            function peg$fail(expected) {
                if (peg$currPos < peg$maxFailPos) {
                    return
                }

                if (peg$currPos > peg$maxFailPos) {
                    peg$maxFailPos = peg$currPos
                    peg$maxFailExpected = []
                }

                peg$maxFailExpected.push(expected)
            }

            function peg$buildSimpleError(message, location) {
                return new peg$SyntaxError(message, null, null, location)
            }

            function peg$buildStructuredError(expected, found, location) {
                return new peg$SyntaxError(peg$SyntaxError.buildMessage(expected, found), expected, found, location)
            }

            function peg$parseExpression() {
                var s0, s1

                s0 = []
                s1 = peg$parsePart()
                if (s1 !== peg$FAILED) {
                    while (s1 !== peg$FAILED) {
                        s0.push(s1)
                        s1 = peg$parsePart()
                    }
                } else {
                    s0 = peg$FAILED
                }

                return s0
            }

            function peg$parsePart() {
                var s0, s1, s2

                s0 = peg$currPos
                s1 = peg$parseFreeText()
                if (s1 !== peg$FAILED) {
                    s2 = peg$parseParameter()
                    if (s2 !== peg$FAILED) {
                        peg$savedPos = s0
                        s1 = peg$c0(s1, s2)
                        s0 = s1
                    } else {
                        peg$currPos = s0
                        s0 = peg$FAILED
                    }
                } else {
                    peg$currPos = s0
                    s0 = peg$FAILED
                }
                if (s0 === peg$FAILED) {
                    s0 = peg$currPos
                    s1 = peg$parseParameter()
                    if (s1 !== peg$FAILED) {
                        peg$savedPos = s0
                        s1 = peg$c1(s1)
                    }
                    s0 = s1
                    if (s0 === peg$FAILED) {
                        s0 = peg$currPos
                        s1 = peg$parseFreeText()
                        if (s1 !== peg$FAILED) {
                            peg$savedPos = s0
                            s1 = peg$c2(s1)
                        }
                        s0 = s1
                    }
                }

                return s0
            }

            function peg$parseParameter() {
                var s0, s1, s2, s3

                s0 = peg$currPos
                s1 = peg$parseOpenBracket()
                if (s1 !== peg$FAILED) {
                    s2 = peg$parseFactor()
                    if (s2 !== peg$FAILED) {
                        s3 = peg$parseCloseBracket()
                        if (s3 !== peg$FAILED) {
                            peg$savedPos = s0
                            s1 = peg$c3(s2)
                            s0 = s1
                        } else {
                            peg$currPos = s0
                            s0 = peg$FAILED
                        }
                    } else {
                        peg$currPos = s0
                        s0 = peg$FAILED
                    }
                } else {
                    peg$currPos = s0
                    s0 = peg$FAILED
                }

                return s0
            }

            function peg$parseFreeText() {
                var s0, s1, s2

                s0 = peg$currPos
                s1 = []
                if (peg$c4.test(input.charAt(peg$currPos))) {
                    s2 = input.charAt(peg$currPos)
                    peg$currPos++
                } else {
                    s2 = peg$FAILED
                    if (peg$silentFails === 0) {
                        peg$fail(peg$c5)
                    }
                }
                if (s2 !== peg$FAILED) {
                    while (s2 !== peg$FAILED) {
                        s1.push(s2)
                        if (peg$c4.test(input.charAt(peg$currPos))) {
                            s2 = input.charAt(peg$currPos)
                            peg$currPos++
                        } else {
                            s2 = peg$FAILED
                            if (peg$silentFails === 0) {
                                peg$fail(peg$c5)
                            }
                        }
                    }
                } else {
                    s1 = peg$FAILED
                }
                if (s1 !== peg$FAILED) {
                    peg$savedPos = s0
                    s1 = peg$c6(s1)
                }
                s0 = s1

                return s0
            }

            function peg$parseString() {
                var s0, s1, s2, s3

                s0 = peg$currPos
                if (input.charCodeAt(peg$currPos) === 34) {
                    s1 = peg$c7
                    peg$currPos++
                } else {
                    s1 = peg$FAILED
                    if (peg$silentFails === 0) {
                        peg$fail(peg$c8)
                    }
                }
                if (s1 !== peg$FAILED) {
                    s2 = []
                    s3 = peg$parseDoubleStringCharacter()
                    while (s3 !== peg$FAILED) {
                        s2.push(s3)
                        s3 = peg$parseDoubleStringCharacter()
                    }
                    if (s2 !== peg$FAILED) {
                        if (input.charCodeAt(peg$currPos) === 34) {
                            s3 = peg$c7
                            peg$currPos++
                        } else {
                            s3 = peg$FAILED
                            if (peg$silentFails === 0) {
                                peg$fail(peg$c8)
                            }
                        }
                        if (s3 !== peg$FAILED) {
                            peg$savedPos = s0
                            s1 = peg$c9(s2)
                            s0 = s1
                        } else {
                            peg$currPos = s0
                            s0 = peg$FAILED
                        }
                    } else {
                        peg$currPos = s0
                        s0 = peg$FAILED
                    }
                } else {
                    peg$currPos = s0
                    s0 = peg$FAILED
                }
                if (s0 === peg$FAILED) {
                    s0 = peg$currPos
                    if (input.charCodeAt(peg$currPos) === 39) {
                        s1 = peg$c10
                        peg$currPos++
                    } else {
                        s1 = peg$FAILED
                        if (peg$silentFails === 0) {
                            peg$fail(peg$c11)
                        }
                    }
                    if (s1 !== peg$FAILED) {
                        s2 = []
                        s3 = peg$parseSingleStringCharacter()
                        while (s3 !== peg$FAILED) {
                            s2.push(s3)
                            s3 = peg$parseSingleStringCharacter()
                        }
                        if (s2 !== peg$FAILED) {
                            if (input.charCodeAt(peg$currPos) === 39) {
                                s3 = peg$c10
                                peg$currPos++
                            } else {
                                s3 = peg$FAILED
                                if (peg$silentFails === 0) {
                                    peg$fail(peg$c11)
                                }
                            }
                            if (s3 !== peg$FAILED) {
                                peg$savedPos = s0
                                s1 = peg$c9(s2)
                                s0 = s1
                            } else {
                                peg$currPos = s0
                                s0 = peg$FAILED
                            }
                        } else {
                            peg$currPos = s0
                            s0 = peg$FAILED
                        }
                    } else {
                        peg$currPos = s0
                        s0 = peg$FAILED
                    }
                }

                return s0
            }

            function peg$parseDoubleStringCharacter() {
                var s0, s1, s2

                s0 = peg$currPos
                s1 = peg$currPos
                peg$silentFails++
                if (input.charCodeAt(peg$currPos) === 34) {
                    s2 = peg$c7
                    peg$currPos++
                } else {
                    s2 = peg$FAILED
                    if (peg$silentFails === 0) {
                        peg$fail(peg$c8)
                    }
                }
                if (s2 === peg$FAILED) {
                    if (input.charCodeAt(peg$currPos) === 92) {
                        s2 = peg$c12
                        peg$currPos++
                    } else {
                        s2 = peg$FAILED
                        if (peg$silentFails === 0) {
                            peg$fail(peg$c13)
                        }
                    }
                }
                peg$silentFails--
                if (s2 === peg$FAILED) {
                    s1 = void 0
                } else {
                    peg$currPos = s1
                    s1 = peg$FAILED
                }
                if (s1 !== peg$FAILED) {
                    if (input.length > peg$currPos) {
                        s2 = input.charAt(peg$currPos)
                        peg$currPos++
                    } else {
                        s2 = peg$FAILED
                        if (peg$silentFails === 0) {
                            peg$fail(peg$c14)
                        }
                    }
                    if (s2 !== peg$FAILED) {
                        peg$savedPos = s0
                        s1 = peg$c15(s2)
                        s0 = s1
                    } else {
                        peg$currPos = s0
                        s0 = peg$FAILED
                    }
                } else {
                    peg$currPos = s0
                    s0 = peg$FAILED
                }
                if (s0 === peg$FAILED) {
                    s0 = peg$currPos
                    if (input.charCodeAt(peg$currPos) === 92) {
                        s1 = peg$c12
                        peg$currPos++
                    } else {
                        s1 = peg$FAILED
                        if (peg$silentFails === 0) {
                            peg$fail(peg$c13)
                        }
                    }
                    if (s1 !== peg$FAILED) {
                        s2 = peg$parseEscapeSequence()
                        if (s2 !== peg$FAILED) {
                            peg$savedPos = s0
                            s1 = peg$c16(s2)
                            s0 = s1
                        } else {
                            peg$currPos = s0
                            s0 = peg$FAILED
                        }
                    } else {
                        peg$currPos = s0
                        s0 = peg$FAILED
                    }
                }

                return s0
            }

            function peg$parseSingleStringCharacter() {
                var s0, s1, s2

                s0 = peg$currPos
                s1 = peg$currPos
                peg$silentFails++
                if (input.charCodeAt(peg$currPos) === 39) {
                    s2 = peg$c10
                    peg$currPos++
                } else {
                    s2 = peg$FAILED
                    if (peg$silentFails === 0) {
                        peg$fail(peg$c11)
                    }
                }
                if (s2 === peg$FAILED) {
                    if (input.charCodeAt(peg$currPos) === 92) {
                        s2 = peg$c12
                        peg$currPos++
                    } else {
                        s2 = peg$FAILED
                        if (peg$silentFails === 0) {
                            peg$fail(peg$c13)
                        }
                    }
                }
                peg$silentFails--
                if (s2 === peg$FAILED) {
                    s1 = void 0
                } else {
                    peg$currPos = s1
                    s1 = peg$FAILED
                }
                if (s1 !== peg$FAILED) {
                    if (input.length > peg$currPos) {
                        s2 = input.charAt(peg$currPos)
                        peg$currPos++
                    } else {
                        s2 = peg$FAILED
                        if (peg$silentFails === 0) {
                            peg$fail(peg$c14)
                        }
                    }
                    if (s2 !== peg$FAILED) {
                        peg$savedPos = s0
                        s1 = peg$c15(s2)
                        s0 = s1
                    } else {
                        peg$currPos = s0
                        s0 = peg$FAILED
                    }
                } else {
                    peg$currPos = s0
                    s0 = peg$FAILED
                }
                if (s0 === peg$FAILED) {
                    s0 = peg$currPos
                    if (input.charCodeAt(peg$currPos) === 92) {
                        s1 = peg$c12
                        peg$currPos++
                    } else {
                        s1 = peg$FAILED
                        if (peg$silentFails === 0) {
                            peg$fail(peg$c13)
                        }
                    }
                    if (s1 !== peg$FAILED) {
                        s2 = peg$parseEscapeSequence()
                        if (s2 !== peg$FAILED) {
                            peg$savedPos = s0
                            s1 = peg$c16(s2)
                            s0 = s1
                        } else {
                            peg$currPos = s0
                            s0 = peg$FAILED
                        }
                    } else {
                        peg$currPos = s0
                        s0 = peg$FAILED
                    }
                }

                return s0
            }

            function peg$parseEscapeSequence() {
                var s0

                if (input.charCodeAt(peg$currPos) === 39) {
                    s0 = peg$c10
                    peg$currPos++
                } else {
                    s0 = peg$FAILED
                    if (peg$silentFails === 0) {
                        peg$fail(peg$c11)
                    }
                }
                if (s0 === peg$FAILED) {
                    if (input.charCodeAt(peg$currPos) === 34) {
                        s0 = peg$c7
                        peg$currPos++
                    } else {
                        s0 = peg$FAILED
                        if (peg$silentFails === 0) {
                            peg$fail(peg$c8)
                        }
                    }
                    if (s0 === peg$FAILED) {
                        if (input.charCodeAt(peg$currPos) === 92) {
                            s0 = peg$c12
                            peg$currPos++
                        } else {
                            s0 = peg$FAILED
                            if (peg$silentFails === 0) {
                                peg$fail(peg$c13)
                            }
                        }
                    }
                }

                return s0
            }

            function peg$parseFactor() {
                var s0, s1, s2, s3, s4

                peg$silentFails++
                s0 = peg$currPos
                s1 = peg$parseField()
                if (s1 !== peg$FAILED) {
                    if (input.charCodeAt(peg$currPos) === 40) {
                        s2 = peg$c18
                        peg$currPos++
                    } else {
                        s2 = peg$FAILED
                        if (peg$silentFails === 0) {
                            peg$fail(peg$c19)
                        }
                    }
                    if (s2 !== peg$FAILED) {
                        s3 = peg$parseValueList()
                        if (s3 !== peg$FAILED) {
                            if (input.charCodeAt(peg$currPos) === 41) {
                                s4 = peg$c20
                                peg$currPos++
                            } else {
                                s4 = peg$FAILED
                                if (peg$silentFails === 0) {
                                    peg$fail(peg$c21)
                                }
                            }
                            if (s4 !== peg$FAILED) {
                                peg$savedPos = s0
                                s1 = peg$c22(s1, s3)
                                s0 = s1
                            } else {
                                peg$currPos = s0
                                s0 = peg$FAILED
                            }
                        } else {
                            peg$currPos = s0
                            s0 = peg$FAILED
                        }
                    } else {
                        peg$currPos = s0
                        s0 = peg$FAILED
                    }
                } else {
                    peg$currPos = s0
                    s0 = peg$FAILED
                }
                if (s0 === peg$FAILED) {
                    s0 = peg$currPos
                    s1 = peg$parseColonFuncs()
                    if (s1 !== peg$FAILED) {
                        peg$savedPos = s0
                        s1 = peg$c23(s1)
                    }
                    s0 = s1
                    if (s0 === peg$FAILED) {
                        s0 = peg$currPos
                        s1 = peg$parseField()
                        if (s1 !== peg$FAILED) {
                            peg$savedPos = s0
                            s1 = peg$c24(s1)
                        }
                        s0 = s1
                    }
                }
                peg$silentFails--
                if (s0 === peg$FAILED) {
                    s1 = peg$FAILED
                    if (peg$silentFails === 0) {
                        peg$fail(peg$c17)
                    }
                }

                return s0
            }

            function peg$parseColonFuncs() {
                var s0, s1, s2, s3

                s0 = peg$currPos
                s1 = peg$parseField()
                if (s1 !== peg$FAILED) {
                    s2 = peg$parseColon()
                    if (s2 !== peg$FAILED) {
                        s3 = peg$parseColonFuncs()
                        if (s3 !== peg$FAILED) {
                            peg$savedPos = s0
                            s1 = peg$c25(s1, s3)
                            s0 = s1
                        } else {
                            peg$currPos = s0
                            s0 = peg$FAILED
                        }
                    } else {
                        peg$currPos = s0
                        s0 = peg$FAILED
                    }
                } else {
                    peg$currPos = s0
                    s0 = peg$FAILED
                }
                if (s0 === peg$FAILED) {
                    s0 = peg$currPos
                    s1 = peg$parseField()
                    if (s1 !== peg$FAILED) {
                        s2 = peg$parseColon()
                        if (s2 !== peg$FAILED) {
                            s3 = peg$parseField()
                            if (s3 !== peg$FAILED) {
                                peg$savedPos = s0
                                s1 = peg$c26(s1, s3)
                                s0 = s1
                            } else {
                                peg$currPos = s0
                                s0 = peg$FAILED
                            }
                        } else {
                            peg$currPos = s0
                            s0 = peg$FAILED
                        }
                    } else {
                        peg$currPos = s0
                        s0 = peg$FAILED
                    }
                }

                return s0
            }

            function peg$parseColon() {
                var s0

                if (input.charCodeAt(peg$currPos) === 58) {
                    s0 = peg$c27
                    peg$currPos++
                } else {
                    s0 = peg$FAILED
                    if (peg$silentFails === 0) {
                        peg$fail(peg$c28)
                    }
                }
                if (s0 === peg$FAILED) {
                    if (input.charCodeAt(peg$currPos) === 61) {
                        s0 = peg$c29
                        peg$currPos++
                    } else {
                        s0 = peg$FAILED
                        if (peg$silentFails === 0) {
                            peg$fail(peg$c30)
                        }
                    }
                }

                return s0
            }

            function peg$parseParamPunc() {
                var s0, s1

                peg$silentFails++
                if (input.charCodeAt(peg$currPos) === 44) {
                    s0 = peg$c32
                    peg$currPos++
                } else {
                    s0 = peg$FAILED
                    if (peg$silentFails === 0) {
                        peg$fail(peg$c33)
                    }
                }
                if (s0 === peg$FAILED) {
                    if (input.charCodeAt(peg$currPos) === 32) {
                        s0 = peg$c34
                        peg$currPos++
                    } else {
                        s0 = peg$FAILED
                        if (peg$silentFails === 0) {
                            peg$fail(peg$c35)
                        }
                    }
                }
                peg$silentFails--
                if (s0 === peg$FAILED) {
                    s1 = peg$FAILED
                    if (peg$silentFails === 0) {
                        peg$fail(peg$c31)
                    }
                }

                return s0
            }

            function peg$parseValueList() {
                var s0, s1, s2, s3, s4, s5

                peg$silentFails++
                s0 = peg$currPos
                s1 = peg$parse_()
                if (s1 !== peg$FAILED) {
                    s2 = peg$parseValue()
                    if (s2 !== peg$FAILED) {
                        s3 = peg$parseParamPunc()
                        if (s3 !== peg$FAILED) {
                            s4 = peg$parse_()
                            if (s4 !== peg$FAILED) {
                                s5 = peg$parseValueList()
                                if (s5 !== peg$FAILED) {
                                    peg$savedPos = s0
                                    s1 = peg$c37(s2, s5)
                                    s0 = s1
                                } else {
                                    peg$currPos = s0
                                    s0 = peg$FAILED
                                }
                            } else {
                                peg$currPos = s0
                                s0 = peg$FAILED
                            }
                        } else {
                            peg$currPos = s0
                            s0 = peg$FAILED
                        }
                    } else {
                        peg$currPos = s0
                        s0 = peg$FAILED
                    }
                } else {
                    peg$currPos = s0
                    s0 = peg$FAILED
                }
                if (s0 === peg$FAILED) {
                    s0 = peg$currPos
                    s1 = peg$parse_()
                    if (s1 !== peg$FAILED) {
                        s2 = peg$parseValue()
                        if (s2 !== peg$FAILED) {
                            peg$savedPos = s0
                            s1 = peg$c38(s2)
                            s0 = s1
                        } else {
                            peg$currPos = s0
                            s0 = peg$FAILED
                        }
                    } else {
                        peg$currPos = s0
                        s0 = peg$FAILED
                    }
                }
                peg$silentFails--
                if (s0 === peg$FAILED) {
                    s1 = peg$FAILED
                    if (peg$silentFails === 0) {
                        peg$fail(peg$c36)
                    }
                }

                return s0
            }

            function peg$parseValue() {
                var s0, s1, s2, s3

                peg$silentFails++
                s0 = peg$parseSimpleFunction()
                if (s0 === peg$FAILED) {
                    s0 = peg$currPos
                    s1 = peg$parse_()
                    if (s1 !== peg$FAILED) {
                        s2 = []
                        if (peg$c40.test(input.charAt(peg$currPos))) {
                            s3 = input.charAt(peg$currPos)
                            peg$currPos++
                        } else {
                            s3 = peg$FAILED
                            if (peg$silentFails === 0) {
                                peg$fail(peg$c41)
                            }
                        }
                        if (s3 !== peg$FAILED) {
                            while (s3 !== peg$FAILED) {
                                s2.push(s3)
                                if (peg$c40.test(input.charAt(peg$currPos))) {
                                    s3 = input.charAt(peg$currPos)
                                    peg$currPos++
                                } else {
                                    s3 = peg$FAILED
                                    if (peg$silentFails === 0) {
                                        peg$fail(peg$c41)
                                    }
                                }
                            }
                        } else {
                            s2 = peg$FAILED
                        }
                        if (s2 !== peg$FAILED) {
                            peg$savedPos = s0
                            s1 = peg$c42()
                            s0 = s1
                        } else {
                            peg$currPos = s0
                            s0 = peg$FAILED
                        }
                    } else {
                        peg$currPos = s0
                        s0 = peg$FAILED
                    }
                    if (s0 === peg$FAILED) {
                        s0 = peg$parseString()
                    }
                }
                peg$silentFails--
                if (s0 === peg$FAILED) {
                    s1 = peg$FAILED
                    if (peg$silentFails === 0) {
                        peg$fail(peg$c39)
                    }
                }

                return s0
            }

            function peg$parseSimpleFunction() {
                var s0, s1, s2, s3, s4

                peg$silentFails++
                s0 = peg$currPos
                s1 = peg$parseField()
                if (s1 !== peg$FAILED) {
                    if (input.charCodeAt(peg$currPos) === 40) {
                        s2 = peg$c18
                        peg$currPos++
                    } else {
                        s2 = peg$FAILED
                        if (peg$silentFails === 0) {
                            peg$fail(peg$c19)
                        }
                    }
                    if (s2 !== peg$FAILED) {
                        s3 = peg$parseValueList()
                        if (s3 !== peg$FAILED) {
                            if (input.charCodeAt(peg$currPos) === 41) {
                                s4 = peg$c20
                                peg$currPos++
                            } else {
                                s4 = peg$FAILED
                                if (peg$silentFails === 0) {
                                    peg$fail(peg$c21)
                                }
                            }
                            if (s4 !== peg$FAILED) {
                                peg$savedPos = s0
                                s1 = peg$c44(s1, s3)
                                s0 = s1
                            } else {
                                peg$currPos = s0
                                s0 = peg$FAILED
                            }
                        } else {
                            peg$currPos = s0
                            s0 = peg$FAILED
                        }
                    } else {
                        peg$currPos = s0
                        s0 = peg$FAILED
                    }
                } else {
                    peg$currPos = s0
                    s0 = peg$FAILED
                }
                peg$silentFails--
                if (s0 === peg$FAILED) {
                    s1 = peg$FAILED
                    if (peg$silentFails === 0) {
                        peg$fail(peg$c43)
                    }
                }

                return s0
            }

            function peg$parseField() {
                var s0, s1, s2, s3

                peg$silentFails++
                s0 = peg$currPos
                s1 = peg$parse_()
                if (s1 !== peg$FAILED) {
                    s2 = []
                    if (peg$c46.test(input.charAt(peg$currPos))) {
                        s3 = input.charAt(peg$currPos)
                        peg$currPos++
                    } else {
                        s3 = peg$FAILED
                        if (peg$silentFails === 0) {
                            peg$fail(peg$c47)
                        }
                    }
                    if (s3 !== peg$FAILED) {
                        while (s3 !== peg$FAILED) {
                            s2.push(s3)
                            if (peg$c46.test(input.charAt(peg$currPos))) {
                                s3 = input.charAt(peg$currPos)
                                peg$currPos++
                            } else {
                                s3 = peg$FAILED
                                if (peg$silentFails === 0) {
                                    peg$fail(peg$c47)
                                }
                            }
                        }
                    } else {
                        s2 = peg$FAILED
                    }
                    if (s2 !== peg$FAILED) {
                        peg$savedPos = s0
                        s1 = peg$c48()
                        s0 = s1
                    } else {
                        peg$currPos = s0
                        s0 = peg$FAILED
                    }
                } else {
                    peg$currPos = s0
                    s0 = peg$FAILED
                }
                peg$silentFails--
                if (s0 === peg$FAILED) {
                    s1 = peg$FAILED
                    if (peg$silentFails === 0) {
                        peg$fail(peg$c45)
                    }
                }

                return s0
            }

            function peg$parse_() {
                var s0, s1

                peg$silentFails++
                s0 = []
                if (peg$c50.test(input.charAt(peg$currPos))) {
                    s1 = input.charAt(peg$currPos)
                    peg$currPos++
                } else {
                    s1 = peg$FAILED
                    if (peg$silentFails === 0) {
                        peg$fail(peg$c51)
                    }
                }
                while (s1 !== peg$FAILED) {
                    s0.push(s1)
                    if (peg$c50.test(input.charAt(peg$currPos))) {
                        s1 = input.charAt(peg$currPos)
                        peg$currPos++
                    } else {
                        s1 = peg$FAILED
                        if (peg$silentFails === 0) {
                            peg$fail(peg$c51)
                        }
                    }
                }
                peg$silentFails--
                if (s0 === peg$FAILED) {
                    s1 = peg$FAILED
                    if (peg$silentFails === 0) {
                        peg$fail(peg$c49)
                    }
                }

                return s0
            }

            function peg$parseOpenBracket() {
                var s0, s1

                peg$silentFails++
                if (peg$c53.test(input.charAt(peg$currPos))) {
                    s0 = input.charAt(peg$currPos)
                    peg$currPos++
                } else {
                    s0 = peg$FAILED
                    if (peg$silentFails === 0) {
                        peg$fail(peg$c54)
                    }
                }
                peg$silentFails--
                if (s0 === peg$FAILED) {
                    s1 = peg$FAILED
                    if (peg$silentFails === 0) {
                        peg$fail(peg$c52)
                    }
                }

                return s0
            }

            function peg$parseCloseBracket() {
                var s0, s1, s2

                peg$silentFails++
                s0 = peg$currPos
                s1 = peg$parse_()
                if (s1 !== peg$FAILED) {
                    if (peg$c56.test(input.charAt(peg$currPos))) {
                        s2 = input.charAt(peg$currPos)
                        peg$currPos++
                    } else {
                        s2 = peg$FAILED
                        if (peg$silentFails === 0) {
                            peg$fail(peg$c57)
                        }
                    }
                    if (s2 !== peg$FAILED) {
                        s1 = [s1, s2]
                        s0 = s1
                    } else {
                        peg$currPos = s0
                        s0 = peg$FAILED
                    }
                } else {
                    peg$currPos = s0
                    s0 = peg$FAILED
                }
                peg$silentFails--
                if (s0 === peg$FAILED) {
                    s1 = peg$FAILED
                    if (peg$silentFails === 0) {
                        peg$fail(peg$c55)
                    }
                }

                return s0
            }

            peg$result = peg$startRuleFunction()

            if (peg$result !== peg$FAILED && peg$currPos === input.length) {
                return peg$result
            } else {
                if (peg$result !== peg$FAILED && peg$currPos < input.length) {
                    peg$fail(peg$endExpectation())
                }

                throw peg$buildStructuredError(
                    peg$maxFailExpected,
                    peg$maxFailPos < input.length ? input.charAt(peg$maxFailPos) : null,
                    peg$maxFailPos < input.length
                        ? peg$computeLocation(peg$maxFailPos, peg$maxFailPos + 1)
                        : peg$computeLocation(peg$maxFailPos, peg$maxFailPos)
                )
            }
        }

        return {
            SyntaxError: peg$SyntaxError,
            parse: peg$parse,
        }
    })()
