import React from 'react'

//TODO: Add mounted check?
export const useDebounce = (value, debounceTime = 200) => {
    const debounce = React.useRef()
    const [debouncedValue, setDebouncedValue] = React.useState(value)

    React.useEffect(() => {
        clearTimeout(debounce.current)
        const clearResults = value.length === 0
        debounce.current = setTimeout(() => {
            return clearResults ? setDebouncedValue('') : setDebouncedValue(value)
        }, debounceTime)
    }, [value])

    return [debouncedValue]
}
