import React from 'react'
import {
    borders,
    display,
    flexbox,
    palette,
    positions,
    shadows,
    sizing,
    spacing,
    typography,
} from '@material-ui/system'
import { makeCachedSheet } from 'common/inline-styles'
import { useTheme } from '@material-ui/core/styles'

export const Box = React.forwardRef(function Box(
    {
        dangerouslySetInnerHTML,
        children,
        onDoubleClick,
        onClick,
        onMouseDownCapture,
        onMouseUpCapture,
        onMouseMoveCapture,
        onBlur,
        onMouseDown,
        onMouseUp,
        onMouseMove,
        onKeyDown,
        onKeyUp,
        onKeyPress,
        className,
        style,
        clone,
        ...props
    },
    ref
) {
    props.theme = useTheme()
    const sheet = {
        box: {
            ...borders(props),
            ...display(props),
            ...flexbox(props),
            ...palette(props),
            ...positions(props),
            ...shadows(props),
            ...sizing(props),
            ...spacing(props),
            ...typography(props),
        },
    }
    const classes = makeCachedSheet(sheet)
    if (clone) {
        return React.cloneElement(children, {
            dangerouslySetInnerHTML,
            ['data-testid']: props['data-testid'],
            style,
            ref,
            className: `MuiBox-root ${classes.box} ${className} ${children?.props?.className || ''}`,
        })
    }
    return (
        <div
            dangerouslySetInnerHTML={dangerouslySetInnerHTML}
            {...{ ['data-testid']: props['data-testid'] }}
            onDoubleClick={onDoubleClick}
            onClick={onClick}
            onBlur={onBlur}
            onMouseDown={onMouseDown}
            onMouseUp={onMouseUp}
            onMouseMove={onMouseMove}
            onKeyDown={onKeyDown}
            onKeyUp={onKeyUp}
            onKeyPress={onKeyPress}
            onMouseDownCapture={onMouseDownCapture}
            onMouseUpCapture={onMouseUpCapture}
            onMouseMoveCapture={onMouseMoveCapture}
            className={`MuiBox-root ${classes.box} ${className}`}
            style={style}
            ref={ref}
        >
            {children}
        </div>
    )
})

export default Box
