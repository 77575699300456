import { define, document, parameter, retrieve, send } from 'common/process'
import { getLocalItem, storeLocalItem } from 'common/local-storage/local-store'

export const getDocuments = retrieve('client.documents', 'docs', parameter('clientId'))
export const getDownloadUrl = retrieve('client.downloadUrl', 'url', document())
export const deleteDocument = send('client.deleteDocument', document())
export const changeDocument = send(
    'client.changeDocument',
    document(),
    parameter('filename'),
    parameter('tags'),
    parameter('homePage')
)
export const uploadDocument = send(
    'client.uploadDocument',
    parameter('clientId'),
    parameter('document'),
    parameter('filename'),
    parameter('fileType'),
    parameter('tags'),
    parameter('homePage')
)

let clientId = document((v) => `${v}:hestia/client`)

export const setClientImage = send(
    'client.setImage',
    clientId,
    parameter('image'),
    parameter('name'),
    parameter('fileType')
)
export const setClientDescription = send('client.setDescription', clientId, parameter('description'))

export const getClient = define('client.get', function ({ returns, offlineCache, cacheResult }) {
    returns('client')
    offlineCache(async () => {
        return await getLocalItem('cache_client')
    })
    cacheResult(async (result) => {
        await storeLocalItem('cache_client', result)
    })
})

export const getLocations = define('client.locations.get', function ({ returns, cacheResult, offlineCache }) {
    cacheResult(async (result) => {
        await storeLocalItem('client_locations_cache', result)
    })
    offlineCache(async () => {
        return await getLocalItem('client_locations_cache')
    })
    returns('locations')
})
export const addLocation = send('client.locations.add', parameter('location'))
export const updateLocation = send('client.locations.update', document(), parameter('location'))
export const setClientContact = send('client.contact.update', clientId, parameter('contact'))

export const deleteLocation = send('client.locations.delete', document())
