import { makeCachedStyles } from 'common/inline-styles'

const DRAWER_WIDTH = 300
export const useStyles = makeCachedStyles((theme) => ({
    removeOutline: {
        // background: 'rgba(0,0,0,0.1)',
        // '&:hover': {
        //     background: 'rgba(0,0,0,0.2)',
        // },
        '&:focus': {
            outline: 'none',
        },
    },
    helpButtonHolder: {
        position: 'absolute',
        right: theme.spacing(1),
        transform: 'translateY(-50%)',
        zIndex: 1000,
        color: 'rgba(235,235,245,1)',
    },
    drawer: {
        flexShrink: 0,
        height: '100%',
    },
    html: {
        padding: theme.spacing(1),
        borderRadius: 4,
        borderColor: '#ccc',
        border: '1px solid #ccc',
        transition: 'border 0.3s linear',
        '&:hover': {
            borderColor: 'black',
        },
    },
    drawerPaper: {
        background: '#eee',
        width: DRAWER_WIDTH,
        position: 'initial !important',
    },
    hidden: {
        display: 'none',
    },
    menuItemText: {
        textTransform: 'capitalize',
    },
    emptyIcon: {
        width: 11,
    },
    icon: {
        fontSize: 23,
    },
    pointer: {
        cursor: 'pointer',
    },
}))
