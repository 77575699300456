import React from 'react'

import PropTypes from 'prop-types'

import { showModal } from 'common/modal'
import { makeLazy } from 'common/lazy'

const VideoPlayer = makeLazy(() => import('./player'))

export function showVideo(url) {
    showModal(VideoPlayer, { fullWidth: true, url, maxWidth: 'md' })
}

VideoPlayer.propTypes = {
    cancel: PropTypes.func,
    url: PropTypes.string,
}
