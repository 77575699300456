import { define, optional, parameter, required, retrieve, returns, send } from 'common/process'
import { handle, raise } from 'common/events'
import { get } from 'common/offline-data-service/functions/get'
import { cacheRecords } from 'common/offline-data-service/caching'
import memoize from 'memoizee'

export const getApps = define('app.types.get', function () {
    returns('apps')
})
export const getTypes = memoize(
    define('document.types.get', function ({ returns, after }) {
        returns('types')
        after(({ types }) => {
            raise('additional-types', types)
            return { types }
        })
    }),
    { maxAge: 60000, primitive: true, promise: true }
)

handle('data.changed', function ({ id }) {
    if (id.includes('app-types') || id.includes('document-types')) {
        getTypes.clear()
    }
})

export const getTypeNames = retrieve('document.types.getNames', 'types')
export const getSections = retrieve('sections.get', 'sections')
export const getSection = retrieve('section.get', 'section', parameter('sectionId'))

export const addType = retrieve('document.types.add', 'id', parameter('name'), parameter('definition'))
export const addApp = define('app.types.add', function () {
    required('name')
    optional('definition')
    returns('id')
})

export const deleteType = send('document.types.delete', parameter('id'))
export const getTypeFromDb = retrieve('document.type.get', 'definition', parameter('id'))
export const setTypeToDb = send('document.type.set', parameter('definition'))
export const updateSection = send('section.update', parameter('section'))
export const getLookupTables = retrieve('lookupTables.get', 'tables')
export const getMetadata = retrieve('awe.metadata.get', 'metadata')

export const upgradeDocuments = define('document.upgrade', function ({ required, optional }) {
    required('documentId')
    required('version')
    optional('fromVersion')
})

const tracking = {}
const dummy = Promise.resolve(true)

export function trackSave(id, promise) {
    tracking[id] = promise
    return promise
}

export async function getType(id) {
    if (!id) return null
    await (tracking[id] || dummy)
    return await get(id, true, false, 'server-preferred')
}

export async function setType(definition) {
    await cacheRecords(definition, true)
    return await setTypeToDb(definition)
}

export const commitDocument = define('commit.document', function ({ required }) {
    required('documentId')
})
