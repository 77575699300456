import React, { useContext, useEffect, useMemo, useRef, useState } from 'react'
import { useLocalEvent } from 'common/use-event'
import debounce from 'lodash/debounce'
import { handle } from 'common/events'
import { useBoundContext } from 'common/component-utilities'
import { useCurrentState } from 'common/use-async'

export const RefreshContext = React.createContext(false)

export function useRefreshOnEvent(event, predicate = () => true) {
    const refresh = useRefresh()
    useLocalEvent(event, (...params) => predicate(...params) && refresh())
    return refresh
}

export function boundRefresh(v) {
    if (v) {
        return (lastRefresh = v)
    } else {
        return lastRefresh
    }
}

let lastRefresh = null
let refreshId = 0
function incrementRefreshId() {
    return refreshId++
}

export function useRefreshWhen(event, ...functions) {
    const refresh = useRefresh(...functions)
    useEffect(() => {
        return handle(event, refresh)
    }, [])
    return refresh
}

export function useSaveRefresh(...fns) {
    const { save } = useBoundContext()
    return useRefresh(save, ...fns)
}

export function noThrottle() {}

export function trackRefresh() {}

export function useRefresh(...functions) {
    const [, refresh, currentId] = useCurrentState(incrementRefreshId)
    const running = useRef()
    const track = useContext(RefreshContext) || functions.includes(trackRefresh)
    if (track) {
        // eslint-disable-next-line no-console
        console.groupCollapsed('refresh')
        // eslint-disable-next-line no-console
        console.trace('stack')
        // eslint-disable-next-line no-console
        console.groupEnd()
    }
    const others = useRef([])
    const isLoaded = useRef(true)
    useEffect(() => {
        return () => {
            isLoaded.current = false
        }
    }, [])

    let result = useMemo(() => {
        let result = () => {
            try {
                if (!isLoaded.current) return
                if (running.current) return
                functions = functions.flat(Infinity)

                running.current = !functions.includes(noThrottle)
                setTimeout(() => (running.current = false), 1)
                for (let fn of functions) {
                    try {
                        if (typeof fn === 'function') fn()
                    } catch (e) {
                        console.error(e)
                    }
                }
                const nextId = incrementRefreshId()
                refresh(nextId)
                others.current.forEach((c) => c(nextId))
            } catch (e) {
                console.error(e)
            }
        }

        result.functions = functions.compact(true)
        result.debounce = function (...params) {
            let debounced = debounce(result, ...params)
            debounced.functions = result.functions
            decorate(debounced)
            debounced.planRefresh = debounce(debounced.planRefresh, ...params)
            return debounced
        }
        return result
    }, [])
    lastRefresh = result

    function decorate(refreshFunction) {
        Object.assign(refreshFunction, {
            execute: (...params) => {
                for (let fn of [...params, ...functions].compact()) {
                    try {
                        typeof fn === 'function' && fn()
                    } catch (e) {
                        console.error(e)
                    }
                }
            },
            planRefresh: (fn) => {
                return (...params) => {
                    if (false !== fn(...params)) {
                        refreshFunction()
                    }
                }
            },
            always: () => {
                refreshFunction.id = refreshId++
                refreshFunction()
            },
            useRefresh: () => {
                // eslint-disable-next-line react-hooks/rules-of-hooks
                const [, setValue] = useState(0)
                // eslint-disable-next-line react-hooks/rules-of-hooks
                useEffect(() => {
                    others.current.push(setValue)
                    return () => {
                        others.current = others.current.filter((f) => f !== setValue)
                    }
                })
            },
        })

        refreshFunction.id = currentId()
        return refreshFunction
    }

    return decorate(result)
}
