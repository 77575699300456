const HookedEvents = require('packages/alcumus-local-events/hooked-events')
const isObject = require('lodash/isObject')
const isString = require('lodash/isString')
const forEach = require('lodash/forEach')
const sortBy = require('lodash/sortBy')
const isArray = require('lodash/isArray')

const DEFAULT = 'default'

class Cancel extends Error {
    constructor(message) {
        super(message)
        Error.captureStackTrace(this, Cancel)
    }
}

function ensureArray(param) {
    return Array.isArray(param) ? param : [param]
}

let availableBehaviours = {}

function doNotMapState(state) {
    return state
}

const _api = {}

const DUMMY = {}

const fixups = new Map()

let events = new HookedEvents({ wildcard: true, maxListeners: 1000, delimiter: '.' })

const mapParent = new WeakMap()
const mapChild = new WeakMap()
const derivedInstance = Symbol('derivedInstance')

function isScanParent(child, parent) {
    let former
    do {
        if (child === parent) return true
        former = child
        child = mapParent.get(child) || child
    } while (former !== child)
    return false
}

function isScanChild(child, parent) {
    let former
    do {
        if (child === parent) return true
        former = child
        child = mapChild.get(child) || child
    } while (former !== child)
    return false
}

function isRelated(parent, child) {
    if (mapParent.get(child) === parent) return 'existing'
    return (
        isScanParent(parent, child) ||
        isScanChild(parent, child) ||
        isScanParent(child, parent) ||
        isScanChild(child, parent)
    )
}

function relate(parent, child) {
    const relationship = isRelated(parent, child)
    if (relationship && relationship !== 'existing') {
        throw new Error('Cyclic relationship')
    }

    mapInstances.delete(parent)
    mapInstances.delete(child)
    mapParent.set(child, parent)
    mapChild.set(parent, child)
}

const mapInstances = new WeakMap()

function isLocalInstance(thing) {
    return !!thing[derivedInstance]
}

function getAssociatedInstances(target) {
    if (!target) return []
    let source = (target = getAssociatedDocument(target))
    if (mapInstances.has(target)) return mapInstances.get(target)
    const list = []
    do {
        list.push(target)
        source = target
        target = mapChild.get(target) || target
    } while (target !== source)
    const items = list.filter((c) => c._behaviours).map((c) => c._behaviours.localInstances)
    let output = items.reduce((c, a, index) => {
        for (let [nameOfBehaviour, listOfInstances] of Object.entries(a)) {
            c[nameOfBehaviour] = c[nameOfBehaviour] || []
            c[nameOfBehaviour].push(
                ...listOfInstances.map((instance) => {
                    instance[derivedInstance] = index > 0
                    return instance
                })
            )
        }
        return c
    }, {})
    mapInstances.set(target, output)
    return output
}

function getAssociatedDocument(target) {
    let source
    do {
        source = target
        target = mapParent.get(target) || target
    } while (target !== source)
    return target
}

function registerApiCall(...name) {
    name = name.flat(Infinity)
    name.forEach((name) => {
        _api[name] =
            _api[name] ||
            function (...params) {
                return this._ref.sendMessage(name, ...params)
            }
    })
}

function reset() {
    methodBase = {}
    bases = {}
    events.removeAllListeners()
    availableBehaviours = {}
}

function isEqual(test, item) {
    let isSame = true
    forEach(item, (value, key) => {
        isSame = isSame && test[key] == value
    })
    return isSame
}

function stringify(source, replacer, space) {
    const data = { source, replacer, space }
    events.emit('behaviour.stringify', data)
    data.result = JSON.stringify(data.source, data.replacer, data.space)
    events.emit('behaviour.stringified', data)
    return data.result
}

function parse(source, reviver) {
    const data = { source, reviver }
    const fixUps = []
    events.emit('behaviour.parse', data)
    data.result = JSON.parse(data.source, (key, value) => {
        if (isObject(value) && value._behaviours) {
            fixUps.push(() => initialize(value))
        }
        if (data.reviver) {
            data.reviver(key, value)
        }
        return value
    })
    events.emit('behaviour.parsed.pre', data)
    fixUps.forEach((fixupFunction) => fixupFunction())
    events.emit('behaviour.parsed.post', data)
    return data.result
}

function resolveMethods(definition, allMethods) {
    let methods = definition.methods || {}
    events.emit('default-methods', methods, definition)
    Object.entries(methods).forEach(function ([key, fn]) {
        if (typeof methods[key] !== 'function') {
            throw new Error('Only functions may be methods')
        }
        allMethods.set(key, [...(allMethods.get(key) || []), fn])
    })
    return allMethods
}

let methodBase = {}
let bases = {}

function noop() {}

const noCall = Symbol('noCall')

function register(name, definition, allowMultiple) {
    if (!isObject(definition)) {
        throw new Error('The definition must be an object')
    }
    if (!isString(name)) {
        throw new Error('The name must be a string')
    }
    if (availableBehaviours[name] && !allowMultiple) {
        throw new Error(`Behaviour '${name}' already registered`)
    }
    let defaults = definition.defaults
    if (defaults) {
        Object.keys(defaults).forEach(function (key) {
            if (typeof defaults[key] === 'function') {
                throw new Error('Defaults must not include functions')
            }
        })
    }
    const baseMethods = (methodBase[name] = methodBase[name] || new Map())
    const base = (bases[name] = bases[name] || { _name: name })
    resolveMethods(definition, baseMethods)
    let states = (definition.states = definition.states || DUMMY)
    Object.keys(states).forEach(function (stateName) {
        let state = states[stateName]
        Object.entries(state.methods || DUMMY).forEach(function ([key, fn]) {
            callStateMethod._name = noCall
            if (typeof fn !== 'function') {
                throw new Error('Only functions may be methods')
            }
            baseMethods.set(key, [...(baseMethods.get(key) || []), callStateMethod])

            function callStateMethod(...params) {
                if ((definition.mapState || doNotMapState).call(this, this.document.behaviours.state) === stateName) {
                    return fn.apply(this, params)
                } else {
                    return noCall
                }
            }
        })
    })
    for (let key of definition.calls || []) {
        registerApiCall(key)
    }
    Object.assign(base, defaults)
    for (let [name, allFns] of baseMethods.entries()) {
        registerApiCall(name)
        const functions = allFns.sort((a) => (a._name === noCall ? -1 : 0))
        if (defaults && defaults[name]) {
            throw new Error(`Member "${name}" already declared`)
        }
        base[name] = function (...params) {
            let called = false
            let seen = false
            let i = 0
            const self = this
            let result = undefined
            return callNext()

            function callNext() {
                if (i >= functions.length) return result

                let fn = functions[i++]
                if (fn._name === noCall) {
                    seen = true
                } else if (called) {
                    return result
                }
                result = fn.apply(self, params)
                called = called || (fn._name === noCall && result !== noCall)
                if (result && result.then) {
                    return result.then(() => callNext())
                } else {
                    return callNext()
                }
            }
        }
    }
    let list = (availableBehaviours[name] = availableBehaviours[name] || [])
    list.push(definition)
    const fixes = fixups.get(name) || []
    fixups.set(name, [])
    for (let fix of fixes) {
        fix()
    }
}

function tryToCall(behaviourName, instance, method, ...params) {
    if (events.emit.apply(events, [`${behaviourName}.${method}`, instance, ...params])) {
        let toCallBehaviours = ensureArray(availableBehaviours[behaviourName])
        let called = false
        for (let behaviour of toCallBehaviours) {
            if (behaviour) {
                let fn = behaviour[method]
                if (fn && (!fn.once || !called)) {
                    called = true
                    return fn.apply(instance, params)
                }
            }
        }
    }
}

const temporary = Symbol('temporary')

function initialize(target, addBehaviours = {}) {
    if (target.behaviours) {
        target.sendMessage('initialized')
        for (let [behaviour, instances] of Object.entries(addBehaviours)) {
            instances = ensureArray(instances)
            for (let instance of instances) {
                target.behaviours.add(behaviour, instance)
            }
        }
        return
    }
    let compiled = new Map()
    let temporaryInstances = []
    let reasons = []
    const config = Object.assign(
        {
            instances: {},
            _state: '',
            _: sendMessageOnTarget,
            sendMessage: sendMessageOnTarget,
            sendMessageAsync,
            destroy,
            add,
            remove,
            setState,
        },
        target._behaviours
    )
    const instances = config.instances
    let behaviours = (target._behaviours = Object.defineProperties(config, {
        allInstances: {
            get() {
                return getAssociatedInstances(target)
            },
        },
        localInstances: {
            get() {
                return instances
            },
        },
        behaviours: {
            get() {
                return target._behaviours
            },
        },
        reasons: {
            get() {
                return reasons
            },
        },
        state: {
            get() {
                return behaviours._state || DEFAULT
            },
            set(endState) {
                endState = endState || DEFAULT
                let startState = behaviours.state
                if (startState === endState) return
                let data = { canChange: true, startState, endState, reasons: [] }
                forEachBehaviour((instance, behaviour) => {
                    let mappingFunction = (behaviour.mapState || doNotMapState).bind(instance)
                    let behaviourStart = mappingFunction(startState)
                    let behaviourEnd = mappingFunction(endState)
                    let oldState = (behaviour.states || DUMMY)[behaviourStart]
                    let newState = (behaviour.states || DUMMY)[behaviourEnd]
                    if (oldState && oldState.canExit) {
                        oldState.canExit.call(instance, data)
                    }
                    if (newState && data.canChange && newState.canEnter) {
                        newState.canEnter.call(instance, data)
                    }
                })
                reasons = data.reasons
                if (!data.canChange) return
                reasons = []
                delete data.canChange
                forEachBehaviour((instance, behaviour) => {
                    let mappingFunction = (behaviour.mapState || doNotMapState).bind(instance)
                    let behaviourStart = mappingFunction(startState)
                    let oldState = (behaviour.states || DUMMY)[behaviourStart]
                    if (oldState && oldState.exit) {
                        let result = oldState.exit.call(instance, data)
                        instance._ready = result && result.then ? result : null
                    } else {
                        instance._ready = null
                    }
                })
                behaviours._state = endState
                behaviours.sendMessage('stateChanged', data)
                events.emit('stateChanged', target, endState, data)
                forEachBehaviour((instance, behaviour) => {
                    let mappingFunction = (behaviour.mapState || doNotMapState).bind(instance)
                    let behaviourEnd = mappingFunction(endState)
                    let newState = (behaviour.states || DUMMY)[behaviourEnd]
                    if (newState && newState.enter) {
                        if (instance._ready) {
                            instance._ready = instance._ready.then(() =>
                                Promise.resolve(newState.enter.call(instance, data))
                            )
                        } else {
                            instance._ready = Promise.resolve(newState.enter.call(instance, data))
                        }
                    }
                })
                behaviours.ready = Promise.all(
                    getAllInstances()
                        .map((pair) => pair.instance._ready)
                        .concat([behaviours.sendMessageAsync('stateChangeComplete', data)])
                )
            },
        },
    }))

    const instanceBase = {
        $: target,
        _: sendMessageOnTarget,
        sendMessage: sendMessageOnTarget,
        sendMessageAsync,
        destroy() {
            behaviours.remove(this._name, this)
        },
    }

    Object.defineProperties(instanceBase, {
        document: {
            get() {
                return getAssociatedDocument(target)
            },
        },
    })

    function sendMessageOnTarget(...params) {
        return target && target.sendMessage && target.sendMessage.apply(target, params)
    }

    if (target._behaviours) {
        forEach(Object.assign({}, target._behaviours.localInstances, addBehaviours), function (list, type) {
            list = isArray(list) ? list : [list]
            list.forEach(function (instance) {
                behaviours.add(type, instance)
            })
        })
    }

    const api = Object.create(_api)
    api._ref = target

    Object.defineProperties(target, {
        behaviours: {
            get() {
                return behaviours
            },
        },
        api: {
            get() {
                return api
            },
        },
        setState: {
            get() {
                return behaviours.setState
            },
        },
        _: {
            get() {
                return sendMessage
            },
        },
        sendMessage: {
            get() {
                return sendMessage
            },
        },
        sendMessageAsync: {
            get() {
                return sendMessageAsync
            },
        },
        [temporary]: {
            get() {
                return temporaryInstances
            },
        },
    })

    target.sendMessage('initialized')
    return target

    function setState(endState) {
        behaviours.state = endState
        return behaviours.ready
    }

    async function remove(behaviourName, instance = null) {
        compiled.clear()
        mapInstances.delete(target)
        if (!instance) {
            let current = behaviours.localInstances[behaviourName] || []
            current.forEach((instance) => {
                tryToCall(behaviourName, instance, 'destroy')
            })
            delete behaviours.localInstances[behaviourName]
        } else {
            let list = (behaviours.localInstances[behaviourName] = behaviours.localInstances[behaviourName] || [])
            let instanceIndex = list.indexOf(instance)
            let temporaryIndex = temporaryInstances.findIndex((item) => item.instance === instance)
            if (instanceIndex !== -1 || temporaryIndex !== -1) {
                tryToCall(behaviourName, instance, 'destroy')
            }
            list.splice(instanceIndex, 1)
            temporaryInstances.splice(temporaryIndex, 1)
            if (!list.length) delete behaviours.localInstances[behaviourName]
        }
    }

    function destroy() {
        compiled.clear()
        mapInstances.delete(target)
        let instances = behaviours.localInstances
        for (let type in instances) {
            behaviours.remove(type)
        }
        temporaryInstances.forEach((pair) => {
            tryToCall(pair.type, pair.instance, 'destroy')
        })
        temporaryInstances.length = 0
    }

    function getAllInstances() {
        let instances = temporaryInstances.slice(0)
        forEach(getAssociatedInstances(target), function (list, type) {
            list.forEach((instance) => {
                instances.push({ type, instance })
            })
        })
        instances = sortBy(
            instances.filter((t) => availableBehaviours[t.type]),
            (pair) => pair.instance._priority || 100
        )
        return instances
    }

    function forEachBehaviour(cb) {
        forEach(getAssociatedInstances(target), function (list, type) {
            let toCallBehaviours = ensureArray(availableBehaviours[type])
            for (let availableBehaviour of toCallBehaviours) {
                if (availableBehaviour) {
                    list.forEach((instance) => {
                        cb(instance, availableBehaviour)
                    })
                }
            }
        })
    }

    function add(behaviourName, instance, temporary = false) {
        compiled.clear()
        mapInstances.delete(target)
        instance = instance || {}
        if (availableBehaviours[behaviourName]) {
            fixup()
        } else {
            const data = { availableBehaviour: {}, behaviourName, instance }
            //Allow an event to modify the behaviour and instance
            events.emit(`behaviour.add.${behaviourName}`, data)
            if (!data.availableBehaviour && instance._mandatory !== false) {
                fixups.set(behaviourName, [...(fixups.get(behaviourName) || []), fixup])
                return
            }
            register(behaviourName, data.availableBehaviour, true)
            fixup()
        }
        function fixup() {
            let existing = availableBehaviours[behaviourName]
            const prototype = Object.create(instanceBase)
            Object.assign(prototype, bases[behaviourName])
            Object.setPrototypeOf(instance, prototype)
            for (let availableBehaviour of existing) {
                if (availableBehaviour.mandatory === false) {
                    instance._mandatory = false
                }

                let newState = (availableBehaviour.states || DUMMY)[behaviours.state] || DUMMY
                if (newState.enter) {
                    let data = { startState: null, endState: behaviours.state }
                    newState.enter.call(instance, data)
                }

                if (availableBehaviour.requires) {
                    if (Array.isArray(availableBehaviour.requires)) {
                        forEach(availableBehaviour.requires, function (type) {
                            if (!behaviours.localInstances[type]) {
                                behaviours.add(type, {})
                            }
                        })
                    } else {
                        forEach(availableBehaviour.requires, function (list, type) {
                            list = Array.isArray(list) ? list : [list]
                            list.forEach(function (instance) {
                                if (
                                    !instance.document &&
                                    (!behaviours.localInstances[type] ||
                                        -1 ===
                                            behaviours.localInstances[type].findIndex((item) =>
                                                isEqual(item, instance)
                                            ))
                                ) {
                                    behaviours.add(type, instance)
                                }
                            })
                        })
                    }
                }
            }
        }

        function notify() {
            tryToCall(behaviourName, instance, 'initialize')
            setTimeout(function () {
                tryToCall(behaviourName, instance, 'postInitialize')
            }, 0)
        }

        if (!temporary && availableBehaviours.temporary !== true) {
            let list = (behaviours.localInstances[behaviourName] = behaviours.localInstances[behaviourName] || [])
            if (list.indexOf(instance) === -1) {
                notify()
                list.push(instance)
            }
        } else {
            temporaryInstances.push({ type: behaviourName, instance })
            notify()
        }
        sendMessageOnTarget('initialized')
        return instance
    }

    function noop(v) {
        return v
    }

    function sendMessage(message, ...params) {
        let fn = compiled.get(message)
        if (fn) {
            if (message === 'fireEvent') {
                console.log('Fire event was compiled')
            }
            return fn(...params)
        }
        let chain = []
        // Deprecated for performance
        // events.emit('willSendMessage', { target, message, params, context })
        let toCall = getAllInstances()
        toCall.forEach(({ type, instance }) => {
            let fn = instance[message]
            if (instance.onMessage) {
                chain.push((result, p) => instance.onMessage.apply(instance, [message, ...p]))
            }
            if (typeof fn === 'function') {
                chain.push((result, params) => fn.apply(instance, params, [...params, result]))
            }
        })
        if (chain.length === 0 && globalThis.DEBUG_MISSING_MESSAGES) {
            console.log('MISSING >>>', message)
        }
        fn = function (...params) {
            if (params.length === 0) params.push([])
            let result = undefined
            let promises = []
            let count = 0
            for (let step of chain) {
                try {
                    count++
                    result = step(result, params)
                    if (result && result.then) {
                        promises.push(result)
                    }
                } catch (e) {
                    if (!(e instanceof Cancel)) throw e
                    break
                }
            }
            params.called = count
            params.result = result
            if (promises.length) {
                let result = Promise.all(promises).then((r) =>
                    params.length >= 1
                        ? Array.isArray(params[0])
                            ? Object.assign(params[0], {
                                  count,
                                  result,
                              })
                            : params
                        : params
                )
                return result
            } else {
                return params.length >= 1
                    ? Array.isArray(params[0])
                        ? Object.assign(params[0], {
                              count,
                              result,
                          })
                        : params
                    : params
            }
        }
        compiled.set(message, fn)
        if (message === 'fireEvent') {
            console.log('Fire event was NEW', chain.length)
        }

        return fn(...params)
    }

    async function sendMessageAsync(message, ...params) {
        if (params.length === 0) params.push([])
        let promises = []
        let count = 0
        let toCall = getAllInstances()
        let messageParameters = [message, ...params]
        await Promise.all(
            toCall.map(async ({ type, instance }) => {
                let fn = instance[message]
                if (instance.onMessage) {
                    promises.push(Promise.resolve(instance.onMessage.apply(instance, messageParameters)))
                }
                if (typeof fn === 'function') {
                    count++
                    promises.push(Promise.resolve(fn.apply(instance, params)))
                }
            })
        )
        params.called = count
        const result = (await Promise.all(promises)).slice(-1)[0]
        if (count === 0 && globalThis.DEBUG_MISSING_MESSAGES) {
            console.log('MISSING >>>', message, params)
        }

        return params.length >= 1
            ? Array.isArray(params[0])
                ? Object.assign(params[0], {
                      count,
                      result,
                  })
                : params
            : params
    }
}

module.exports = {
    events,
    availableBehaviours,
    registerApiCall,
    register,
    initialize,
    reset,
    stringify,
    parse,
    Cancel,
    relate,
    isLocalInstance,
}
